import React, { useEffect, useState } from 'react';
import { Box, Popover, Tab, Tabs, List, ListItem, Typography } from '@mui/material';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ChatMessage } from "../../../../Models/Chat/ChatMessage";
import { UserService } from "../../../../Services/UserService";
import {User} from "../../../../Models/User";

interface ChatReadPopupProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  message: ChatMessage;
  onClose: () => void;
}

/**
 * チャット既読ポップアップ
 * @param props
 * @constructor
 */
export const ChatReadPopup = (props: ChatReadPopupProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    selectedTab: 0,
    readUsers: [] as User[],
    unreadUsers: [] as User[]
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 表示時
   */
  useEffect(() => {
    if (!props.open) return;

    const allUsers = UserService.getInstance().getAllUsers();
    // 投稿者を除外
    const allUsersExceptOwner = allUsers.filter(user => user.id !== props.message.user_id);

    // 既読ユーザー
    const readUsers = props.message.reads?.map(
    read => (UserService.getInstance().getUserById(read.user_id ?? 0) ?? new User())) || [];

    // 未読ユーザー
    const unreadUsers = allUsersExceptOwner.filter(user =>
      !props.message.reads?.some(read => read.user_id === user.id )
    );

    updateState({ readUsers, unreadUsers });
  }, [props.message, props.open]);

  /**
   * タブ変更
   * @param event
   * @param newValue
   */
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    updateState({ selectedTab: newValue });
  };

  /**
   * 描画
   */
  const userList = state.selectedTab === 0 ? state.readUsers : state.unreadUsers;
  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      sx={{ overflow: 'auto', maxWidth: '100%' }}
    >
      <Box sx={{ minWidth: '300px' }}>
        <Tabs
          value={state.selectedTab}
          onChange={handleTabChange}
          aria-label="既読・未読タブ"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="既読" />
          <Tab label="未読" />
        </Tabs>
        <List sx={{ minHeight: "300px", overflow: 'auto' }}>
          {userList.map((user, index) => (
            <ListItem key={index}>
              <Box display="flex" alignItems="center" gap={1}>
                <AccountCircleIcon fontSize="medium" style={{ color: UserService.getInstance().getUserColor(user.id ?? 0) }} />
                <Typography variant="body1">{user.name}</Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Popover>
  );
};
