import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import { ChatMessage } from "../../../../Models/Chat/ChatMessage";
import { UserService } from "../../../../Services/UserService";
import {Constants} from "../../../../Common/Constants";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {Typography} from "@mui/material";

interface ReactionUserInfo {
  userId: number;
  userName: string;
}

interface ChatReactionPopupProps {
  open: boolean;
  anchorEl: HTMLElement | null; // anchorPositionからanchorElへ変更
  message: ChatMessage;
  onClose: () => void;
}

/**
 * チャットリアクションポップアップ
 * @param props
 * @constructor
 */
export const ChatReactionPopup = (props: ChatReactionPopupProps) => {

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    selectedTab: 0,
    reactionNumbers: [] as number[],
    userList: [] as ReactionUserInfo[],
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 初回
   */
  useEffect(() => {
    const numbers = Array.from(
      new Set(
        props.message.reactions
          ?.map(reaction => reaction.reaction_number)
          .filter((number): number is number => number !== undefined)
      )
    ).sort((a, b) => a - b);
    updateState({ reactionNumbers: numbers });
  }, [props.message.reactions]);

  /**
   * リアクション選択
   */
  useEffect(() => {
    if ( state.reactionNumbers.length === 0 ) {
      return;
    }

    const selectedReactionNumber = state.reactionNumbers[state.selectedTab];
    const users = props.message.reactions
      ?.filter(reaction => reaction.reaction_number === selectedReactionNumber)
      .map(reaction => ({
        userId: reaction.user_id ?? 0,
        userName: UserService.getInstance().getUserName(reaction.user_id ?? 0) ?? '不明',
      })) ?? [];
    updateState({ userList: users});

  }, [state.selectedTab, state.reactionNumbers, props.message.reactions]);

  /**
   * タブ変更時
   * @param event
   * @param newValue
   */
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    updateState({selectedTab: newValue});
  };

  /**
   * 描画
   */
  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'top', // アンカー要素の下部を基準に
        horizontal: 'center', // アンカー要素の中央を基準に
      }}
      transformOrigin={{
        vertical: 'bottom', // ポップアップの上部を変換の原点に
        horizontal: 'center', // ポップアップの中央を変換の原点に
      }}
      onClose={props.onClose}
      sx={{ overflow: 'auto' }} // スクロール可能にする
    >
      <Box sx={{ minWidth: '300px' }}> {/* コンテンツの幅を指定 */}
        <Tabs
          value={state.selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {/* リアクションタブを動的に生成 */}
          {state.reactionNumbers?.map((reactionNumber, index) => (
            <Tab
              key={index}
              label={Constants.CHAT_REACTION[reactionNumber ?? 0] ?? reactionNumber?.toString()}
              sx={{fontSize: Constants.CHAT_REACTION_FONT_SIZE}}
            />
          ))}
        </Tabs>
        <List sx={{ minHeight: '300px', overflow: 'auto' }}> {/* リストの最大高さとスクロールを設定 */}
          {state.userList.map((user, index) => (
            <ListItem key={index}>
              <Box display="flex" alignItems="center" gap={1}>
                <Box>
                  <AccountCircleIcon fontSize="medium" style={{ color: UserService.getInstance().getUserColor(user.userId ?? 0) }} />
                </Box>
                <Typography variant="body1">{user.userName}</Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Popover>
  );
};
