// ActionPanelVisibilityService.ts

export class ActionPanelVisibilityService {
  private static instance: ActionPanelVisibilityService;
  private activeMessageId: number | null = null;
  private listeners: Function[] = [];

  private constructor() {}

  public static getInstance(): ActionPanelVisibilityService {
    if (!ActionPanelVisibilityService.instance) {
      ActionPanelVisibilityService.instance = new ActionPanelVisibilityService();
    }
    return ActionPanelVisibilityService.instance;
  }

  public toggleMessageIdVisibility(messageId: number): void {
    if (this.activeMessageId === messageId) {
      this.activeMessageId = null;
    } else {
      this.activeMessageId = messageId;
    }
    this.notifyListeners();
  }

  public setMessageIdVisibility(messageId: number, visible: boolean): void {
    if (visible) {
      this.activeMessageId = messageId;
    } else if (this.activeMessageId === messageId) {
      this.activeMessageId = null;
    }
    this.notifyListeners();
  }

  public isMessageIdVisible(messageId: number): boolean {
    return this.activeMessageId === messageId;
  }

  public subscribe(listener: Function): void {
    this.listeners.push(listener);
  }

  public unsubscribe(listener: Function): void {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  private notifyListeners(): void {
    this.listeners.forEach(listener => listener());
  }
}
