/**
 * 仕入れ価格情報
 */
export class ProcurementPrice {
  mall_no?: number;
  procurement_price1?: number;
  procurement_price2?: number;

  constructor(params: Partial<ProcurementPrice> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<ProcurementPrice>): ProcurementPrice {
    return new ProcurementPrice({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): ProcurementPrice {
    return new ProcurementPrice({
      ...json
    });
  }

  static fromJson(jsonString: string): ProcurementPrice {
    const decoded = JSON.parse(jsonString);
    return ProcurementPrice.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  getDataGridValues(): string[] {
    return [
      this.mall_no?.toString() || '',
      this.procurement_price1?.toString() || '',
      this.procurement_price2?.toString() || ''
    ];
  }
}
