export class CalendarColorItem {
  number?: number;
  color?: string;
  displayName?: string;

  constructor(params: Partial<CalendarColorItem> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<CalendarColorItem>): CalendarColorItem {
    return new CalendarColorItem({
      ...this,
      ...params,
    });
  }

  static fromMap(json: { [key: string]: any }): CalendarColorItem {
    return new CalendarColorItem(json);
  }

  static fromJson(jsonString: string): CalendarColorItem {
    const decoded = JSON.parse(jsonString);
    return CalendarColorItem.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      number: this.number,
      color: this.color,
      displayName: this.displayName,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }
}
