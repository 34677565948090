import {Box, Typography} from "@mui/material";

interface ChatTagToAllProps {

}

/**
 * チャットタグ TO ALL
 * @param props
 * @constructor
 */
export const ChatTagToAll = () => {
  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center', mb: 1, verticalAlign: 'top'}}>
      <Box sx={{ alignItems: 'center', backgroundColor: '#7fffd4', borderRadius: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', ml:0.5, mr:0.5 }}>
          TO ALL
        </Typography>
      </Box>
    </Box>
  );
};

