import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { ChatMessage } from "../../../../Models/Chat/ChatMessage";
import {ChatReadPopup} from "./ChatReadPopup";
import {Typography} from "@mui/material";

interface ChatReadPanelProps {
  message: ChatMessage;
}

/**
 * 既読パネル
 * @param props
 * @constructor
 */
export const ChatReadPanel = (props: ChatReadPanelProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    popupOpen: false,
    anchorEl: null as HTMLElement | null,
  });
  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * ポップアップを開く
   */
  const handleOpenPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    updateState({ anchorEl: event.currentTarget, popupOpen: true });
  };
  /**
   * ポップアップを閉じる
   */
  const handleClosePopup = () => {
    updateState({ popupOpen: false, anchorEl: null });
  };

  /**
   * 描画
   */
  const readCount = props.message.reads?.length ?? 0;
  return (
    <div>
      <Button onClick={handleOpenPopup}>
        <Typography variant={"body2"}>
          既読: {readCount}
        </Typography>
      </Button>
      {state.popupOpen && (
        <ChatReadPopup
          open={state.popupOpen}
          message={props.message}
          onClose={handleClosePopup}
          anchorEl={state.anchorEl}
        />
      )}
    </div>
  );
};
