import {UnitProduct} from "./UnitProduct";
import {Tag} from "../Tag/Tag";

/**
 * 商品
 */
export class Product {
  id?: number;
  stock_code?: string;
  unreceived_stock?: number;
  mall_flag?: number;
  free_note?: string;
  stock_pattern_id?: number;
  price_pattern_id?: number;
  procurement_lower?: number;
  procurement_upper?: number;
  left_note?: string;
  right_note?: string;
  created_at?: Date;
  updated_at?: Date;

  unit_products?: UnitProduct[] = [];
  tags?: Tag[] = [];

  constructor(params: Partial<Product> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<Product>): Product {
    return new Product({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): Product {
    return new Product({
      ...json,
      unit_products: json.unit_products ? json.unit_products.map((up: any) => UnitProduct.fromMap(up)) : undefined,
      tags: json.tags ? json.tags.map((t: any) => Tag.fromMap(t)) : undefined,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): Product {
    const decoded = JSON.parse(jsonString);
    return Product.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      unit_products: this.unit_products ? this.unit_products.map(up => up.toMap()) : undefined,
      tags: this.tags ? this.tags.map(t => t.toMap()) : undefined,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  // こちらはDataGridの値として適切な項目を選択していますが、変更する必要がある場合があります。
  getDataGridValues(): string[] {
    return [
      this.id?.toString() || '',
      this.stock_code || '',
      this.unreceived_stock?.toString() || '',
      // ... その他の項目も同様に追加 ...
    ];
  }

  updateUnitProduct(unitProduct: UnitProduct) {
    const index = this.unit_products?.findIndex(
      up => up.stock_code === unitProduct.stock_code && up.unit_code === unitProduct.unit_code
    );

    if (index !== undefined && index !== -1 && this.unit_products) {
      this.unit_products[index] = unitProduct;
    }
  }

}
