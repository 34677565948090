import {HttpServiceBase} from "../HttpServiceBase";
import {PricePattern} from "../../Models/Pattern/PricePattern";
import {LogUtils} from "../../Common/LogUtils";
import {EventService} from "../System/EventService";
import {CommonUtils} from "../../Common/CommonUtils";
import axios from "axios";
import {EcmgApiError} from "../../Common/EcmgApiError";

/**
 * 価格パターンサービス
 */
export class PricePatternService extends HttpServiceBase {
  private static instance: PricePatternService = new PricePatternService();
  private pricePatternMap: Map<number, PricePattern> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): PricePatternService {
    return this.instance;
  }

  /**
   * 初期化
   */
  async init(): Promise<void> {
    LogUtils.d("Initializing PricePatternService");
    await this._loadPricePatterns();

    // 価格パターン更新イベント
    const eventService = EventService.getInstance();
    eventService.onEvent(EventService.EVENT_UPDATE_PRICE_PATTERNS, async () => {
      await this._loadPricePatterns();
    });
  }

  /**
   * 価格パターンを読み込む
   * @private
   */
  private async _loadPricePatterns(): Promise<void> {
    try {
      const url = "api/price_patterns";
      const axios = await this.getAxios();

      const response = await axios.get(url, this.makeAuthorizeOption());
      LogUtils.d(response.toString());

      this.setData(response.data);

    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * データ設定
   * @param data
   */
  public setData(data: any): void {
    this.pricePatternMap.clear();
    if (data && Array.isArray(data)) {
      data.forEach(patternData => {
        const pattern = PricePattern.fromMap(patternData);
        if (pattern.id !== undefined) {
          this.pricePatternMap.set(pattern.id, pattern);
        }
      });
    }
  }

  /**
   * IDから価格パターンを取得する
   * @param id
   */
  getPatternById(id: number): PricePattern | undefined {
    return this.pricePatternMap.get(id);
  }

  /**
   * 価格パターンを全て取得する
   */
  getAllPatterns(): PricePattern[] {
    return Array.from(this.pricePatternMap.values());
  }


  /**
   * 新しい価格パターンを作成する
   * @param file アップロードするCSVファイル
   */
  public async createPattern(file: File): Promise<string> {
    LogUtils.d();

    const formData = new FormData();
    formData.append('csv_file', file);

    const options = await this.makeAuthorizeOption({
      'Content-Type': 'multipart/form-data'
    });

    // 拡張子を除いたファイル名
    const fileName = CommonUtils.removeExtension(file.name);
    try {
      const response = await axios.post(
        `api/price-patterns?name=${fileName}`,
        formData,
        options
      );
      LogUtils.d(response.toString());

      // 新しい価格パターンを読み込む
      await this._loadPricePatterns();
      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_PRICE_PATTERNS);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 価格パターンを更新する
   * @param patternId 価格パターンID
   * @param file 更新するCSVファイル
   */
  public async updatePattern(patternId: number, file: File): Promise<string> {
    LogUtils.d();

    const formData = new FormData();
    formData.append('csv_file', file);

    const options = await this.makeAuthorizeOption({
      'Content-Type': 'multipart/form-data'
    });

    // 拡張子を除いたファイル名
    const fileName = CommonUtils.removeExtension(file.name);
    try {
      const response = await axios.post(
        `api/price-patterns/${patternId}?name=${fileName}`,
        formData,
        options
      );
      LogUtils.d(response.toString());

      // 新しい価格パターンを読み込む
      await this._loadPricePatterns();
      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_PRICE_PATTERNS);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 価格パターンを削除する
   * @param patternId 価格パターンID
   */
  public async deletePattern(patternId: number): Promise<string> {
    LogUtils.d();

    try {
      const axios = await this.getAxios(true);
      const response = await axios.delete(
        `api/price-patterns/${patternId}`,
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 新しい価格パターンを読み込む
      await this._loadPricePatterns();
      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_PRICE_PATTERNS);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
}
