import { HttpServiceBase } from "../HttpServiceBase";
import {TagCategory} from "../../Models/Tag/TagCategory";
import {LogUtils} from "../../Common/LogUtils";
import {EventService} from "../System/EventService";
import {EcmgApiError} from "../../Common/EcmgApiError";

/**
 * タグ分類サービス
 */
export class TagCategoryService extends HttpServiceBase {
  private static instance: TagCategoryService = new TagCategoryService();
  private tagCategoryMap: Map<number, TagCategory> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): TagCategoryService {
    return this.instance;
  }

  async init(): Promise<void> {
    LogUtils.d("Initializing TagCategoryService");
    await this._loadTagCategories();
  }

  /**
   * タグ分類データ読み込み
   * @private
   */
  private async _loadTagCategories(): Promise<void> {
    try {
      const url = "api/tag_categories";
      const axios = await this.getAxios();

      const response = await axios.get(url, this.makeAuthorizeOption());
      LogUtils.d(response.toString());

      this.setData(response.data);

    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * データ設定
   * @param data
   */
  public setData(data: any): void {
    this.tagCategoryMap.clear();
    if (data && Array.isArray(data)) {
      data.forEach(tagCategoryData => {
        const tagCategory = TagCategory.fromMap(tagCategoryData);
        if (tagCategory.id !== undefined) {
          this.tagCategoryMap.set(tagCategory.id, tagCategory);
        }
      });
    }
  }

  /**
   * タグ分類データ取得
   * @param id
   */
  getTagCategoryById(id: number): TagCategory | undefined {
    return this.tagCategoryMap.get(id);
  }

  /**
   * すべてのタグ分類を取得
   */
  getAllTagCategories(): TagCategory[] {
    return Array.from(this.tagCategoryMap.values());
  }

  /**
   * タグ分類データ更新
   * @param tagCategory
   */
  public async updateTagCategory(tagCategory: TagCategory): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/tag_categories/${tagCategory.id}`,
        tagCategory,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      await this._loadTagCategories();

      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_TAG_CATEGORIES);

      return response.data.message;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * タグ分類追加
   * @param tagCategory
   */
  public async addTagCategory(tagCategory: TagCategory): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        'api/tag_categories',
        tagCategory,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());
      await this._loadTagCategories();

      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_TAG_CATEGORIES);

      return response.data.message;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * タグ分類削除
   * @param tagCategoryId
   */
  public async deleteTagCategory(tagCategoryId: number): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.delete(
        `api/tag_categories/${tagCategoryId}`,
        this.makeAuthorizeOption()
      );

      LogUtils.d(`Deleted tag category with ID ${tagCategoryId}`);
      await this._loadTagCategories();

      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_TAG_CATEGORIES);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * タグ分類移動
   * @param id
   * @param direction
   */
  public async moveTagCategory(id: number, direction: 'up' | 'down'): Promise<void> {
    try {
      const axios = await this.getAxios(true);
      await axios.put(
        `api/tag_categories/${id}/move`,
        { direction },
        this.makeAuthorizeOption()
      );

      await this._loadTagCategories();

      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_TAG_CATEGORIES);

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

}
