import { LogUtils } from "../../Common/LogUtils";
import { HttpServiceBase } from "../HttpServiceBase";
import { EcmgApiError } from "../../Common/EcmgApiError";
import { Store } from "../../Models/Store";

/**
 * エクスポートサービス
 */
export class ExportService extends HttpServiceBase {
  private static instance: ExportService = new ExportService();
  private storeMap: Map<number, Store> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): ExportService {
    return this.instance;
  }

  /**
   * エクスポート
   * @private
   */
  public async export(exportType: number, selectedOptions: string[], queryString?: string): Promise<void> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/export${queryString}`,
        {
          export_type: exportType,
          selectedOptions: selectedOptions
        },
        await this.makeAuthorizeOption({}, 'blob')
      );
      //LogUtils.d(response.toString());

      // ファイル名はヘッダーから取得
      const fileName = response.headers['content-disposition'].split('filename=')[1];

      // ファイルダウンロード
      let blob: Blob = new Blob(
        [response.data],
        {type: response.headers['content-type']}
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

}
