import React from 'react';
import {Box, Typography} from '@mui/material';
import {AIChatMessageBubble} from "./AIChatMessageBubble";
import {CommonUtils} from "../../../Common/CommonUtils";
import {AIChatMessage} from "../Model/AIChatMessage";
import {AIChatUser} from "./AIChatUser";

interface AIChatMessageItemProps {
  message: AIChatMessage;
  bubbleWidth?: number;
}

/**
 * AIチャットメッセージアイテム
 * @param props
 * @constructor
 */
export const AIChatMessageItem = (props: AIChatMessageItemProps) => {
  /**
   * 日時・既読情報
   */
  const dateTimeAndRead = (align: string) => {
    if ( props.message.sent_date_time == null ) {
      return '';
    }
    const dateString = CommonUtils.getDateStringJP(props.message.sent_date_time);
    const dateArray = dateString.split(' ');

    return (
      <Box display={"flex"} flexDirection={"column"}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Box display={"flex"} flexDirection={"column"} alignItems={align}>
            <Typography variant="body2">{dateArray[0]}</Typography>
            <Typography variant="body2">{dateArray[1]}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  /**
   * 他人のメッセージ
   */
  const otherMessage = () => {
    return (
      <Box display={"flex"} flexDirection={"column"}>
        <AIChatUser message={props.message} showName={true}/>
        <Box display={"flex"} flexDirection={"row"} alignItems={"end"}>
          <Box ml={4} />
          <AIChatMessageBubble
            message={props.message}
            minWidth={props.bubbleWidth}
            maxWidth={props.bubbleWidth}
          />
          <Box ml={1} />
          {dateTimeAndRead( "start")}
        </Box>
      </Box>
    );
  }

  /**
   * 自分のメッセージ
   */
  const myMessage = () => {
    return (
      <Box display={"flex"} flexDirection={"column"} alignItems={"end"}>
        <AIChatUser message={props.message} showName={false}/>
        <Box display={"flex"} flexDirection={"row"} alignItems={"end"}>
          {dateTimeAndRead( "end")}
          <Box mr={1} />
          <AIChatMessageBubble
            message={props.message}
            right={true}
            minWidth={props.bubbleWidth}
            maxWidth={props.bubbleWidth}
          />
          <Box mr={4} />
        </Box>
      </Box>
    );
  }

  /**
   * コンテンツ
   */
  const contents = () => {
    if ( props.message.isMyMessage() ){
      return myMessage();
    }
    else {
      return otherMessage();
    }
  }

  /**
   * 描画
   */
  return (
    <Box>
      {contents()}
    </Box>
  );
};

