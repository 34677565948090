import {StockPatternSetting} from "./StockPatternSetting";
import {Box} from "@mui/material";
import {PricePatternSetting} from "./PricePatternSetting";

/**
 * 自動調整設定
 * @constructor
 */
export const AutoAdjustSetting = () => {
  return (
    <div>
      <Box mt={2}></Box>
      <StockPatternSetting />
      <Box mt={4}></Box>
      <PricePatternSetting />
    </div>
  );
}
