import { StoreProduct } from "../../../../../Models/Product/StoreProduct";
import { UnitProduct } from "../../../../../Models/Product/UnitProduct";
import { Box } from "@mui/material";
import React from "react";
import { StoreService } from "../../../../../Services/StoreService";
import {Constants} from "../../../../../Common/Constants";
import {LogUtils} from "../../../../../Common/LogUtils";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {CommonUtils} from "../../../../../Common/CommonUtils";

interface UnitNameProps {
  unitProduct: UnitProduct;
  storeProducts: StoreProduct[];
  width: number;
}

/**
 * ユニット名
 * @param props
 * @constructor
 */
export const UnitName = (props: UnitNameProps) => {

  /**
   * ユニット名がクリックされたときの処理
   */
  const handleUnitNameClick = () => {
    props.storeProducts.forEach(storeProduct => {
      CommonUtils.openStoreProductUrl(storeProduct);
    });
  };

  /**
   * 描画
   */
  return (
    <Box
      onClick={handleUnitNameClick}
      sx={{
        width: props.width,
        textDecoration: props.unitProduct.is_min_unit === 1 ? 'underline' : 'none',
        fontWeight: props.unitProduct.is_min_unit === 1 ? 'bold' : 'normal',
        cursor: 'pointer'
      }}
      color={MainPageConstants.COLOR_DARK_GRAY}
    >
      {props.unitProduct.unit_name}
    </Box>
  );
};
