import React, {useEffect, useState} from 'react';
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography, Box
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {SalesChartCondition, SalesData} from "../../../../../Models/SalesData/SalesData";
import {SalesDailyHistoryTable} from "./SalesDailyHistoryTable";
import {SalesSummaryComponent} from "./SalesSummaryComponent";
import {SalesWeeklyHistoryTable} from "./SalesWeeklyHistoryTable";
import {SalesMonthlyHistoryTable} from "./SalesMonthlyHistoryTable";
import {SalesChartPanel} from "./Chart/SalesChartPanel";
import {LogUtils} from "../../../../../Common/LogUtils";


interface SalesDataDialogProps {
  open: boolean;
  onClose: () => void;
  salesData: SalesData;
  displayCode: string;
  productName: string;
  stockCode: string;
  mallNo?: number;
  mallProductId?: string;
}

/**
 * 販売推移データダイアログ
 * @constructor
 * @param props
 */
export const SalesDataDialog = (props: SalesDataDialogProps) => {
  /**
   * 定数
   */
  // リスト高さ
  const DAILY_LIST_HEIGHT = 800;
  const WEEKLY_LIST_HEIGHT = 300;
  const MONTHLY_LIST_HEIGHT = 300;
  const GRAPH_HEIGHT = 340;
  // 日次データ幅
  const DAILY_DATA_WIDTH = 540;
  // フルスクリーン幅
  const FULL_SCREEN_WIDTH = 1520;
  // フルスクリーン グラフ高さ
  const FULL_SCREEN_GRAPH_HEIGHT = 600;

  /**
   * useState
   */
  const [state, setState] = useState({
    isFullScreen: false,
    chartCondition: new SalesChartCondition(),
  });

  /**
   * コンポーネントの状態を更新します。
   * @param newState - 更新する新しい部分状態。
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };


  /**
   * props変更時
   */
  useEffect(() => {
    // フルスクリーン解除
    updateState({ isFullScreen: false });

    // 日次データを今日から70日前に設定
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 70);

    // 月次データを今月から24ヶ月前に設定
    const startMonth = new Date();
    startMonth.setMonth(startMonth.getMonth() - 24);

    const chartCondition = new SalesChartCondition({
      startDate: startDate.toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
      startMonth: startMonth.toISOString().slice(0, 7),
      endMonth: new Date().toISOString().slice(0, 7),
    });
    updateState({ chartCondition: chartCondition });

  }, [props]);

  /**
   * フルスクリーンボタンクリック
   */
  const onFullScreenClick = () => {
    LogUtils.d('onFullScreenClick');

    updateState({ isFullScreen: !state.isFullScreen });
  }

  /**
   * チャート条件変更
   * @param condition
   */
  const onChartConditionChange = (condition: SalesChartCondition) => {
    updateState({ chartCondition: condition });
  }

  /**
   * 通常モード
   */
  const renderNormal = () => {
    return (
      <Dialog maxWidth={'xl'} open={props.open} onClose={props.onClose}>
        <AppBar position="static" style={{ minHeight: '40px' }}>
          <Toolbar style={{ minHeight: '40px' }}>
            <Typography sx={{ flexGrow: 1 }}>
              販売データ - {props.displayCode}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box  display={'flex'} flexDirection={'row'}>
            <Box sx={{minWidth: DAILY_DATA_WIDTH}}>
              <SalesDailyHistoryTable
                maxHeight={DAILY_LIST_HEIGHT}
                dailySalesHistory={props.salesData.sales_daily_history}
              />
            </Box>
            <Box ml={2} display={'flex'} flexDirection={'column'} >
              <Box>
                {/* SalesSummaryComponent */}
                <SalesSummaryComponent
                  salesSummary={props.salesData.sales_summary}
                  stockCode={props.stockCode}
                  mallNo={props.mallNo}
                  mallProductId={props.mallProductId}
                />
              </Box>
              <Box mt={2} display={'flex'} flexDirection={'row'}>
                <SalesWeeklyHistoryTable
                  maxHeight={WEEKLY_LIST_HEIGHT}
                  weeklySalesHistory={props.salesData.sales_weekly_history}
                />
                <Box ml={2} />
                <SalesMonthlyHistoryTable
                  maxHeight={MONTHLY_LIST_HEIGHT}
                  monthlySalesHistory={props.salesData.sales_monthly_history}
                />
              </Box>
              <Box mt={1}>
                <SalesChartPanel
                  graphHeight={GRAPH_HEIGHT}
                  stockCode={props.stockCode}
                  mallNo={props.mallNo}
                  mallProductId={props.mallProductId}
                  onFullScreenClick={onFullScreenClick}
                  isFullScreen={false}
                  chartCondition={state.chartCondition}
                  onChartConditionChange={onChartConditionChange}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  /**
   * フルスクリーンモード
   */
  const renderFullScreen = () => {
    return (
      <Dialog maxWidth={'xl'} open={props.open} onClose={props.onClose}>
        <AppBar position="static" style={{ minHeight: '40px' }}>
          <Toolbar style={{ minHeight: '40px' }}>
            <Typography sx={{ flexGrow: 1 }}>
              販売データ - {props.displayCode}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{minWidth: FULL_SCREEN_WIDTH}}>
          <SalesChartPanel
            graphHeight={FULL_SCREEN_GRAPH_HEIGHT}
            stockCode={props.stockCode}
            mallNo={props.mallNo}
            mallProductId={props.mallProductId}
            onFullScreenClick={onFullScreenClick}
            isFullScreen={true}
            chartCondition={state.chartCondition}
            onChartConditionChange={onChartConditionChange}
          />
        </DialogContent>
      </Dialog>
    );
  }

  /**
   * レンダリング
   */
  return (
    <>
      {state.isFullScreen ? renderFullScreen() : renderNormal()}
    </>
  );
};
