/**
 * 在庫パターン (stock_patterns)
 */
export class StockPattern {
  id?: number;
  name?: string; // Max length is 255. It's also unique.
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<StockPattern>) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<StockPattern>): StockPattern {
    return new StockPattern({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): StockPattern {
    return new StockPattern({
       ...json ,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): StockPattern {
    const decoded = JSON.parse(jsonString);
    return StockPattern.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }
}
