import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel, ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps, Theme,
  Typography
} from "@mui/material";
import {CommonUtils} from "../../../Common/CommonUtils";


interface EMSelectProps {
  error?: string;
  multiple?: boolean;
  label: string;
  value?: string;
  multiValues?: string[]; // 複数選択用
  onChange?: (value: string) => void;
  onMultiSelectChange?: (values: string[]) => void; // 複数選択用
  disabled?: boolean;
  menuItems: any[];
  itemValueKey: string | ((item: any) => string);
  itemLabelKey: string | ((item: any) => string);
  showValueWithLabel?: boolean;
  hideUnselected?: boolean;
  sx?: SxProps<Theme>;
}

/**
 * プルダウンコントロール
 *
 * @constructor
 */
export const EMSelect = (props: EMSelectProps) => {

  /**
   * 項目値
   * @param item
   */
  const itemValue = (item: any) => {
    if ( typeof props.itemValueKey === "string" ) {
      return item[props.itemValueKey]?.toString();
    }
    return props.itemValueKey(item);
  }

  /**
   * 項目ラベル
   * @param item
   */
  const itemLabel = (item: any) => {
    if ( typeof props.itemLabelKey === "string" ) {
      let label = item[props.itemLabelKey];
      if ( props.showValueWithLabel == null || props.showValueWithLabel ) {
        label = itemValue(item) + '：' + label;
      }
      return label;
    }
    return props.itemLabelKey(item);
  }

  /**
   * 選択変更
   * @param event
   */
  const handleChange = (event: SelectChangeEvent<typeof props.value>) => {
    const { value } = event.target;
    if (props.multiple && typeof value === 'object') {
      // 複数選択の場合、valueはstring[]型
      if ( props.onMultiSelectChange ){
        props.onMultiSelectChange(value as string[]);
      }
    } else {
      // 単一選択の場合、valueはstring型
      if ( props.onChange ) {
        props.onChange(value as string);
      }
    }
  };

  /**
   * カラーボックスを生成
   * @param item
   */
  const renderColorBox = (item: any) => {
    return item.color ? (
      <Box
        component="span"
        sx={{
          width: 20,
          height: 20,
          backgroundColor: item.color,
          display: 'inline-block',
          marginRight: 1,
          verticalAlign: 'middle',
          borderRadius: '4px',
          boxShadow: '0 0 2px rgba(0, 0, 0, 0.3)',
        }}
      />
    ) : null;
  }

  /**
   * メニューアイテム
   */
  const menuItems = () => {
    let ret = [];
    // 複数選択の場合、チェックボックスを表示
    if ( props.multiple ) {
      ret = props.menuItems.map((item) => (
            <MenuItem key={itemValue(item)} value={itemValue(item)}>
              <Checkbox
                checked={props.multiValues?.includes(itemValue(item))} />
              {renderColorBox(item)}  {/* カラーボックスを表示 */}
              <ListItemText primary={itemLabel(item)} />
            </MenuItem>
      ));
    }
    // 単一選択の場合、通常のプルダウンを表示
    else {
      if ( !props.hideUnselected ){
        ret.push(
          <MenuItem key={-1} value={''}>
            <Typography>未選択</Typography>
          </MenuItem>
        );
      }
      const items = props.menuItems.map((item) => (
        <MenuItem key={itemValue(item)} value={itemValue(item)}>
          {renderColorBox(item)}  {/* カラーボックスを表示 */}
          <Typography component="span">{itemLabel(item)}</Typography>
        </MenuItem>
      ));
      ret.push(items);
    }

    return ret;
  }

  /**
   * 複数選択値の描画
   * @param selected
   */
  const renderMultipleValue = (selected: any) => {
    // ラベルを表示
    const labels = props.menuItems.filter(item => selected.includes(itemValue(item))).map(item => itemLabel(item));
    return labels.join(', ');
  }


  /**
   * 描画
   */
  return (
    <FormControl
      size={"small"}
      variant="outlined"
      error={!CommonUtils.isEmpty(props.error)}
      sx={props.sx}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        multiple={props.multiple}
        value={props.multiple ? props.multiValues as any: props.value}
        onChange={handleChange}
        disabled={props.disabled}
        label={props.label}
        renderValue={props.multiple ? renderMultipleValue : undefined}
      >

        {/* 項目 */}
        {menuItems()}

      </Select>
      {!CommonUtils.isEmpty(props.error) && (
        <FormHelperText>{props.error}</FormHelperText>
      )}
    </FormControl>
  );
}
