import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography
} from '@mui/material';
import {useSnackbar} from "../../Common/Provider/SnackbarContext";
import ECProgress from "../../Common/Components/ECProgress";
import {LogUtils} from "../../../Common/LogUtils";
import {EcmgApiError} from "../../../Common/EcmgApiError";
import {CommonUtils} from "../../../Common/CommonUtils";
import {DeveloperService} from "../../../Services/DeveloperService";
import {AIConstants} from "../../AI/AIConstants";

/**
 * 開発者設定コンポーネント
 */
export const DeveloperSetting = () => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
  };
  /**
   * useState
   */
  const [state, setState] = useState({
    processing: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * マウント時の処理
   */
  useEffect(() => {

    // アンマウント時の処理
    return () => {
    };
  }, []);

  /**
   * 全実行中プロセスエラー終了クリック時の処理
   */
  const handleSetAllRunningProcessesAsError = async () => {
    let message = '';

    try {
      const ret = window.confirm('全実行中プロセスをエラー終了にしますか?');
      if(!ret) {
        return;
      }

      updateState({ processing: true });

      message = await DeveloperService.getInstance().setAllRunningProcessesAsError();

      updateState({ processing: false });

    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = '全実行中プロセスエラー終了処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  /**
   * デザインAIクリック時の処理
   */
  const onClickDesignAI = () => {
    const url = `${window.location.origin}/ai?type=` + AIConstants.AI_TYPE_DESIGN;
    window.open(url, "_blank");
  }

  /**
   * データAIクリック時の処理
   */
  const onClickDataAI = () => {
    const url = `${window.location.origin}/ai?type=` + AIConstants.AI_TYPE_DATA;
    window.open(url, "_blank");
  }

  /**
   * 販売予測AIクリック時の処理
   */
  const onClickSalesForecastAI = () => {
    const url = `${window.location.origin}/ai?type=` + AIConstants.AI_TYPE_SALES_FORECAST;
    window.open(url, "_blank");
  }

  /**
   * 描画
   */
  return (
    <>
      <Box mt={2}></Box>
      <Box sx={BOX_STYLE}>
        <Box><Typography>開発者設定</Typography></Box>

        <Box mt={2}></Box>
        <Button variant={"outlined"} onClick={() => handleSetAllRunningProcessesAsError()}>
          全ての実行中プロセスをエラー終了
        </Button>

      </Box>
      <Box mt={2}></Box>

      <Box sx={BOX_STYLE}>
        <Box><Typography>AI機能(実験的)</Typography></Box>

        <Box mt={2}></Box>
        <Button variant={"outlined"} onClick={onClickDesignAI}>
          システムの仕様に関するアシスタントを開く
        </Button>

        <Box mt={2}></Box>
        <Button variant={"outlined"} onClick={onClickDataAI}>
          システムのデータに関するアシスタントを開く
        </Button>

        <Box mt={2}></Box>
        <Button variant={"outlined"} onClick={onClickSalesForecastAI}>
          利益シミュレーションに関するアシスタントを開く
        </Button>

      </Box>

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </>
  );
};

