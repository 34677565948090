import React, {useEffect, useState} from 'react';
import {CommonLayout} from "./Common/Layouts/CommonLayout";
import {Box, Button, FormControl, Stack, TextField} from "@mui/material";
import ECProgress from "./Common/Components/ECProgress";
import {Align, Alignment} from "./Common/Components/Align";
import {LoginService} from "../Services/LoginService";
import {LogUtils} from "../Common/LogUtils";
import {EcmgApiError} from "../Common/EcmgApiError";
import {useSnackbar} from "./Common/Provider/SnackbarContext";
import {CommonUtils} from "../Common/CommonUtils";

/**
 * ログイン画面
 *
 * @constructor
 */
export const LoginPage = () => {
  /**
   * スタイル
   */
  // フォームコントロール
  const styleForm = {
    mt: 2,
    width: '400px',
  };
  // ボタン
  const styleButton = {
    width: '100px'
  }

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    email: '',
    password: '',
    processing: false,
    errors: {} as any,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
  // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    // クリーンアップ
    return () => {
    };
  }, []);

  /**
   * ログインボタンクリック時
   */
  const onLoginClick = async () => {
    try {
      updateState({processing: true});

      await LoginService.getInstance().login(state.email, state.password);
    }
    catch (e) {
      LogUtils.ex(e);
      if (e instanceof EcmgApiError && !e.isServerError()) {
        updateState({
          errors: {
            ...state.errors,
            email: e.getErrorMessage('email'),
            password: e.getErrorMessage('password')
          }
        });
      }
      else {
        showSnackbarMessage('ログイン処理エラーが発生しました。');
      }
    }
    finally {
      updateState({processing: false});
    }
  };

  /**
   * 描画
   */
  return (
    <CommonLayout
      title={'ログイン'}
      hideMainElement={true}
    >
      <Box mt={2}></Box>
      <Align align={Alignment.center}>
        <Stack spacing={2}>
          {/* 名前 */}
          <FormControl sx={styleForm}>
            <TextField
              id='email'
              label={'メールアドレス'}
              variant='outlined'
              value={state.email}
              error={!CommonUtils.isEmpty(state.errors.email)}
              helperText={state.errors.email}
              onChange={(event)=>{
                updateState({email: event.target.value});
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  onLoginClick().then();
                }
              }}
            />
          </FormControl>

          {/* パスワード */}
          <FormControl sx={styleForm}>
            <TextField
              id='password'
              type={'password'}
              label='パスワード'
              variant='outlined'
              value={state.password}
              error={!CommonUtils.isEmpty(state.errors.password)}
              helperText={state.errors.password}
              onChange={(event)=>{
                state.password = event.target.value;
                updateState({password: event.target.value});
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  onLoginClick().then();
                }
              }}
            />
          </FormControl>

          <Align align={Alignment.center}>
            <Button
              variant={'contained'}
              sx={styleButton}
              onClick={onLoginClick}>
              ログイン
            </Button>
          </Align>
        </Stack>
      </Align>

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </CommonLayout>
  );
}
