import {LoginService} from "../../Services/LoginService";

/**
 * チャットメンション
 */
export class ChatMention {
  id?: number;
  chat_message_id?: number;
  user_id?: number;
  type?: number;
  reply_message_id?: number;
  send_user_id?: number;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<ChatMention> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<ChatMention>): ChatMention {
    return new ChatMention({
      ...this,
      ...params,
    });
  }

  static fromMap(json: { [key: string]: any }): ChatMention {
    return new ChatMention({
      ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): ChatMention {
    const decoded = JSON.parse(jsonString);
    return ChatMention.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  /**
   * 自分宛てか？
   */
  public isForMe(): boolean {
    return LoginService.getInstance().isLoginUser(this.user_id ?? 0)
  }
}
