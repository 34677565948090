import {createContext, useContext} from "react";

interface SnackbarContextType {
  showSnackbarMessage: (message: string, showError?: boolean) => void;
}

export const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

