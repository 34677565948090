import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TableContainer,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SpecialDate } from "../../../Models/SpecialDate";
import { SpecialDateService } from "../../../Services/SpecialDate/SpecialDateService";
import { Constants } from "../../../Common/Constants";
import { LogUtils } from "../../../Common/LogUtils";
import { EcmgApiError } from "../../../Common/EcmgApiError";
import ECProgress from "../../Common/Components/ECProgress";
import { useSnackbar } from "../../Common/Provider/SnackbarContext";
import {SpecialDateEditDialog} from "./SpecialDateEditDialog";
import {CommonUtils} from "../../../Common/CommonUtils";
import {SystemSetting} from "../../../Models/SystemSetting";
import {PatternDateEditDialog} from "./PatternDateEditDialog";
import {SystemSettingService} from "../../../Services/SystemSettingService";

/**
 * 特別日設定
 * @constructor
 */
export const SpecialDateSetting = () => {
  /**
   * コンポーネントのスタイル定義
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
  };

  /**
   * useState
   */
  const [state, setState] = useState({
    specialDates: [] as SpecialDate[],
    processing: false,
    editSpecialDateDialogOpen: false,
    editingSpecialDate: null as SpecialDate | null,
    editSpecialDateErrors: {} as Record<string, string>,
    editPatternDateDialogOpen: false,
    editingPatternDate: null as SystemSetting | null,
    editPatternDateErrors: {} as Record<string, string>,
  });

  /**
   * useContext スナックバーの表示に使用するコンテキスト
   */
  const { showSnackbarMessage } = useSnackbar();


  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useEffect
   */
  useEffect(() => {
    const specialDates = SpecialDateService.getInstance().getAllSpecialDates();
    updateState({ specialDates });

    const patternDate
      = SystemSettingService.getInstance().getSettingByKey(SystemSetting.KEY_PATTERN_DATE);
    updateState({ editingPatternDate: patternDate });

  }, []);

  /**
   * 特別日データの更新時に呼ばれるメソッド
   */
  const onSpecialDateUpdated = () => {
    const specialDates = SpecialDateService.getInstance().getAllSpecialDates();
    updateState({ specialDates });
  };

  /**
   * 丸のつく日データの更新時に呼ばれるメソッド
   */
  const onPatternDateUpdated = () => {
    const patternDate
      = SystemSettingService.getInstance().getSettingByKey(SystemSetting.KEY_PATTERN_DATE);
    updateState({ editingPatternDate: patternDate });
  };


  /**
   * 編集ボタンクリック時に呼ばれるメソッド
   * @param specialDate
   */
  const handleSpecialDateEdit = (specialDate: SpecialDate) => {
    updateState({
      editingSpecialDate: specialDate,
      editSpecialDateDialogOpen: true,
      editSpecialDateErrors: {},
    });
  };

  /**
   * ◯の付く日編集ボタンクリック時に呼ばれるメソッド
   */
  const handlePatternDateEdit = () => {
    updateState({
      editPatternDateDialogOpen: true,
      editPatternDateErrors: {},
    });
  };

  /**
   * 特別日保存ボタンクリック時に呼ばれるメソッド
   * @param specialDate
   */
  const handleSpecialDateSave = async (specialDate: SpecialDate) => {
    try {
      updateState({
        processing: true,
        editingSpecialDate: specialDate
      });

      let message;
      if (specialDate.id == null) {
        // 新規特別日の追加
        message = await SpecialDateService.getInstance().addSpecialDate(specialDate);
      } else {
        // 既存の特別日の更新
        message = await SpecialDateService.getInstance().updateSpecialDate(specialDate);
      }

      updateState({
        processing: false,
        editSpecialDateDialogOpen: false,
        editingSpecialDate: null,
        editSpecialDateErrors: {},
      });

      showSnackbarMessage(message);
      onSpecialDateUpdated();

    } catch (e) {
      // エラーハンドリング
      LogUtils.ex(e);
      updateState({ processing: false });

      let errorMessage = 'エラーが発生しました。';
      if (e instanceof EcmgApiError && e.message) {
        errorMessage = `エラー：${e.message}`;
      }
      showSnackbarMessage(errorMessage);
    }
  };

  /**
   * 特別日削除ボタンクリック時に呼ばれるメソッド
   * @param specialDateId
   */
  const handleSpecialDateDelete = async (specialDateId: number) => {
    if (!window.confirm('この特別日を削除しますか？')) {
      return;
    }

    try {
      updateState({ processing: true });

      const message = await SpecialDateService.getInstance().deleteSpecialDate(specialDateId);
      updateState({ processing: false });
      showSnackbarMessage(message);
      onSpecialDateUpdated();
    } catch (e) {
      // エラーハンドリング
      LogUtils.ex(e);
      updateState({ processing: false });

      let errorMessage = 'エラーが発生しました。';
      if (e instanceof EcmgApiError && e.message) {
        errorMessage = `エラー：${e.message}`;
      }
      showSnackbarMessage(errorMessage);
    }
  };

  /**
   * 特別日の日付範囲をフォーマットする関数
   * @param startDate
   * @param endDate
   */
  const formatDateRange = (startDate: Date, endDate: Date | undefined) => {
    return endDate ? `${CommonUtils.formatDate(startDate)} 〜 ${CommonUtils.formatDate(endDate)}` : CommonUtils.formatDate(startDate);
  };


  /**
   * ◯の付く日保存ボタンクリック時に呼ばれるメソッド
   * @param systemSetting
   */
  const handlePatternDateSave = async (systemSetting: SystemSetting) => {
    try {
      systemSetting.key = SystemSetting.KEY_PATTERN_DATE;


      updateState({
        processing: true,
        editingPatternDate: systemSetting
      });

      const message = await SystemSettingService.getInstance().updateSetting(systemSetting);
      // 特別日を再読み込み
      await SpecialDateService.getInstance().init();

      updateState({
        processing: false,
        editPatternDateDialogOpen: false,
        editingPatternDate: null,
        editPatternDateErrors: {},
      });

      showSnackbarMessage(message);
      onPatternDateUpdated();

    } catch (e) {
      // エラーハンドリング
      LogUtils.ex(e);
      updateState({ processing: false });

      let errorMessage = 'エラーが発生しました。';
      if (e instanceof EcmgApiError && e.message) {
        errorMessage = `エラー：${e.message}`;
      }
      showSnackbarMessage(errorMessage);
    }
  };


  /**
   * レンダリング
   */
  return (
    <Box sx={BOX_STYLE} mt={2}>
      <Box><Typography>特別日設定</Typography></Box>
      <Box display="flex" justifyContent="flex-end" mr={2} gap={2}>
        <Button variant={"contained"} component="span" onClick={() => handleSpecialDateEdit(new SpecialDate({}))}>
          新規登録
        </Button>
        <Button variant={"contained"} color="secondary" component="span" onClick={() => handlePatternDateEdit()}>
          ◯の付く日
        </Button>
      </Box>
      <TableContainer style={{ maxHeight: '400px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>日付</TableCell>
              <TableCell>特別日タイプ</TableCell>
              <TableCell>備考</TableCell>
              <TableCell align={"center"}>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.specialDates.map(specialDate => (
              <TableRow key={specialDate.id}>
                <TableCell>{formatDateRange(specialDate.start_date!, specialDate.end_date)}</TableCell>
                <TableCell>{Constants.specialDateLabels[specialDate.special_type ?? -1 ] ?? ''}</TableCell>
                <TableCell>{specialDate.note}</TableCell>
                <TableCell align={"center"}>
                  <Button onClick={() => handleSpecialDateEdit(specialDate)}>
                    <EditIcon />
                  </Button>
                  <Button onClick={() => handleSpecialDateDelete(specialDate.id!)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {state.specialDates.length === 0 && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Typography variant={"body2"}>登録されている特別日はありません</Typography>
        </Box>
      )}

      {/* 特別日編集ダイアログ */}
      <SpecialDateEditDialog
        open={state.editSpecialDateDialogOpen}
        specialDate={state.editingSpecialDate ?? new SpecialDate({})}
        onClose={() => updateState({ editSpecialDateDialogOpen: false })}
        onSave={handleSpecialDateSave}
        errors={state.editSpecialDateErrors}
      />

      {/* ◯の付く日編集ダイアログ */}
      <PatternDateEditDialog
        open={state.editPatternDateDialogOpen}
        systemSetting={state.editingPatternDate ?? new SystemSetting({})}
        onClose={() => updateState({ editPatternDateDialogOpen: false })}
        onSave={handlePatternDateSave}
        errors={state.editPatternDateErrors}
      />

      {/* プログレス */}
      <ECProgress open={state.processing}></ECProgress>
    </Box>
  );


}
