/**
 * カレンダーアイテム
 */
export class CalendarItem {
  id?: number;
  date?: Date;
  stock_code?: string;
  calendar_type?: string;
  color?: string;
  info?: string;
  created_at?: Date;
  updated_at?: Date;

  displayName?: string;

  constructor(params: Partial<CalendarItem> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<CalendarItem>): CalendarItem {
    return new CalendarItem({
      ...this,
      ...params,
    });
  }

  static fromMap(json: { [key: string]: any }): CalendarItem {
    return new CalendarItem({
      ...json,
      date: json.date ? new Date(json.date) : undefined,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): CalendarItem {
    const decoded = JSON.parse(jsonString);
    return CalendarItem.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      date: this.date ? this.date.toISOString() : undefined,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }
}
