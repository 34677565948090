import React, {useEffect, useState} from 'react';
import {Box, IconButton} from '@mui/material';
import {ProductMemoPhoto} from "../../../../../Models/Product/ProductMemoPhoto";
import {CustomImageGallery} from "../../Common/CustomImageGallery";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAPhoto from '@mui/icons-material/AddAPhoto';
import {LogUtils} from "../../../../../Common/LogUtils";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {ProductService} from "../../../../../Services/ProductService";
import {EcmgApiError} from "../../../../../Common/EcmgApiError";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";
import ECProgress from "../../../../Common/Components/ECProgress";


/**
 * 画像アイテム
 * @param image
 * @param index
 * @param handleImageClick
 * @param onShiftClick
 * @param imageWidth
 * @constructor
 */
const ImageItem: React.FC<{
  image: ProductMemoPhoto;
  index: number;
  handleImageClick: (index: number) => void;
  onShiftClick: (index: number) => void;
  imageWidth: number;
}> = ({ image, index, handleImageClick, onShiftClick, imageWidth }) => {
  /**
   * 定数
   */
    // SKU
  const BOX_STYLE = {
      boxSizing: 'border-box',
      border: '1px solid lightgray',
      margin: '1px',
    };
  /**
   * 画像クリック時処理
   * @param e
   */
  const handleClick = (e: React.MouseEvent) => {
    if (e.shiftKey) {
      onShiftClick(index);
    } else {
      handleImageClick(index);
    }
  };

  /**
   * 描画
   */
  return (
    <Box sx={BOX_STYLE} onClick={handleClick}>
      <img
        src={image.thumbnailImageUrl}
        alt={image.stock_code}
        style={{
          maxWidth: imageWidth + 'px',
          maxHeight: imageWidth + 'px',
        }}
      />
    </Box>
  );
};


interface ProductMemoImageListProps {
  stockCode: string;
  images: ProductMemoPhoto[];
  width?: number;
  canChange: boolean;
  imageWidth: number;
  hideActionButton?: boolean;
}

/**
 * 商品メモ画像リスト
 * @param props
 * @constructor
 */
export const ProductMemoImageList = (props: ProductMemoImageListProps) => {

  /**
   * useSnackbar
   */
  const { showSnackbarMessage } = useSnackbar();

  /**
   * useRef
   */
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  /**
   * useState
   */
  const [state, setState] = useState({
    images: [] as ProductMemoPhoto[],
    isDeleteMode: false,
    isImageViewerOpen: false,
    selectedImageIndex: -1,
    processing: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * props.images更新時処理
   */
  useEffect(() => {
    updateState({ images: props.images });
  }, [props.images]);

  /**
   * 削除モード切替
   */
  const toggleDeleteMode = () => {
    updateState({ isDeleteMode: !state.isDeleteMode });
  };

  /**
   * 画像ダイアログを開く
   * @param index
   */
  const openImageDialog = (index: number) => {
    updateState({ isImageViewerOpen: true, selectedImageIndex: index });
  };

  /**
   * 画像ダイアログを閉じる
   */
  const closeImageDialog = () => {
    updateState({ isImageViewerOpen: false, selectedImageIndex: -1 });
  };

  /**
   * 画像クリック時処理
   * @param index
   */
  const handleImageClick = (index: number) => {
  LogUtils.d('handleImageClick: ' + index);

    if (state.isDeleteMode) {
      deleteImage(index).then();
    } else {
      openImageDialog(index);
    }
  };

  /**
   * 画像シフトクリック時処理
   * @param index
   */
  const handleShiftClick = (index: number) => {
    updateState({selectedImageIndex: index});
    fileInputRef.current?.click(); // ファイル選択ダイアログを開く
  };

  /**
   * ファイル選択時処理
   * @param e
   */
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) {
      return;
    }

    // 次回同じファイルを選択してもイベントが発生するようにする
    e.target.value = '';

    let message = '';

    try {
      updateState({ processing: true });

      // メモ画像追加
      const addIndex = state.selectedImageIndex < 0 ? -1 : state.selectedImageIndex;
      message = await ProductService.getInstance().addMemoPhoto(props.stockCode, file, addIndex);

      updateState({processing: false});
    } catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false});

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = 'メモ画像追加処理でエラーが発生しました。';
      }
    }
    updateState({selectedImageIndex: -1});

    showSnackbarMessage(message);
  };

  /**
   * ドラッグ終了時処理
   * @param result
   */
  const handleOnDragEnd = async (result: any) => {
    let message = '';

    const oldImages = state.images;
    try {
      if (!result.destination) return;

      // 画像を入れ替え
      const items = Array.from(oldImages);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      updateState({ processing: true, images: items });

      // 新しい順序のIDリストを生成
      const reorderedIds = items.map(image => image.id!);
      // メモ画像入れ替え
      message = await ProductService.getInstance().reorderMemoPhotos(props.stockCode, reorderedIds);

      updateState({processing: false});
    } catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false, images: oldImages});

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = 'メモ画像順序入れ替え処理でエラーが発生しました。';
      }
    }

    showSnackbarMessage(message);
  };

  /**
   * 画像削除
   * @param index
   */
  const deleteImage = async (index: number) => {
    let message = '';

    try {
      updateState({ processing: true });

      // メモ画像削除
      const id = state.images[index].id!;
      message = await ProductService.getInstance().deleteMemoPhoto(props.stockCode, id);

      updateState({processing: false});
    } catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false});

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = 'メモ画像削除処理でエラーが発生しました。';
      }
    }

    showSnackbarMessage(message);
  }


  /**
   * 描画
   */
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      gap={1}
    >
      {/* ドラッグ&ドロップ領域 */}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {/* ドロップ可能領域 */}
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              display="flex"
              flexDirection="row"
              sx={{
                overflowX: 'scroll',
                width: props.width || 'auto'
              }}
            >
              {state.images.map((image, index) => (
                // ドラッグ可能領域
                <Draggable
                  key={image.id}
                  draggableId={image.id!.toString()}
                  index={index}
                  isDragDisabled={!props.canChange}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      {/* サムネイル画像 */}
                      <ImageItem
                        key={image.id}
                        image={image}
                        index={index}
                        handleImageClick={handleImageClick}
                        onShiftClick={handleShiftClick}
                        imageWidth={props.imageWidth}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      {/* ボタン */}
      {!props.hideActionButton && (
        <Box display={"flex"} flexDirection={"row"} alignItems="center" justifyContent={"end"}>
          {/* 画像追加ボタン */}
          <label>
            <IconButton
              component="span"
              aria-label="add photo"
              sx={{
                color: 'green',
                '&:hover': {
                  backgroundColor: 'transparent',
                }
              }}
              disabled={!props.canChange}
            >
              <AddAPhoto />
            </IconButton>
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleFileChange}
              disabled={!props.canChange}
              ref={fileInputRef}
            />
          </label>

          {/* 削除モードトグルボタン */}
          <IconButton
            onClick={toggleDeleteMode}
            aria-label="delete mode"
            sx={{
              backgroundColor: state.isDeleteMode ? 'red' : 'default', // ONになったときは赤色
              color: state.isDeleteMode ? 'white' : 'inherit', // ONになったときのアイコンの色を白にする
              '&:hover': {
                backgroundColor: state.isDeleteMode ? '#b71c1c' : 'rgba(0, 0, 0, 0.08)', // ホバー時の背景色を変更
              },
            }}
            disabled={!props.canChange}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}

      {/* イメージギャラリー */}
      {state.isImageViewerOpen &&
        <CustomImageGallery
          open={state.isImageViewerOpen}
          onClose={closeImageDialog}
          items={state.images.map(image => ({
            original: image.originalImageUrl,
            thumbnail: image.thumbnailImageUrl,
            }))
          }
          startIndex={state.selectedImageIndex}
        />}

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </Box>
  );
};
