import {NavigateFunction} from "react-router-dom";
import {LogUtils} from "./LogUtils";
import {LoginService} from "../Services/LoginService";
import { utcToZonedTime, format } from 'date-fns-tz';
import {Constants} from "./Constants";
import {StoreProduct} from "../Models/Product/StoreProduct";
import {StoreService} from "../Services/StoreService";
import {SystemSetting} from "../Models/SystemSetting";
import {SystemSettingService} from "../Services/SystemSettingService";

/**
 * 共通ユーティリティクラス
 */
export class CommonUtils {
  /**
   * 空文字判定
   */
  static isEmpty(str: string | undefined | null): boolean {
    return str === undefined || str === null || str === "";
  }

  /**
   * ログインしていなければログインページへ
   */
  static checkLogin(navigate: NavigateFunction): void {
    try {
      if (!LoginService.getInstance().isLogin()) {
        navigate("/login");
      }
    }
    catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * 0以上の整数かどうか
   * @param value
   */
  static isUnsignedInteger(value: string): boolean {
    if (value === '') {
      return false;
    }
    return /^\d+$/.test(value);
  }

  /**
   * 0以上の数値かどうか
   * @param value
   */
  static isUnsignedNumeric(value: string): boolean {
    return value !== '' && ( /^\d*\.?\d+$/.test(value) || /^\d+\.$/.test(value) );
  }

  /**
   * 数値とハイフンのみ許可
   * @param value
   */
  static isSignedInteger(value: string): boolean {
    if (value === '') {
      return false;
    }
    const regex = /^-?\d*$/;
    return regex.test(value);
  }

  /**
   * Sleep
   */
  static sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /**
   * 日付文字列のタイムゾーン変換
   * @param dateString
   * @returns
   */
  public static convertToAppTimezone(dateString: string): string {
    // 末尾がZならUTCとみなす
    if (dateString.endsWith('Z')) {
      const appTimezone = "Asia/Tokyo";
      const zonedDate = utcToZonedTime(dateString, appTimezone);
      return format(zonedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone: appTimezone });
    }

    // 末尾がZでない場合は、既にアプリケーションのタイムゾーンであるとみなしてそのまま返す
    return dateString;
  }

  /**
   * ファイル名から拡張子を除去する
   * @param filename
   */
  public static removeExtension(filename: string) {
    return filename.split('.').slice(0, -1).join('.');
  }

  /**
   * 数値のフォーマット
   * @param value
   * @param digits
   */
  public static formatNumber(value: number | undefined, digits = 0): string {
    if ( value === undefined ) {
      return '';
    }
    return value.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits });
  }


  /**
   * 日付のフォーマット
   * @param date
   * @returns
   */
  public static formatDate(date: Date): string {
    return format(date, Constants.DATE_FORMAT);

  }

  /**
   * 文字列の先頭の一部を返す関数
   * @param str 入力文字列
   * @param maxLength 表示する最大文字数
   * @return 省略された文字列
   */
  public static getStringSnippet(str: string, maxLength: number): string{
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  /**
   * 表用の日付文字列を取得（月が一つ前の日付と違う場合は「MM/DD」形式で返す）
   * @param date
   * @param prevDate
   */
  public static getDateTextForTable(date: string, prevDate: string | undefined): string{
    const inputDate = new Date(date);
    const month = inputDate.getMonth();
    const day = inputDate.getDate();

    let showMonth = false;
    if ( prevDate != null ){
      const prevInputDate = new Date(prevDate);
      const prevMonth = prevInputDate.getMonth();
      if ( month !== prevMonth ){
        showMonth = true;
      }
    }
    else {
      showMonth = true;
    }

    // 月が一つ前の日付と違う場合は「MM/DD」形式で返す
    if (showMonth) {
      return `${(month + 1).toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
    } else {
      // それ以外の場合、「DD」形式で返す（0埋め)
      return `${day.toString().padStart(2, '0')}`;
    }
  }

  /**
   * 表用の曜日文字列を取得
   * @param date
   */
  public static getWeekTextForTable(date: string): string{
    const day = new Date(date).getDay();
    // アルファベット３文字の曜日を取得
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][day];
  }

  /**
   * 表用の時間文字列を取得
   * @param dateTime
   */
  public static getTimeTextForTable(dateTime: string): string{
    const time = new Date(dateTime).getTime();
    // HH:MM形式で返す
    return format(time, 'HH:mm');
  }
  /**
   * 店舗商品のURLを開く
   * @param storeProduct
   */
  public static openStoreProductUrl(storeProduct: StoreProduct): void {
    const store = StoreService.getInstance().getStoreById(storeProduct.store_id!);

    if (store) {
      let url = '';

      if ( storeProduct.pub_status === 1 ) {
        switch (store.mall_no) {
          case Constants.MALL_BASE: // BASE
            url = `${store.base_shop_url}/items/${storeProduct.mall_product_id}`;
            break;
          case Constants.MALL_YAHOO: // Yahoo!
            url = `https://store.shopping.yahoo.co.jp/${store.mall_store_id}/${storeProduct.mall_product_id}.html`;
            break;
          case Constants.MALL_RAKUTEN: // 楽天
            url = `https://item.rakuten.co.jp/${store.rkt_shop_url}/${storeProduct.mall_product_id}`;
            break;
          default:
            break;
        }
      }
      else {
        switch (store.mall_no) {
          case Constants.MALL_BASE: // BASE
            url = `https://admin.thebase.com/shop_admin/items/edit/${storeProduct.mall_product_id}`;
            break;
          case Constants.MALL_YAHOO: // Yahoo!
            url = `https://editor.store.yahoo.co.jp/RT/${store.mall_store_id}/PageEdit/makePreview/?page_key=${storeProduct.mall_product_id}`;
            break;
          case Constants.MALL_RAKUTEN: // 楽天
            url = `https://soko.rms.rakuten.co.jp/${store.rkt_shop_url}/${storeProduct.mall_product_id}`;
            break;
          default:
            break;
        }
      }
      LogUtils.d(url);
      if (url) {
        window.open(url, '_blank');
      }
    }
  }

  /**
   * 商品ページを開く
   * @param stockCode
   */
  public static openProductPage(stockCode: string): void {
    const keyword = encodeURIComponent(stockCode);
    const url = `${window.location.origin}/main?keyword=${keyword}`;
    window.open(url, "_blank");
  }

  /**
   * カレンダー名を取得
   * @param calendarType
   */
  public static getCalendarName(calendarType: string): string {
    const titleKey
      = calendarType === 'A' ? SystemSetting.KEY_CALENDAR_NAME_A : SystemSetting.KEY_CALENDAR_NAME_B;
    const titleSetting = SystemSettingService.getInstance().getSettingByKey(titleKey);
    let title = titleSetting?.value;
    if ( title == null || title.length === 0){
      title = calendarType === 'A' ? 'カレンダーA' : 'カレンダーB';
    }
    return title;
  }


  /**
   * 日本語の日時文字列を取得
   * @param date
   */
  public static getDateStringJP(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() は 0 から始まるので +1 する
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const dayOfWeek = date.getDay(); // 曜日を取得 (0=日曜日, 1=月曜日, ..., 6=土曜日)
    const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]; // 曜日の文字列

    // ゼロ埋めするために、'0'を追加してから最後の2文字を取得
    const formattedMonth = month;
    const formattedDay = day;
    const formattedHour = ("0" + hour).slice(-2);
    const formattedMinute = ("0" + minute).slice(-2);

    // フォーマット後の時間文字列を構築
    return `${year}年${formattedMonth}月${formattedDay}日(${dayOfWeekStr}) ${formattedHour}:${formattedMinute}`;
  }

  /**
   * 文字列の最初のスラッシュ以降の文字列をトリム
   * @param str
   */
  public static trimAfterFirstSlash(str: string): string {
    // 最初のスラッシュの位置を見つける
    const indexOfSlash = str.indexOf('/');

    // スラッシュが見つからない場合、元の文字列を返す
    if (indexOfSlash === -1) {
      return str;
    }

    // 最初のスラッシュ以降の文字列を返す
    return str.slice(indexOfSlash + 1);
  }

  /**
   * 配列同士が等しいかどうか
   * @param array1
   * @param array2
   */
  public static areArraysEqual(array1: any, array2: any): boolean {
    if (array1 === array2) return true; // 同じ参照の場合は同一
    if (array1 == null && array2 == null) return true; // どちらもnullの場合は同一
    if (array1 == null || array2 == null) return false; // どちらかがnullの場合は非同一
    if (array1.length !== array2.length) return false; // 長さが異なる場合は非同一

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false; // 要素が異なる場合は非同一
      }
    }
    return true; // 全ての要素が同一であれば同一
  }

}
