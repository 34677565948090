import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogContent,
  Toolbar,
  Typography,
  IconButton,
  AppBar,
  Box,
  Divider
} from '@mui/material';
import moment from 'moment';
import {Calendar, momentLocalizer, Messages, EventPropGetter} from 'react-big-calendar';
import 'moment/locale/ja';
import CloseIcon from "@mui/icons-material/Close";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {LogUtils} from "../../../../Common/LogUtils";
import {EcmgApiError} from "../../../../Common/EcmgApiError";
import {CommonUtils} from "../../../../Common/CommonUtils";
import {useSnackbar} from "../../Provider/SnackbarContext";
import {CalendarService} from "../../../../Services/CalendarService";
import {MainPageConstants} from "../../../Main/Common/MainPageConstants";
import {ProductService} from "../../../../Services/ProductService";

/**
 * カレンダーロケール設定
 */
moment.locale('ja', {
  week: {
    dow: 1, // 週の最初の曜日を月曜日に設定（0=日曜日, 1=月曜日）
  },
});
// `react-big-calendar`用のローカライザーを設定
const localizer = momentLocalizer(moment);

// カスタマイズしたメッセージ
const customMessages: Messages = {
  allDay: '終日',
  previous: '前',
  next: '次',
  today: '今日',
  month: '月',
  week: '週',
  day: '日',
  agenda: 'アジェンダ',
  date: '日付',
  time: '時刻',
  event: 'イベント',
  noEventsInRange: 'この範囲にイベントはありません。',
  showMore: (total) => `+${total} もっと見る`
};

const customEventStyle = (event: MyEvent) => {
  return {
    backgroundColor: event.color,
    color: 'black',
    borderRadius: '4px',
    opacity: 0.8,
    border: '0px',
    display: 'block',
    fontSize: '0.8em',
  };
};

/**
 * イベントデータ
 */
interface MyEvent {
  id: number;
  title: string;
  start: Date;
  end: Date;
  color?: string; // イベントの色を指定するためのオプションフィールド
  stockCode: string;
}

interface CalendarDialogProps {
  open: boolean;
  onClose: () => void;
  calendarType: string;
}
/**
 * カレンダーダイアログ
 * @param props
 * @constructor
 */
export const CalendarDialog = (props: CalendarDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    events: [] as MyEvent[],
    title: '',
    yearMonth: undefined as string | undefined,
    showMoreDialogOpen: false,
    showMoreEvents: [] as MyEvent[],
    showMoreDate: undefined as Date | undefined,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  /**
   * useSnackbar
   */
  const { showSnackbarMessage } = useSnackbar();

  /**
   * props 更新時
   */
  useEffect(() => {
    if ( props.open ){
      const title = CommonUtils.getCalendarName(props.calendarType);
      const yearMonth = moment().format('YYYY-MM');
      updateState({title: title, yearMonth: yearMonth});
      loadEvents(yearMonth).then();
    }
  }, [props]);

  /**
   * state.yearMonth更新時
   */
  useEffect(() => {
    if ( props.open && state.yearMonth != null){
      loadEvents(state.yearMonth).then();
    }
  }, [state.yearMonth]);

  /**
   * イベント読み込み
   */
  const loadEvents = async (yearMonth: string) => {
    try {

      const calendarData = await CalendarService.getInstance().getCalendarData(yearMonth, props.calendarType);
      const events = calendarData.map((data) => {
        return {
          id: data.id!,
          start: data.date!,
          end: data.date!,
          title: data.displayName ?? '',
          color: data.color ,
          stockCode: data.stock_code!,
        };
      });

      updateState({events: events});

    } catch (e) {
      LogUtils.ex(e);

      let message = '';
      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = 'カレンダー情報の取得処理でエラーが発生しました。';
      }
      showSnackbarMessage(message);
    }
  }

  /**
   * イベントスタイル取得
   * @param event
   */
  const eventStyleGetter: EventPropGetter<MyEvent> = (event) => {
    let style = customEventStyle(event);
    return {
      style: style
    };
  };

  /**
   * もっと見るクリック時の処理
   * @param events
   * @param date
   */
  const handleShowMore = (events: MyEvent[], date: Date) => {
    updateState({
      showMoreDialogOpen: true,
      showMoreDate: date,
      showMoreEvents: events,
    });
  };


  /**
   * カスタムイベント
   */
  interface CustomEventProps {
    event: MyEvent;
  }
  const CustomEvent = (customEventProps: CustomEventProps) => {
    /**
     * useState
     */
    const [hover, setHover] = useState(false);

    /**
     * 選択時イベント
     * @param event
     */
    const handleSelectEvent = (event: MyEvent) => {
      CommonUtils.openProductPage(event.stockCode);
    };

    /**
     * 削除ボタンクリック時の処理
     */
    const handleDelete = async () => {
      try {

        await CalendarService.getInstance().deleteCalendarItem(customEventProps.event.id);
        if (state.yearMonth != null) {
          await loadEvents(state.yearMonth);
        }

        // 検索結果更新
        setTimeout(async () => {
          await ProductService.getInstance().updateSearchResultByStockCode(customEventProps.event.stockCode);
        }, 100);

        // showMoreEventsの更新(削除したイベントを除外)
        const showMoreEvents = state.showMoreEvents.filter((event) => event.id !== customEventProps.event.id);
        updateState({showMoreEvents: showMoreEvents,});

        // もっと見るダイアログを開いている場合、イベントが0件になったら閉じる
        if (state.showMoreDialogOpen && showMoreEvents.length === 0) {
          updateState({showMoreDialogOpen: false});
        }

      } catch (e) {
        LogUtils.ex(e);

        let message = '';
        if (e instanceof EcmgApiError && e.message != null) {
          message = 'エラー：' + e.message;
        }
        if (CommonUtils.isEmpty(message)) {
          message = 'カレンダー情報の削除処理でエラーが発生しました。';
        }
        showSnackbarMessage(message);
      }
    };

    /**
     * 描画
     */
    return (
      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => handleSelectEvent(customEventProps.event)}
        style={{ position: 'relative', padding: '2px 5px', cursor: 'pointer', }}
      >
        {customEventProps.event.title}
        {hover && (
          <Box>
            <button
              onClick={(e) => {
                handleDelete().then();
                e.stopPropagation();
              }}
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                cursor: 'pointer',
                border: 'none',
                background: 'rgba(255, 255, 255, 0.7)',
                padding: '0px',
              }}
            >
              <CloseIcon fontSize="medium" style={{ color: 'black' }} />
            </button>
          </Box>
        )}
      </Box>
    );
  };

  /**
   * もっと見るポップアップ
   */
  interface ShowMoreDialogProps {
    open: boolean;
    onClose: () => void;
    events: MyEvent[];
    date?: Date;
  }
  const ShowMoreDialog = (showMoreDialogProps: ShowMoreDialogProps) => {
    return (
      <Dialog open={showMoreDialogProps.open} onClose={showMoreDialogProps.onClose}  maxWidth="md">
        <AppBar position="static" style={{ minHeight: '40px' }}>
          <Toolbar style={{ minHeight: '40px' }}>
            <Typography sx={{ flexGrow: 1 }}>
              {showMoreDialogProps.date != null ? moment(showMoreDialogProps.date).format('YYYY/MM/DD') : ''}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={showMoreDialogProps.onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {showMoreDialogProps.events.map((event, index) => (
            <Box key={index} >
              <Box style={customEventStyle(event)}>
                <CustomEvent event={event} />
              </Box>
              <Divider sx={{my: 1, backgroundColor: MainPageConstants.COLOR_DARK_GRAY}}/>
            </Box>
          ))}
        </DialogContent>
      </Dialog>
    );
  };

  /**
   * 描画
   */
  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="lg">
        <AppBar position="static" style={{ minHeight: '40px' }}>
          <Toolbar style={{ minHeight: '40px' }}>
            <Typography sx={{ flexGrow: 1 }}>
              {state.title}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Calendar
            localizer={localizer}
            events={state.events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 600 }}
            messages={customMessages}
            defaultView="month" // 初期表示を月表示に設定
            views={{ month: true, week: true }} // 月と週表示のみを利用可能にする
            onNavigate={(date) => {
              updateState({
                yearMonth: moment(date).format('YYYY-MM')
              });
            }}
            eventPropGetter={eventStyleGetter}
            components={{
              event: CustomEvent,
            }}
            onShowMore={handleShowMore}
          />
        </DialogContent>
      </Dialog>

      {/* もっと見るポップアップ */}
      <ShowMoreDialog
        open={state.showMoreDialogOpen}
        onClose={() => updateState({showMoreDialogOpen: false})}
        events={state.showMoreEvents}
        date={state.showMoreDate}
      />
    </>
  );
};


