/**
 * 定数
 */
export class ListConstants
{
  //----------------------------------
  // 商品部
  //----------------------------------
  // 在庫数 高さ
  public static STOCK_COUNT_HEIGHT = 80;
  // 在庫数 横幅
  public static STOCK_COUNT_WIDTH = 60;
  // 配送方法横幅
  public static SHIPPING_METHOD_WIDTH = 120;
  // 商品名高さ
  public static PRODUCT_NAME_HEIGHT = 100;
  // サムネイル画像サイズ
  public static IMAGE_SIZE = 114;
  // サムネイル画像 右マージン
  public static IMAGE_MARGIN_RIGHT = 26;
  // 受取在庫関連エリア 高さ
  public static RECEIVED_COUNT_AREA_HEIGHT = 114;
  // 受取在庫関連エリア 横幅
  public static RECEIVED_COUNT_AREA_WIDTH = 200;
  // 未着在庫関連エリア 高さ
  public static UNRECEIVED_COUNT_AREA_HEIGHT = 114;
  // 未着在庫関連エリア 横幅
  public static UNRECEIVED_COUNT_AREA_WIDTH = 700;
  // 仕入数関連エリア 高さ
  public static PROCUREMENT_COUNT_AREA_HEIGHT = 114;
  // 仕入数関連エリア 横幅
  public static PROCUREMENT_COUNT_AREA_WIDTH = 200;

  // 販売傾向 高さ
  public static SALES_TREND_HEIGHT = 60;
  // 販売傾向 横幅
  //public static SALES_TREND_WIDTH = 1050;

  // カレンダー追加最小幅
  public static CALENDAR_ADD_MIN_WIDTH = 174;
}
