import React, {useEffect, useState} from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import {StoreProduct} from "../../../../../Models/Product/StoreProduct";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {ProductService} from "../../../../../Services/ProductService";
import {EcmgApiError} from "../../../../../Common/EcmgApiError";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";
import {LogUtils} from "../../../../../Common/LogUtils";
import {MainPageConstants} from "../../../Common/MainPageConstants";

interface StoreProductStatusProps {
  storeProduct: StoreProduct;
  width: number;
  canChange: boolean;
}

/**
 * 店舗商品公開ステータス
 * @constructor
 */
export const StoreProductStatus = (props: StoreProductStatusProps) => {

  /**
   * useState
   */
  const [state, setState] = useState({
    pubStatus: 0,
    processing: false,
  });

  /**
   * useContext
   */
  // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {

  }, []);


  /**
   * props変更時処理
   */
  useEffect(() => {
    const pubStatus = props.storeProduct.pub_status || 0;
    updateState({ pubStatus: pubStatus});

  }, [props]);

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 公開ステータス変更時処理
   * @param event
   */
  const handleStatusChange = async (event: SelectChangeEvent<number>) => {
    const newStatus = Number(event.target.value);

    if (state.pubStatus === newStatus) {
      return; // 変更なし
    }

    updateState({processing: true, pubStatus: newStatus});

    let message = '';
    try {
      // 公開ステータス更新処理
      message = await ProductService.getInstance().updateStoreProductPublishStatus(props.storeProduct, newStatus === 1);

      updateState({processing: false});
    } catch (e) {
      LogUtils.ex(e);
      updateState({processing: false, pubStatus: props.storeProduct.pub_status});

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = '公開ステータス更新処理でエラーが発生しました。';
      }
    }

    showSnackbarMessage(message);
  }

  /**
   * 描画
   */
  return (
    <Box sx={{ width: props.width, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <FormControl fullWidth variant="outlined">
        <Select
          value={state.pubStatus}
          onChange={handleStatusChange}
          disabled={state.processing || !props.canChange}
          size="small"
          sx={{
              height: "30px",
              padding: 0,
          }}
        >
          <MenuItem value={1}>
            <Typography variant="body2">公開</Typography>
          </MenuItem>
          <MenuItem value={0}>
            <Typography variant="body2">非公開</Typography>
          </MenuItem>
        </Select>
      </FormControl>

      {state.processing && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
            height: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
            ml: 1 }}>
          <CircularProgress size={MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT} />
        </Box>
      )}
    </Box>
  );
};
