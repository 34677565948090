/**
 * 販売推移画面共通の定数
 */
export class SalesDataConstants
{
  public static LIGHT_GRAY = '#f0f0f0';
  public static MID_GRAY = '#b7b7b7';
  public static DARK_GRAY = '#4f4f4f';

}
