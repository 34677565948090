import React, {useState} from 'react';
import {Constants} from "../../../Common/Constants";
import {
  AppBar,
  Button,
  Checkbox,
  Dialog, DialogActions,
  DialogContent, Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel, IconButton,
  Radio,
  RadioGroup, Stack, Toolbar, Typography
} from "@mui/material";
import {LogUtils} from "../../../Common/LogUtils";
import {EcmgApiError} from "../../../Common/EcmgApiError";
import {CommonUtils} from "../../../Common/CommonUtils";
import {ExportService} from "../../../Services/IO/ExportService";
import {useSnackbar} from "../../Common/Provider/SnackbarContext";
import CloseIcon from "@mui/icons-material/Close";
import ECProgress from "../../Common/Components/ECProgress";

interface ExportDialogProps {
  open: boolean;
  onClose: () => void;
}

/**
 * エクスポートダイアログ
 * @constructor
 * @param props
 */
export const ExportDialog = (props: ExportDialogProps) => {
  // 在庫コードが常に選択されるようにするための初期値
  const initialExportFields: { [key: string]: boolean } = {
    ...Object.fromEntries(Object.keys(Constants.EXPORT_LABELS).map(key => [key, false])),
    [Constants.EXPORT_STOCK_CODE]: true, // 在庫コードを常に選択
  };

  /**
   * useState
   */
  const [state, setState] = useState({
    exportTarget: Constants.EXPORT_TYPE_ALL,
    exportFields: initialExportFields,
    selectAll: false,
    processing: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * ラジオボタン変更時処理
   * @param event
   */
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateState({ exportTarget: Number(event.target.value) });
  };

  /**
   * チェックボックス変更時処理
   * @param event
   */
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const newExportFields = { ...state.exportFields, [name]: checked };

    updateState({
      exportFields: newExportFields,
    });

    const allSelected = Object.values(newExportFields).every(value => value);
    updateState({selectAll: allSelected});
  };

  /**
   * 全選択チェックボックス変更時処理
   * @param event
   */
  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedState = event.target.checked;

    // 新しい exportFields を作成し、それを state に反映させる
    const newExportFields: { [key: string]: boolean } = {};
    Object.keys(Constants.EXPORT_LABELS).forEach(key => {
      newExportFields[key] = newCheckedState;
    });

    // ここで新しい exportFields と selectAll の状態を更新する
    updateState({
      exportFields: newExportFields,
      selectAll: newCheckedState,
    });
  };

  /**
   * エクスポート実行
   */
  const executeExport = async () => {
    try {
      props.onClose();
      updateState({processing: true});

      const selectedOptions = Object.keys(Constants.EXPORT_LABELS)
        .filter(key => state.exportFields[key] || key === Constants.EXPORT_STOCK_CODE); // 在庫コードは常に含める

      await ExportService.getInstance().export(
            state.exportTarget,
            selectedOptions,
            window.location.search
      );

      updateState({processing: false});

    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false});
      let message = '';
      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = 'エクスポート処理でエラーが発生しました。';
      }

      // スナックバー表示
      showSnackbarMessage(message);
    }

  };

  /**
   * 描画
   */
  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              エクスポート
            </Typography>
            <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Stack direction="column" spacing={2}  sx={{width: 600}}>
            <FormControl component="fieldset">
              <FormLabel component="legend">出力対象</FormLabel>
              <RadioGroup row value={state.exportTarget} onChange={handleRadioChange}>
                <FormControlLabel value={Constants.EXPORT_TYPE_ALL} control={<Radio />} label="全て" />
                <FormControlLabel value={Constants.EXPORT_TYPE_SEARCH_RESULT} control={<Radio />} label="現在の検索結果" />
              </RadioGroup>
            </FormControl>
            <Divider/>
            <FormControl component="fieldset" style={{ overflow: 'visible' }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.selectAll}
                      indeterminate={
                        Object.values(state.exportFields).some(value => value) && !state.selectAll
                      }
                      onChange={handleSelectAllChange}
                      color="primary"
                    />
                  }
                  label="全てを選択"
                />
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" style={{ maxHeight: 400, overflow: 'auto' }}>
              <FormLabel component="legend">出力項目選択</FormLabel>
              <FormGroup>
                {Object.keys(Constants.EXPORT_LABELS).map((key) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={state.exportFields[key] || key === Constants.EXPORT_STOCK_CODE} // 在庫コードは常にチェック
                        onChange={handleCheckboxChange}
                        name={key}
                        disabled={key ===  Constants.EXPORT_STOCK_CODE} // 在庫コードは変更不可にする
                      />
                    }
                    label={Constants.EXPORT_LABELS[key]}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Stack>

          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={executeExport}
              //disabled={!Object.values(state.exportFields).some(value => value)} // 一つも選択されていない場合は無効
            >
              実行
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </>
  );
};
