import React, {useEffect, useRef, useState} from 'react';
import {ChatMessage} from '../../Models/Chat/ChatMessage';
import {ChatMessageItem} from "./Item/ChatMessageItem";
import {Box, debounce} from "@mui/material";
import {EventService} from "../../Services/System/EventService";

interface ChatMessagesListProps {
  stockCode: string;
  messages: ChatMessage[];
  initialDispMessageId?: number;
  onLoadMore: (direction: 'up' | 'down') => void;
  addEvent?: {
    prevMessageId: number;
  }
  editingMessageId?: number;
  onVisibleMessagesChange: (visibleMessageIds: number[]) => void;
  bubbleWidth?: number;
  onReloadPage: (messageId: number) => void;
  chatResetTimeStamp?: number;
}

/**
 * チャットメッセージリスト
 * @param props
 * @constructor
 */
export const ChatMessagesList = (props: ChatMessagesListProps) => {
  /**
   * 定数
   */

  /**
   * useRef
   */
  const boxRef = useRef<HTMLDivElement>(null);

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    initialScrolledMessageId: 0,
  });
  const lastScrollTopRef = useRef(0);

  /**
   * useEffect
   */
  useEffect(() => {
    // イベントハンドラ登録（イベントハンドラの中でstateの値を参照しないこと）
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_JUMP, onMessageJump);

    // クリーンアップ
    return () => {
      // イベントハンドラ解除
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_JUMP, onMessageJump);
    };
  }, []);

  /**
   * 在庫コード変更時（またはリセットタイムスタンプ変更時）
   */
  useEffect(() => {
    // 初回スクロール位置を初期化
    updateState({initialScrolledMessageId: 0});
  }, [props.stockCode, props.chatResetTimeStamp]);

  /**
   * メッセージジャンプ（stateは参照できない）
   * @param messageId
   */
  const onMessageJump = (messageId: any) => {

    if ( messageId == null ) {
      return;
    }
    const box = boxRef.current;
    if (!box) {
      return;
    }
    const targetElement = box.querySelector(`[data-message-id='${messageId}']`);
    if (targetElement) {
      // スクロールを実行
      targetElement.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
    else {
      // 見つからない場合は読み込まれていない可能性があるので、ジャンプ
      props.onReloadPage(Number(messageId));
    }
  }

  /**
   * 初回スクロール
   */
  useEffect(() => {
    // LogUtils.d('state.initialScrolledMessageId:' + state.initialScrolledMessageId
    //   + ' props.initialDispMessageId:' + props.initialDispMessageId
    //   + ' props.messages.length:' + props.messages.length
    // );

    if (state.initialScrolledMessageId === props.initialDispMessageId) {
      return;
    }
    if ( props.messages.length === 0) {
      return;
    }
    const box = boxRef.current;
    if (!box) {
      return;
    }

    // 特定のメッセージIDが指定されている場合、その位置にスクロール
    if (props.initialDispMessageId != null && props.initialDispMessageId > 0) {
      const targetElement = box.querySelector(`[data-message-id='${props.initialDispMessageId}']`);
      if (targetElement) {
        // 要素のビューポートに対する位置を取得（scrollIntoViewを使うとチャットパネルが見切れている時に、右端に強制スクロールされるため）
        const elementRect = targetElement.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.scrollY;
        // スクロール位置を更新
        box.scrollTop = absoluteElementTop - box.offsetTop;

        // 状態を更新
        updateState({
          initialScrolledMessageId: props.initialDispMessageId,
        });
        lastScrollTopRef.current = box.scrollTop;
      }
    }

    // 表示中のメッセージIDを通知
    setTimeout(notifyVisibleMessageIds, 100);// スクロールを考慮して遅延

  }, [props.messages, props.initialDispMessageId]);

  /**
   * メッセージ追加時
   */
  useEffect(() => {
    if (props.addEvent == null) {
      return;
    }

    //----------------------------------
    // 必要に応じて最下部にスクロール
    //----------------------------------
    const box = boxRef.current;
    if (box) {
      const targetElement = box.querySelector(`[data-message-id='${props.addEvent.prevMessageId}']`);
      if (targetElement) {
        // 要素がビューポート内にあるかどうかをチェック
        const rect = targetElement.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
        if (isVisible) {
          box.scrollTop = box.scrollHeight;
        }
      }
    }

  }, [props.addEvent]);


  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * スクロール時
   */
  const handleScroll = () => {

    if (!boxRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = boxRef.current;

    // スクロールの方向を判断
    const isScrollingDown = scrollTop > lastScrollTopRef.current;
    const isScrollingUp = !isScrollingDown;

    // スクロール位置が上端に近い場合にのみ 'up' をトリガー
    if (isScrollingUp && scrollTop < 200) {
      props.onLoadMore('up');
    }

    // スクロール位置が下端に近い場合にのみ 'down' をトリガー
    const bottom = scrollHeight - scrollTop - clientHeight;
    if (isScrollingDown && bottom < 200) {
      props.onLoadMore('down');
    }

    // 現在のスクロール位置を記録
    lastScrollTopRef.current = scrollTop;

    // 表示中のメッセージIDを通知
    notifyVisibleMessageIdsDebounced();
  };

  /**
   * 表示中のメッセージIDを通知
   */
  const notifyVisibleMessageIds = () => {
    if (!boxRef.current) return;

    const viewportTop = 64; // ビューポートの上端からのオフセット
    const viewportBottom = window.innerHeight - 124; // ビューポートの下端からのオフセット
    const visibleMessageIds = Array.from(boxRef.current.querySelectorAll('[data-message-id]'))
      .filter(element => {
        const rect = element.getBoundingClientRect();
        const elementCenter = rect.top + (rect.height / 2); // 要素の縦方向の真ん中
        // 要素の縦方向の真ん中がビューポート内にあるかどうか
        return elementCenter >= viewportTop && elementCenter <= viewportBottom;
      })
      .map(element => parseInt(element.getAttribute('data-message-id') ?? "0", 10))
      .filter(messageId => messageId > 0);

    // 親コンポーネントに現在表示されているメッセージのIDを通知
    props.onVisibleMessagesChange(visibleMessageIds);
  };

  // notifyVisibleMessageIdsの処理をデバウンス
  const notifyVisibleMessageIdsDebounced = debounce(() => {
    notifyVisibleMessageIds();
  }, 500);

  /**
   * 描画
   */
  return (
    <Box
      ref={boxRef}
      onScroll={handleScroll}
      sx={{
        overflow: 'auto',
        width: '100%',
        flex: 1, // flex: 1 はこのコンポーネントでスクロール可とするのに、ここで必要
        '&::-webkit-scrollbar': {
          display: 'none' // WebKitブラウザ用のスタイル
        }
      }}
    >
      {props.messages.map((message) => (
        <div　key={message.id} data-message-id={message.id}>
          <Box mt={2} mb={2} >
            <ChatMessageItem
              message={message}
              isEditing={props.editingMessageId === message.id}
              bubbleWidth={props.bubbleWidth}
            />
          </Box>
        </div>
      ))}
    </Box>
  );
}

