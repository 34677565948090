import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Box} from "@mui/material";
import {Constants} from "../../../../../Common/Constants";
import {SelectionService} from "../../../../../Services/System/SelectionService";
import {LogUtils} from "../../../../../Common/LogUtils";
import {EcmgApiError} from "../../../../../Common/EcmgApiError";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import ECProgress from "../../../../Common/Components/ECProgress";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";
import {BulkActionService} from "../../../../../Services/BulkActionService";
import {ProductService} from "../../../../../Services/ProductService";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {LoginService} from "../../../../../Services/LoginService";
import {Visibility} from "@mui/icons-material";


interface BulkActionMenuProps {
  // 現時点ではプロパティを持たない
}

/**
 * 一括処理メニュー
 * @constructor
 */
export const BulkActionMenu = (props: BulkActionMenuProps) => {

  /**
   * useState
   */
  const [state, setState] = useState({
    anchorEl: null as null | HTMLElement,
    processing: false,
  });

  /**
   * コンポーネントの状態を更新します。
   * @param newState - 更新する新しい部分状態。
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useSnackbar
   */
  const { showSnackbarMessage } = useSnackbar();

  /**
   * マウスオーバー時
   * @param event
   */
  const handleMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.focus(); // ボタンにフォーカスを持ってくる
    updateState({ anchorEl: event.currentTarget});
  };

  /**
   * メニュークローズ
   */
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    updateState({ anchorEl: null });
  };

  /**
   * アクションクリック
   * @param actionType
   */
  const onActionClick = async (actionType: number) => {
    // 一括公開/非公開の場合
    if(actionType === Constants.BULK_ACTION_TYPE_SHOW_PRODUCT || actionType === Constants.BULK_ACTION_TYPE_HIDE_PRODUCT) {
      const selectCount = SelectionService.instance().getSelectCount();
      if(selectCount > Constants.BULK_ACTION_SHOW_HIDE_PRODUCT_MAX_COUNT) {
        const message = '一括公開/非公開できる商品の最大数は' + Constants.BULK_ACTION_SHOW_HIDE_PRODUCT_MAX_COUNT + '件です。';
        window.alert(message);
        return;
      }
    }
    // 一括削除の場合
    else if(actionType === Constants.BULK_ACTION_TYPE_DELETE_PRODUCT) {
      const selectCount = SelectionService.instance().getSelectCount();
      if(selectCount > Constants.BULK_ACTION_DELETE_PRODUCT_MAX_COUNT) {
        const message = '一括削除できる商品の最大数は' + Constants.BULK_ACTION_DELETE_PRODUCT_MAX_COUNT + '件です。';
        window.alert(message);
        return;
      }
    }

    // 確認メッセージ
    let message = SelectionService.instance().getSelectCount()
      + '件の商品に対して' + Constants.BULK_ACTION_TYPE_LABELS[actionType] + 'を実行しますか?';
    const ret = window.confirm(message);
    if(!ret) {
      return;
    }

    try {
      updateState({processing: true});

      // 一括処理
      message = await BulkActionService.getInstance().executeBulkAction(actionType);
      // 選択クリア
      SelectionService.instance().clearSelection();
      // 再検索
      await ProductService.getInstance().reSearch();

      updateState({processing: false});
    }
    catch (e) {
      LogUtils.ex(e);

      updateState({processing: false});

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = '一括処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  }

  /**
   * アイコン取得
   * @param actionType
   */
  const icon = (actionType: number) => {
    switch (actionType) {
      case Constants.BULK_ACTION_TYPE_SHOW_PRODUCT:
        return <Visibility color={"action"}/>;
      case Constants.BULK_ACTION_TYPE_HIDE_PRODUCT:
        return <VisibilityOffIcon color={"action"}/>;
      case Constants.BULK_ACTION_TYPE_DELETE_PRODUCT:
        return <DeleteIcon  color={"action"}/>;
      default:
        return <></>;
    }
  }

  /**
   * 描画
   */
  const isAnySelected = SelectionService.instance().isAnySelect();
  const isAdmin = LoginService.getInstance().loginUser?.isAdmin() ?? false;

  const open = Boolean(state.anchorEl);
  return (
    <Box>
      <Button
        id="button-menu"
        aria-controls="bulk-action-menu"
        aria-haspopup="true"
        onMouseOver={handleMouseOver}
        variant={'outlined'}
        color={'secondary'}
        disabled={!isAnySelected || !isAdmin}
      >
        一括処理▼
      </Button>
      <Menu
        id="bulk-action-menu"
        anchorEl={state.anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{
          onMouseLeave: handleClose
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {Object.keys(Constants.BULK_ACTION_TYPE_LABELS).map((key) => {
          const actionType = parseInt(key, 10);  // キーを数値に変換
          const label = Constants.BULK_ACTION_TYPE_LABELS[actionType];

          return (
            <MenuItem key={actionType} onClick={() => onActionClick(actionType)}>
              {icon(actionType)}{"　" + label}
            </MenuItem>
          );
        })}
      </Menu>

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </Box>
  );
};
