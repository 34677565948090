import React, {KeyboardEvent, useEffect, useState} from 'react';
import {TextField, Button, Box, Divider} from '@mui/material';

interface AIChatInputFormProps {
  onSendMessage: (messageText: string) => void;
}

/**
 * AIチャット入力フォーム
 * @param props
 * @constructor
 */
export const AIChatInputForm = (props: AIChatInputFormProps ) => {

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    messageText: '',
  });


  /**
   * useEffect
   */
  useEffect(() => {
  }, []);

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 送信/保存ボタン クリック時
   */
  const handleSubmit = () => {
    props.onSendMessage(state.messageText);
    updateState({ messageText: '' });
  };

  /**
   * キーダウン時
   * @param e
   */
  const handleKeyDown = (e: KeyboardEvent) => {
    // Shift + Enterが押された場合の処理
    if (e.ctrlKey && e.key === 'Enter') {
      handleSubmit();
      // ここに必要なアクションを実装
      e.preventDefault(); // テキストフィールドのデフォルトの挙動（改行の挿入）を防ぐ
    }
  };

  /**
   * 描画
   */
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Divider />
      <Box mt={0.5} />
      <Box display={"flex"} flexDirection={"row"} pl={1} pr={1}>
        <Box flexGrow={1} />
        <Button
          variant="contained"
          color="primary"
          size={"small"}
          disabled={state.messageText.replaceAll("\n", "").length === 0}
          onClick={handleSubmit}
        >
          送信
        </Button>
      </Box>
      <Box mt={0.5} />
      <TextField
        id={"AIChatInputForm"}
        minRows={3}
        multiline={true}
        value={state.messageText}
        onChange={(e) => updateState({messageText: e.target.value})}
        onKeyDown={handleKeyDown}
        placeholder={"ここにメッセージ内容を入力\n(Ctrl + Enterキーで送信)"}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: 0, // アウトラインドスタイルのパディングを削除
            '& .MuiOutlinedInput-input': {
              padding: '8px', // アウトラインドインプットの内側のパディングを調整
            },
            '& .MuiOutlinedInput-multiline': {
              padding: 0, // 複数行テキストのパディングを削除
            }
          },
          '& .MuiInputBase-input': {
            fontSize: '16px',
          },
        }}
      />
    </Box>
  );
};
