import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Store} from "../../../Models/Store";
import {AppBar, Box, IconButton, Toolbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Constants} from "../../../Common/Constants";
import {CommonUtils} from "../../../Common/CommonUtils";
import {EMTextField} from "../../Common/Components/EMTextField";
import {EMDatePicker} from "../../Common/Components/EMDatePicker";

interface StoreEditDialogProps {
  open: boolean;
  store: Store;
  onClose: () => void;
  onSave: (store: Store) => void;
  errors: Record<string, string>,
}

/**
 * 店舗設定ダイアログ
 * @constructor
 * @param props
 */
export const StoreEditDialog = (props: StoreEditDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    editedStore: new Store({}),
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * props変更時処理
   */
  useEffect(() => {
    const editedStore = props.store.copyWith({});
    updateState({ editedStore: editedStore });
  }, [props]);


  /**
   * フィールドが変更されたときのハンドラー
   * @param name
   * @param value
   */
  const handleChange = (name: string, value: string) => {
    const newStore = state.editedStore.copyWith({ [name]: value });
    updateState({ editedStore: newStore });
  };

  /**
   * フィールドが変更されたときのハンドラー（数値）
   * @param name
   * @param value
   */
  const handleChangeNumber =  (name: string, value: string) => {

    let updatedValue: string|undefined = value;
    if ( value === '' ){
      updatedValue = undefined;
    }
    else {
      if ( !CommonUtils.isUnsignedInteger(value) ) {
        return;
      }
    }
    const newMall = state.editedStore.copyWith({ [name]: updatedValue });
    updateState({ editedStore: newMall });
  };

  /**
   * フィールドが変更されたときのハンドラー（日付）
   * @param key
   * @param value
   */
  const handleChangeDate = (key: string, value: Date|undefined) => {
    const newStore = state.editedStore.copyWith({ [key]: value });
    updateState({ editedStore: newStore });
  };

  /**
   * 保存ボタンが押されたときの処理
   */
  const handleSave = () => {
    props.onSave(state.editedStore);
  };

  /**
   * テキストフィールド
   * @param name
   * @param label
   * @param value
   * @param handleChange
   * @param rows
   * @param inputMode
   */
  const renderInput = (
    name: string,
    label: string,
    value: string | number | undefined,
    handleChange: (name: string, value: string) => void,
    rows: number = 1,
    inputMode: 'numeric' | 'text' = 'text' ) => {

    return (
      <Box mt={2}>
        <EMTextField
          label={label}
          value={value == null ? '' : String(value)}
          onChange={(newValue) => handleChange(name, newValue)}
          error={props.errors[name]}
          multiline={rows > 1}
          rows={rows}
          inputProps={{
            inputMode: inputMode,
          }}
        />
      </Box>
    );

  };

  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            店舗編集
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {/* 各フィールドの入力コンポーネント */}
        <Box mt={2} />
        <Typography>モール: {Constants.mallLabels[props.store.mall_no!] ?? ''}</Typography> {/* 表示専用 */}
        <Box mt={2} />
        <Typography>モール店舗識別子: {props.store.mall_store_id ?? ''}</Typography> {/* 表示専用 */}
        <Box mt={2} />
        {renderInput('store_name', '店舗名', state.editedStore.store_name, handleChange)}
        {renderInput('store_code', '店舗コード', state.editedStore.store_code, handleChange)}
        {renderInput('client_id', 'クライアントID', state.editedStore.client_id, handleChange)}
        {renderInput('client_secret', 'クライアントシークレット', state.editedStore.client_secret, handleChange)}
        {renderInput('rkt_service_secret', '楽天サービスシークレット', state.editedStore.rkt_service_secret, handleChange)}
        {renderInput('rkt_license_key', '楽天ライセンスキー', state.editedStore.rkt_license_key, handleChange)}
        <Box mt={2} />
        <EMDatePicker
          label={"楽天ライセンスキー登録日時"}
          value={state.editedStore.rkt_reg_date}
          onChange={(value) => handleChangeDate('rkt_reg_date', value)}
        />

        {renderInput('rkt_shop_url', '楽天ショップURL（店名のみ）', state.editedStore.rkt_shop_url, handleChange)}
        {renderInput('yahoo_pub_key', 'Yahoo!公開鍵', state.editedStore.yahoo_pub_key, handleChange, 8)}
        {renderInput(
          'yahoo_pub_key_ver',
          'Yahoo!公開鍵バージョン',
          state.editedStore.yahoo_pub_key_ver,
          handleChangeNumber,
          1,
          'numeric'
        )}
        <Box mt={2} />
        <EMDatePicker
          label={"Yahoo!公開鍵登録日時"}
          value={state.editedStore.yahoo_reg_date}
          onChange={(value) => handleChangeDate('yahoo_reg_date', value)}
        />

        {renderInput('base_shop_url', 'BASEショップURL', state.editedStore.base_shop_url, handleChange)}

        <DialogActions>
          <Button onClick={handleSave} color="primary" variant={"contained"}>保存</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

