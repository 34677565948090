import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { MuiColorInput } from "mui-color-input";
import {Tag} from "../../../../Models/Tag/Tag";
import {TagCategoryService} from "../../../../Services/Tag/TagCategoryService";
import {EMTextField} from "../EMTextField";
import {EMSelect} from "../EMSelect";
import {LogUtils} from "../../../../Common/LogUtils";

interface TagEditDialogProps {
  open: boolean;
  tag: Tag;
  onClose: () => void;
  onSave: (tag: Tag) => void;
  errors: Record<string, string>,
}

/**
 * タグ編集ダイアログ
 * @param props
 * @constructor
 */
export const TagEditDialog = (props: TagEditDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    editedTag: new Tag({}),
  });

  /**
   * props変更時処理
   */
  useEffect(() => {
    updateState({
      editedTag: props.tag.copyWith({})
    });
  }, [props]);

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * テキストフィールドの値変更時
   * @param name
   * @param value
   */
  const handleChange = (name: string, value: string) => {
    const newTagData = state.editedTag.copyWith({ [name]: value });
    updateState({ editedTag: newTagData });
  };

  /**
   * タグ分類の変更時
   * @param value
   */
  const handleTagCategoryChange = (value: string) => {
    const newTagCategoryId = parseInt(value);

    const newTagData = state.editedTag.copyWith({
      tag_category_id: isNaN(newTagCategoryId) ? undefined : newTagCategoryId,
    });
    updateState({ editedTag: newTagData });
  };

  /**
   * 色の変更時
   * @param value
   */
  const handleColorChange = (value: string) => {
    const newTagData = state.editedTag.copyWith({ color: value });
    updateState({ editedTag: newTagData });
  };

  /**
   * 保存ボタンクリック時
   */
  const handleSave = () => {
    props.onSave(state.editedTag);
  };

  /**
   * タグ分類の選択肢を取得
   */
  const tagCategories = () => {
    return TagCategoryService.getInstance().getAllTagCategories().map(tagCategory => ({
      id: tagCategory.id?.toString() ?? '',
      label: tagCategory.name ?? '',
      color: tagCategory.color ?? '',
    }));
  }

  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            タグ編集
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box mt={2} />
        <EMTextField
          label="タグ名"
          value={state.editedTag.name ?? ''}
          onChange={(value) => handleChange('name', value)}
          error={props.errors['name']}
          sx={{ width: 400 }}
        />
        <Box mt={3} />
        <EMSelect
          label="タグ分類"
          value={state.editedTag.tag_category_id?.toString() ?? ''}
          onChange={handleTagCategoryChange}
          menuItems={tagCategories()}
          itemValueKey="id"
          itemLabelKey="label"
          hideUnselected={false}
          showValueWithLabel={false}
          error={props.errors['tag_category_id']}
          sx={{ width: 400 }}
        />
        <Box mt={1} />
        <Box display={'flex'} flexDirection={"column"} alignItems={'start'} sx={{ width: 200 }}>
          <Typography variant={"caption"} sx={{ color: "grey" }}>タグの色</Typography>
          <Box ml={2} />
          <MuiColorInput
            size={'small'}
            format={'hex'}
            isAlphaHidden={false}
            value={state.editedTag.color ?? ''}
            onChange={handleColorChange}
            sx={{ width: 400 }}
          />
        </Box>
        <Box mt={2} />
        <DialogActions>
          <Button onClick={handleSave} color="primary" variant={"contained"}>保存</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
