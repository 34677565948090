import React, {useEffect, useRef} from 'react';
import ImageGallery from 'react-image-gallery';
import {AppBar, Box, Dialog, IconButton, Toolbar, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {LogUtils} from "../../../../Common/LogUtils";

interface CustomImageGalleryProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  items: {
    original: string;
    thumbnail: string;
    description?: string;
  }[];
  startIndex?: number;
  showFullscreenButton?: boolean;
}

/**
 * イメージギャラリーダイアログ
 * @param props
 * @constructor
 */
export const CustomImageGallery = (props: CustomImageGalleryProps) => {
  /**
   * useRef
   */
  const galleryRef = useRef<ImageGallery>(null);

  /**
   * ギャラリーのルート要素
   */
  let galleryRoot: HTMLElement | undefined;

  /**
   * マウスホイールイベントのハンドラ
   * @param event
   */
  const handleWheel = (event: WheelEvent) => {
    //LogUtils.d('handleWheel')
    if (galleryRef.current) {
      const currentIndex = galleryRef.current.getCurrentIndex();
      const totalImages = props.items.length;

      if (event.deltaY > 0 && currentIndex > 0) {
        // 下にスクロールし、かつ最初の画像ではない場合、前の画像へ
        galleryRef.current.slideToIndex(currentIndex - 1);
      } else if (event.deltaY < 0 && currentIndex < totalImages - 1) {
        // 上にスクロールし、かつ最後の画像ではない場合、次の画像へ
        galleryRef.current.slideToIndex(currentIndex + 1);
      }
    }
  };

  /**
   * スロットル処理
   * @param func
   * @param limit
   */
  function throttle(func: (event: WheelEvent) => void, limit: number) {
    let inThrottle: boolean;
    return function(this: any) {
      const args = arguments as unknown as [WheelEvent];
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => inThrottle = false, limit);
      }
    }
  }

  /**
   * スロットル処理されたホイールイベントのハンドラ
   */
  const throttledHandleWheel = throttle(handleWheel, 100); // 100ミリ秒間隔でスロットル

  /**
   * 画像読み込みイベント処理
   */
  const onImageLoad = () => {
    // 初回だけイベントリスナーを登録
    if ( !galleryRoot ) {
      galleryRoot = document.querySelector('.image-gallery') as any;
      if (galleryRoot) {
        //LogUtils.d('addEventListener')
        galleryRoot.addEventListener('wheel', throttledHandleWheel);
      }
    }
  }

  /**
   * ダイアログ表示イベント処理
   */
  useEffect(() => {
    // 表示終了時
    return () => {
      if ( galleryRoot ) {
        //LogUtils.d('removeEventListener')
        galleryRoot?.removeEventListener('wheel', throttledHandleWheel);
        galleryRoot = undefined;
      }
    };
  }, [props.open]);

  /**
   * サムネイル描画
   * @param item
   */
  const renderThumbInner = (item: any) => {
    return (
      <Box position="relative" margin={1}>
        <img
          src={item.thumbnail}
          alt={item.description}
          style={{
            height: '100%',
            width: '100%',
          }}
        />
        {item.description && (
          <Box position="absolute" bottom={0} width="100%" bgcolor="rgba(0, 0, 0, 0.6)" p={0.5}>
            <Typography variant="caption" color="white">
              {item.description}
            </Typography>
          </Box>
        )}
      </Box>

    );
  };

  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ImageGallery
        ref={galleryRef}
        items={props.items}
        renderThumbInner={renderThumbInner}
        showPlayButton={false}
        showFullscreenButton={props.showFullscreenButton ?? true}
        slideDuration={10}
        startIndex={props.startIndex}
        onImageLoad={onImageLoad}
      />
    </Dialog>
  );
}

