import React, {useEffect, useState} from 'react';
import {components, OnChangeValue, StylesConfig} from 'react-select';
import { Tag } from "../../../../Models/Tag/Tag";
import chroma from "chroma-js";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CreatableSelect from 'react-select/creatable';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PaletteIcon from '@mui/icons-material/Palette';
import {Box, IconButton, Popover} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {ColorDialog} from "../ColorDialog";
import {TagEditDialog} from "./TagEditDialog";
import {TagCategory} from "../../../../Models/Tag/TagCategory";
import {TagCategoryService} from "../../../../Services/Tag/TagCategoryService";

interface ECTagSelectProps {
  optionTags: Tag[];
  selectedTags: Tag[];
  onChange?: (selectedTags: Tag[]) => void;
  onCreateNewTag?: (newTagName: string) => void;
  onTagPropertiesChange?: (tag: Tag) => void;
  onTagDelete?: (tag: Tag) => void;
  showEditButton?: boolean;
  showBorder?: boolean;
  creatable?: boolean;
  isClearable: boolean;
}
export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
}

/**
 * タグ選択
 * @param props
 * @constructor
 */
export const ECTagSelect = (props: ECTagSelectProps) => {

  const MAX_MENU_HEIGHT = 600; // 表示するメニュー高さ。あまり大きくするとスクロールしなくなる。。

  /**
   * useState
   */
  const [state, setState] = useState({
    openEditDialog: false,
    openColorDialog: false,
    openContextMenu: false,
    editingTag: undefined as Tag | undefined,
    sortedOptionTags: undefined as Tag[] | undefined,
    sortedSelectedTags: undefined as Tag[] | undefined,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * props更新時
   */
  useEffect(() => {
    const sortedOptionTags = sortTags(props.optionTags);
    const sortedSelectedTags = sortTags(props.selectedTags);
    updateState({ sortedOptionTags, sortedSelectedTags });
  }, [props.optionTags, props.selectedTags]);

  /**
   * タグ選択時
   * @param newValue
   */
  const handleChange = (
    newValue: OnChangeValue<{ label: string; value: string }, true>,
  ) => {
    // newValueからTagオブジェクトの配列を復元する際に、valueがundefinedでないことを保証
    const selectedTags: Tag[] = (newValue as any[]).filter(option => option.value !== undefined).map(option => {
      return props.optionTags.find(tag => tag.id?.toString() === option.value) as Tag;
    });

    if ( props.onChange ) {
      props.onChange(selectedTags);
    }
  };

  /**
   * 新規タグ作成
   * @param inputValue
   */
  const handleCreate = async (inputValue: string) => {
    // 新規タグ作成用のコールバックが提供されている場合は、その関数を呼び出す
    if (props.onCreateNewTag) {
      props.onCreateNewTag(inputValue);
    }
  };

  /**
   * タグをソートする関数
   * @param tags
   * @returns ソートされたタグの配列
   */
  const sortTags = (tags: Tag[]): Tag[] => {
    return [...tags].sort((a, b) => {
      const tagCategoryA = TagCategoryService.getInstance().getTagCategoryById(a.tag_category_id ?? 0);
      const tagCategoryB = TagCategoryService.getInstance().getTagCategoryById(b.tag_category_id ?? 0);

      const orderA = tagCategoryA?.order_number ?? Infinity;
      const orderB = tagCategoryB?.order_number ?? Infinity;

      if (orderA !== orderB) {
        return orderA - orderB;
      }
      return (a.name ?? '').localeCompare(b.name ?? '');
    });
  };

  /**
   * optionsTagsからselectのoptionsを生成
   */
  const options = state.sortedOptionTags?.map(tag => ({
    label: tag.name ?? '',
    value: tag.id?.toString() ?? '',
    color: tag.color ?? 'black',
  })).filter(option => option.value !== '') ?? [];

  /**
   * selectedTagsから現在選択されている値を生成
   */
  const value = state.sortedSelectedTags?.map(tag => ({
    label: tag.name ?? '',
    value: tag.id?.toString() ?? '',
    color: tag.color ?? 'black',
  })).filter(option => option.value !== '') ?? []; // idがundefinedのオプションを除外

  /**
   * スタイル
   */
  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => (
      props.showBorder ? styles : { ...styles, border: 0, boxShadow: 'none' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color ?? 'black');
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.1).css()
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

  /**
   * ドロップダウンマーク
   * @param selectProps
   * @constructor
   */
  const DropdownIndicator = (selectProps: any) => {
    return (
      <components.DropdownIndicator {...selectProps}>
        <LocalOfferIcon />
      </components.DropdownIndicator>
    );
  };


  /**
   * タグ分類ラベル
   * @param item
   */
  const tagCategoryLabel = (item?: TagCategory) => {
    const tagCategoryName = item?.name ?? '未分類';
    const tagCategoryColor = item?.color ?? '#FFFFFF';

    return (
      <Box display="flex" alignItems="center">
        {/* タグ分類の色の四角 */}
        <Box
          sx={{
            width: 160,
            height: 20,
            backgroundColor: tagCategoryColor,
            display: 'inline-block',
            marginRight: 1,
            verticalAlign: 'middle',
            borderRadius: '2px',
            padding: '4px 8px',
          }}
        >
          {/* タグ分類名 */}
          <Box sx={{
            color: 'black',
            width: 160,
            display: 'inline-block',
            marginRight: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis' }}>

            {tagCategoryName}
          </Box>
        </Box>
      </Box>
    );
  }


  /**
   * カスタムオプション
   * @param selectProps
   * @constructor
   */
  const CustomOption = (selectProps: any) => {
    // アイコンクリックイベント用のハンドラ
    const handleEditClick = (e: any) => {
      e.stopPropagation(); // オプションの選択を防ぐ
      // propsよりタグを見つける
      const tag = props.optionTags.find(tag => tag.id?.toString() === selectProps.data.value);
      if ( tag ) {
        updateState({ openEditDialog: true, editingTag: tag });
      }
    };

    const handleColorChangeClick = (e: any) => {
      e.stopPropagation();
      // propsよりタグを見つける
      const tag = props.optionTags.find(tag => tag.id?.toString() === selectProps.data.value);
      if ( tag ) {
        updateState({ openColorDialog: true, editingTag: tag });
      }
    };

    const handleDeleteClick = (e: any) => {
      e.stopPropagation();
      // propsよりタグを見つける
      const tag = props.optionTags.find(tag => tag.id?.toString() === selectProps.data.value);
      if ( tag ) {
        handleDeleteTagClick(tag);
      }
    };

    // タグ分類
    const tag = props.optionTags.find(tag => tag.id?.toString() === selectProps.data.value);
    const tagCategory = TagCategoryService.getInstance().getTagCategoryById(tag?.tag_category_id ?? 0);

    return (
      <components.Option {...selectProps}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          {/* タグ分類 */}
          {tagCategoryLabel(tagCategory)}

          <Box ml={2} />

          {/* タグ名 */}
          {selectProps.children}

          <Box flexGrow={1} />

          {props.showEditButton && (
            <>
              <span onClick={handleEditClick} style={{cursor: 'pointer'}}>
                <EditIcon fontSize="small"/>
              </span>
              <span onClick={handleColorChangeClick} style={{cursor: 'pointer', marginLeft: '10px'}}>
                <PaletteIcon fontSize="small"/>
              </span>
              <span onClick={handleDeleteClick} style={{cursor: 'pointer', marginLeft: '10px'}}>
                <DeleteIcon fontSize="small"/>
              </span>
            </>
          )}
        </Box>

      </components.Option>
    );
  };

  /**
   * カスタムマルチバリューラベル
   * @param selectProps
   * @constructor
   */
  const CustomMultiValueLabel = (selectProps: any) => {
    const [isHovered, setIsHovered] = useState(false); // マウスホバー状態のトラッキング
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMoreClick = (event: any) => {
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iconColor = selectProps.data.color ? selectProps.data.color : 'inherit';

    return (
      <Box
        onMouseEnter={() => setIsHovered(true)} // マウスオーバー時に状態更新
        onMouseLeave={() => setIsHovered(false)} // マウスアウト時に状態更新
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <components.MultiValueLabel {...selectProps}>
          {selectProps.children}
        </components.MultiValueLabel>
        {props.showEditButton && isHovered && (
          <IconButton size="small" sx={{ color: iconColor }} onClick={handleMoreClick} style={{ margin: '-6px -8px' }}>
            <MoreHorizIcon fontSize="small" />
          </IconButton>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box p={1} display="flex">
            <IconButton sx={{ color: iconColor }} onClick={() => {
              // propsよりタグを見つける
              const tag = props.optionTags.find(tag => tag.id?.toString() === selectProps.data.value);
              if ( tag ) {
                updateState({ openEditDialog: true, editingTag: tag });
              }
            }} size="small">
              <EditIcon fontSize="small" />
            </IconButton>

            <IconButton sx={{ color: iconColor }} onClick={() => {
              // propsよりタグを見つける
              const tag = props.optionTags.find(tag => tag.id?.toString() === selectProps.data.value);
              if ( tag ) {
                updateState({ openColorDialog: true, editingTag: tag });
              }
            }} size="small">
              <PaletteIcon fontSize="small" />
            </IconButton>

            <IconButton sx={{ color: iconColor }} onClick={() => {
              // propsよりタグを見つける
              const tag = props.optionTags.find(tag => tag.id?.toString() === selectProps.data.value);
              if ( tag ) {
                handleDeleteTagClick(tag);
              }
            }} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        </Popover>

      </Box>
    );
  };

  /**
   * タグ情報変更
   * @param updatedTag
   */
  const onTagInfoChanged = (updatedTag: Tag) => {
    if (props.onTagPropertiesChange && state.editingTag) {
      const newTag = state.editingTag.copyWith({
        name: updatedTag.name,
        color: updatedTag.color,
        tag_category_id: updatedTag.tag_category_id,
      });
      props.onTagPropertiesChange(newTag);
    }
    updateState({ openEditDialog: false, editingTag: undefined });
  };


  /**
   * タグ色変更
   */
  const onTagColorChanged = (newColor: string) => {
    if ( props.onTagPropertiesChange && state.editingTag ) {
      const newTag = state.editingTag.copyWith({color: newColor});
      props.onTagPropertiesChange(newTag);
    }
    updateState({ openColorDialog: false, editingTag: undefined });
  }

  /**
   * タグ削除ボタンクリック
   */
  const handleDeleteTagClick = (tag: Tag) => {
    if ( props.onTagDelete ) {
      props.onTagDelete(tag);
    }
  }

  /**
   * 描画
   */
  return (
    <>
      <CreatableSelect
        options={options}
        value={value}
        onChange={handleChange}
        onCreateOption={handleCreate}
        isMulti={true}
        isClearable={props.isClearable}
        isValidNewOption={props.creatable ? undefined : () => false}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: 'lightgrey',
            primary: 'grey',
          },
        })}
        placeholder=""
        styles={colourStyles}
        components={{
          DropdownIndicator,
          Option: CustomOption,
          MultiValueLabel: CustomMultiValueLabel,
        }}
        formatCreateLabel={(inputValue) => `新規作成: "${inputValue}"`}
        maxMenuHeight={MAX_MENU_HEIGHT}
        menuPortalTarget={document.body}
      />

      {/*  タグ情報変更ダイアログ */}
      <TagEditDialog
        open={state.openEditDialog}
        tag={state.editingTag ?? new Tag()}
        onClose={() => updateState({ openEditDialog: false, editingTag: undefined })}
        onSave={onTagInfoChanged}  // onTagInfoChangedを使用
        errors={{}}
      />

      {/* 色変更ダイアログ */}
      <ColorDialog
        title={"タグ色の変更"}
        initialColor={state.editingTag?.color}
        open={state.openColorDialog}
        format={"hex"}
        isAlphaHidden={true}
        onClose={() => updateState({
          openColorDialog: false,
          editingTag: undefined,
        })}
        onSave={onTagColorChanged}
      />
    </>
  );
};
