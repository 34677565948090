import React, {useState} from "react";
import { SnackbarContext } from './SnackbarContext';
import {Button, IconButton, Snackbar, SnackbarContent} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface SnackbarProviderOption {
  children: React.ReactNode;
}

/**
 * スナックバー表示
 * @param props
 * @constructor
 */
export const SnackbarProvider = (props: SnackbarProviderOption) => {
  const DURATION = 2000;

  /**
   * useState
   */
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const [autoHideDuration, setAutoHideDuration] = useState<number | null>(DURATION);
  const [showError, setShowError] = useState<boolean>(false);

  /**
   * Snackbarを表示
   * @param message
   * @param showError
   */
  const showSnackbarMessage = (message: string, showError: boolean = false) => {
    setSnackBarMessage(message);
    setShowError(showError);
    if (showError) {
      setAutoHideDuration(null); // 自動的に閉じない設定
    } else {
      setAutoHideDuration(DURATION); // 自動的に閉じる設定
    }
  };
  /**
   * Snackbarを閉じる
   */
  const handleSnackbarClose = (
    event: React.SyntheticEvent<any, Event> | Event,
    reason: string
  ) => {
    if (reason === 'clickaway' && autoHideDuration === null) {
      return; // 自動閉じが無効の場合は、clickaway で閉じない
    }
    onClose();
  };

  /**
   * Snackbarを閉じる
   */
  const onClose = () => {
    setSnackBarMessage(null);
    setShowError(false);
    setAutoHideDuration(DURATION);
  }

  /**
   * 描画
   */
  return (
    <SnackbarContext.Provider value={{ showSnackbarMessage }}>
      <Snackbar
        open={Boolean(snackBarMessage)}
        onClose={handleSnackbarClose}
        autoHideDuration={autoHideDuration}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <SnackbarContent
          style={ showError ? { backgroundColor: 'red', fontSize: '5rem' } : {}}
          message={snackBarMessage}
          action={
            showError ?
            <Button variant={"outlined"} color={"inherit"} onClick={onClose}>OK</Button> :
            <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Snackbar>
      {props.children}
    </SnackbarContext.Provider>
  );
};
