import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TableContainer,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { User } from "../../../Models/User";
import { UserService } from "../../../Services/UserService";
import { SettingConstants } from "../SettingConstants";
import {Constants} from "../../../Common/Constants";
import {LogUtils} from "../../../Common/LogUtils";
import {EcmgApiError} from "../../../Common/EcmgApiError";
import {CommonUtils} from "../../../Common/CommonUtils";
import ECProgress from "../../Common/Components/ECProgress";
import {useSnackbar} from "../../Common/Provider/SnackbarContext";
import {EventService} from "../../../Services/System/EventService";
import {UserEditDialog} from "./UserEditDialog";
import {OfficeService} from "../../../Services/OfficeService";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

/**
 * ユーザー設定
 * @constructor
 */
export const UserSetting = () => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
  };

  /**
   * useState
   */
  const [state, setState] = useState({
    users: [] as User[],
    processing: false,
    editDialogOpen: false,
    editingUser: null as User | null,
    editErrors: {} as Record<string, string>,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    const users = UserService.getInstance().getAllUsers();
    updateState({ users });

    // イベントハンドラ登録
    EventService.getInstance().onEvent(EventService.EVENT_UPDATE_USERS, onUsersUpdated);

    // クリーンアップ
    return () => {
      EventService.getInstance().removeListener(EventService.EVENT_UPDATE_USERS, onUsersUpdated);
    };
  }, []);


  /**
   * ユーザー更新時
   */
  const onUsersUpdated = () => {
    const users = UserService.getInstance().getAllUsers();
    updateState({ users });
  }

  /**
   * ユーザー編集ボタンクリック時
   * @param user
   */
  const handleEdit = (user: User) => {
    updateState({
      editingUser: user,
      editDialogOpen: true ,
      editErrors: {},
    });
  };

  /**
   * ユーザー保存ボタンクリック時
   * @param user
   */
  const handleUserSave = async (user: User) => {

    let message = '';

    try {
      updateState({
        processing: true,
        editingUser: user
      });

      if ( user.id == null ) {
        // ユーザー新規登録
        message = await UserService.getInstance().addUser(user);
      }
      else {
        // ユーザー更新
        message = await UserService.getInstance().updateUser(user);
      }

      updateState({
        processing: false,
        editDialogOpen: false,
        editingUser: null,
        editErrors: {},
      });

    }
    catch (e) {
      LogUtils.ex(e);

      updateState({
        processing: false,
      });

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
        updateState({ editErrors: e.getErrorMessageMap()});
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = 'ユーザー登録処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);

  };

  /**
   * ユーザー削除ボタンクリック時
   * @param userId
   */
  const handleDelete = async (userId: number) => {
    if(!window.confirm('このユーザーを削除しますか？')) {
      return;
    }

    let message = '';

    try {
      updateState({ processing: true });

      // ユーザー削除削除
      message = await UserService.getInstance().deleteUser(userId);

      updateState({ processing: false });

    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = 'ユーザー削除処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  // 描画
  return (
    <Box sx={BOX_STYLE}  mt={2}>
      <Box><Typography>ユーザー設定</Typography></Box>
      <Box display="flex" justifyContent="flex-end" mr={2}>
        <Button variant={"contained"} component="span" onClick={() => handleEdit(new User())}>
          新規登録
        </Button>
      </Box>
      <TableContainer style={{ maxHeight: '400px' }}>
        <Table>
          <TableHead style={SettingConstants.TABLE_HEADER_STYLE}>
            <TableRow>
              <TableCell>色</TableCell>
              <TableCell>ユーザー名</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>ユーザータイプ</TableCell>
              <TableCell>事業所</TableCell>
              <TableCell align={"center"}>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.users.map(user => (
              <TableRow key={user.id}>
                <TableCell>
                  <AccountCircleIcon fontSize="large" style={{ color: UserService.getInstance().getUserColor(user.id ?? 0) }} />
                </TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{Constants.USER_TYPE_LABELS[user.user_type ?? 0]}</TableCell>
                <TableCell>{OfficeService.getInstance().getOfficeById(user.office_id ?? 0)?.name}</TableCell>
                <TableCell align={"center"}>
                  <Button onClick={() => handleEdit(user)}>
                    <EditIcon />
                  </Button>
                  <Button onClick={() => handleDelete(user.id!)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {state.users.length === 0 ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <Typography variant={"body2"}>登録されているユーザーはありません</Typography>
        </Box>
      ) : null }

      {/* ユーザー編集ダイアログ */}
      <UserEditDialog
        open={state.editDialogOpen}
        user={state.editingUser ?? new User()}
        onClose={() => updateState({ editDialogOpen: false })}
        onSave={handleUserSave}
        errors={state.editErrors}
      />

      {/* プログレス */}
      <ECProgress open={state.processing}></ECProgress>
    </Box>
  );
}
