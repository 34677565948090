import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {EMDatePicker} from "./EMDatePicker";

interface DateRangeDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (startDate: Date, endDate: Date) => void;
  initialStartDate?: Date;
  initialEndDate?: Date;
}

/**
 * 日付範囲ダイアログ
 * @param props
 * @constructor
 */
export const DateRangeDialog = (props: DateRangeDialogProps) => {

  /**
   * 状態
   */
  const [state, setState] = useState({
    startDate: props.initialStartDate,
    endDate: props.initialEndDate,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  /**
   * props変更時
   */
  useEffect(() => {
    // ダイアログが開かれた時に初期値をセットする
    if (props.open) {
      setState({
        startDate: props.initialStartDate,
        endDate: props.initialEndDate,
      });
    }
  }, [props.open, props.initialStartDate, props.initialEndDate]);


  /**
   * 保存
   */
  const handleSave = () => {
    props.onSave(state.startDate!, state.endDate!);
  };


  /**
   * 開始日変更
   * @param date
   */
  const onStartChanged = (date: Date | undefined) => {
    // 開始日が変更された場合は、終了日も変更
    updateState({ startDate: date, endDate: date });
  }


  const isSaveDisabled = !state.startDate || !state.endDate || new Date(state.startDate) > new Date(state.endDate);


  return (
    <Dialog open={props.open} onClose={props.onClose} >
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            開始日と終了日の入力
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box mt={2}>
          <EMDatePicker
            label="開始日"
            value={state.startDate}
            onChange={onStartChanged}
          />
        </Box>
        <Box mt={2}>
          <EMDatePicker
            label="終了日"
            value={state.endDate}
            onChange={(date) => updateState({ endDate: date })}
          />
        </Box>
        <Box mt={2}>
          <DialogActions>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              disabled={isSaveDisabled}>
              保存
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
