import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {MallService} from "../../../Services/MallService";
import {Mall} from "../../../Models/Mall";
import {SettingConstants} from "../SettingConstants";
import {MallEditDialog} from "./MallEditDialog";
import {LogUtils} from "../../../Common/LogUtils";
import {EcmgApiError} from "../../../Common/EcmgApiError";
import {CommonUtils} from "../../../Common/CommonUtils";
import {useSnackbar} from "../../Common/Provider/SnackbarContext";
import ECProgress from "../../Common/Components/ECProgress";

/**
 * モール設定コンポーネント
 */
export const MallSetting = () => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
  };
  /**
   * useState
   */
  const [state, setState] = useState({
    malls: [] as Mall[],
    editDialogOpen: false,
    editErrors: {} as Record<string, string>,
    selectedMall: new Mall({}),
    processing: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();
  /**
   * マウント時の処理
   */
  useEffect(() => {
    updateState({ malls: MallService.getInstance().getAllMalls() });

    // アンマウント時の処理
    return () => {
    };
  }, []);

  /**
   * 編集ボタンクリック時の処理
   * @param mall
   */
  const handleEditClick = (mall: Mall) => {
    updateState({
      selectedMall: mall ,
      editDialogOpen: true,
      editErrors: {},
    });
  };

  /**
   * ダイアログでの保存処理
   * @param editedMall
   */
  const handleSave = async (editedMall: Mall) => {
    let message = '';

    try {
      updateState({
        processing: true,
        selectedMall: editedMall
      });

      // モール情報更新
      message = await MallService.getInstance().updateMall(editedMall);

      // モール情報再取得
      updateState({
        processing: false,
        editDialogOpen: false,
        selectedMall: new Mall({}),
        editErrors: {},
        malls: MallService.getInstance().getAllMalls()
      });
    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });
      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
        updateState({ editErrors: e.getErrorMessageMap()});
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = 'モール情報登録処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  /**
   * ダイアログを閉じる処理
   */
  const handleClose = () => {
    updateState({
      editDialogOpen: false,
    });
  };

  /**
   * 描画
   */
  return (
    <>
      <Box mt={2}></Box>
      <Box sx={BOX_STYLE}>
        <Box><Typography>モール設定</Typography></Box>
        <TableContainer>
            <Table>
              <TableHead style={SettingConstants.TABLE_HEADER_STYLE}>
                <TableRow>
                  <TableCell>モール名</TableCell>
                  <TableCell align={"center"}>アクション</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.malls.map((mall) => (
                  <TableRow key={mall.mall_no}>
                    <TableCell>{mall.name}</TableCell>
                    <TableCell align={"center"}>
                      <Button onClick={() => handleEditClick(mall)}>
                        <EditIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
        </TableContainer>
      </Box>

      {/*  編集ダイアログ */}
      <MallEditDialog
        open={state.editDialogOpen}
        mall={state.selectedMall}
        onClose={handleClose}
        onSave={handleSave}
        errors={state.editErrors}
      />

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </>
  );
};

