/**
 * 定数
 */
export class AIConstants
{
  // AIタイプ
  public static AI_TYPE_DESIGN = 'design';
  public static AI_TYPE_DATA = 'data';
  public static AI_TYPE_SALES_FORECAST = 'sales_forecast';

  // ロール
  public static AI_ROLE_USER = 'user';
  public static AI_ROLE_ASSISTANT = 'assistant';

  // 待機メッセージ
  public static WAITING_MESSAGE = '[WAITING_MESSAGE]';

}
