import React from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';  // 宅急便のアイコン
import EmailIcon from '@mui/icons-material/Email';  // メール便のアイコン
import Box from '@mui/material/Box';
import { UnitProduct } from "../../../../Models/Product/UnitProduct";
import {Constants} from "../../../../Common/Constants";

type ShippingMethodProps = {
  unitProducts: UnitProduct[];
  width: number;
};

/**
 * 配送方法
 * @param props
 * @constructor
 */
export const ShippingMethod = (props: ShippingMethodProps) => {
  /**
   * 定数
   */
  // 外側Boxのスタイル
  const BOX_STYLE = {
      boxSizing: 'border-box',
      display: 'flex',
      flexWrap: 'wrap',
      width: props.width,
      minWidth: props.width,
    };

  /**
   * 配送方法コードに応じたアイコンを返す
   * @param shippingCode
   */
  const getIconForShippingCode = (shippingCode: number) => {
    switch (shippingCode) {
      case Constants.SHIPPING_METHOD_CODE_EXPRESS:
        return <LocalShippingIcon sx={{ color: 'deepskyblue' }} />;
      case Constants.SHIPPING_METHOD_CODE_MAIL:
        return <EmailIcon sx={{ color: 'darkorange' }} />;
      default:
        return null;
    }
  }

  /**
   * 描画
   */
  // 1件の場合は100%, それ以外は33.33%で描画
  const width = props.unitProducts.length === 1 ? '100%' : '33.33%';
  return (
    <Box sx={BOX_STYLE}>
      {props.unitProducts.map((unitProduct: UnitProduct, index: number) => (
        <Box key={index} sx={{ width: width, display: 'flex', justifyContent: 'center' }}>
          {getIconForShippingCode(unitProduct.shipping_code || 0)}
        </Box>
      ))}
    </Box>
  );
};
