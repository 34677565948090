import React, {ReactNode, useEffect, useState} from 'react';
import {Box, Link} from '@mui/material';
import {ChatMessage} from "../../../Models/Chat/ChatMessage";
import {ChatTagTo} from "./Tag/ChatTagTo";
import {ChatTagToAll} from "./Tag/ChatTagToAll";
import {ChatTagReply} from "./Tag/ChatTagReply";
import {ChatTagQuote} from "./Tag/ChatTagQuote";
import {ChatQuotePanel} from "./Quote/ChatQuotePanel";
import {ChatTagToOffice} from "./Tag/ChatTagToOffice";
import {ReplyInfo} from "./ChatInterface";

interface ChatMessageContentProps {
  message: ChatMessage;
  onRePopup?: (replyInfo: ReplyInfo) => void; // ポップアップ表示時に再度ポップアップを表示した場合のコールバック
  parentRef?: React.RefObject<HTMLDivElement>; // 親コンポーネントのRef
}

/**
 * チャット内容
 * @param props
 * @constructor
 */
export const ChatMessageContent = (props: ChatMessageContentProps) => {

  // 引用ボタンのオフセット
  const QUOTE_BUTTON_OFFSET_X = 20;
  const QUOTE_BUTTON_OFFSET_Y = 30;

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    selectedText: '',
    anchorPosition: { top: 0, left: 0 },
    openQuotePopover: false,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useEffect マウント・アンマウント時
   */
  useEffect(() => {
  //LogUtils.d('ChatMessageContent useEffect');

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('selectionchange', handleSelectionChange);

    // コンポーネントのクリーンアップ時にイベントリスナーを削除
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  /**
   * 選択範囲変更時の処理
   */
  const handleSelectionChange = () => {
    const selection = window.getSelection();
    if (selection != null && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const contentNode = props.parentRef?.current as any;
      // 選択された範囲の開始ノードがコンポーネント内にあるかどうかをチェック
      if (contentNode && contentNode.contains(range.commonAncestorContainer)) {
        setState( prevState => {
          return { ...prevState, selectedText: selection.toString() };
        });
      }
    }
  };

  /**
   * マウスアップ時の処理
   * @param e
   */
  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const handleMouseUp = (e: MouseEvent) => {
    // コンポーネント内でmouseupイベントが発生したかチェック
    const contentNode = props.parentRef?.current as any;
    if (contentNode && contentNode.contains(e.target)) {
      // 既存のタイマーがあればクリア
      if (timeoutId !== null) clearTimeout(timeoutId);

      // 新しいタイマーを設定（トリプルクリックを有効にするために）
      const newTimeoutId = window.setTimeout(() => {
        const selection = window.getSelection();
        const selectedText = selection ? selection.toString() : '';
        if (selectedText.length > 0) {
          setState(prevState => ({
            ...prevState,
            selectedText: selectedText,
            anchorPosition: {
              top: e.clientY + QUOTE_BUTTON_OFFSET_Y,
              left: e.clientX + QUOTE_BUTTON_OFFSET_X
            },
            openQuotePopover: true
          }));
        }
      }, 400); // 遅延

      setTimeoutId(newTimeoutId);
    }
  };
  // コンポーネントのクリーンアップ時にタイマーをクリア
  useEffect(() => {
    return () => {
      if (timeoutId !== null) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  /**
   * 文字列解析
   * @param content
   */
  const parseTaggedContent = (content: string): ReactNode[] => {
    const stack: string[] = [];
    let currentText = '';
    const nodes: ReactNode[] = [];
    let componentKey = 0; // コンポーネントに割り当てるキー

    for (let i = 0; i < content.length; i++) {
      const char = content[i];
      //---------
      // タグ開始
      //---------
      if (char === '[') {
        if (currentText) {
          nodes.push(...renderTextWithLineBreaks(`text-${componentKey}`, currentText));
          componentKey++;
          currentText = '';
        }
        stack.push(char);
      }
      //---------
      // タグ終了
      //---------
      else if (char === ']') {
        const tagContent = stack.join('') + char;
        //---------
        // TO
        //---------
        if (tagContent.startsWith('[To:')) {
          nodes.push(<ChatTagTo key={componentKey++} tag={tagContent} />);
        }
        //---------
        // TO ALL
        //---------
        else if (tagContent === '[toall]') {
          nodes.push(<ChatTagToAll key={componentKey++} />);
        }
        //---------
        // TO OFFICE
        //---------
        else if (tagContent.startsWith('[tooffice:')) {
          nodes.push(<ChatTagToOffice key={componentKey++} tag={tagContent} />);
        }
        //---------
        // リプライ
        //---------
        else if (tagContent.startsWith('[rp aid=')) {
          const replyInfo = parseReplyTag(tagContent);
          nodes.push(<ChatTagReply
            key={componentKey++}
            replyInfo={replyInfo}
            onRePopup={props.onRePopup}
          />);
        }
        //---------
        // 引用
        //---------
        else if ( tagContent.startsWith('[引用') ) {
          const quoteContent = parseQuoteContent(content, i + 1);
          i = quoteContent.index;
          nodes.push(<ChatTagQuote
            key={componentKey++}
            tag={tagContent}
          >
            {quoteContent.nodes}
          </ChatTagQuote>);
        }
        else {
          currentText += (tagContent);
        }

        stack.length = 0;
      }
      // タグ開始中
      else if (stack.length > 0) {
        stack.push(char);
      }
      // 通常の文字
      else {
        currentText += char;
      }
    }

    if (currentText) {
      nodes.push(...renderTextWithLineBreaks(`text-${componentKey}`, currentText));
    }

    return nodes;
  }

  /**
   * 引用解析
   * @param content
   * @param startIdx
   */
  const parseQuoteContent = (content: string, startIdx: number): { nodes: ReactNode[], index: number } => {
    const nodes: ReactNode[] = [];
    let currentText = '';
    let depth = 1;

    for (let i = startIdx; i < content.length; i++) {
      const char = content[i];
      if (content.substring(i).startsWith('[引用')) {
        depth++;
      } else if (content.substring(i).startsWith('[/引用]')) {
        depth--;
        if (depth === 0) {
          if (currentText) {
            nodes.push(parseTaggedContent(currentText));
            currentText = ''; // Reset currentText after processing
          }
          return { nodes, index: i + '[/引用]'.length }; // Skip "[/引用]"
        }
      }
      currentText += char;
    }

    // Handle the case where the quote is not properly closed
    if (currentText) {
      nodes.push(parseTaggedContent(currentText));
    }
    return { nodes, index: content.length };
  }


  /**
   * 返信タグを解析
   * @param tag
   */
  const parseReplyTag = (tag: string): ReplyInfo | null => {
    // タグを分解して各部分を抽出
    const aidMatch = tag.match(/aid=(\d+)/);
    const uidMatch = tag.match(/uid=(\d+)/);

    const aid = aidMatch ? aidMatch[1] : '';
    const uid = uidMatch ? uidMatch[1] : '';

    if ( Number(aid) > 0 ) {
      return {
        aid: aid,
        uid: uid,
      };
    }
    return null;
  }

  /**
   * テキストを改行で分割して描画
   * @param keyPrefix
   * @param text
   */
  const  renderTextWithLineBreaks = (keyPrefix: string, text: string): ReactNode[] => {
    return text.split('\n').map((line, index) => (
      <span key={`${keyPrefix}-${index}`} style={{verticalAlign: 'top'}}>
        {renderMemoWithLinks(line)}
        {index < text.split('\n').length - 1 && <br />}
      </span>
    ));
  }


  /**
   * メモをリンク付きでレンダリング
   * @param text
   */
  const renderMemoWithLinks = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).flatMap((part, i) =>
      i % 2 === 0 ? (
        part.split('\n').map((line, lineIndex) =>
          // Check if it's the last line; if not, add a <br /> after it
          <React.Fragment key={`${i}-${lineIndex}`}>
            {line}
            {lineIndex < part.split('\n').length - 1 ? <br /> : null}
          </React.Fragment>
        )
      ) : (
        <Link
          href={part}
          key={i}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            whiteSpace: 'break-spaces',
            overflowWrap: 'break-word',
            wordBreak: 'break-all'
          }}>
          {part}
        </Link>
      )
    );
  };

  /**
   * 描画
   */
  return (
    <Box>
      {parseTaggedContent(props.message.message ?? '')}

      <ChatQuotePanel
        open={state.openQuotePopover}
        anchorPosition={state.anchorPosition}
        message={props.message}
        selectedText={state.selectedText}
        onClose={() => updateState({ openQuotePopover: false })}
      />
    </Box>
  );
};

