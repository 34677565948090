/**
 * Mainページ共通の定数
 */
export class MainPageConstants
{
  // 黒
  public static COLOR_BLACK = "#000000";
  // 濃い灰色
  public static COLOR_DARK_GRAY = "#777777";
  // 薄い灰色
  public static COLOR_LIGHT_GRAY = "#CCCCCC";
  // 赤
  public static COLOR_RED = "#ff0000";
  // 緑
  public static COLOR_LIGHT_GREEN = "#EFFFEF";
  // 薄黄
  public static COLOR_LIGHT_YELLOW = "#FFFFE5";
  // 薄紫
  public static COLOR_LIGHT_PURPLE = "#ffe5ff";
  // 透明
  public static COLOR_TRANSPARENT = "transparent";


  // 数値入力欄 横幅
  public static COUNT_INPUT_WIDTH = 80;

  // CircleProgressのサイズ（テキストフィールド用）
  public static CIRCLE_PROGRESS_SIZE_FOR_INPUT = 16;
  // ４つボタンのサイズ
  public static FOUR_BUTTON_SIZE = 30;

}
