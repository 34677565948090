/**
 * 価格パターン (price_patterns)
 */
export class PricePattern {
  id?: number;
  name?: string; // Max length is 255. It's also unique.
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<PricePattern>) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<PricePattern>): PricePattern {
    return new PricePattern({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): PricePattern {
    return new PricePattern({
     ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): PricePattern {
    const decoded = JSON.parse(jsonString);
    return PricePattern.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }
}
