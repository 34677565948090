import {CommonUtils} from "./CommonUtils";
import {Constants} from "./Constants";

/**
 * 検索条件
 */
export class SearchParam {
  keyword?: string;
  page?: number;
  sortNo?: number;
  isDesc?: boolean;
  excludeNoList?: number[];
  searchTargets?: number[];
  includeTagList?: number[];
  excludeTagList?: number[];
  includeTagOr?: boolean;
  chatMessageId?: number;

  constructor(params: Partial<SearchParam>) {
    Object.assign(this, params);
  }

  /**
   * 現在の検索条件をURLから取得
   */
  static fromUrl(): SearchParam {
    const params = new URLSearchParams(window.location.search);
    const keyword = params.get('keyword');
    const page = parseInt(params.get('page') || '1', 10);
    const sortNo = params.get('sort_no') !== null ? parseInt(params.get('sort_no')!, 10) : null;
    const isDesc = params.get('is_desc') !== null ? params.get('is_desc') === 'true' : null;
    const excludeNoList = params.get('exclude_no_list') ? params.get('exclude_no_list')!.split(',').map(Number) : [];
    const searchTargets = params.get('search_targets') ? params.get('search_targets')!.split(',').map(Number) : [];
    const includeTagList = params.get('include_tag_list') ? params.get('include_tag_list')!.split(',').map(Number) : [];
    const excludeTagList = params.get('exclude_tag_list') ? params.get('exclude_tag_list')!.split(',').map(Number) : [];
    const includeTagOr = params.get('include_tag_or') !== null ? params.get('include_tag_or') === 'true' : null;
    const chatMessageId = params.get('chat_message_id') !== null ? parseInt(params.get('chat_message_id')!, 10) : null;

    // 検索対象が指定されていない場合は商品名を検索対象にする
    if (searchTargets.length === 0) {
      searchTargets.push(Constants.SEARCH_TARGET_PRODUCT_NAME);
    }

    return new SearchParam({
      keyword:  keyword ?? undefined,
      page: isNaN(page) ? 1 : page,
      sortNo: sortNo == null ? undefined : isNaN(sortNo) ? undefined : sortNo,
      isDesc: isDesc ?? true, // 降順をデフォルトに
      excludeNoList: excludeNoList,
      searchTargets: searchTargets,
      includeTagList: includeTagList,
      excludeTagList: excludeTagList,
      includeTagOr: includeTagOr ?? false,
      chatMessageId: chatMessageId == null ? undefined : isNaN(chatMessageId) ? undefined : chatMessageId,
    });
  }
  /**
   * クエリストリングを生成する
   */
  toQueryString(): string {
    const params = new URLSearchParams();
    if (this.keyword != null) {
      params.append('keyword', this.keyword);
    }
    if ( this.page != null) {
      params.append('page', this.page.toString());
    }
    if (this.sortNo != null) {
      params.append('sort_no', this.sortNo.toString());
    }
    if (this.isDesc != null) {
      params.append('is_desc', this.isDesc ? 'true' : 'false');
    }
    if (this.excludeNoList != null && this.excludeNoList.length > 0) {
      params.append('exclude_no_list', this.excludeNoList.join(','));
    }
    if (this.searchTargets != null && this.searchTargets.length > 0) {
      params.append('search_targets', this.searchTargets.join(','));
    }
    if (this.includeTagList != null && this.includeTagList.length > 0) {
      params.append('include_tag_list', this.includeTagList.join(','));
    }
    if (this.excludeTagList != null && this.excludeTagList.length > 0) {
      params.append('exclude_tag_list', this.excludeTagList.join(','));
    }
    if (this.includeTagOr != null) {
      params.append('include_tag_or', this.includeTagOr ? 'true' : 'false');
    }
    if (this.chatMessageId != null) {
      params.append('chat_message_id', this.chatMessageId.toString());
    }

    return params.toString();
  }

  /**
   * 検索実行して良いか?
   */
  canSearch(): boolean {
    return (!CommonUtils.isEmpty(this.keyword) && (this.searchTargets?.length ?? 0) > 0) ||
      this.sortNo != null ||
      (this.excludeNoList?.length ?? 0) > 0 ||
      (this.includeTagList?.length ?? 0) > 0 ||
      (this.excludeTagList?.length ?? 0) > 0;
  }

  /**
   * 現在のインスタンスをコピーし、指定されたパラメータで更新します。
   * @param params - 更新するパラメータ。
   */
  copyWith(params: Partial<SearchParam>): SearchParam {
    return new SearchParam({
      ...this,
      ...params
    });
  }

  /**
   * オブジェクトから SearchParam インスタンスを作成します。
   * @param json - オブジェクト。
   */
  static fromMap(json: { [key: string]: any }): SearchParam {
    return new SearchParam({
      keyword: json.keyword,
      page: json.page,
      sortNo: json.sortNo,
      isDesc: json.isDesc,
      excludeNoList: json.excludeNoList,
      searchTargets: json.searchTargets,
      includeTagList: json.includeTagList,
      excludeTagList: json.excludeTagList,
      includeTagOr: json.includeTagOr,
      chatMessageId: json.chatMessageId,
    });
  }

  /**
   * JSON 文字列から SearchParam インスタンスを作成します。
   * @param jsonString - JSON 文字列。
   */
  static fromJson(jsonString: string): SearchParam {
    const decoded = JSON.parse(jsonString);
    return SearchParam.fromMap(decoded);
  }

  /**
   * SearchParam インスタンスをオブジェクトに変換します。
   */
  toMap(): { [key: string]: any } {
    return {
      keyword: this.keyword,
      page: this.page,
      sortNo: this.sortNo,
      isDesc: this.isDesc,
      excludeNoList: this.excludeNoList,
      searchTargets: this.searchTargets,
      includeTagList: this.includeTagList,
      excludeTagList: this.excludeTagList,
      includeTagOr: this.includeTagOr,
      chatMessageId: this.chatMessageId,
    };
  }

  /**
   * SearchParam インスタンスを JSON 文字列に変換します。
   */
  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  /**
   * 選択状態をクリアする必要があるか?
   */
  needClearSelection(newSearchParam: SearchParam): boolean {
    // // 自分をjsonでログ出力
    // console.log('this: ' + JSON.stringify(this));
    // // 引数をjsonでログ出力
    // console.log('newSearchParam: ' + JSON.stringify(newSearchParam));

    // キーワードが変わった
    if (this.keyword !== newSearchParam.keyword) {
      return true;
    }
    // excludeNoListが変わった
    else if (!CommonUtils.areArraysEqual(this.excludeNoList, newSearchParam.excludeNoList)) {
      return true;
    }
    // searchTargetsが変わった
    else if (!CommonUtils.areArraysEqual(this.searchTargets, newSearchParam.searchTargets)) {
      return true;
    }
    // includeTagListが変わった
    else if (!CommonUtils.areArraysEqual(this.includeTagList, newSearchParam.includeTagList)) {
      return true;
    }
    // excludeTagListが変わった
    else if (!CommonUtils.areArraysEqual(this.excludeTagList, newSearchParam.excludeTagList)) {
      return true;
    }
    // includeTagOrが変わった
    else if (this.includeTagOr !== newSearchParam.includeTagOr) {
      return true;
    }

    return false; // ページ番号やソート順は変わっても選択状態はクリアしない

  }
}
