
/**
 * チャットページのパラメータ
 */
export class AIChatPageParam {
  type?: string;

  constructor(params: Partial<AIChatPageParam>) {
    Object.assign(this, params);
  }

  /**
   * 現在のチャットページパラメータをURLから取得
   */
  static fromUrl(): AIChatPageParam {
    const params = new URLSearchParams(window.location.search);
    const type = params.get('type');

    return new AIChatPageParam({
      type:  type ?? undefined,
    });
  }

  /**
   * クエリストリングを生成する
   */
  toQueryString(): string {
    const params = new URLSearchParams();
    if (this.type != null) {
      params.append('type', this.type);
    }
    return params.toString();
  }

  /**
   * 現在のインスタンスをコピーし、指定されたパラメータで更新します。
   * @param params - 更新するパラメータ。
   */
  copyWith(params: Partial<AIChatPageParam>): AIChatPageParam {
    return new AIChatPageParam({
      ...this,
      ...params
    });
  }

  /**
   * オブジェクトから ChatPageParam インスタンスを作成します。
   * @param json - オブジェクト。
   */
  static fromMap(json: { [key: string]: any }): AIChatPageParam {
    return new AIChatPageParam({
      type: json.type,
    });
  }

  /**
   * JSON 文字列から ChatPageParam インスタンスを作成します。
   * @param jsonString - JSON 文字列。
   */
  static fromJson(jsonString: string): AIChatPageParam {
    const decoded = JSON.parse(jsonString);
    return AIChatPageParam.fromMap(decoded);
  }

  /**
   * ChatPageParam インスタンスをオブジェクトに変換します。
   */
  toMap(): { [key: string]: any } {
    return {
      type: this.type,
    };
  }

  /**
   * ChatPageParam インスタンスを JSON 文字列に変換します。
   */
  toJson(): string {
    return JSON.stringify(this.toMap());
  }
}
