import {Box, Paper, styled, Theme, Typography} from '@mui/material';
import {ChatMessage} from "../../../Models/Chat/ChatMessage";
import {ChatMessageContent} from "./ChatMessageContent";
import React, {useEffect, useState} from "react";
import {ChatActionPanel} from "./Action/ChatActionPanel";
import {ChatReactionPanel} from "./Reaction/ChatReactionPanel";
import {ActionPanelVisibilityService} from "./Action/ActionPanelVisibilityService";


interface ChatMessageBubbleProps {
  message: ChatMessage;
  right?: boolean;
  mention?: boolean;
  isEditing?: boolean;
  parentRef?: React.RefObject<HTMLDivElement>;
  maxWidth?: number;
  minWidth?: number;
  minHeight?: number;
}

/**
 * チャット吹き出し
 * @param props
 * @constructor
 */
export const ChatMessageBubble = (props: ChatMessageBubbleProps) => {

  // 背景色
  let backgroundColor = '#EDEDED';
  if (props.mention) {
    backgroundColor = '#fffacd';
  }
  else if(props.right) {
    if (props.isEditing) {
      backgroundColor = '#c0c0c0';
    }
    else {
      backgroundColor = '#BDF498';
    }
  }

  // アクションパネル表示サービス
  const actionVisibilityService = ActionPanelVisibilityService.getInstance();

  /**
   * useEffect
   */
  useEffect(() => {
    actionVisibilityService.subscribe(updateActionVisibility);

    return () => {
      actionVisibilityService.unsubscribe(updateActionVisibility);
    };
  }, []);

  /**
   * アクションパネルの表示更新イベント
   */
  const updateActionVisibility = () => {
    setState(prevState => {
      const showActionPanel = actionVisibilityService.isMessageIdVisible(props.message.id ?? 0);
      if ( showActionPanel === prevState.isActionPanelVisible ) {
        return prevState;
      }
      return {
        ...prevState,
        isActionPanelVisible: showActionPanel
      };
    });
  }

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    isActionPanelVisible: false,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * アクションパネル表示
   */
  const isShowActionPanel = () => {
    return state.isActionPanelVisible && !props.isEditing;
  }

  /**
   * 吹き出しとテールのスタイルを定義
   */
  const Bubble = styled(Paper)(({ theme }: { theme: Theme }) => ({
    position: 'relative',
    padding: theme.spacing(1),
    backgroundColor: backgroundColor,
    minWidth: props.maxWidth ? props.maxWidth : 800,
    maxWidth: props.minWidth ? props.minWidth : 800,
    minHeight: props.minHeight ? props.minHeight : 50,
    borderRadius: theme.shape.borderRadius,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: props.right ? '90%' : 10,
      width: 0,
      height: 0,
      border: '10px solid transparent',
      borderTop: 'none', // 上のボーダーを消去
      borderBottomColor: backgroundColor,
      transform: 'translateY(-100%)', // Y軸方向の位置を上に調整
    }
  }));

  /**
   * アクションパネル
   */
  const actionPanel = () => {
    if (props.isEditing) {
      return (
        <Typography variant={"caption"} color={"primary"} sx={{fontWeight: "bold"}}>
          編集中
        </Typography>
      );
    }
    else {
      return (
        <ChatActionPanel
          visible={isShowActionPanel()}
          message={props.message}
        />
      );
    }
  }

  /**
   * 描画
   */
  return (
    <>
      <Bubble elevation={3}
        onMouseEnter={() => {
          if ( !actionVisibilityService.isMessageIdVisible(props.message.id ?? 0) ) {
            actionVisibilityService.setMessageIdVisibility(props.message.id ?? 0, true);
          }
        }}
        // onMouseLeave={() => {
        //   if ( state.isHovered ) {
        //     //console.log("onMouseLeave" + props.message.id);
        //     updateState({isHovered: false});
        //   }
        // }}
      >

        <ChatMessageContent
          message={props.message}
          parentRef={props.parentRef}
        />
        <Box display={"flex"} flexDirection={"row"} >
          <ChatReactionPanel message={props.message} />
          <Box flexGrow={1} />
          {actionPanel()}
        </Box>
      </Bubble>
    </>
  );
};

