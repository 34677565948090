import React, {KeyboardEvent, useEffect, useState} from "react";
import {Box, CircularProgress, FormControl, TextField} from "@mui/material";
import {Product} from "../../../../../Models/Product/Product";
import {UnitProduct} from "../../../../../Models/Product/UnitProduct";
import {StoreProduct} from "../../../../../Models/Product/StoreProduct";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {ProductService} from "../../../../../Services/ProductService";
import {EcmgApiError} from "../../../../../Common/EcmgApiError";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {LogUtils} from "../../../../../Common/LogUtils";

interface UnitStockCountProps {
  product: Product;
  unitProduct: UnitProduct;
  storeProducts: StoreProduct[];
  width: number;
  canChange: boolean;
}

/**
 * ユニット在庫数
 * @constructor
 */
export const UnitStockCount = (props: UnitStockCountProps) => {
  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    stockCount: undefined as number | undefined,
    processing: false,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {

    // クリーンアップ
    return () => {
    };

  }, []);


  /**
   * props変更時処理
   */
  useEffect(() => {
    // 合計在庫数を計算
    const totalStockCount = props.storeProducts.reduce((total, sp) => total + (sp.mall_quantity || 0), 0);

    // 商品のモール連動フラグに応じて表示する在庫数を選択
    const stockCount = props.product.mall_flag === 1 ? props.unitProduct.stock_count : totalStockCount;
    updateState({ stockCount: stockCount || 0});

  }, [props]);

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * ユニット在庫数変更時
   * @param event
   */
  const handleStockChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if ( value === '' ){
      updateState({stockCount: undefined});
      return;
    }

    if ( !CommonUtils.isUnsignedInteger(value) ) {
      return;
    }
    const stockCount = Number(event.target.value);
    updateState({stockCount: stockCount});
  };

  /**
   * キー押下時
   * @param event
   */
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      // 在庫更新処理を実行する
      updateStockCount().then();
    }
  };

  /**
   * フォーカス時
   * @param event
   */
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  /**
   * フォーカスアウト時
   */
  const handleBlur = () => {
    if ( !state.processing ){
      updateState({stockCount: props.unitProduct.stock_count});
    }
  };


  /**
   * 在庫数更新
   */
  const updateStockCount = async () => {
    let message = '';

    try {
      // 在庫数が変わっていないなら何もしない
      if (state.stockCount === props.unitProduct.stock_count) {
        return;
      }
      // 在庫数がnullまたは、0未満なら何もしない
      if (state.stockCount == null || state.stockCount < 0) {
        const stockCount = props.unitProduct.stock_count || 0;
        updateState({ stockCount: stockCount});
        return;
      }

      updateState({processing: true});

      // 在庫更新
      message = await ProductService.getInstance().updateUnitProductStock(props.unitProduct, state.stockCount);

      updateState({processing: false});
    }
    catch (e) {
      LogUtils.ex(e);

      const stockCount = props.unitProduct.stock_count || 0;
      updateState({ stockCount: stockCount, processing: false});

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = '在庫数更新処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);

  }

  /**
   * 使用不可か？
   */
  const isDisabled = () => {
    // 変更不可なら使用不可
    if ( !props.canChange ){
      return true;
    }

    // モール間連動してないなら使用不可
    if (props.product.mall_flag !== 1){
      return true;
    }

    // 訳ありは使用可
    if ( props.unitProduct.has_defect ){
      return false;
    }
    // それ以外はモール在庫パターンが設定されているなら使用不可
    else return props.product.stock_pattern_id !== null;

  }

  /**
   * 描画
   */
  return (
    <Box sx={{ width: props.width, display: 'flex', alignItems: 'center' }}>
      <FormControl fullWidth variant="outlined">
        <TextField
          value={state.stockCount ?? 0}
          onChange={handleStockChange}
          onKeyDown={handleKeyPress}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={isDisabled() || state.processing}
          sx={{
            backgroundColor: '#f0f8ff',
            '& fieldset': {
              borderColor: 'darkgreen',
            },
            '& .MuiInputBase-input': {
              color: MainPageConstants.COLOR_DARK_GRAY
            }
          }}
          inputProps={{
            inputMode: 'numeric',
            maxLength: 3,
            style: {
              textAlign: 'right',
              padding: 2,
            },
          }}
          size="small"
        />
      </FormControl>
      {state.processing && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
            height: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
            ml: 1 }}>
          <CircularProgress size={MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT} />
        </Box>
      )}
    </Box>
  );
};
