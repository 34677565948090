import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React from "react";
import {SearchResult} from "../../../../../Models/SearchResult";

interface SaleReceivedDataProps {
  searchResult: SearchResult;
  height: number;
  width: number;
}

/**
 * 販売数・受取数データ
 */
export const SaleReceivedData = (props: SaleReceivedDataProps) => {
  /**
   * 定数
   */
  const FONT_SIZE = '14px';
  const PADDING = '4px';

  /**
   * ヘッダーセル
   * @param text
   * @param align
   */
  const headerCell = (text: string, align = 'center' as any) => {
    return (
      <TableCell
        align={align}
        sx={{fontSize: FONT_SIZE, padding: PADDING}}
      >
        <strong>{text}</strong>
      </TableCell>
    );
  }

  /**
   * セル
   * @param text
   * @param align
   */
  const tableCell = (text: string, align = 'center' as any) => {
    let style = {fontSize: FONT_SIZE, padding: PADDING} as any;
    return (
      <TableCell align={align} style={style}>
        {text}
      </TableCell>
    );
  }

  /**
   * 販売数
   * @param days
   */
  const sale = (days: number) : string => {
    let average30 = props.searchResult.orderCountData.averageSales ?? 0;
    if ( average30 === -1 ) { // 販売データがない場合
      average30 = 0;
    }
    return Math.round(average30 * days).toString();
  }


  /**
   * 描画
   */
  return (
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{paddingX: 1}}
        style={{
          height: props.height,
          width: props.width,
          boxSizing: 'border-box',
          border: '1px solid lightgray',
          borderRadius: '8px',
          overflow: 'auto' ,}}
      >
        <Table aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {headerCell("")}
              {headerCell("14d", 'right')}
              {headerCell("30d", 'right')}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow key={"sale"}>
              {tableCell("販売", 'left')}
              {tableCell(sale(14), 'right')}
              {tableCell(sale(30), 'right')}
            </TableRow>
            <TableRow key={"received"}>
              {tableCell("受取", 'left')}
              {tableCell(props.searchResult.receiveCount14?.toString() ?? '', 'right')}
              {tableCell(props.searchResult.receiveCount30?.toString() ?? '', 'right')}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
  );
}
