import {LogUtils} from "../Common/LogUtils";
import {HttpServiceBase} from "./HttpServiceBase";
import {EcmgApiError} from "../Common/EcmgApiError";
import {StoreProduct} from "../Models/Product/StoreProduct";
import {UnitProduct} from "../Models/Product/UnitProduct";
import {EventService} from "./System/EventService";
import {SearchResult} from "../Models/SearchResult";
import {Product} from "../Models/Product/Product";
import {ProductMemoPhoto} from "../Models/Product/ProductMemoPhoto";
import axios from "axios";
import {SearchParam} from "../Common/SearchParam";
import {TagService} from "./Tag/TagService";

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  total: number;
}

/**
 * 商品サービス
 */
export class ProductService extends HttpServiceBase {
  /**
   * 検索結果
   * @private
   */
  private searchResult: SearchResult[] = [];
  /**
   * 検索結果
   * SKUモード時の検索結果に紐づく店舗商品マップ（ユニットコード - 店舗商品リスト）
   * @private
   */
  private storeProductMap: { [key: string]: StoreProduct[] } = {};
  /**
   * 検索結果
   * SKUモード時の検索結果に紐づく商品メモ画像リスト
   */
  private productMemoPhotoList: ProductMemoPhoto[] = [];
  /**
   * 検索結果（ページネーション）
   */
  private paginationInfo: PaginationInfo = {
    currentPage: 1,
    totalPages: 1,
    total: 0
  };

  /**
   * シングルトン
   * @private
   */
  private static instance: ProductService = new ProductService();
  private constructor() {
    super();

    // イベントハンドラ登録
    EventService.getInstance().onEvent(EventService.EVENT_UPDATE_DELETE_TAGS, this.onTagsUpdateDelete.bind(this));
  }

  static getInstance(): ProductService {
    return this.instance;
  }

  /**
   * 初期化
   */
  async init(): Promise<void> {
    LogUtils.d();
  }

  /**
   * 商品検索
   */
  async searchProducts(searchParam: SearchParam): Promise<void> {
    try {
      // ページ番号をクエリパラメータとして追加
      const url = `api/products/search?${searchParam.toQueryString()}`;
      const axios = await this.getAxios(false);

      const response = await axios.get(url);
      LogUtils.d(response.toString());

      // 商品データはresponse.data.dataに格納されている
      const searchResultData = response.data.data;
      if (searchResultData && searchResultData.length > 0) {
        this.searchResult = searchResultData.map((searchResult: any) => SearchResult.fromMap(searchResult));

        // 結果が1件ならば、SKUモード時の検索結果に紐づく関連情報を取得
        if (this.searchResult.length === 1) {
          const stockCode = this.searchResult[0].product.stock_code;
          // 店舗商品マップ
          this.storeProductMap = await this.getStoreProductsByStockCode(stockCode!);
          // メモ画像
          this.productMemoPhotoList = await this.getProductMemoPhotosByStockCode(stockCode!);
        }
      } else {
        this.searchResult = [];
      }

      // ページネーション情報の更新
      this.paginationInfo.currentPage = response.data['current_page'] ?? 1;
      this.paginationInfo.totalPages = response.data['last_page'] ?? 1;
      this.paginationInfo.total = response.data['total'] ?? 0;

      // 検索結果更新イベント発行
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_SEARCH_RESULTS);

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 再検索
   */
  async reSearch(): Promise<void> {
    const searchParam = SearchParam.fromUrl();
    await this.searchProducts(searchParam);
  }

  /**
   * 検索結果クリア
   */
  clearSearchResults(): void {
    this.searchResult = [];
    this.storeProductMap = {};
    this.productMemoPhotoList = [];
    this.paginationInfo = {
      currentPage: 1,
      totalPages: 1,
      total: 0
    };
    // 検索結果更新イベント発行
    EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_SEARCH_RESULTS);
  }

  /**
   * 商品検索結果取得
   */
  getSearchResults(): SearchResult[] {
    return this.searchResult;
  }

  /**
   * 在庫コードを指定して検索結果を取得
   * @param stockCode
   */
  getSearchedResultByStockCode(stockCode: string): SearchResult | undefined {
    return this.searchResult.find((result) => result.product.stock_code === stockCode);
  }

  /**
   * SKUモード時の検索結果に紐づく店舗商品群を取得
   * @param unitCode
   */
  getStoreProductsResult(unitCode: string): StoreProduct[] {
    return this.storeProductMap[unitCode] ?? [];
  }

  /**
   * SKUモード時の検索結果に紐づく商品メモ画像群を取得
   */
  getProductMemoPhotos(): ProductMemoPhoto[] {
    return this.productMemoPhotoList;
  }

  /**
   * ページネーション情報取得
   */
  getPaginationInfo(): PaginationInfo {
    return this.paginationInfo;
  }

  /**
   * リストモードか？
   */
  isListMode(): boolean {
    return this.searchResult.length > 1 || this.paginationInfo.totalPages > 1;
  }
  //
  // /**
  //  * 商品画像取得
  //  * @param stockCode
  //  */
  // async getPhotos(stockCode: string): Promise<StoreProductPhoto[]> {
  //   try {
  //     const url = `api/products/${stockCode}/photos`;
  //     const axios = await this.getAxios(false);
  //
  //     const response = await axios.get(
  //       url,
  //     );
  //     LogUtils.d(response.toString());
  //
  //     if (response.data ) {
  //       return response.data.map((photo: any) =>
  //         StoreProductPhoto.fromMap(photo)
  //       );
  //     }
  //     else {
  //       return [];
  //     }
  //   } catch (e) {
  //     LogUtils.ex(e);
  //     throw EcmgApiError.fromError(e);
  //   }
  // }
  //

  //
  // /**
  //  * 商品の注文数データを取得
  //  * @param stockCode
  //  */
  // async getOrderCountData(stockCode: string): Promise<OrderCountData> {
  //   try {
  //     const url = `api/products/${stockCode}/order-count`;
  //     const axios = await this.getAxios(false);
  //
  //     const response = await axios.get(url);
  //     LogUtils.d(response.toString());
  //
  //     return OrderCountData.fromMap(response.data);
  //
  //   } catch (e) {
  //     LogUtils.ex(e);
  //     throw EcmgApiError.fromError(e);
  //   }
  // }

  /**
   * ユニット商品在庫数更新
   * @param unitProduct
   * @param stock
   */
  public async updateUnitProductStock(unitProduct: UnitProduct, stock: number): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/unit-products/${unitProduct.stock_code}/${unitProduct.unit_code}/stock`,
        {
          updated_stock_count: stock,
          last_updated_at: unitProduct.updated_at
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      //await this.updateSearchResultByUnit(unitProduct.stock_code!, unitProduct.unit_code!);// 待たないとおかしくなる
      await this.updateSearchResultByStockCode(unitProduct.stock_code!);// 待たないとおかしくなる

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 店舗商品価格更新
   * @param storeProduct
   * @param price
   */
  public async updateStoreProductPrice(storeProduct: StoreProduct, price: number): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/store-products/${storeProduct.id}/price`,
        {
          updated_price: price,
          last_updated_at: storeProduct.updated_at
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      //await this.updateSearchResultByStore(storeProduct.id!);// 待たないとおかしくなる
      await this.updateSearchResultByStockCode(storeProduct.stock_code!);// 待たないとおかしくなる

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }

  }

  /**
   * 一括価格更新処理
   * @param storeProduct
   * @param updatedPrice
   */
  public async bulkUpdatePrice(storeProduct: StoreProduct, updatedPrice: number): Promise<{message: string, success: boolean}> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/store-products/${storeProduct.id}/bulk-update-price`,
        {
          updated_price: updatedPrice,
          last_updated_at: storeProduct.updated_at
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      // await this.updateSearchResultByUnit(storeProduct.stock_code!, storeProduct.unit_code!);// 待たないとおかしくなる
      await this.updateSearchResultByStockCode(storeProduct.stock_code!);// 待たないとおかしくなる

      return response.data;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 店舗商品公開ステータス更新
   * @param storeProduct
   * @param published
   */
  public async updateStoreProductPublishStatus(storeProduct: StoreProduct, published: boolean): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/store-products/${storeProduct.id}/pub-status`,
        {
          published: published,
          last_updated_at: storeProduct.updated_at
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      //await this.updateSearchResultByStore(storeProduct.id!); // 待たないとおかしくなる
      await this.updateSearchResultByStockCode(storeProduct.stock_code!); // 待たないとおかしくなる

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * モール間在庫連動ON
   * @param stockCode
   */
  public async updateMallIntegration(stockCode: string): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/products/${stockCode}/update-mall-integration`,
        {},
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      await this.updateSearchResultByStockCode(stockCode);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 一括在庫振分処理
   * @param stockCode 在庫コード
   * @param incrementalStockCount 増減在庫数
   */
  public async bulkDistributeStock(stockCode: string, incrementalStockCount: number): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/products/bulk-distribute-stock/${stockCode}`,
        {
          incremental_stock_count: incrementalStockCount,
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      await this.updateSearchResultByStockCode(stockCode);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 商品の在庫パターンを更新する処理
   * @param product 商品オブジェクト
   * @param newPatternId 新しい在庫パターンID
   */
  public async updateProductStockPattern(
    product: Product,
    newPatternId: number | undefined): Promise<string> {

    LogUtils.d();

    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/products/update-stock-pattern/${product.stock_code}`,
        {
          stock_pattern_id: newPatternId,
          product_last_updated_at: product.updated_at,
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      await this.updateSearchResultByStockCode(product.stock_code!);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }


  /**
   * 商品の価格パターンを更新する処理
   * @param product 商品オブジェクト
   * @param newPatternId 新しい価格パターンID
   */
  public async updateProductPricePattern(product: Product, newPatternId: number | undefined): Promise<{message: string, success: boolean}> {
    LogUtils.d();

    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/products/update-price-pattern/${product.stock_code}`,
        {
          price_pattern_id: newPatternId,
          product_last_updated_at: product.updated_at,
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      await this.updateSearchResultByStockCode(product.stock_code!);

      return response.data;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 未着在庫数を更新する
   * @param stockCode
   * @param addValue 加算する在庫数
   */
  public async addUnreceivedStock(stockCode: string, addValue: number): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/products/${stockCode}/add-unreceived-stock`,
        {
          add_value: addValue
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      await this.updateSearchResultByStockCode(stockCode);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 受け取り在庫数を更新する
   * @param stockCode
   * @param receivedValue 受け取った在庫数
   */
  public async addReceivedStock(stockCode: string, receivedValue: number): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/products/${stockCode}/add-received-stock`,
        {
          received_value: receivedValue
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      await this.updateSearchResultByStockCode(stockCode);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }


  /**
   * 仕入れの上限値更新
   * @param stockCode
   * @param value 値
   */
  public async updateProcurementUpper(stockCode: string, value: number): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/products/${stockCode}/procurement-upper`,
        {
          value: value
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      await this.updateSearchResultByStockCode(stockCode);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }


  /**
   * 仕入れの下限値更新
   * @param stockCode
   * @param value 値
   */
  public async updateProcurementLower(stockCode: string, value: number): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/products/${stockCode}/procurement-lower`,
        {
          value: value
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      await this.updateSearchResultByStockCode(stockCode);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * フリマ情報を更新
   * @param stockCode 在庫コード
   * @param number 番号
   * @param name フリマ名
   * @param price フリマ相場
   * @param quantityString 出品数の上下限文字列
   */
  public async updateFreeMarketInfo(
      stockCode: string,
      number: number,
      name: string | undefined,
      price: number | undefined,
      quantityString: string | undefined
    ): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/products/free-market-info/${stockCode}`,
        {
          number: number,
          name: name,
          price: price,
          quantity: quantityString
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      await this.updateSearchResultByStockCode(stockCode);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * フリーメモ更新
   * @param stockCode
   * @param type フリーメモタイプ
   * @param value 値
   */
  public async updateFreeMemo(stockCode: string, type: number, value: string): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/products/${stockCode}/free-memo?type=${type}`,
        {
          value: value
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 検索結果更新
      await this.updateSearchResultByStockCode(stockCode);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * メモ写真の順序更新
   * @param stockCode 商品のストックコード
   * @param orderedIds 新しい順序のIDリスト
   */
  public async reorderMemoPhotos(stockCode: string, orderedIds: number[]): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/products/${stockCode}/memo-photos/order`,
        {
          ordered_ids: orderedIds // 新しい順序のIDリストをパラメータとして送信
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // メモ画像 更新
      this.productMemoPhotoList = await this.getProductMemoPhotosByStockCode(stockCode);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * メモ画像を登録する
   * @param stockCode 商品のストックコード
   * @param file アップロードする画像ファイル
   * @param addIndex 追加するインデックス(末尾の時は-1)
   */
  public async addMemoPhoto(stockCode: string, file: File, addIndex: number): Promise<string> {
    LogUtils.d();

    const formData = new FormData();
    formData.append('photo', file);

    const options = this.makeAuthorizeOption({
      'Content-Type': 'multipart/form-data'
    });

    try {
      const response = await axios.post(
        `api/products/${stockCode}/memo-photos?add_index=${addIndex}`,
        formData,
        options
      );
      LogUtils.d(response.toString());

      // メモ画像 更新
      this.productMemoPhotoList = await this.getProductMemoPhotosByStockCode(stockCode);

      return response.data.message;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * メモ画像を削除する
   * @param stockCode 商品のストックコード
   * @param id メモ画像
   */
  public async deleteMemoPhoto(stockCode: string, id: number): Promise<string> {
    LogUtils.d();

    try {
      const axios = await this.getAxios(true);
      const response = await axios.delete(
        `api/products/${stockCode}/memo-photos/${id}`,
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // メモ画像 更新
      this.productMemoPhotoList = await this.getProductMemoPhotosByStockCode(stockCode);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 商品メモ画像取得
   * @param fileName
   */
  public async getMemoPhoto(fileName: string): Promise<string> {
    LogUtils.d();

    try {
      const axios = await this.getAxios(true);
      const response = await axios.get(
        `api/products/memo-photos?filename=${fileName}`,
        this.makeAuthorizeOption({}, 'blob')
      );
      LogUtils.d(response.toString());

      return URL.createObjectURL(response.data);

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 商品削除
   * @param products モール名、モール店舗識別子、商品識別子の配列
   */
  public async deleteProducts(products: string[][]): Promise<string> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/products/delete`,
        products,
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 必要に応じて追加処理をここに記述（例: 検索結果の更新など）

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  //------------------------
  // タグ関連
  //------------------------
  /**
   * 商品に複数のタグを関連付け（同期）する
   * @param stockCode 商品の在庫コード
   * @param tagIds 関連付けるタグのIDの配列
   */
  public async syncTags(stockCode: string, tagIds: number[]): Promise<void> {
    try {
      const axios = await this.getAxios(true);
      await axios.post('api/sync-tags', {
        stock_code: stockCode,
        tag_ids: tagIds
      }, this.makeAuthorizeOption());

      // 検索結果更新
      await this.updateSearchResultByStockCode(stockCode);

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
  /**
   * タグ情報更新時
   */
  public async onTagsUpdateDelete() {
    try {
      // すべてのsearchResultからstockCodeを抽出
      const stockCodes
        = this.searchResult.map(result => result.product.stock_code).filter(code => code != null) as string[];

      // 複数の商品に関連付けられたタグを一度に取得
      const tagsMap = await TagService.getInstance().fetchTagsForMultipleProducts(stockCodes);

      // 検索結果の更新
      this.searchResult = this.searchResult.map(searchResult => {
        const stockCode = searchResult.product.stock_code;
        const tags = tagsMap[stockCode ?? ''] || []; // stockCodeに対応するタグがない場合は空の配列を使用
        const newProduct = searchResult.product.copyWith({tags: tags});
        return searchResult.copyWith({product: newProduct});
      });
      // 検索結果更新イベント発行
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_SEARCH_RESULTS);
    }
    catch (e) {
      LogUtils.ex(e);
    }
  }

  //------------------------
  // 定期実行処理
  //------------------------
  /**
   * 検索結果が更新されているかチェックし、新しい検索結果を取得
   * 未読のメンションも同時にチェックする
   * @param lastCheckedTime
   *
   */
  public async checkSearchResultsUpdates(lastCheckedTime: Date): Promise<boolean> {
    try {
      // 現在表示中の検索結果の在庫コードを取得
      const stockCodes = this.searchResult.map(result => result.product.stock_code);
      // if (stockCodes.length === 0) {
      //   return false;
      // }

      const url = `api/products/check-search-result-updates`;
      const axios = await this.getAxios(false);

      const response = await axios.get(url, {
        params: {
          stock_codes: stockCodes,
          last_checked_time: lastCheckedTime.toISOString()
        }
      });
      LogUtils.d(response.toString());

      //------------------------
      // チャットメッセージ
      //------------------------
      // 未読のメッセージ件数（自分へのメッセージ）
      const toMeUnreadMessageCount = response.data['to_me_unread_message_count'];
      EventService.getInstance().emitEvent(EventService.EVENT_CHAT_TO_ME_UNREAD_MESSAGES, toMeUnreadMessageCount);
      // 未返信のメッセージ件数（自分へのメッセージ）
      const toMeUnrespondedMessageCount = response.data['to_me_unresponded_message_count'];
      EventService.getInstance().emitEvent(EventService.EVENT_CHAT_TO_ME_UNRESPONDED_MESSAGES, toMeUnrespondedMessageCount);
      // 未返信のメッセージ件数（自分からのメッセージ）
      const fromMeUnrespondedMessageCount = response.data['from_me_unresponded_message_count'];
      EventService.getInstance().emitEvent(EventService.EVENT_CHAT_FROM_ME_UNRESPONDED_MESSAGES, fromMeUnrespondedMessageCount);

      // 更新された検索結果
      const updatedSearchResults = response.data['updated_search_results'];
      // 検索結果の変換
      const searchResults: SearchResult[]
        = updatedSearchResults.map((searchResultData: { [key: string]: any }) => SearchResult.fromMap(searchResultData));

      if ( searchResults.length === 0 ) {
        return false;
      }

      // ループ
      for (const searchResult of searchResults) {
        // 検索結果の在庫コードを取得
        const stockCode = searchResult.product.stock_code;
        // 検索結果のインデックスを取得
        const index = this.searchResult.findIndex((result: SearchResult) => result.product.stock_code === stockCode);
        // 検索結果の更新
        if (index !== -1) {
          this.searchResult[index] = searchResult;
        }
      }
      // SKUモードならば関連情報を再取得
      if (searchResults.length > 0 && this.searchResult.length === 1) {
        const stockCode = this.searchResult[0].product.stock_code;
        // 店舗商品マップ
        this.storeProductMap = await this.getStoreProductsByStockCode(stockCode!);
        // メモ画像
        this.productMemoPhotoList = await this.getProductMemoPhotosByStockCode(stockCode!);
      }

      // 検索結果更新イベント発行
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_SEARCH_RESULTS);

      return true;

    } catch (e) {
      LogUtils.ex(e);
      return false;
    }
  }


  //------------------------
  // 検索結果の更新処理
  //------------------------
  /**
   * 最新の検索結果(1件)を取得して検索結果を更新する
   * @param stockCode
   * @private
   */
  public async updateSearchResultByStockCode(stockCode: string): Promise<void> {
    // 検索結果取得
    const searchResult = await this.getSearchResultByStockCode(stockCode);
    // searchResultよりインデックスを取得
    const index = this.searchResult.findIndex((searchResult: SearchResult) => searchResult.product.stock_code === stockCode);
    if ( index === -1 ){
      return;
    }
    // 検索結果の更新
    this.searchResult[index] = searchResult;

    // SKUモード時の検索結果に紐づく店舗商品マップの更新（メモ画像は関係ないので更新しない）
    if (this.searchResult.length === 1) {
      const stockCode = this.searchResult[0].product.stock_code;
      this.storeProductMap = await this.getStoreProductsByStockCode(stockCode!);
    }

    // イベント発行
    //EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_SEARCH_RESULT_ONE, searchResult);
    EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_SEARCH_RESULTS, searchResult);
  }

  // /**
  //  * 最新のユニット商品情報を取得して検索結果を更新する
  //  * @param stockCode
  //  * @param unitCode
  //  * @private
  //  */
  // private async updateSearchResultByUnit(stockCode: string, unitCode: string): Promise<void> {
  //   // ユニット商品取得
  //   const unitProduct = await this.getUnitProduct(stockCode, unitCode);
  //   const searchResult
  //     = this.searchResult.find((searchResult: SearchResult) => searchResult.product.stock_code === stockCode);
  //   if (searchResult) {
  //     // ユニット商品情報の更新
  //     searchResult.product.updateUnitProduct(unitProduct);
  //     // 商品在庫数の更新
  //     searchResult.stockCount = await this.getStockByCode(stockCode);
  //   }
  //
  //   // SKUモード時の検索結果に紐づく店舗商品マップの更新
  //   if (this.searchResult.length === 1) {
  //     const stockCode = this.searchResult[0].product.stock_code;
  //     this.storeProductMap = await this.getStoreProductsByStockCode(stockCode!);
  //   }
  //
  //   // イベント発行
  //   EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_UNIT, unitProduct);
  // }
  //
  // /**
  //  * 最新の店舗商品を取得して検索結果を更新する
  //  * @param storeProductId
  //  */
  // private async updateSearchResultByStore(storeProductId: number): Promise<void> {
  //   const storeProduct = await this.getStoreProduct(storeProductId);
  //   // storeProductMapの更新
  //   if ( storeProduct ) {
  //     const storeProductList = this.storeProductMap[storeProduct.unit_code!];
  //     if ( storeProductList ) {
  //       const index = storeProductList.findIndex((p: StoreProduct) => p.id === storeProductId);
  //       if ( index >= 0 ) {
  //         storeProductList[index] = storeProduct;
  //       }
  //     }
  //   }
  //   // イベント発行
  //   EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_STORE_PRODUCT, storeProduct);
  // }


  //------------------------
  // その他
  //------------------------
  /**
   * 商品検索(1件)
   * @param stockCode
   */
  public async getSearchResultByStockCode(stockCode: string): Promise<SearchResult> {
    try {
      const url = `api/products/search/${stockCode}`;
      const axios = await this.getAxios(false);

      const response = await axios.get(url);
      LogUtils.d(response.toString());

      return SearchResult.fromMap(response.data ?? {}); // nullの場合あり

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  //------------------------
  // Private
  //------------------------

  // /**
  //  * ユニット商品取得
  //  * @param stockCode
  //  * @param unitCode
  //  */
  // private async getUnitProduct(stockCode: string, unitCode: string): Promise<UnitProduct> {
  //   try {
  //     const url = `api/unit-products/${stockCode}/${unitCode}`;
  //     const axios = await this.getAxios(false);
  //
  //     const response = await axios.get(url);
  //     LogUtils.d(response.toString());
  //
  //     return UnitProduct.fromMap(response.data);
  //
  //   } catch (e) {
  //     LogUtils.ex(e);
  //     throw EcmgApiError.fromError(e);
  //   }
  // }

  /**
   * 在庫コードに紐づく店舗商品を取得
   * @param stockCode
   *
   * ユニットコード - 店舗商品リストのマップを返す
   */
  private async getStoreProductsByStockCode(stockCode: string): Promise<{ [key: string]: StoreProduct[] }> {
    try {
      const url = `api/products/${stockCode}/store-products`;
      const axios = await this.getAxios(false);

      const response = await axios.get(url);
      LogUtils.d(response.toString());

      const transformedData: { [key: string]: StoreProduct[] } = {};
      for (const unitCode in response.data) {
        if (response.data.hasOwnProperty(unitCode)) {
          transformedData[unitCode] = response.data[unitCode].map((storeProductData: { [key: string]: any }) => StoreProduct.fromMap(storeProductData));
        }
      }
      return transformedData;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
  //
  // /**
  //  * 店舗商品取得
  //  * @param storeProductId
  //  */
  // private async getStoreProduct(storeProductId: number): Promise<StoreProduct> {
  //   try {
  //     const url = `api/store-products/${storeProductId}`;
  //     const axios = await this.getAxios(false);
  //
  //     const response = await axios.get(url);
  //     LogUtils.d(response.toString());
  //
  //     return StoreProduct.fromMap(response.data);
  //
  //   } catch (e) {
  //     LogUtils.ex(e);
  //     throw EcmgApiError.fromError(e);
  //   }
  // }

  /**
   * 在庫コードに紐づく商品メモ画像を取得
   * @param stockCode
   *
   * ユニットコード - 店舗商品リストのマップを返す
   */
  private async getProductMemoPhotosByStockCode(stockCode: string): Promise<ProductMemoPhoto[]> {
    try {
      const url = `api/products/${stockCode}/memo-photos`;
      const axios = await this.getAxios(false);

      const response = await axios.get(url);
      LogUtils.d(response.toString());

      return response.data.map((photo: any) => ProductMemoPhoto.fromMap(photo));

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 在庫コード移行前のチェックを行う
   * @param oldStockCode 移行前の在庫コード
   * @param newStockCode 移行後の在庫コード
   */
  public async checkStockCodeMigration(oldStockCode: string, newStockCode: string): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        'api/products/stock_code_migration/check',
        {
          old_stock_code: oldStockCode,
          new_stock_code: newStockCode,
        }
      );
      LogUtils.d(response.toString());

      return response.data;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 在庫コード移行を行う
   * @param oldStockCode 移行前の在庫コード
   * @param newStockCode 移行後の在庫コード
   */
  public async migrationStockCode(oldStockCode: string, newStockCode: string): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        'api/products/stock_code_migration',
        {
          old_stock_code: oldStockCode,
          new_stock_code: newStockCode,
        }
      );
      LogUtils.d(response.toString());

      return response.data.message;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

}
