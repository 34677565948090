import {HttpServiceBase} from "../../Services/HttpServiceBase";
import {EcmgApiError} from "../../Common/EcmgApiError";
import {LogUtils} from "../../Common/LogUtils";
import {ChatMessage} from "../../Models/Chat/ChatMessage";
import {AIConstants} from "./AIConstants";

/**
 * AIチャットサービス
 */
export class AIChatService extends HttpServiceBase {

  private static instance: AIChatService = new AIChatService();

  private constructor() {
    super();
  }

  public static getInstance(): AIChatService {
    return AIChatService.instance;
  }

  /**
   * スレッドを作成する。
   */
  public async createThread(): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/ai/create-thread`, // APIエンドポイント
        {}, // 送信するメッセージデータ
        this.makeAuthorizeOption() // 認証情報を含むオプション
      );
      return response.data;

    } catch (e) {
      LogUtils.ex(e); // エラーログの出力
      throw EcmgApiError.fromError(e); // エラーを適切にラップしてスロー
    }
  }

  /**
   * チャットメッセージを送信する。
   * https://zenn.dev/teramotodaiki/scraps/f016ed832d6f0d
   * @param type AIタイプ(design or data)
   * @param prompt 新しいメッセージのデータ
   * @param threadId
   * @param callback
   */
  public async sendMessage(type: string, prompt: string, threadId: string, callback: (text: string) => void): Promise<void> {
    try {
      const url = type === AIConstants.AI_TYPE_DESIGN ? '/api/ai/design' :
                  type === AIConstants.AI_TYPE_DATA ?  '/api/ai/data' :
                  type === AIConstants.AI_TYPE_SALES_FORECAST ? '/api/ai/sales-forecast' : '';

      await this.getAxios(true);
      const headers = this.makeAuthorizeOption()?.headers as any;

      const formData = new FormData();
      formData.append('question', prompt);
      formData.append('thread_id', threadId);

      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: formData,
      });

      const reader = response.body?.getReader();
      if (!reader) return;

      let decoder = new TextDecoder();
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        if (!value) continue;
        const lines = decoder.decode(value);

        //LogUtils.d(lines);

        const texts = lines.split('data: ')
          .map(line => line.replace(/\n\n$/, "")); // 末尾のデータ終端文字列(\n\n)を削除

        for (const text of texts) {
          try {
            // if (json === '[DONE]') {
            //   return; // 終端記号
            // }
            callback(text);
          } catch (error) {
            LogUtils.ex(error);
          }
        }
      }

    } catch (e) {
      LogUtils.ex(e); // エラーログの出力
      throw EcmgApiError.fromError(e); // エラーを適切にラップしてスロー
    }
  }

}
