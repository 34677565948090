/**
 * 商品の注文数データ
 */
export class OrderCountData {
  todaySales?: number;
  oneWSales?: number;
  totalSales?: number;
  averageSales?: number;
  dailySales?: { [key: number]: number };
  dailyStock?: { [key: number]: number };
  unitData?: { [key: string]: UnitOrderCountData }; // unit_code単位で分ける

  constructor(params: Partial<OrderCountData> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<OrderCountData>): OrderCountData {
    return new OrderCountData({
      ...this,
      ...params
    });
  }

  static fromMap(map: { [key: string]: any }): OrderCountData {
    const unitDataEntries: [string, UnitOrderCountData][] =
      Object.entries(map.unitData || {}).map(([unitCode, data]) =>
        [unitCode, UnitOrderCountData.fromMap(data as { [key: string]: any })]);
    return new OrderCountData({
      ...map,
      unitData: Object.fromEntries(unitDataEntries)
    });
  }

  static fromJson(jsonString: string): OrderCountData {
    const decoded = JSON.parse(jsonString);
    return OrderCountData.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      unitData: Object.fromEntries(
        Object.entries(this.unitData || {}).map(([unitCode, data]) => [unitCode, data.toMap()])
      )
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  getUnitOrderCountData(unitCode: string): UnitOrderCountData | undefined {
    return this.unitData ? this.unitData[unitCode] : undefined;
  }
}

/**
 * unit_codeごとの注文数データ
 */
export class UnitOrderCountData {
  totalSales?: number;
  averageSales?: number;
  dailySales?: { [key: number]: number };
  storeData?: { [key: number]: StoreOrderCountData }; // store_id単位で分ける

  constructor(params: Partial<UnitOrderCountData> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<UnitOrderCountData>): UnitOrderCountData {
    return new UnitOrderCountData({
      ...this,
      ...params
    });
  }

  static fromMap(map: { [key: string]: any }): UnitOrderCountData {
    const storeDataEntries: [string, StoreOrderCountData][] =
      Object.entries(map.storeData || {}).map(([storeId, data]) =>
        [storeId, StoreOrderCountData.fromMap(data as { [key: string]: any })]);
    return new UnitOrderCountData({
      ...map,
      storeData: Object.fromEntries(storeDataEntries)
    });
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      storeData: Object.fromEntries(
        Object.entries(this.storeData || {}).map(([storeId, data]) => [storeId, data.toMap()])
      )
    };
  }

  getStoreOrderCountData(storeId: number): StoreOrderCountData | undefined {
    return this.storeData ? this.storeData[storeId] : undefined;
  }
}

/**
 * 店舗ごとの注文数データ
 */
export class StoreOrderCountData {
  totalSales?: number;
  averageSales?: number;
  dailySales?: { [key: number]: number };

  constructor(params: Partial<StoreOrderCountData> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<StoreOrderCountData>): StoreOrderCountData {
    return new StoreOrderCountData({
      ...this,
      ...params
    });
  }

  static fromMap(map: { [key: string]: any }): StoreOrderCountData {
    return new StoreOrderCountData(map);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this
    };
  }
}
