import React, {useEffect, useState} from 'react';
import {Box, Divider, Typography} from '@mui/material';
import {FreeMarketInfo} from "../../../../../Models/Product/FreeMarketInfo";
import {InlineEditField} from "../../../../Common/Components/InlineEditField";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {ProductService} from "../../../../../Services/ProductService";
import {LogUtils} from "../../../../../Common/LogUtils";
import {EcmgApiError} from "../../../../../Common/EcmgApiError";

interface FreeMarketInfoTableProps {
  stockCode: string;
  data: FreeMarketInfo[];
  width: number;
}

/**
 * フリマ情報
 * @param props
 * @constructor
 */
export const FreeMarketInfoTable = (props: FreeMarketInfoTableProps) => {

  /**
   * useSnackbar
   */
  const { showSnackbarMessage } = useSnackbar();

  /**
   * useState
   */
  const [state, setState] = useState({
    data: Array(3).fill({} as { name?: string, price?: number, quantity?: string}),
    processing: Array(3).fill({} as { name?: boolean, price?: boolean, quantity?: boolean}),
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * props更新時
   */
  useEffect(() => {
    const data = Array(3).fill({});
    const processing = Array(3).fill({});
    props.data.forEach((info, index) => {
      data[index] = {
        name: info.name,
        price: info.price,
        quantity: getQuantityRange(info),
      };
      processing[index] = {};
    });
    updateState({ data: data, processing: processing });
  }, [props]);

  /**
   * 仕入れ範囲
   */
  const getQuantityRange = (info: FreeMarketInfo): string => {
    const lower = info.quantity_lower;
    const upper = info.quantity_upper;
    if ( lower == null || upper == null ) {
      return '()';
    }

    return lower === upper ? `(${lower})` : `(${lower} - ${upper})`;
  }

  /**
   * 名称を更新
   * @param index
   * @param value
   */
  const handleNameUpdate = (index: number, value: string) => {
    const info = state.data[index];
    updateFreeMarketInfo(index, value, info.price, info.quantity, 'name').then();
  };

  /**
   * 価格を更新
   * @param index
   * @param value
   */
  const handlePriceUpdate = (index: number, value: string) => {
    const info = state.data[index];
    updateFreeMarketInfo(index, info.name, Number(value), info.quantity, 'price').then();

  };

  /**
   * 数量上下限を更新
   * @param index
   * @param value
   */
  const handleQuantityUpdate = (index: number, value: string) => {
    const info = state.data[index];
    updateFreeMarketInfo(index, info.name, info.price, value, 'quantity').then();
  };

  /**
   * 処理中フラグ更新
   */
  const updateProcessing = ( index: number, key: string, isProcessing: boolean) => {
    const processing = state.processing;
    processing[index] = { [key]: isProcessing };
    updateState({ processing: processing });
  }

  /**
   * データ更新
   */
  const updateStateData = (index: number, value: any) => {
    const data = state.data;
    data[index] = value;
    updateState({ data: data });
  }

  /**
   * 処理中フラグ取得
   * @param index
   * @param key
   */
  const getProcessing = (index: number, key: string): boolean => {
    const processing = state.processing;
    return processing[index][key];
  }

  /**
   * フリマ情報更新
   */
  const updateFreeMarketInfo = async (
      index: number,
      name: string|undefined,
      price: number|undefined,
      quantityString: string | undefined,
      processingKey: string) => {

    let message = '';

    const oldData = state.data[index];
    const newData = {
      name: name,
      price: price,
      quantity: quantityString,
    }

    try {
      updateProcessing(index, processingKey, true);
      updateStateData(index, newData);

      // フリマ情報更新
      message = await ProductService.getInstance().updateFreeMarketInfo(
        props.stockCode,
        index+1,
        name,
        price,
        quantityString
      );

      updateProcessing(index, processingKey, false);

    } catch (e) {
      LogUtils.ex(e);

      updateProcessing(index, processingKey, false);
      updateStateData(index, oldData);

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = 'フリマ情報の更新処理でエラーが発生しました。';
      }
    }

    showSnackbarMessage(message);
  };


  /**
   * 描画
   */

  return (
    <Box sx={{width: props.width}}>
      {state.data.slice(0, 3).map((info, index) => (
        <Box key={index} display={"flex"} flexDirection={"column"}>
          <Box display="flex" alignItems={"center"} sx={{height: 30}}>
            <InlineEditField
              value={info.name}
              onAction={(value) => handleNameUpdate(index, value)}
              isDisabled={false}
              inputType="text"
              textAlign={"left"}
              width="30%"
              processing={getProcessing(index, 'name')}
            />

            <Typography>：</Typography>

            <InlineEditField
              value={info.price?.toLocaleString()} // 3桁区切り
              onAction={(value) => handlePriceUpdate(index, value)}
              isDisabled={false}
              inputType="numeric"
              allowNegative={false}
              textAlign={"right"}
              width="30%"
              processing={getProcessing(index, 'price')}
            />

            <InlineEditField
              value={info.quantity}
              onAction={(value) => handleQuantityUpdate(index, value)}
              isDisabled={false}
              inputType="text"
              textPattern="^[\d\(\)\- ]*$" // 数字と()と-と空白のみ許可
              textAlign={"left"}
              width="40%"
              processing={getProcessing(index, 'quantity')}
            />
          </Box>
          <Divider />
        </Box>
      ))}
    </Box>
  );
};
