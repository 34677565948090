import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {LogUtils} from "../../../../Common/LogUtils";
import {UnitProduct} from "../../../../Models/Product/UnitProduct";
import {MainPageConstants} from "../../Common/MainPageConstants";
import {useSnackbar} from "../../../Common/Provider/SnackbarContext";
import {Product} from "../../../../Models/Product/Product";
import {ProductService} from "../../../../Services/ProductService";
import {CommonUtils} from "../../../../Common/CommonUtils";
import {ECTagSelect} from "../../../Common/Components/Tag/ECTagSelect";
import {Tag} from "../../../../Models/Tag/Tag";
import {TagService} from "../../../../Services/Tag/TagService";
import {EcmgApiError} from "../../../../Common/EcmgApiError";
import {EventService} from "../../../../Services/System/EventService";
import {StoreProduct} from "../../../../Models/Product/StoreProduct";


interface ProductNameProps {
  product: Product;
  minUnitProduct?: UnitProduct;
  height: number;
  isListMode?: boolean;
}

/**
 * 商品名
 * @param props
 * @constructor
 */
export const ProductName = (props: ProductNameProps) => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    paddingTop: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    cursor: 'pointer',
    height: props.height,
    borderRadius: '8px',
  };

  /**
   * useState
   */
  const [state, setState] = useState({
    selectedTags: [] as Tag[],
    optionTags: [] as Tag[],
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();


  /**
   * マウント・アンマウント時処理
   */
  useEffect(() => {
    // イベントハンドラ登録（イベントハンドラの中でstateの値を参照しないこと）
    EventService.getInstance().onEvent(EventService.EVENT_NEW_TAGS, onNewTags);

    // クリーンアップ
    return () => {
      // イベントハンドラ解除
      EventService.getInstance().removeListener(EventService.EVENT_NEW_TAGS, onNewTags);
    };
  }, []);

  /**
   * tags変更時
   */
  useEffect(() => {
    const selectedTags = props.product.tags ?? [] as Tag[];
    const optionTags = TagService.getInstance().getAllTags();
    updateState({selectedTags, optionTags});
  }, [props.product.tags]);

  /**
   * タグ追加時
   */
  const onNewTags = () => {
    const optionTags = TagService.getInstance().getAllTags();
    updateState({optionTags});
  }

  // /**
  //  * タグデータを読み込む
  //  */
  // const loadTags = async () => {
  //   let message = '';
  //
  //   try {
  //     const selectedTags = await TagService.getInstance().fetchTagsForProduct(props.product.stock_code!);
  //     const optionTags = await TagService.getInstance().fetchTags();
  //     updateState({selectedTags, optionTags});
  //   }
  //   catch (e) {
  //     LogUtils.ex(e);
  //
  //     if (e instanceof EcmgApiError && e.message != null ) {
  //       message = 'エラー：' + e.message;
  //     }
  //     if ( CommonUtils.isEmpty(message) ) {
  //       message = 'タグ読み込み時にエラーが発生しました。';
  //     }
  //   }
  //
  //   // スナックバー表示
  //   showSnackbarMessage(message);
  // }

  /**
   * 商品名クリック時処理
   */
  const handleProductClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    try {
      const isMac = navigator.userAgent.indexOf('Mac') !== -1;

      // リストモードかつAltキーが押されていない場合
      if (props.isListMode && !event.altKey) {
        CommonUtils.openProductPage(props.minUnitProduct?.stock_code ?? '');
      }
      // SKUモードかつ［Ctrl］＋［Shift］キーが押されている場合
      else if (!props.isListMode &&
              ((isMac && event.metaKey && event.shiftKey) || (!isMac && event.ctrlKey && event.shiftKey))) {
        openAllStoreProductUrl();
      }
      // 上記以外は在庫コードをコピー
      else {
        await navigator.clipboard.writeText(props.minUnitProduct?.stock_code ?? '');
        showSnackbarMessage('在庫コードをコピーしました。[' + props.minUnitProduct?.stock_code + ']');
      }

    } catch (e) {
      LogUtils.ex(e);
    }
  };


  /**
   * 商品に紐づく全店舗商品のURLを開く
   */
  const openAllStoreProductUrl = () => {
    const targets = [] as StoreProduct[];
    props.product.unit_products?.forEach(unitProduct => {
      const storeProducts = ProductService.getInstance().getStoreProductsResult(unitProduct.unit_code ?? '');
      storeProducts.forEach(storeProduct => {
        targets.push(storeProduct);
      });
    });
    // ソート
    targets.sort((a, b) => {
      // モール番号で比較
      if (a.mall_no !== b.mall_no) {
        return (a.mall_no ?? 0) - (b.mall_no ?? 0);
      }
      // 店舗IDで比較
      if (a.store_id !== b.store_id) {
        return (a.store_id ?? 0) - (b.store_id ?? 0);
      }
      // 訳あり状態で比較（訳ありが優先されるため、逆順で比較）
      if (a.has_defect !== b.has_defect) {
        return (b.has_defect ?? 0) - (a.has_defect ?? 0);
      }
      // ユニット単位で比較
      return (a.unit ?? 0) - (b.unit ?? 0);
    });
    // URLを開く
    targets.forEach(storeProduct => {
      CommonUtils.openStoreProductUrl(storeProduct);
    });
  }

  /**
   * タグ選択変更時
   */
  const handleTagSelectChange = async (selectedTags: Tag[]) => {
    let message = '';

    try {
      updateState({
        selectedTags,
      });

      await ProductService.getInstance().syncTags(props.product.stock_code!, selectedTags.map(tag => tag.id!));
    }
    catch (e) {
      LogUtils.ex(e);

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = 'タグ設定時にエラーが発生しました。';
      }

      // スナックバー表示
      showSnackbarMessage(message);
    }
  }

  /**
   * 新規タグ追加
   */
  const handleCreateNewTag = async (inputValue: string) => {
    let message = '';

    try {
      const newTag = await TagService.getInstance().createTag({name: inputValue});
      // 選択リストに追加
      const selectedTags = [...state.selectedTags, newTag];

      updateState({
        selectedTags,
      });
      await ProductService.getInstance().syncTags(props.product.stock_code!, selectedTags.map(tag => tag.id!));

    }
    catch (e) {
      LogUtils.ex(e);

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = 'タグ作成時にエラーが発生しました。';
      }

      // スナックバー表示
      showSnackbarMessage(message);
    }
  }

  /**
   * タグプロパティ変更時
   * @param tag
   */
  const onTagPropertiesChange = async (tag: Tag) => {
    let message = '';

    try {
      await TagService.getInstance().updateTag(tag.id!, tag);

      const selectedTags = state.selectedTags.map(selectedTag => {
        if (selectedTag.id === tag.id) {
          return tag;
        }
        return selectedTag;
      });

      updateState({
        selectedTags,
      });
    }
    catch (e) {
      LogUtils.ex(e);

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = 'タグ更新時にエラーが発生しました。';
      }

      // スナックバー表示
      showSnackbarMessage(message);
    }
  }


  /**
   * タグ削除時
   * @param tag
   */
  const onTagDelete = async (tag: Tag) => {
    let message = '';

    // 確認
    if (!window.confirm('タグを削除しますか？')) {
      return;
    }

    try {
      await TagService.getInstance().deleteTag(tag.id!);
      // 商品のデータも書き換える
      const selectedTags = state.selectedTags.filter(selectedTag => selectedTag.id !== tag.id);
      updateState({
        selectedTags,
      });
    }
    catch (e) {
      LogUtils.ex(e);

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = 'タグ削除時にエラーが発生しました。';
      }

      // スナックバー表示
      showSnackbarMessage(message);
    }
  }

  /**
   * 描画
   */
  return (
    <Box sx={BOX_STYLE} onClick={handleProductClick} display={"flex"} flexDirection={"column"}>
      <Typography color={MainPageConstants.COLOR_BLACK}>
        {props.minUnitProduct?.name ?? ''}
      </Typography>
      <Box flexGrow={1} />
      <Box onClick={(e) => e.stopPropagation()}>
        <ECTagSelect
          selectedTags={state.selectedTags}
          optionTags={state.optionTags}
          showBorder={false}
          showEditButton={true}
          creatable={true}
          isClearable={false}
          onChange={handleTagSelectChange}
          onCreateNewTag={handleCreateNewTag}
          onTagPropertiesChange={onTagPropertiesChange}
          onTagDelete={onTagDelete}
        />
      </Box>
    </Box>
  );
};
