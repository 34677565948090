import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import { Product } from "../../../../../Models/Product/Product";
import { EcmgApiError } from "../../../../../Common/EcmgApiError";
import { useSnackbar } from "../../../../Common/Provider/SnackbarContext";
import { LogUtils } from "../../../../../Common/LogUtils";
import { MainPageConstants } from "../../../Common/MainPageConstants";
import {StockPatternService} from "../../../../../Services/Pattern/StockPatternService";
import {StockPattern} from "../../../../../Models/Pattern/StockPattern";
import {ProductService} from "../../../../../Services/ProductService";

interface ProductStockPatternProps {
  product: Product;
  width: number;
  canChange: boolean;
}

/**
 * 商品の在庫パターンコンポーネント
 */
export const ProductStockPattern = (props: ProductStockPatternProps) => {

  /**
   * useState
   */
  const [state, setState] = useState({
    stockPatternId: -1,
    stockPatterns: [] as StockPattern[],
    processing: false
  });

  /**
   * useContext
   */
  const { showSnackbarMessage } = useSnackbar();

  /**
   * マウント時の処理
   */
  useEffect(() => {
    const patterns = StockPatternService.getInstance().getAllPatterns();
    updateState({ stockPatterns: patterns });
  }, []);

  /**
   * props変更時の処理
   */
  useEffect(() => {
    updateState({ stockPatternId: props.product.stock_pattern_id || -1 });
  }, [props]);

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 在庫パターン変更時の処理
   */
  const handleStockPatternChange = async (event: SelectChangeEvent<number>) => {
    let newPatternId: number = Number(event.target.value);

    if (state.stockPatternId === newPatternId) {
      return;  // 変更なし
    }

    updateState({ processing: true });

    let message: string;
    try {
      // 在庫パターン更新処理
      message
        = await ProductService.getInstance().updateProductStockPattern(
          props.product,
        newPatternId === -1 ? undefined : newPatternId);

      updateState({ stockPatternId: newPatternId, processing: false });
    } catch (e) {
      LogUtils.ex(e);
      updateState({ processing: false });

      if (e instanceof EcmgApiError && e.message) {
        message = 'エラー：' + e.message;
      } else {
        message = '在庫パターン更新処理でエラーが発生しました。';
      }
    }

    showSnackbarMessage(message);
  };


  /**
   * 描画
   */
  return (
    <Box sx={{ width: props.width, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <FormControl fullWidth variant="outlined">
        <Select
          value={state.stockPatternId}
          onChange={handleStockPatternChange}
          disabled={state.processing || !props.canChange}
          size="small"
          sx={{
            height: "30px",
            padding: 0,
          }}
        >
          <MenuItem value={-1}>
            <Typography variant="body2">未選択</Typography>
          </MenuItem>

          {state.stockPatterns.map((pattern) => (
            <MenuItem key={pattern.id} value={pattern.id}>
              <Typography variant="body2">{pattern.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {state.processing && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
            height: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
            ml: 1 }}>
          <CircularProgress size={MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT} />
        </Box>
      )}
    </Box>
  );
};
