import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { AppBar, Box, IconButton, Toolbar, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SpecialDate } from '../../../Models/SpecialDate';
import { Constants } from '../../../Common/Constants';
import { EMTextField } from '../../Common/Components/EMTextField';
import { EMSelect } from '../../Common/Components/EMSelect';
import { EMDatePicker } from '../../Common/Components/EMDatePicker';

interface SpecialDateEditDialogProps {
  open: boolean;
  specialDate: SpecialDate;
  onClose: () => void;
  onSave: (specialDate: SpecialDate) => void;
  errors: Record<string, string>;
}

/**
 * 特別日編集ダイアログ
 * @param props
 * @constructor
 */
export const SpecialDateEditDialog = (props: SpecialDateEditDialogProps) => {
  const [state, setState] = useState({
    editedSpecialDate: new SpecialDate({}),
    dateType: 'single', // 'single' or 'range'
  });

  /**
   * props変更時処理
   */
  useEffect(() => {
    setState({
      editedSpecialDate: props.specialDate.copyWith({}),
      dateType: props.specialDate.end_date ? 'range' : 'single',
    });
  }, [props]);

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * テキストフィールドの値変更時
   * @param name
   * @param value
   */
  const handleChange = (name: string, value: string) => {
    const newSpecialDateData = state.editedSpecialDate.copyWith({ [name]: value });
    updateState({ editedSpecialDate: newSpecialDateData });
  };

  /**
   * 日付フィールドの値変更時
   * @param name
   * @param value
   */
  const handleDateChange = (name: string, value?: Date) => {
    const newSpecialDateData = state.editedSpecialDate.copyWith({ [name]: value });
    // 期間指定で開始日が変更された場合は、終了日も変更
    if (name === 'start_date' && state.dateType === 'range') {
      newSpecialDateData.end_date = value;

    }
    updateState({ editedSpecialDate: newSpecialDateData });
  };

  /**
   * 日付タイプ変更時
   * @param event
   */
  const handleDateTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSpecialDateData = state.editedSpecialDate.copyWith({
      end_date: undefined
    });
    updateState({
      dateType: event.target.value,
      editedSpecialDate: newSpecialDateData
    });
  };

  /**
   * 特別日タイプの変更時
   * @param value
   */
  const handleSpecialDateTypeChange = (value: string) => {
    const newSpecialDateType = parseInt(value);
    const newSpecialDateData = state.editedSpecialDate.copyWith({
      special_type: isNaN(newSpecialDateType) ? undefined : newSpecialDateType
    });
    updateState({ editedSpecialDate: newSpecialDateData });
  };

  /**
   * 保存ボタンクリック時
   */
  const handleSave = () => {
    if (state.dateType === 'single') {
      state.editedSpecialDate.end_date = undefined; // Clear end date if single date is selected
    }
    props.onSave(state.editedSpecialDate);
  };

  /**
   * 特別日タイプのリストを返す
   */
  const specialDateTypes = () => {
    return Object.entries(Constants.specialDateLabels).map(([id, label]) => ({
      id: parseInt(id),
      label
    }));

  }

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            特別日編集
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box mt={2}>
          <EMSelect
            label="特定日タイプ"
            value={state.editedSpecialDate.special_type?.toString() ?? ''}
            onChange={handleSpecialDateTypeChange}
            menuItems={specialDateTypes()}
            itemValueKey="id"
            itemLabelKey="label"
            error={props.errors['special_type']}
            sx={{ minWidth: 180 }}
          />
        </Box>
        <Box mt={2}>
          <EMTextField
            label="備考"
            value={state.editedSpecialDate.note ?? ''}
            onChange={(value) => handleChange('note', value)}
            error={props.errors['note']}
            multiline={true}
            rows={3}
          />
        </Box>
        <Box mt={2}>
          <RadioGroup row value={state.dateType} onChange={handleDateTypeChange}>
            <FormControlLabel value="single" control={<Radio />} label="１日" />
            <FormControlLabel value="range" control={<Radio />} label="期間" />
          </RadioGroup>
        </Box>
        <Box mt={2}>
          <EMDatePicker
            label={state.dateType === 'single' ? "日付" : "開始日"}
            value={state.editedSpecialDate.start_date}
            onChange={(date) => handleDateChange('start_date', date )}
            error={props.errors['start_date']}
          />
        </Box>
        {state.dateType === 'range' && (
          <Box mt={2}>
            <EMDatePicker
              label="終了日"
              value={state.editedSpecialDate.end_date}
              onChange={(date) => handleDateChange('end_date', date)}
              error={props.errors['end_date']}
            />
          </Box>
        )}
        <Box mt={2}>
          <DialogActions>
            <Button onClick={handleSave} color="primary" variant="contained">保存</Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
