import { Paper, styled, Theme,} from '@mui/material';
import React, {useEffect, useState} from "react";
import {AIChatMessageContent} from "./AIChatMessageContent";
import {AIChatMessage} from "../Model/AIChatMessage";


interface AIChatMessageBubbleProps {
  message: AIChatMessage;
  right?: boolean;
  maxWidth?: number;
  minWidth?: number;
  minHeight?: number;
}

/**
 * AIチャット吹き出し
 * @param props
 * @constructor
 */
export const AIChatMessageBubble = (props: AIChatMessageBubbleProps) => {

  // 背景色
  let backgroundColor = '#EDEDED';
  if(props.right) {
    backgroundColor = '#BDF498';
  }

  /**
   * useEffect
   */
  useEffect(() => {
  }, []);

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 吹き出しとテールのスタイルを定義
   */
  const Bubble = styled(Paper)(({ theme }: { theme: Theme }) => ({
    position: 'relative',
    padding: theme.spacing(1),
    backgroundColor: backgroundColor,
    minWidth: props.maxWidth ? props.maxWidth : 800,
    maxWidth: props.minWidth ? props.minWidth : 800,
    minHeight: props.minHeight ? props.minHeight : 50,
    borderRadius: theme.shape.borderRadius,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: props.right ? '90%' : 10,
      width: 0,
      height: 0,
      border: '10px solid transparent',
      borderTop: 'none', // 上のボーダーを消去
      borderBottomColor: backgroundColor,
      transform: 'translateY(-100%)', // Y軸方向の位置を上に調整
    }
  }));

  /**
   * 描画
   */
  return (
    <>
      <Bubble elevation={3}
      >
        <AIChatMessageContent
          message={props.message}
        />
      </Bubble>
    </>
  );
};

