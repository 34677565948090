import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {LogUtils} from "../Common/LogUtils";

/**
 * HTTPサービス基底クラス
 */
export class HttpServiceBase {
  private baseURL(): string {
    // const debugMode = process.env.NODE_ENV !== "production";
    //return debugMode ? "http://localhost/" : "";
    return ""; // CORS対策のため、package.jsonのproxy設定を使う。
  }

  async getAxios(getCsrfCookie = false): Promise<AxiosInstance> {
    const instance = axios.create({
      baseURL: this.baseURL(),
      withCredentials: true
    });

    if (getCsrfCookie) {
      await instance.get("sanctum/csrf-cookie");
    }

    return instance;
  }

  makeAuthorizeOption(additionalHeaders: any = {}, responseType?: any): AxiosRequestConfig | undefined {
    try {
      const xsrfToken = (document.cookie.match(/XSRF-TOKEN=([^;]+)/) || [undefined, ""])[1];
      LogUtils.d(`xsrfToken=${xsrfToken}`);

      return {
        headers: {
          "accept": "application/json",
          "X-XSRF-TOKEN": decodeURIComponent(xsrfToken),
          ...additionalHeaders
        },
        responseType
      };
    } catch (e) {
      LogUtils.ex(e);
      return undefined;
    }
  }

}
