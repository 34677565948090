import React, {useEffect, useRef, useState} from 'react';
import {Box} from "@mui/material";
import {AIChatMessage} from "./Model/AIChatMessage";
import {AIChatMessageItem} from "./Item/AIChatMessageItem";
import {LogUtils} from "../../Common/LogUtils";

interface AIChatMessagesListProps {
  messages: AIChatMessage[];
  bubbleWidth?: number;
  addEvent?: {
    prevMessageId: number;
  }
}

/**
 * AIチャットメッセージリスト
 * @param props
 * @constructor
 */
export const AIChatMessagesList = (props: AIChatMessagesListProps) => {
  /**
   * 定数
   */

  /**
   * useRef
   */
  const boxRef = useRef<HTMLDivElement>(null);

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
  });

  /**
   * useEffect
   */
  useEffect(() => {
  }, []);


  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };


  /**
   * メッセージ追加時
   */
  useEffect(() => {
    if (props.addEvent == null) {
      return;
    }

    //----------------------------------
    // 必要に応じて最下部にスクロール
    //----------------------------------
    const box = boxRef.current;
    if (box) {
      const targetElement = box.querySelector(`[data-message-id='${props.addEvent.prevMessageId}']`);
      if (targetElement) {
        // 要素がビューポート内にあるかどうかをチェック
        const rect = targetElement.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
        if (isVisible) {
          box.scrollTop = box.scrollHeight;
        }
      }
    }

  }, [props.addEvent]);

  /**
   * 描画
   */
  return (
    <Box
      ref={boxRef}
      sx={{
        overflow: 'auto',
        width: '100%',
        flex: 1, // flex: 1 はこのコンポーネントでスクロール可とするのに、ここで必要
        '&::-webkit-scrollbar': {
          display: 'none' // WebKitブラウザ用のスタイル
        }
      }}
    >
      {props.messages.map((message) => (
        <div　key={message.id} data-message-id={message.id}>
          <Box mt={2} mb={2} >
            <AIChatMessageItem
              message={message}
              bubbleWidth={props.bubbleWidth}
            />
          </Box>
        </div>
      ))}
    </Box>
  );
}

