// StockCount.tsx
import React, {useEffect} from 'react';
import {Box} from "@mui/material";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {SearchResult} from "../../../../../Models/SearchResult";
import {AddProductStock} from "../../SKU/Product/AddProductStock";
import {StockCount} from "./StockCount";

interface StockCountComponentProps {
  searchResult: SearchResult;
  height: number;
  width: number;
  isSKU: boolean;
}

/**
 * 合計在庫数
 * @param props
 * @constructor
 */
const StockCountComponent = (props: StockCountComponentProps) => {
  /**
   * 定数
   */
  // SKU
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
    height: props.height,
    width: props.width,
    minWidth: props.width,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    color: MainPageConstants.COLOR_BLACK,
  };
  // List
  const BOX_STYLE_LIST = {
    boxSizing: 'border-box',
    height: props.height,
    width: props.width,
    minWidth: props.width,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    color: MainPageConstants.COLOR_BLACK,
  };

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
  }, []);


  /**
   * SKUモード用のコンポーネント
   */
  const skuComponents = () => {
    return (
      <Box mt={1}>
        <AddProductStock product={props.searchResult.product} />
      </Box>

    );
  }

  /**
   * 描画
   */
  return (
    <Box sx={props.isSKU ? BOX_STYLE : BOX_STYLE_LIST}>
      {/* 合計在庫数/潜在在庫数 */}
      <StockCount
        searchResult={props.searchResult}
        isSKU={props.isSKU}
      />

      {/* SKUモード用のコンポーネント */}
      {
        props.isSKU && skuComponents()
      }
    </Box>
  );
}

export default StockCountComponent;
