import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {MuiColorInput} from "mui-color-input";

interface ColorDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (text: string) => void;
  initialColor?: string;
  title?: string;
  isAlphaHidden?: boolean;
  format?: 'hex' | 'rgb' | 'hsl' | 'hex8' | 'hsv';
}

/**
 * 文字列ダイアログ
 * @param props
 * @constructor
 */
export const ColorDialog = (props: ColorDialogProps) => {
  const [state, setState] = useState({
    color: props.initialColor ?? '',
  });

  useEffect(() => {
    if (props.open) {
      setState({
        color: props.initialColor ?? '',
      });
    }
  }, [props.open, props.initialColor]);

  const handleSave = () => {
    if (state.color) {
      props.onSave(state.color);
    }
  };


  /**
   * 色変更
   * @param color
   */
  const onColorChanged = (color: string | undefined) => {
    setState({
      color: color ?? '',
    });
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box mt={2}>
          <MuiColorInput
            format={props.format}
            isAlphaHidden={props.isAlphaHidden}
            value={state.color}
            onChange={onColorChanged}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained">
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};
