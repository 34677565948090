/**
 * ユーザークラス
 */
export class User {
  id?: number;
  name?: string;
  email?: string;
  user_type?: number;
  office_id?: number;
  color?: string;
  password?: string;
  password_confirm?: string;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<User> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<User>): User {
    return new User({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): User {
    return new User({
      ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): User {
    const decoded = JSON.parse(jsonString);
    return User.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

}
