import React, {useState} from 'react';
import {Constants} from "../../../Common/Constants";
import {
  Button,
} from "@mui/material";
import {LogUtils} from "../../../Common/LogUtils";
import {EcmgApiError} from "../../../Common/EcmgApiError";
import {CommonUtils} from "../../../Common/CommonUtils";
import {useSnackbar} from "../../Common/Provider/SnackbarContext";
import ECProgress from "../../Common/Components/ECProgress";
import UploadIcon from "@mui/icons-material/Upload";
import {ImportService} from "../../../Services/IO/ImportService";
import Tooltip from "@mui/material/Tooltip";


/**
 * インポートボタン
 * @constructor
 */
export const ImportButton = () => {

  /**
   * useState
   */
  const [state, setState] = useState({
    exportTarget: Constants.EXPORT_TYPE_ALL,
    processing: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * アップロードボタンクリック時処理
   * @param event
   */
  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let message = '';

    try {
      const files = event.target.files;
      if ( !files || files.length === 0 ) {
        return;
      }
      const ret = window.confirm('ファイルをインポートしますか?');
      if(!ret) {
        return;
      }
      const selectedFile = files[0];


      updateState({ processing: true });

      // インポート
      message = await ImportService.getInstance().import(selectedFile);

      updateState({ processing: false });

    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = 'インポート処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);

    // inputのvalueをクリア
    event.target.value = '';
  };

  /**
   * 描画
   */
  return (
    <>
      <label>
        <Tooltip title={'インポート'} >
          <Button component="span" color="inherit">
            <UploadIcon />
          </Button>
        </Tooltip>
        <input
          type="file"
          accept=".csv"
          hidden
          onChange={event => handleUpload(event)} />
      </label>

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </>
  );
};
