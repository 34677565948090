import React, {useEffect, useState} from 'react';
import {MinUnitProductImage} from "../Common/MinUnitProductImage";
import StockCountComponent from "../Common/StockCount/StockCountComponent";
import {SalesTrend} from "../Common/SalesTrend";
import {UnitProductList} from "./UnitProduct/UnitProductList";
import {ProductName} from "../Common/ProductName";
import {Box, Divider, Stack, Typography} from '@mui/material';
import { ShippingMethod } from '../Common/ShippingMethod';
import {SKUConstants} from "./SKUConstants";
import {SearchResult} from "../../../../Models/SearchResult";
import {MallIntegrationButton} from "./MallIntegrationButton";
import {ProductStockPattern} from "./Product/ProductStockPattern";
import {ProductPricePattern} from "./Product/ProductPricePattern";
import {UnreceivedStockArea} from "../Common/UnreceivedStockArea/UnreceivedStockArea";
import {ProcurementCountArea} from "../Common/ProcurementCountArea/ProcurementCountArea";
import {ReceivedStockArea} from "../Common/ReceivedStockArea/ReceivedStockArea";
import {ProcurementPrices} from "./Product/ProcurementPrices";
import {FreeMarketInfoTable} from "./Product/FreeMarketInfos";
import {FreeMemoField} from "./Product/FreeMemoField";
import {ProductMemoImageList} from "./Product/ProductMemoImageList";
import {ProductService} from "../../../../Services/ProductService";
import {MainPageConstants} from "../../Common/MainPageConstants";
import {SaleReceivedData} from "./Product/SaleReceivedData";
import {AddCalendar} from "../Common/Calendar/AddCalenar";
import {ChatButton} from "../Common/Chat/ChatButton";
import {LoginService} from "../../../../Services/LoginService";
import {UserService} from "../../../../Services/UserService";
import {OfficeService} from "../../../../Services/OfficeService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Constants} from "../../../../Common/Constants";
import {UnreceivedInfoPanel} from "./UnreceivedInfoPanel";

interface SKUPanelProps {
  searchResult: SearchResult;
}

/**
 * SKUパネル
 * @param props
 * @constructor
 */
export const SKUPanel = (props: SKUPanelProps) => {
  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    detailType: Constants.SKU_INITIAL_SALES, // 詳細情報表示タイプ
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    // ログイン中のユーザーより初期表示を決定
    const loginUser = LoginService.getInstance().loginUser;
    const user = UserService.getInstance().getUserById(loginUser?.id ?? 0);
    const office = OfficeService.getInstance().getOfficeById(user?.office_id ?? 0);
    updateState({detailType: office?.sku_initial ?? Constants.SKU_INITIAL_SALES});


  }, []);

  //
  // /**
  //  * 検索結果(1件)更新時
  //  */
  // const onSearchResultOne = (searchResult: SearchResult) => {
  //   // 同じ在庫コードの商品の場合のみ更新
  //   if (searchResult.product.stock_code !== props.searchResult.product.stock_code) {
  //     return;
  //   }
  //   // LogUtils.d('onSearchResultOne: ' + JSON.stringify(searchResult));
  //   updateState({ searchResult: searchResult });
  // }

  /**
   * モール連動ボタン
   */
  const mallIntegrationButton = () => {
    if (props.searchResult.product?.mall_flag) {
      return null;
    }
    return (
      <MallIntegrationButton
        product={props.searchResult.product}
        width={SKUConstants.MALL_INTEGRATION_BUTTON_WIDTH}
      />
    );
  }

  /**
   * 販売情報
   */
  const detailSale = () => {
    return (
      <Box flexGrow={1}>
        <Box width={SKUConstants.IMAGE_MEMO_LIST_WIDTH}>
          {/* 画像メモリスト */}
          <ProductMemoImageList
            stockCode={props.searchResult.product.stock_code!}
            images={ProductService.getInstance().getProductMemoPhotos()}
            canChange={isUserOrAdmin}
            imageWidth={SKUConstants.IMAGE_SIZE_SALES_INFO}
          />
        </Box>
        <Box mt={1} />
        {/* ユニット商品リスト */}
        <UnitProductList
          product={props.searchResult.product}
          orderCountData={props.searchResult.orderCountData}
          canChange={isAdmin}
        />
      </Box>
    );
  }

  /**
   * 仕入れ情報
   */
  const detailUnreceived = () => {
    return (
      <UnreceivedInfoPanel searchResult={props.searchResult} />
    );
  }


  // 管理者か
  const isAdmin = LoginService.getInstance().loginUser?.isAdmin() ?? false;
  // 管理者またはユーザーか
  const isUserOrAdmin = LoginService.getInstance().loginUser?.isUserOrAdmin() ?? false;

  /**
   * 描画
   */
  return (
    <Stack spacing={1}>
      {/* 1段目 */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        {/* サムネイル画像 */}
        <MinUnitProductImage
          searchResult={props.searchResult}
          imageSize={SKUConstants.IMAGE_SIZE}
        />
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          {/* 配送方法 */}
          <ShippingMethod
            unitProducts={props.searchResult.product.unit_products ?? []}
            width={SKUConstants.SHIPPING_METHOD_WIDTH}
          />
          {/* チャットボタン  */}
          <ChatButton
            searchResult={props.searchResult}
          />
        </Box>
        {/* 商品名 */}
        <Box flexGrow={1}>
          <ProductName
            product={props.searchResult.product}
            minUnitProduct={props.searchResult.minimalUnitProduct}
            height={SKUConstants.PRODUCT_NAME_HEIGHT}
          />
        </Box>
        {/* モール連携ボタン */}
        {mallIntegrationButton()}
      </Box>
      {/* 2段目 */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        gap={2}
      >
        {/* 合計在庫数 */}
        <StockCountComponent
          isSKU={true}
          searchResult={props.searchResult}
          height={SKUConstants.STOCK_COUNT_HEIGHT}
          width={SKUConstants.STOCK_COUNT_WIDTH}
        />
        <Box display="flex" flexDirection="column" flexGrow={1} gap={1}>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
            {/* 未着在庫関連 */}
            <UnreceivedStockArea
              searchResult={props.searchResult}
              height={SKUConstants.UNRECEIVED_COUNT_AREA_HEIGHT}
              width={SKUConstants.UNRECEIVED_COUNT_AREA_WIDTH}
            />
          </Box>

          <Box display={"flex"} flexDirection={"row"} alignItems={"center"} >
            {/* 受取在庫関連 */}
            <ReceivedStockArea
              searchResult={props.searchResult}
              height={SKUConstants.RECEIVED_COUNT_AREA_HEIGHT}
              width={SKUConstants.RECEIVED_COUNT_AREA_WIDTH}
            />
            <Box ml={1} />
            {/* 仕入数関連 */}
            <ProcurementCountArea
              searchResult={props.searchResult}
              height={SKUConstants.PROCUREMENT_COUNT_AREA_HEIGHT}
              width={SKUConstants.PROCUREMENT_COUNT_AREA_WIDTH}
            />
            <Box ml={1} />
            {/* 販売・受取数データ */}
            <SaleReceivedData
              searchResult={props.searchResult}
              height={SKUConstants.SALE_RECEIVED_DATA_AREA_HEIGHT}
              width={SKUConstants.SALE_RECEIVED_DATA_AREA_WIDTH}
            />
          </Box>
        </Box>

        {/* 販売傾向 */}
        <SalesTrend
          stockCode={props.searchResult.product.stock_code!}
          isSKU={true}
          orderCountData={props.searchResult.orderCountData}
          height={SKUConstants.SALES_TREND_HEIGHT}
          width={SKUConstants.SALES_TREND_WIDTH}
        />
      </Box>

      <Divider  />

      {/* 3段目 */}
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        padding: 1
      }} >
        {/* 仕入れ価格 */}
        <ProcurementPrices
         procurementPrices={props.searchResult.procurementPrices}
          width={SKUConstants.PROCUREMENT_PRICE_WIDTH}
        />

        <Box ml={2} />
        <Divider orientation={"vertical"} />
        <Box ml={2} />

        {/* フリマ情報 */}
        <FreeMarketInfoTable
          stockCode={props.searchResult.product.stock_code!}
          data={props.searchResult.freeMarketInfos}
          width={SKUConstants.FREE_MARKET_INFO_WIDTH}
        />

        <Box ml={2} />

        <Box display={"flex"} flexDirection={"row"}>
          {/* メモ */}
          <Box flexGrow={1}>
            <FreeMemoField
              stockCode={props.searchResult.product.stock_code!}
              freeMemoType={Constants.FREE_MEMO_TYPE_NORMAL}
              freeNote={props.searchResult.product.free_note ?? ''}
              height={SKUConstants.FREE_MEMO_HEIGHT}
              width={SKUConstants.FREE_MEMO_WIDTH}
            />
          </Box>

          {/*  カレンダー追加　*/}
          <Box ml={2} />
          <AddCalendar
            stockCode={props.searchResult.product.stock_code!}
            calendarInfo={props.searchResult.calendarInfo}
            isSKU={true}
          />
        </Box>

      </Box>

      <Divider  />

      {/* 4段目 */}
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "end",
      }} >
        {/* 在庫パターン */}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography  fontSize={"14px"}>在庫パターン：</Typography>
          <ProductStockPattern
            product={props.searchResult.product}
            width={SKUConstants.STOCK_PATTERN_WIDTH}
            canChange={isAdmin}
          />
        </Box>
        <Box ml={2} />
        {/* 価格パターン */}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography  fontSize={"14px"}>価格パターン：</Typography>
          <ProductPricePattern
            product={props.searchResult.product}
            width={SKUConstants.PRICE_PATTERN_WIDTH}
            canChange={isAdmin}
          />
        </Box>
        <Box ml={2} />
        {/* ユニット情報 */}
        <Typography color={MainPageConstants.COLOR_BLACK} fontSize={"14px"}>
          Unit情報：{props.searchResult.unitInfo}
        </Typography>
      </Box>

      {/* 5段目 */}
      <Tabs value={state.detailType}
        onChange={(event, value) => updateState({ detailType: value })}
      >
        <Tab value={Constants.SKU_INITIAL_SALES} label={Constants.SKU_INITIAL_LABELS[Constants.SKU_INITIAL_SALES]} />
        <Tab value={Constants.SKU_INITIAL_UNRECEIVED} label={Constants.SKU_INITIAL_LABELS[Constants.SKU_INITIAL_UNRECEIVED]} />
      </Tabs>

      {/* 詳細情報 */}
      {state.detailType === Constants.SKU_INITIAL_SALES && detailSale()}
      {state.detailType === Constants.SKU_INITIAL_UNRECEIVED && detailUnreceived()}
    </Stack>
  );
}

