/**
 * システム設定
 */
export class SystemSetting {
  /**
   * 定数
   */
  // ◯の付く日
  static readonly KEY_PATTERN_DATE = 'pattern_date';
  // 商品同期対象外キーワード
  static readonly KEY_EXCLUSIVE_KEYWORD = 'exclusive_keyword';
  // カレンダー名A
  static readonly KEY_CALENDAR_NAME_A = 'calendar-name-a';
  // カレンダー名B
  static readonly KEY_CALENDAR_NAME_B = 'calendar-name-b';
  // カレンダー色設定
  static readonly KEY_CALENDAR_COLOR_ITEMS = 'calendar-color-items';

  id?: number;
  key?: string;
  value?: string;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<SystemSetting>) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<SystemSetting>): SystemSetting {
    return new SystemSetting({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): SystemSetting {
    return new SystemSetting({
      ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): SystemSetting {
    const decoded = JSON.parse(jsonString);
    return SystemSetting.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }


  /**
   * ◯の付く日データ取得
   */
  getPatternDate() : [number?, string?]{
    const value = this.value;
    if (value == null) {
      return [undefined, ''];
    }
    // コロンで分割
    const values = value.split(':');
    if (values.length !== 2) {
      return [undefined, ''];
    }
    const specialType = parseInt(values[0]);
    const patternDateString = values[1];
    return [specialType, patternDateString ?? ''];
  }

  /**
   * ◯の付く日データ更新
   */
  updatePatternDate(specialType?: string, patternDateString?: string)  {
    // データ取得
    const [oldSpecialType, oldPatternDateString] = this.getPatternDate();
    const newSpecialType = specialType ?? oldSpecialType;
    const newPatternDateString = patternDateString ?? oldPatternDateString;
    // コロンでつなぐ
    this.value = `${newSpecialType}:${newPatternDateString}`;
  }


}
