import React from 'react';
import {Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {SpecialDateService} from "../../../../../Services/SpecialDate/SpecialDateService";
import {SalesDailyHistory} from "../../../../../Models/SalesData/SalesData";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {SalesDataConstants} from "./SalesDataConstants";


interface SalesDailyHistoryTableProps {
  maxHeight: number;
  dailySalesHistory: SalesDailyHistory[];
}

/**
 * 販売数履歴（日次）
 * @param props
 * @constructor
 */
export const SalesDailyHistoryTable = (props: SalesDailyHistoryTableProps) => {
  /**
   * 定数
   */
  const FONT_SIZE = '12px';
  const PADDING = '4px';

  /**
   * セル
   * @param text
   * @param date
   * @param index
   * @param align
   * @param borderRight
   */
  const tableCell = (text: string, date: string, index: number, align = 'center' as any, borderRight = false) => {
    let style = SpecialDateService.getInstance().getSalesHistoryStyleBeforeDays(index);
    style = {...style, fontSize: FONT_SIZE, padding: PADDING};

    const dayOfWeek = new Date(date).getDay();
    // 曜日が火曜日(2)または木曜日(4)の場合に背景色を設定
    if (!style.backgroundColor && (dayOfWeek === 2 || dayOfWeek === 4)) {
      style = {...style, backgroundColor: SalesDataConstants.LIGHT_GRAY};
    }

    // 右端に縦線を追加するオプション
    if (borderRight) {
      style = {...style, borderRight: '1px solid #ddd'};
    }

    return (
      <TableCell align={align} style={style}>
        {text}
      </TableCell>
    );
  }


  /**
   * 数値をフォーマット
   * @param num
   */
  const formatNumber = (num: number | undefined) => {
    if ( num == null ){
      return '---';
    }
    return CommonUtils.formatNumber(num);
  }

  /**
   * Y・Rを取得
   * @param row
   */
  const getYR = (row: SalesDailyHistory) => {
    const y = row.y_sales != null ? CommonUtils.formatNumber(row.y_sales) : '---';
    const r = row.r_sales != null ? CommonUtils.formatNumber(row.r_sales) : '---';
    return `(${y} / ${r})`;
  }

  /**
   * ヘッダーセル
   * @param text
   * @param borderRight
   */
  const headerCell = (text: string, borderRight = false) => {
    const style = {
      fontSize: FONT_SIZE,
      padding: PADDING,
      backgroundColor: SalesDataConstants.MID_GRAY,
      ...(borderRight && { borderRight: '1px solid #ddd' })
    };

    return (
      <TableCell align="center" style={style}>
        <strong>{text}</strong>
      </TableCell>
    );
  }


  /**
   * Divider
   */
  const divider = (index: number) => {
    const style = { backgroundColor: SalesDataConstants.DARK_GRAY };
    // 先頭行
    if (index === 0) {
      return (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={12}>
            <Divider style={style}/>
          </TableCell>
        </TableRow>
      );
    }
    // 2行目
    else if (index === 1) {
      // indexが0と1の間に二重線を表示
      return (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={12}>
            <Divider style={style}/>
            <Box sx={{marginTop: '2px'}}/>
            <Divider style={style}/>
          </TableCell>
        </TableRow>
      );
    } else if (index > 1 && (index - 1) % 7 === 0) {
      // それ以降の場合は7つごとに実線を表示
      return (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={12}>
            <Divider style={style}/>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  };

  /**
   * 描画
   */
  return (
    <TableContainer component={Paper} style={{ maxHeight: props.maxHeight, overflow: 'auto' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headerCell("週")}
            {headerCell("日付")}
            {headerCell("曜日", true)}
            {headerCell("1w販売")}
            {headerCell("潜在", true)}
            {headerCell("仕入数")}
            {headerCell("受取数")}
            {headerCell("在庫数", true)}
            {headerCell("販売数")}
            {headerCell("Y・R")}
            {headerCell("Y価格")}
            {headerCell("R価格")}
          </TableRow>
        </TableHead>

        <TableBody>
          {props.dailySalesHistory.map((row, index) => {
            const prevDate = index > 0 ? props.dailySalesHistory[index - 1].date : undefined;

            return (
              <>
                {divider(index)}
                <TableRow key={row.date}>
                  {tableCell(row.week != null ? row.week + 'w' : '', row.date, index, 'right')}
                  {tableCell(CommonUtils.getDateTextForTable(row.date, prevDate), row.date, index, 'right')}
                  {tableCell(CommonUtils.getWeekTextForTable(row.date), row.date, index, 'left', true)}
                  {tableCell(formatNumber(row.one_w_sales), row.date, index, 'right')}
                  {tableCell(formatNumber(row.potential_stock), row.date, index, 'right', true)}
                  {tableCell(formatNumber(row.unreceived), row.date, index, 'right')}
                  {tableCell(formatNumber(row.received), row.date, index, 'right')}
                  {tableCell(formatNumber(row.stock), row.date, index, 'right', true)}
                  {tableCell(formatNumber(row.sales), row.date, index, 'right')}
                  {tableCell(getYR(row), row.date, index, 'center')}
                  {tableCell(formatNumber(row.y_price), row.date, index, 'right')}
                  {tableCell(formatNumber(row.r_price), row.date, index, 'right')}
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
