import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  Toolbar,
  Typography, IconButton, AppBar
} from '@mui/material';
import {EMDatePicker} from "../../../../Common/Components/EMDatePicker";
import CloseIcon from "@mui/icons-material/Close";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {CalendarColorItem} from "../../../../../Models/Calendar/CalendarColorItem";
import {SystemSettingService} from "../../../../../Services/SystemSettingService";

interface AddCalendarDialogProps {
  open: boolean;
  onClose: () => void;
  defaultCalendarType?: string;
  onSave: (data: {calendarType: string, date: Date, color: string}) => void;
}

/**
 * カレンダー追加ダイアログ
 * @param props
 * @constructor
 */
export const AddCalendarDialog = (props: AddCalendarDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    calendarType: 'A',
    calendarNameA: '',
    calendarNameB: '',
    date: new Date(),
    colors: [] as CalendarColorItem[],
    selectedColorNumber: -1,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  /**
   * props更新時
   */
  useEffect(() => {
    if (props.open) {
      const calendarNameA = CommonUtils.getCalendarName('A');
      const calendarNameB = CommonUtils.getCalendarName('B');
      const colors = SystemSettingService.getInstance().getCalendarColorItems();

      updateState({
        calendarNameA,
        calendarNameB,
        calendarType: props.defaultCalendarType ?? 'A',
        date: new Date(),
        colors: colors,
        selectedColorNumber: colors[0].number,
      });
    }
  }, [props]);

  /**
   * 保存
   */
  const handleSave = () => {
    if (state.selectedColorNumber === -1) {
      return;
    }
    const color = state.colors.find((item) => item.number === state.selectedColorNumber)?.color ?? '';
    props.onSave({
      calendarType: state.calendarType,
      date: state.date,
      color: color,
    });
  };

  /**
   * レンダリング
   */
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            カレンダー追加
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <Box sx={{fontWeight: "bold"}}>対象カレンダー</Box>
          <RadioGroup
            name="calendarType"
            value={state.calendarType}
            onChange={(e) => updateState({ calendarType: e.target.value as 'A' | 'B' })}
          >
            <FormControlLabel value="A" control={<Radio />} label={state.calendarNameA} />
            <FormControlLabel value="B" control={<Radio />} label={state.calendarNameB} />
          </RadioGroup>
        </FormControl>
        <Box mt={2} mb={2}>
          <EMDatePicker
            sx={{ width: '100%' }}
            label="登録日"
            value={state.date}
            onChange={(date: Date | undefined) => updateState({ date })}
          />
        </Box>
        <FormControl fullWidth sx={{ mb: 2 }} size={"small"}>
          <Select
            value={state.selectedColorNumber}
            onChange={(e) =>
              updateState({ selectedColorNumber: e.target.value as number })}
            displayEmpty
            inputProps={{ 'aria-label': '色' }}
          >
            {state.colors.map((calendarColorItem) => (
              <MenuItem key={calendarColorItem.number} value={calendarColorItem.number}>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                  <Box component="span"
                       sx={{
                         width: 24,
                         height: 24,
                         backgroundColor: calendarColorItem.color,
                         display: 'inline-block', mr: 1
                       }} />
                  {calendarColorItem.displayName}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary" variant="contained">
          登録
        </Button>
      </DialogActions>
    </Dialog>
  );
};
