import React, {KeyboardEvent, useEffect, useState} from 'react';
import {TextField, Button, Box, Divider} from '@mui/material';
import {EventService} from "../../Services/System/EventService";
import {ChatToPopup} from "./Item/To/ChatToPopup";

interface ChatInputFormProps {
  onSendMessage: (messageText: string) => void;
  editMode?: boolean;
  onEditCancel?: () => void;
}

/**
 * チャット入力フォーム
 * @param props
 * @constructor
 */
export const ChatInputForm = (props: ChatInputFormProps ) => {

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    messageText: '',
    toPopupOpen: false,
    anchorEl: null as HTMLElement | null,
  });


  /**
   * useEffect
   */
  useEffect(() => {
    // イベントハンドラ登録（イベントハンドラの中でstateの値を参照しないこと）
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_MESSAGE_INSERT, onMessageInsert);
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_MESSAGE_REPLACE, onMessageReplace);

    // クリーンアップ
    return () => {
      // イベントハンドラ解除
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_MESSAGE_INSERT, onMessageInsert);
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_MESSAGE_REPLACE, onMessageReplace);
    };
  }, []);

  /**
   * イベントハンドラ（メッセージ挿入）
   * @param message
   */
  const onMessageInsert = (message: string) => {
    setState(prevState => {
      // テキストエリアの要素を取得
      const input = document.getElementById('ChatInputForm') as HTMLInputElement;
      if (!input) return prevState; // inputがnullの場合、早期に処理を終了

      const start = input.selectionStart ?? 0; // 選択開始位置
      const end = input.selectionEnd ?? 0; // 選択終了位置
      const textBefore = prevState.messageText.substring(0, start); // 挿入位置前のテキスト
      const textAfter = prevState.messageText.substring(end); // 挿入位置後のテキスト

      const newText = textBefore + message + textAfter; // 新しいテキストを構築

      // カーソル位置を挿入したテキストの後に設定
      setTimeout(() => {
        input.focus();
        input.setSelectionRange(start + message.length, start + message.length);
      }, 0);

      return {
        ...prevState,
        messageText: newText // 新しいテキストで状態を更新
      };
    });
  };

  /**
   * イベントハンドラ（メッセージ置換）
   * @param message
   */
  const onMessageReplace = (message: string) => {
    setState( prevState => {
      return { ...prevState, messageText: message };
    });
  }

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 送信/保存ボタン クリック時
   */
  const handleSubmit = () => {
    props.onSendMessage(state.messageText);
    updateState({ messageText: '' });
  };

  /**
   * キーダウン時
   * @param e
   */
  const handleKeyDown = (e: KeyboardEvent) => {
    // Toポップアップが開いてたら閉じる
    if (state.toPopupOpen) {
      updateState({toPopupOpen: false});
    }

    // Shift + Enterが押された場合の処理
    if (e.ctrlKey && e.key === 'Enter') {
      handleSubmit();
      // ここに必要なアクションを実装
      e.preventDefault(); // テキストフィールドのデフォルトの挙動（改行の挿入）を防ぐ
    }
  };

  /**
   * 編集キャンセル
   */
  const onEditCancel = () => {
    // 文字をクリア
    updateState({ messageText: '' });
    // キャンセル処理
    props.onEditCancel && props.onEditCancel();
  }

  /**
   * Toボタンのクリックイベントハンドラ
   * @param e
   */
  const onToClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    updateState({anchorEl: e.currentTarget}); // アンカー要素を設定
    updateState({toPopupOpen: true}); // ポップアップを開く
  };

  /**
   * Toポップアップを閉じる
   */
  const handleCloseToPopup = () => {
    updateState({toPopupOpen: false});
  };

  /**
   * 描画
   */
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Divider />
      <Box mt={0.5} />
      <Box display={"flex"} flexDirection={"row"} pl={1} pr={1}>
        <Button
          variant="outlined"
          color="primary"
          size={"small"}
          onClick={onToClick}
        >
         To
        </Button>
        <Box flexGrow={1} />
        <Button
          variant="contained"
          color="primary"
          size={"small"}
          disabled={state.messageText.replaceAll("\n", "").length === 0}
          onClick={handleSubmit}
        >
          {props.editMode ? '保存' : '送信'}
        </Button>
        {/*  編集キャンセル*/}
        {props.editMode && (
          <>
            <Box ml={1} />
            <Button
              variant="outlined"
              color="primary"
              size={"small"}
              onClick={onEditCancel}
            >
              キャンセル
            </Button>
          </>
        )}
      </Box>
      <Box mt={0.5} />
      <TextField
        id={"ChatInputForm"}
        minRows={3}
        multiline={true}
        value={state.messageText}
        onChange={(e) => updateState({messageText: e.target.value})}
        onKeyDown={handleKeyDown}
        placeholder={"ここにメッセージ内容を入力\n(Ctrl + Enterキーで送信)"}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: 0, // アウトラインドスタイルのパディングを削除
            '& .MuiOutlinedInput-input': {
              padding: '8px', // アウトラインドインプットの内側のパディングを調整
            },
            '& .MuiOutlinedInput-multiline': {
              padding: 0, // 複数行テキストのパディングを削除
            }
          },
          '& .MuiInputBase-input': {
            fontSize: '16px',
          },
        }}
      />

      {/* ChatToPopupの条件付きレンダリング */}
      {state.toPopupOpen && (
        <ChatToPopup
          open={state.toPopupOpen}
          anchorEl={state.anchorEl}
          onClose={handleCloseToPopup}
        />
      )}

    </Box>
  );
};
