import React from 'react';
import {Box} from "@mui/material";
import {SearchResult} from "../../../../../Models/SearchResult";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {AddReceivedStock} from "./AddReceivedStock";

interface ReceivedStockAreaProps {
  searchResult: SearchResult;
  height: number;
  width: number;
}

/**
 * 受取在庫関連エリア
 * @param props
 * @constructor
 */
export const ReceivedStockArea = (props: ReceivedStockAreaProps) => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
    height: props.height,
    width: props.width,
    backgroundColor: MainPageConstants.COLOR_LIGHT_PURPLE,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'center',
    gap: 2,
  };

  /**
   * 描画
   */
  return (
    <Box sx={BOX_STYLE}>
      {/* 受取在庫数 */}
      <AddReceivedStock product={props.searchResult.product} />

    </Box>
  );
}
