import {StoreOrderCountData} from "../../../../../Models/Product/OrderCountData";
import {StoreProduct} from "../../../../../Models/Product/StoreProduct";
import {StoreService} from "../../../../../Services/StoreService";
import {Box, Typography} from "@mui/material";
import {LogUtils} from "../../../../../Common/LogUtils";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";

interface StoreCodeProps {
  storeProduct: StoreProduct;
  width: number;
}

/**
 * 店舗コード
 * @param props
 * @constructor
 */
export const StoreCode = (props: StoreCodeProps) => {

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * コードクリック時処理
   */
  const handleCodeClick = async () => {
    try {
      await navigator.clipboard.writeText(props.storeProduct?.mall_product_id ?? '');

      showSnackbarMessage('商品コードをコピーしました。[' + props.storeProduct?.mall_product_id + ']');
    } catch (e) {
      LogUtils.ex(e);
    }
  };

  /**
   * 描画
   */
  const store = StoreService.getInstance().getStoreById(props.storeProduct.store_id!);
  return (
    <Box
      onClick={handleCodeClick}
      sx={{
        width: props.width,
        textAlign: "center",
      }}
    >
      <Typography sx={{cursor: "pointer"}} color={MainPageConstants.COLOR_DARK_GRAY}>
        {store?.store_code}
      </Typography>
    </Box>
  );
};
