import {LogUtils} from "../../Common/LogUtils";
import EventEmitter from "events";

/**
 * イベントサービス
 */
export class EventService extends EventEmitter {
  /**
   * 定数
   */
  //----------------------------------------
  // ログイン・ログアウト
  //----------------------------------------
  public static EVENT_LOGGED_IN = "EVENT_LOGGED_IN";  // ログインイベント
  public static EVENT_LOGGED_OUT = "EVENT_LOGGED_OUT";  // ログアウトイベント
  //----------------------------------------
  // 商品検索
  //----------------------------------------
  public static EVENT_UPDATE_SEARCH_RESULTS = "EVENT_UPDATE_SEARCH_RESULTS";  // 検索結果更新イベント
  // public static EVENT_UPDATE_SEARCH_RESULT_ONE = "EVENT_UPDATE_SEARCH_RESULT_ONE";  // 検索結果更新イベント（１件）
  // public static EVENT_UPDATE_UNIT = "EVENT_UPDATE_UNIT";  // ユニット商品更新イベント
  // public static EVENT_UPDATE_STORE_PRODUCT = "EVENT_UPDATE_STORE_PRODUCT";  // 店舗商品更新イベント
  public static EVENT_SHOW_SALES_DATA = "EVENT_SHOW_SALES_DATA";  // 販売推移データ表示イベント（在庫コードを指定）
  //----------------------------------------
  // 設定画面
  //----------------------------------------
  public static EVENT_UPDATE_STOCK_PATTERNS = "EVENT_UPDATE_STOCK_PATTERNS";  // 在庫パターン更新イベント
  public static EVENT_UPDATE_PRICE_PATTERNS = "EVENT_UPDATE_PRICE_PATTERNS";  // 価格パターン更新イベント
  public static EVENT_UPDATE_USERS = "EVENT_UPDATE_USERS";  // ユーザー更新イベント
  public static EVENT_UPDATE_OFFICES = "EVENT_UPDATE_OFFICE";  // 事業所更新イベント
  //----------------------------------------
  // 店舗設定
  //----------------------------------------
  public static EVENT_RESERVE_ALL_PRODUCT_SYNC = "EVENT_RESERVE_ALL_PRODUCT_SYNC";  // 全商品同期予約イベント
  //----------------------------------------
  // タグ
  //----------------------------------------
  public static EVENT_UPDATE_DELETE_TAGS = "EVENT_UPDATE_DELETE_TAGS";  // タグ情報変更イベント（変更・削除）
  public static EVENT_NEW_TAGS = "EVENT_NEW_TAGS";  // タグ情報変更イベント（新規）
  //----------------------------------------
  // タグ分類
  //----------------------------------------
  public static EVENT_UPDATE_TAG_CATEGORIES = "EVENT_UPDATE_TAG_CATEGORIES";  // タグ分類情報変更イベント（変更・削除）

  //----------------------------------------
  // チャット
  //----------------------------------------
  public static EVENT_CHAT_JUMP = "EVENT_CHAT_JUMP";  // メッセージ移動
  public static EVENT_CHAT_MESSAGE_INSERT = "EVENT_CHAT_MESSAGE_INSERT";  // チャットメッセージ挿入
  public static EVENT_CHAT_MESSAGE_REPLACE = "EVENT_CHAT_MESSAGE_REPLACE";  // チャットメッセージ置換
  public static EVENT_CHAT_EDIT = "EVENT_CHAT_EDIT";  // チャットメッセージ編集
  public static EVENT_CHAT_DELETE = "EVENT_CHAT_DELETE";  // チャットメッセージ削除
  public static EVENT_CHAT_REACTION = "EVENT_CHAT_REACTION";  // チャットリアクション選択
  public static EVENT_CHAT_REACTION_DELETE = "EVENT_CHAT_REACTION_DELETE";  // チャットリアクション更新
  public static EVENT_CHAT_TO_ME_UNREAD_MESSAGES = "EVENT_CHAT_TO_ME_UNREAD_MESSAGES";  // 未読メッセージ(自分に対するメンション）
  public static EVENT_CHAT_TO_ME_UNRESPONDED_MESSAGES = "EVENT_CHAT_TO_ME_UNRESPONDED_MESSAGES";  // 未返信メッセージ(自分に対するメンション）
  public static EVENT_CHAT_FROM_ME_UNRESPONDED_MESSAGES = "EVENT_CHAT_FROM_ME_UNRESPONDED_MESSAGES";  // 未返信メッセージ（自分からのメッセージ）
  public static EVENT_CHAT_OPEN = "EVENT_CHAT_OPEN";    // チャットパネルを開く
  public static EVENT_CHAT_CLOSE = "EVENT_CHAT_CLOSE";  // チャットパネルを閉じる
  //----------------------------------------
  // 選択状態
  //----------------------------------------
  public static EVENT_SELECTION_CHANGE = "EVENT_SELECTION_CHANGE";  // 選択状態変更

  private static instance: EventService = new EventService();

  private constructor() {
    super();
  }

  public static getInstance(): EventService {
    return this.instance;
  }

  async init(): Promise<void> {
    LogUtils.d();
  }

  emitEvent(eventId: string, data: any = undefined) {
    this.emit(eventId, data);
  }

  onEvent(eventId: string, listener: (data: any) => void) {
    this.on(eventId, listener);
  }

}
