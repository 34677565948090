/**
 * 定数
 */
export class SettingConstants
{
  // テーブルヘッダスタイル
  public static TABLE_HEADER_STYLE: any = {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  };
}
