/**
 * 定数
 */
export class SKUConstants
{
  //----------------------------------
  // 商品部
  //----------------------------------
  // サムネイル画像サイズ
  public static IMAGE_SIZE = 100;
  // 配送方法横幅
  public static SHIPPING_METHOD_WIDTH = 120;
  // 商品名高さ
  public static PRODUCT_NAME_HEIGHT = 100;
  // 在庫数 高さ
  public static STOCK_COUNT_HEIGHT = 224;
  // 在庫数 横幅
  public static STOCK_COUNT_WIDTH = 220;
  // 未着在庫関連エリア 高さ
  public static UNRECEIVED_COUNT_AREA_HEIGHT = 114;
  // 未着在庫関連エリア 横幅
  public static UNRECEIVED_COUNT_AREA_WIDTH = 640;
  // 仕入数関連エリア 高さ
  public static PROCUREMENT_COUNT_AREA_HEIGHT = 100;
  // 仕入数関連エリア 横幅
  public static PROCUREMENT_COUNT_AREA_WIDTH = 200;
  // 受取在庫関連エリア 高さ
  public static RECEIVED_COUNT_AREA_HEIGHT = 100;
  // 受取在庫関連エリア 横幅
  public static RECEIVED_COUNT_AREA_WIDTH = 200;
  // 販売・受取数データエリア 高さ
  public static SALE_RECEIVED_DATA_AREA_HEIGHT = 100;
  // 販売・受取数データエリア 横幅
  public static SALE_RECEIVED_DATA_AREA_WIDTH = 160;
  // 販売傾向 高さ
  public static SALES_TREND_HEIGHT = 224;
  // 販売傾向 横幅
  public static SALES_TREND_WIDTH = 700;
  // 仕入れ価格 横幅
  public static PROCUREMENT_PRICE_WIDTH = 200;
  // フリマ情報 横幅
  public static FREE_MARKET_INFO_WIDTH = 320;
  // フリーメモ高さ
  public static FREE_MEMO_HEIGHT = 100;
  // フリーメモ 横幅
  public static FREE_MEMO_WIDTH = 800;
  // モール間連動ボタン 横幅
  public static MALL_INTEGRATION_BUTTON_WIDTH = 220;
  // 在庫パターン 横幅
  public static STOCK_PATTERN_WIDTH = 200;
  // 価格パターン 横幅
  public static PRICE_PATTERN_WIDTH = 200;
  // 画像メモリスト横幅
  public static IMAGE_MEMO_LIST_WIDTH = 800;
  // 画像メモ画像サイズ(販売情報)
  public static IMAGE_SIZE_SALES_INFO = 100;
  // 画像メモ画像サイズ(仕入情報)
  public static IMAGE_SIZE_UNRECEIVED_INFO = 200;
  // フリーメモ(仕入情報)高さ
  public static FREE_MEMO_UNRECEIVED_INFO_HEIGHT = 300;

  //----------------------------------
  // ユニット部
  //----------------------------------
  // ユニット名 幅
  public static UNIT_NAME_WIDTH = 180;
  // ユニット配送方法 幅
  public static UNIT_SHIPPING_METHOD_WIDTH = 80;
  // ユニット在庫数 幅
  public static UNIT_STOCK_COUNT_WIDTH = 100;
  // 店舗商品 店舗コード 幅
  public static STORE_PRODUCT_CODE_WIDTH = 60;
  // 店舗商品 価格 幅
  public static STORE_PRODUCT_PRICE_WIDTH = 100;
  // 店舗商品 販売実績 推移 幅
  public static STORE_PRODUCT_SALES_TREND_WIDTH = 440;
  // 店舗商品 公開ステータス 幅
  public static STORE_PRODUCT_STATUS_WIDTH = 100;
  // 店舗商品 更新日時 幅
  public static STORE_PRODUCT_UPDATED_AT_WIDTH = 120;
}
