/**
 * タグ分類
 */
export class TagCategory {
  id?: number;
  name?: string;
  color?: string;
  order_number?: number;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<TagCategory> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<TagCategory>): TagCategory {
    return new TagCategory({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): TagCategory {
    return new TagCategory({
      ...json,
      order_number: json.order_number ? Number(json.order_number) : undefined,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): TagCategory {
    const decoded = JSON.parse(jsonString);
    return TagCategory.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      order_number: this.order_number ? Number(this.order_number) : undefined,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  // DataGridなどで表示するための値を選択的に取得します。
  getDataGridValues(): string[] {
    return [
      this.id?.toString() || '',
      this.name || '',
      this.color || '',
      this.order_number?.toString() || '',
      // ... 必要に応じてその他の項目を追加 ...
    ];
  }
}
