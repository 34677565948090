import {LogUtils} from "../Common/LogUtils";
import {HttpServiceBase} from "./HttpServiceBase";
import {EcmgUser} from "../Models/EcmgUser";
import {EcmgApiError} from "../Common/EcmgApiError";
import {EventService} from "./System/EventService";

/**
 * ログインサービス
 */
export class LoginService extends HttpServiceBase {
  private static instance: LoginService = new LoginService();
  loginUser?: EcmgUser;

  private constructor() {
    super();
  }

  static getInstance(): LoginService {
    return this.instance;
  }

  async init(): Promise<void> {
    LogUtils.d();
    await this._loadLoginUser();

    LogUtils.d(JSON.stringify(this.loginUser));
  }

  private async _loadLoginUser(): Promise<void> {
    try {
      const url = "api/check-login";
      const axios = await this.getAxios();

      const response = await axios.get(url, this.makeAuthorizeOption());
      LogUtils.d(response.toString());

      if (response.data && response.data.id) {
        this.loginUser = EcmgUser.fromMap(response.data);
      } else {
        this.loginUser = undefined;
      }
    } catch (e) {
      LogUtils.ex(e);
    }
  }

  isLogin(): boolean {
    return this.loginUser != null;
  }

  /**
   * ログインユーザーかどうか
   * @param userId
   */
  isLoginUser( userId: number ): boolean {
    return this.loginUser?.id === userId;
  }

  async login(email: string, password: string): Promise<void> {
    LogUtils.d();

    try {
      const url = `api/login`;
      const axios = await this.getAxios(true);

      const response = await axios.post(
        url,
        {
          email,
          password,
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      this.loginUser = EcmgUser.fromMap(response.data);

      // ログインイベント発行
      EventService.getInstance().emitEvent(EventService.EVENT_LOGGED_IN, this.loginUser);

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  async logout(): Promise<void> {
    LogUtils.d();
    const url = "api/logout";
    const axios = await this.getAxios();
    const response = await axios.post(url, { headers: this.makeAuthorizeOption() });
    LogUtils.d(response.toString());
    this.loginUser = undefined;

    // ログアウトイベント発行
    EventService.getInstance().emitEvent(EventService.EVENT_LOGGED_OUT);
  }

  async sendPasswordResetRequest(email: string, admin = false): Promise<void> {
    LogUtils.d();

    try {
      const url = `api/${admin ? "adminApiPrefix/" : ""}forgot-password`;
      const axios = await this.getAxios(true);

      const response = await axios.post(
        url,
        { email },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  async sendPasswordReset(
    email: string,
    password: string,
    confirmPassword: string,
    token: string,
    admin = false
  ): Promise<void> {
    LogUtils.d();

    try {
      const url = `api/${admin ? "adminApiPrefix/" : ""}reset-password`;
      const axios = await this.getAxios(true);

      const response = await axios.post(
        url,
        {
          email,
          password,
          password_confirmation: confirmPassword,
          token
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
}
