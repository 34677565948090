import {
  AppBar, Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {CommonUtils} from "../../Common/CommonUtils";
import {EcmgApiError} from "../../Common/EcmgApiError";
import {LogUtils} from "../../Common/LogUtils";
import {useSnackbar} from "../Common/Provider/SnackbarContext";
import {EMTextField} from "../Common/Components/EMTextField";
import ECProgress from "../Common/Components/ECProgress";
import {ProductService} from "../../Services/ProductService";


interface MigrateStockCodeDialogProps {
  open: boolean;
  onClose: () => void;
}

/**
 * 在庫コード移行ダイアログ
 * @param props
 * @constructor
 */
export const MigrateStockCodeDialog = (props: MigrateStockCodeDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    oldStockCode: '' as string,
    newStockCode: '' as string,
    processing: false,
  });

  /**
   * ダイアログ表示時処理
   */
  useEffect(() => {
    if (props.open) {
      updateState({oldStockCode: ''});
      updateState({newStockCode: ''});
    }
  }, [props.open]);

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * 実行ボタンクリック時
   */
  const handleExecute = async () => {
    let message = '';

    //----------------------------------------
    // チェック
    //----------------------------------------
    try {
      message = await ProductService.getInstance().checkStockCodeMigration(state.oldStockCode, state.newStockCode);
    }
    catch (e) {
      LogUtils.ex(e);
      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = '在庫コード移行処理チェックでエラーが発生しました。';
      }
      // 終了
      alert(message);
      return;
    }


    //----------------------------------------
    // 移行処理
    //----------------------------------------
    try {
      updateState({processing: true});

      // 在庫コード移行
      message = await ProductService.getInstance().migrationStockCode(state.oldStockCode, state.newStockCode);

      updateState({processing: false});
    }
    catch (e) {
      LogUtils.ex(e);

      updateState({processing: false});

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = '指定商品同期処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);

    // ダイアログを閉じる
    props.onClose();
  };

  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            在庫コード移行
          </Typography>
          <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography variant={"body1"}>
          指定された在庫コードの商品に関連するデータを新在庫コードの商品へ移行するための機能です。
        </Typography>
        <Typography variant={"body2"}>
          <br/>
          以下の点にご注意ください。<br/>
          ※ 移行先の商品は、既に商品同期されている必要があります。<br/>
          ※ 移行先の商品は、在庫連動OFFの状態である必要があります。<br/>
          ※ 移行先の商品の各種情報（チャットなど）は、初期状態である必要があります。<br/>
          ※ 移行後、移行元のデータは削除されます。
        </Typography>

        <Box mt={4} />

        <EMTextField
          label="移行元 在庫コード"
          value={state.oldStockCode}
          onChange={(value) => {
            if (/^[a-zA-Z0-9-]*$/.test(value)) {
              updateState({ oldStockCode: value });
            }
          }}
        />

        <Box mt={4} />

        <EMTextField
          label="移行先 在庫コード"
          value={state.newStockCode}
          onChange={(value) => {
            if (/^[a-zA-Z0-9-]*$/.test(value)) {
              updateState({ newStockCode: value });
            }
          }}
        />

        <Box mt={2} />

        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleExecute}>
            実行
          </Button>
        </DialogActions>
      </DialogContent>

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </Dialog>
  );
};
