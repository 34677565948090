import { LogUtils } from "../../Common/LogUtils";
import { HttpServiceBase } from "../HttpServiceBase";
import { EcmgApiError } from "../../Common/EcmgApiError";
import {Tag} from "../../Models/Tag/Tag";
import {EventService} from "../System/EventService";

/**
 * タグサービス
 */
export class TagService extends HttpServiceBase {
  private static instance: TagService = new TagService();
  private tagMap: Map<number, Tag> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): TagService {
    return this.instance;
  }

  async init(): Promise<void> {
    LogUtils.d("Initializing TagService");
    await this._loadTags();
  }


  /**
   * タグデータ読み込み
   * @private
   */
  private async _loadTags(): Promise<void> {
    try {
      const axios = await this.getAxios();
      const response = await axios.get(
        `api/tags`,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      this.setData(response.data);

    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * データ設定
   * @param data
   */
  public setData(data: any): void {
    this.tagMap.clear();
    if (data && Array.isArray(data)) {
      data.forEach(tagData => {
        const tag = Tag.fromMap(tagData);
        if (tag.id !== undefined) {
          this.tagMap.set(tag.id, tag);
        }
      });
    }
  }


  /**
   * タグデータ取得
   * @param id
   */
  getTagById(id: number): Tag | undefined {
    return this.tagMap.get(id);
  }

  /**
   * すべてのタグを取得
   */
  getAllTags(): Tag[] {
    return Array.from(this.tagMap.values());
  }

  /**
   * タグデータ作成
   * @param tagData
   */
  public async createTag(tagData: Partial<Tag>): Promise<Tag> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/tags`,
        tagData,
        this.makeAuthorizeOption()
      );

      await this._loadTags();

      // イベント発行
      EventService.getInstance().emitEvent(EventService.EVENT_NEW_TAGS);

      return Tag.fromMap(response.data);
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
  //
  // /**
  //  * タグデータ取得
  //  * @param id
  //  */
  // public async fetchTagById(id: number): Promise<Tag> {
  //   try {
  //     const axios = await this.getAxios();
  //     const response = await axios.get(
  //       `api/tags/${id}`,
  //       this.makeAuthorizeOption()
  //     );
  //
  //     return Tag.fromMap(response.data);
  //   } catch (e) {
  //     LogUtils.ex(e);
  //     throw EcmgApiError.fromError(e);
  //   }
  // }

  /**
   * タグデータ更新
   * @param id
   * @param tagData
   */
  public async updateTag(id: number, tagData: Partial<Tag>): Promise<Tag> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/tags/${id}`,
        tagData,
        this.makeAuthorizeOption()
      );

      await this._loadTags();

      // イベント発行
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_DELETE_TAGS);

      return Tag.fromMap(response.data);
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * タグデータ削除
   * @param id
   */
  public async deleteTag(id: number): Promise<void> {
    try {
      const axios = await this.getAxios(true);
      await axios.delete(
        `api/tags/${id}`,
        this.makeAuthorizeOption()
      );

      await this._loadTags();

      // イベント発行
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_DELETE_TAGS);

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
  //
  // /**
  //  * 商品にタグを関連付ける
  //  * @param stockCode 商品の在庫コード
  //  * @param tagId 関連付けるタグのID
  //  */
  // public async attachTag(stockCode: string, tagId: number): Promise<void> {
  //   try {
  //     const axios = await this.getAxios(true);
  //     await axios.post('api/attach-tag', {
  //       stock_code: stockCode,
  //       tag_id: tagId
  //     }, this.makeAuthorizeOption());
  //   } catch (e) {
  //     LogUtils.ex(e);
  //     throw EcmgApiError.fromError(e);
  //   }
  // }
  //
  // /**
  //  * 商品からタグの関連付けを解除する
  //  * @param stockCode 商品の在庫コード
  //  * @param tagId 解除するタグのID
  //  */
  // public async detachTag(stockCode: string, tagId: number): Promise<void> {
  //   try {
  //     const axios = await this.getAxios(true);
  //     await axios.post('api/detach-tag', {
  //       stock_code: stockCode,
  //       tag_id: tagId
  //     }, this.makeAuthorizeOption());
  //   } catch (e) {
  //     LogUtils.ex(e);
  //     throw EcmgApiError.fromError(e);
  //   }
  // }

  /**
   * 商品に関連付けられたタグを取得する
   * @param stockCode 商品の在庫コード
   */
  public async fetchTagsForProduct(stockCode: string): Promise<Tag[]> {
    try {
      const axios = await this.getAxios();
      const response = await axios.get(`api/product-tags?stock_code=${stockCode}`, this.makeAuthorizeOption());
      const tags: Tag[] = [];
      if (response.data && Array.isArray(response.data)) {
        response.data.forEach(tagData => {
          const tag = Tag.fromMap(tagData);
          tags.push(tag);
        });
      }
      return tags;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 複数の商品に関連付けられたタグを取得する
   * @param stockCodes 商品の在庫コードの配列
   */
  public async fetchTagsForMultipleProducts(stockCodes: string[]): Promise<{ [stockCode: string]: Tag[] }> {
    try {
      const axios = await this.getAxios();
      // stockCodesをクエリパラメータとしてエンコード
      const queryParam = encodeURIComponent(stockCodes.join(','));
      const response = await axios.get(`api/product-tags-for-multi?stock_codes=${queryParam}`, this.makeAuthorizeOption());

      const tagsMap: { [stockCode: string]: Tag[] } = {};

      if (response.data && typeof response.data === 'object') {
        Object.keys(response.data).forEach(stockCode => {
          tagsMap[stockCode] = response.data[stockCode].map(Tag.fromMap);
        });
      }

      return tagsMap;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }


}
