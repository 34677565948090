import {StoreProduct} from "../../../../../Models/Product/StoreProduct";
import {UnitProduct} from "../../../../../Models/Product/UnitProduct";
import {UnitOrderCountData} from "../../../../../Models/Product/OrderCountData";
import React, {useEffect, useState} from "react";
import {Box, Stack} from "@mui/material";
import {StoreProductList} from "../StoreProduct/StoreProductList";
import {ShippingMethod} from "../../Common/ShippingMethod";
import {SKUConstants} from "../SKUConstants";
import {UnitName} from "./UnitName";
import {UnitStockCount} from "./UnitStockCount";
import {Product} from "../../../../../Models/Product/Product";
import {ProductService} from "../../../../../Services/ProductService";
import {MainPageConstants} from "../../../Common/MainPageConstants";

interface UnitProductPanelProps {
  product: Product;
  unitProduct: UnitProduct;
  unitOrderCountData: UnitOrderCountData | undefined;
  canChange: boolean;
}

/**
 * ユニット商品パネル
 * @param props
 * @constructor
 */
export const UnitProductPanel = (props: UnitProductPanelProps) => {
  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    storeProducts: [] as StoreProduct[],
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    // // イベントハンドラ登録
    // EventService.getInstance().onEvent(EventService.EVENT_UPDATE_UNIT, onUnitProductUpdated);
    // EventService.getInstance().onEvent(EventService.EVENT_UPDATE_STORE_PRODUCT, onStoreProductUpdated);
    //
    // // クリーンアップ
    // return () => {
    //   EventService.getInstance().removeListener(EventService.EVENT_UPDATE_UNIT, onUnitProductUpdated);
    //   EventService.getInstance().removeListener(EventService.EVENT_UPDATE_STORE_PRODUCT, onStoreProductUpdated);
    // };

  }, []);


  /**
   * props変更時処理
   */
  useEffect(() => {
    const storeProducts = ProductService.getInstance().getStoreProductsResult(props.unitProduct.unit_code ?? '');
    updateState({ storeProducts: storeProducts });


  }, [props]);

  //
  // /**
  //  * ユニット商品更新時
  //  */
  // const onUnitProductUpdated = (updatedUnitProduct: UnitProduct) => {
  //   // 同じユニットコードのユニット商品の場合のみ更新
  //   if (updatedUnitProduct.unit_code !== props.unitProduct.unit_code) {
  //     return;
  //   }
  //   LogUtils.d('onUnitProductUpdated: ' + JSON.stringify(updatedUnitProduct));
  //   // 店舗商品を読み直す
  //   const storeProducts = ProductService.getInstance().getStoreProductsResult(props.unitProduct.unit_code ?? '');
  //   updateState({ storeProducts: storeProducts });
  // }
  // /**
  //  * 店舗商品更新時
  //  */
  // const onStoreProductUpdated = (updatedStoreProduct: StoreProduct) => {}
  //   // 同じユニットコードの店舗商品の場合のみ更新
  //   if (updatedStoreProduct.unit_code !== props.unitProduct.unit_code) {
  //     return;
  //   }
  //   LogUtils.d('onStoreProductUpdated: ' + JSON.stringify(updatedStoreProduct));
  //   // 店舗商品を読み直す
  //   const storeProducts = ProductService.getInstance().getStoreProductsResult(props.unitProduct.unit_code ?? ''();
  //   updateState({ storeProducts: storeProducts });
  // }

  /**
   * 単品用の線
   */
  const singleProductLine = () => {
    if (!props.unitProduct.is_min_unit) {
      return null;
    }
    return (
      <>
        <Box
          mt={1}
          mb={0.3}
          sx={{
            width: '100%',
            height: '2px',
            backgroundColor: MainPageConstants.COLOR_BLACK,
          }}
        />
        <Box
          mt={0.3}
          mb={1}
          sx={{
            width: '100%',
            height: '2px',
            backgroundColor: MainPageConstants.COLOR_BLACK,
          }}
        />
      </>
    );
  }

  /**
   * 描画
   */
  return (
    <Stack>
      {singleProductLine()}

      <Box
        pt={2}
        sx={props.unitProduct.has_defect ? { backgroundColor: MainPageConstants.COLOR_LIGHT_YELLOW } : {}}
        display="flex"
        alignItems="start"
      >
        {/* ユニット商品名 */}
        <UnitName
          unitProduct={props.unitProduct}
          storeProducts={state.storeProducts}
          width={SKUConstants.UNIT_NAME_WIDTH}
        />
        {/* 配送方法 */}
        <ShippingMethod
          unitProducts={[props.unitProduct]}
          width={SKUConstants.UNIT_SHIPPING_METHOD_WIDTH}
        />
        {/* 在庫数 */}
        <UnitStockCount
          product={props.product}
          unitProduct={props.unitProduct}
          storeProducts={state.storeProducts}
          width={SKUConstants.UNIT_STOCK_COUNT_WIDTH}
          canChange={props.canChange}
        />
        {/* 店舗商品リスト */}
        <Box>
          <StoreProductList
            product={props.product}
            unitProduct={props.unitProduct}
            storeProducts={state.storeProducts}
            unitOrderCountData={props.unitOrderCountData}
            canChange={props.canChange}
          />
        </Box>
      </Box>

      {singleProductLine()}
    </Stack>
  );
};
