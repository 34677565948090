import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import {SalesGraphData} from "../../../../../../Models/SalesData/SalesData";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface SalesChartProps {
  graphData: SalesGraphData;
  height?: number;
  noRotation?: boolean;
}

/**
 * 販売数グラフ
 * @param props
 * @constructor
 */
export const SalesChart = (props: SalesChartProps) => {
  const graphData = props.graphData;
  const data = {
    labels: graphData.labels,
    datasets: [
      {
        label: 'Yahoo!',
        data: graphData.yahooSales,
        backgroundColor: '#FFB640',
      },
      {
        label: '楽天',
        data: graphData.rakutenSales,
        backgroundColor: '#BF0000',
      },
      {
        label: 'BASE',
        data: graphData.baseSales,
        backgroundColor: '#67AEA8',
      },
    ],
  };

  const options = {
    animation: {
      duration: 0 // アニメーションの持続時間を0に設定して無効化
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: false, // 自動スキップを無効にする
          maxRotation: props.noRotation ? 0 : undefined, // ラベルの最大回転角度
          minRotation: props.noRotation ? 0 : undefined // ラベルの最小回転角度
        }
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false // この行を追加
      }
    }
  };

  return (
    <div style={{ height: props.height }}>
      <Bar data={data} options={options} />
    </div>
  );
};
