import {Box, Typography} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {FormatQuote} from "@mui/icons-material";
import React from "react";
import {CommonUtils} from "../../../../Common/CommonUtils";
import {UserService} from "../../../../Services/UserService";

interface ChatTagToQuoteProps {
  tag: string;
  children: React.ReactNode;
}

/**
 * チャットタグ 引用
 * @param props
 * @constructor
 */
export const ChatTagQuote = (props: ChatTagToQuoteProps) => {
  const { tag } = props;
  // タグを分解して各部分を抽出
  const aidMatch = tag.match(/aid=(\d+)/);
  const timeMatch = tag.match(/time=(\d+)/);
  const uidMatch = tag.match(/uid=([^]+) time=/);
  const nameMatch = tag.match(/name=([^]+) time=/); // 旧バージョンとの互換性維持のため

  const aid = aidMatch ? aidMatch[1] : '不明';
  let uid = uidMatch ? uidMatch[1] : 0;
  let time = timeMatch ? timeMatch[1] : '';
  let name = nameMatch ? nameMatch[1] : '';

  if ( name.length === 0 ){
    // ユーザー取得
    const user = UserService.getInstance().getUserById(Number(uid));
    name = user?.name ?? '不明'
  }

  // UNIXタイムスタンプを日本時間の 'YYYY年MM月DD日 HH:MM' 形式に変換
  if (time) {
    const date = new Date(Number(time) * 1000); // UNIXタイムスタンプはミリ秒単位に変換
    time = CommonUtils.getDateStringJP(date);
  }
  return (
    <>
      <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
        <FormatQuote sx={{ fontSize: 32, }} />
        <AccountCircleIcon fontSize="medium" sx={{ mr: 1, color: UserService.getInstance().getUserColor(Number(uid)) }}/>
        {name} - {time}
      </Typography>
      <Box sx={{ borderLeft: 1, borderColor: "black", ml: 2, mb: 1, mt: 1, pl: 1 }}>
        <Box>
          {props.children}
        </Box>
      </Box>
    </>
  );
};
