import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import { UserService } from "../../../../Services/UserService";
import {LoginService} from "../../../../Services/LoginService";
import {User} from "../../../../Models/User";
import {EventService} from "../../../../Services/System/EventService";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {Office} from "../../../../Models/Office";
import {OfficeService} from "../../../../Services/OfficeService";

interface ChatToPopupProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

/**
 * チャット宛先ポップアップ
 * @param props
 * @constructor
 */
export const ChatToPopup = (props: ChatToPopupProps) => {

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    members: [] as User[],
    offices: [] as Office[],
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 初期化
   */
  useEffect(() => {
    // 現在ログインしているユーザーのIDを取得
    const myUserId = LoginService.getInstance().loginUser?.id ?? 0;

    // 全ユーザーを取得し、自分を除く
    const allMembers = UserService.getInstance().getAllUsers()
      .filter(user => user.id !== myUserId) ?? [];

    // 全事業所を取得
    const allOffices = OfficeService.getInstance().getAllOffices();

    // 状態を更新
    updateState({ members: allMembers, offices: allOffices });
  }, []);

  /**
   * すべて選択クリック
   */
  const handleSelectAllClick = () => {
    // ユーザーをループ
    let message = '';
    state.members.forEach(member => {
      const to = "[To:" + member.id + "] " + member.name + "さん\n";
      message += to;
    });
    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_MESSAGE_INSERT, message);
  };


  /**
   * To All クリック
   */
  const handleToAllClick = () => {
    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_MESSAGE_INSERT, "[toall]\n");
  }

  /**
   * メンバークリック
   * @param userId
   */
  const handleMemberClick = (userId: number) => {
    const userName = UserService.getInstance().getUserName(userId) ?? '??';
    const to = "[To:" + userId + "] " + userName + "さん\n";
    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_MESSAGE_INSERT, to);
  };


  /**
   * 事業所クリック
   * @param officeId
   */
  const handleOfficeClick = (officeId: number) => {
    const toOffice = "[tooffice:" + officeId + "]\n";
    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_MESSAGE_INSERT, toOffice);
  };

  /**
   * 描画
   */
  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      disableRestoreFocus={true}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{ overflow: 'auto' }}
    >
      <Box sx={{ minWidth: '300px' }}>
        <List
          sx={{
            overflow: 'auto', // コンテンツが最大高さを超える場合はスクロール可能にする
          }}
        >
          <ListItem disablePadding>
            <ListItemButton onClick={handleSelectAllClick}>
              <Typography variant="body1" color={"primary"} sx={{fontWeight: "bold"}}>すべて選択</Typography>
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={handleToAllClick}>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Box sx={{ alignItems: 'center', backgroundColor: '#7fffd4', borderRadius: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', ml:0.5, mr:0.5 }}>
                    ALL
                  </Typography>
                </Box>
                <Box mr={1} />
                <Box display={"flex"} flexDirection={"column"} alignItems={"start"}>
                  <Typography variant="caption">すべてのメンバー</Typography>
                  <Typography variant="caption" color={"secondary"}>{state.members.length}人に通知が送られます</Typography>
                </Box>
              </Box>
            </ListItemButton>
          </ListItem>

          {state.offices.map((office, index) => (
            <Box key={index} >
              <Divider />
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleOfficeClick(office.id ?? 0)}>
                  <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <Box sx={{ alignItems: 'center', backgroundColor: '#7fffd4', borderRadius: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', ml:0.5, mr:0.5 }}>
                        {office.name}
                      </Typography>
                    </Box>
                    <Box mr={1} />
                    <Box display={"flex"} flexDirection={"column"} alignItems={"start"}>
                      <Typography variant="caption" color={"secondary"}>
                        {UserService.getInstance().getUsersByOfficeId(office.id ?? 0, true).length}人に通知が送られます
                      </Typography>
                    </Box>
                  </Box>
                </ListItemButton>
              </ListItem>
            </Box>
          ))}

          {state.members.map((member, index) => (
            <Box key={index} >
              <Divider />
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleMemberClick(member.id ?? 0)}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box>
                      <AccountCircleIcon fontSize="medium" style={{ color: UserService.getInstance().getUserColor(member.id ?? 0) }} />
                    </Box>
                    <Typography variant="body1">{member.name}</Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
            </Box>
          ))}
        </List>
      </Box>
    </Popover>
  );
};
