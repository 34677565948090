import React, {useEffect, useState} from 'react';
import {UnitProductList} from "./UnitProduct/UnitProductList";
import {Box} from '@mui/material';
import {SKUConstants} from "./SKUConstants";
import {SearchResult} from "../../../../Models/SearchResult";
import {ProductMemoImageList} from "./Product/ProductMemoImageList";
import {ProductService} from "../../../../Services/ProductService";
import {LoginService} from "../../../../Services/LoginService";
import {FreeMemoField} from "./Product/FreeMemoField";
import {AddCalendar} from "../Common/Calendar/AddCalenar";
import {Constants} from "../../../../Common/Constants";

interface UnreceivedInfoPanelProps {
  searchResult: SearchResult;
}

/**
 * 仕入情報パネル
 * @param props
 * @constructor
 */
export const UnreceivedInfoPanel = (props: UnreceivedInfoPanelProps) => {
  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {

  }, []);


  // 管理者またはユーザーか
  const isUserOrAdmin = LoginService.getInstance().loginUser?.isUserOrAdmin() ?? false;

  /**
   * 描画
   */
  return (
    <Box flexGrow={1}>
      <Box width={SKUConstants.IMAGE_MEMO_LIST_WIDTH}>
        {/* 画像メモリスト */}
        <ProductMemoImageList
          stockCode={props.searchResult.product.stock_code!}
          images={ProductService.getInstance().getProductMemoPhotos()}
          canChange={isUserOrAdmin}
          imageWidth={SKUConstants.IMAGE_SIZE_UNRECEIVED_INFO}
          hideActionButton={false}
        />
      </Box>
      <Box mt={1} />
      <Box display={"flex"} flexDirection={"row"}>
        {/* 左側メモ */}
        <Box flex={6}>
          <FreeMemoField
            stockCode={props.searchResult.product.stock_code!}
            freeMemoType={Constants.FREE_MEMO_TYPE_UNRECEIVED_LEFT}
            freeNote={props.searchResult.product.left_note ?? ''}
            height={SKUConstants.FREE_MEMO_UNRECEIVED_INFO_HEIGHT}
            isDisabled={!isUserOrAdmin}
          />
        </Box>

        <Box ml={1} />
        {/* 右側メモ */}
        <Box flex={4}>
          <FreeMemoField
            stockCode={props.searchResult.product.stock_code!}
            freeMemoType={Constants.FREE_MEMO_TYPE_UNRECEIVED_RIGHT}
            freeNote={props.searchResult.product.right_note ?? ''}
            height={SKUConstants.FREE_MEMO_UNRECEIVED_INFO_HEIGHT}
            selectRowOnClick={true}
            isDisabled={!isUserOrAdmin}
          />
        </Box>
      </Box>
      <Box mt={4} />
    </Box>
  );
}

