import React, { useState, KeyboardEvent, useRef, useEffect } from 'react';
import { Box, TextField, FormControl, CircularProgress } from '@mui/material';
import {MainPageConstants} from "../../Main/Common/MainPageConstants";
import {LogUtils} from "../../../Common/LogUtils";

interface InlineEditFieldProps {
  value: string | undefined;
  onAction: (value: string) => void;
  processing?: boolean;
  isDisabled: boolean;
  inputType: 'text' | 'numeric';
  allowNegative?: boolean;
  textPattern?: string;
  textAlign?: 'center' | 'left' | 'right';
  width?: string | number;
}

/**
 * インライン入力フィールド
 * @param props
 * @constructor
 */
export const InlineEditField = (props: InlineEditFieldProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    isEditing: false,
    localValue: props.value ?? '',
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * inputRef
   */
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * props変更時
   */
  useEffect(() => {
    if (state.isEditing) {
      return;
    }
    updateState({ localValue: props.value ?? '' });
  }, [props]);

  /**
   * ダブルクリック時処理
   */
  const handleDoubleClick = () => {
    // 数値の場合はカンマを除去
    if (props.inputType === 'numeric') {
      updateState({ localValue: state.localValue.replace(/,/g, '') });
    }

    updateState({ isEditing: true });
  };

  /**
   * 数値入力時
   * @param event
   */
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (props.inputType === 'numeric') {
      if (props.allowNegative) {
        if (!/^-?\d*$/.test(value)) {
          return;
        }
      } else {
        if (!/^\d*$/.test(value)) {
          return;
        }
      }
    }
    else if (props.inputType === 'text' && props.textPattern) {
      const regex = new RegExp(props.textPattern);
      if (!regex.test(value)) {
        return;
      }
    }

    updateState({ localValue: value });
  };
  /**
   * キープレス
   * @param event
   */
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      updateState({ isEditing: false });

      // 空文字の場合は元に戻す
      if (state.localValue === '') {
        updateState({ localValue: props.value ?? '' });
        return;
      }

      props.onAction(state.localValue);
    }
  };

  /**
   * フォーカス
   * @param event
   */
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  /**
   * フォーカスアウト
   */
  const handleBlur = () => {
    if (!props.processing) {
      updateState({ isEditing: false, localValue: props.value ?? '' });
    }
  };

  /**
   * レンダリング
   */
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: props.width, minHeight: '20px' }}>
      {!state.isEditing ? (
        <span
          onDoubleClick={handleDoubleClick}
          style={{
            display: 'inline-block',
            width: '100%',
            minHeight: '20px',
            lineHeight: '20px',
            cursor: 'pointer',
            textAlign: props.textAlign ?? 'center',
            color: MainPageConstants.COLOR_DARK_GRAY
          }}
        >
        {state.localValue || '　'} {/* 空文字の場合はノーブレークスペース */}
      </span>
      ) : (
        <FormControl fullWidth>
          <TextField
            ref={inputRef}
            value={state.localValue}
            onChange={handleValueChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyPress}
            autoFocus
            size="small"
            InputProps={{
              style: {
                border: 'none',
                boxShadow: 'none',
              },
              inputMode: props.inputType === 'numeric' ? 'numeric' : 'text',
            }}
            inputProps={{
              style: {
                textAlign: props.textAlign ?? 'center',
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 4,
                paddingRight: 4,
              }
            }}
            disabled={props.isDisabled}
          />
        </FormControl>
      )}
      {props.processing && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
            height: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
            ml: 1
          }}
        >
          <CircularProgress size={MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT} />
        </Box>
      )}
    </Box>
  );

};
