import React, {useState} from 'react';
import {Box, Typography} from '@mui/material';
import { CalendarButton } from '../../../../Common/Components/Calendar/CalendarButton';
import {AddCalendarDialog} from "./AddCalendarDialog";
import {LogUtils} from "../../../../../Common/LogUtils";
import {EcmgApiError} from "../../../../../Common/EcmgApiError";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";
import ECProgress from "../../../../Common/Components/ECProgress";
import {CalendarService} from "../../../../../Services/CalendarService";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {ProductService} from "../../../../../Services/ProductService";
// import {LogUtils} from "../../../../../../Common/LogUtils"; // AddCalendarButtonをインポート

interface AddCalendarProps {
  stockCode: string;
  calendarInfo: { [key: string]: string; };
  isSKU: boolean;
}

/**
 * カレンダー追加
 * @constructor
 */
export const AddCalendar = (props: AddCalendarProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    dialogOpen: false,
    selectedLabel: '',
    processing: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  /**
   * useSnackbar
   */
  const { showSnackbarMessage } = useSnackbar();

  /**
   * ボタンクリック時の処理
   * @param label
   */
  const handleButtonClick = (label: string) => {
    updateState({ selectedLabel: label, dialogOpen: true });
  };

  /**
   * ダイアログクローズ時の処理
   */
  const handleDialogClose = () => {
    updateState({ dialogOpen: false });
  };

  /**
   * 保存時の処理
   * @param data
   */
  const handleSave = async (data: {calendarType: string, date: Date, color: string}) => {
    let message = '';

    try {
      updateState({ processing: true, dialogOpen: false });

      // カレンダー登録
      await CalendarService.getInstance().createCalendarItem({
        date: data.date,
        calendar_type: data.calendarType,
        stock_code: props.stockCode,
        color: data.color,
      });

      // 検索結果更新
      await ProductService.getInstance().updateSearchResultByStockCode(props.stockCode);

      updateState({processing: false});
      message = CommonUtils.getCalendarName(data.calendarType) + 'に登録しました。';

    } catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false, dialogOpen: false});

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = 'カレンダー登録処理でエラーが発生しました。';
      }
    }

    showSnackbarMessage(message);
  }

  /**
   * カレンダー日付取得
   * @param type
   */
  const calendarDate = (type: string) => {
    const date = props.calendarInfo[type] != null ? new Date(props.calendarInfo[type]) : null;
    if ( date == null ) {
      return '';
    }
    // MM/DD形式に変換
    return date.getMonth() + 1 + '/' + date.getDate();
  }

  /**
   * カレンダーコンポーネント
   * @param type
   */
  const calendarComponent = (type: string) => {
    return (
      <Box display={"flex"} flexDirection={"row"} justifyContent={"start"} alignItems={"end"}>
        <CalendarButton label={type} showPlus={true} onClick={() => handleButtonClick(type)} />
        <Typography variant={"body1"} color={MainPageConstants.COLOR_DARK_GRAY}>{calendarDate(type)}</Typography>
      </Box>
    )
  }

  /**
   * レンダリング
   */
  return (
    <>
      {/* カレンダーボタン */}
      {props.isSKU ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {calendarComponent('A')}
          {calendarComponent('B')}
        </Box>
      ):
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {calendarComponent('A')}
          {calendarComponent('B')}
        </Box>
      }

      {/* AddCalendarDialogの表示。表示内容はstate.selectedLabelによって変わる */}
      <AddCalendarDialog
        open={state.dialogOpen}
        onClose={handleDialogClose}
        defaultCalendarType={state.selectedLabel === 'A' ? 'A' : 'B'}
        onSave={handleSave}
      />
      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>
    </>
  );
};
