import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { UserService } from "../../../../Services/UserService";
import { ChatMessage } from "../../../../Models/Chat/ChatMessage";
import {CommonUtils} from "../../../../Common/CommonUtils";
import {Dialog, Divider, IconButton} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Tooltip from "@mui/material/Tooltip";
import {SearchParam} from "../../../../Common/SearchParam";
import {useNavigate} from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface ChatMentionDialogProps {
  open: boolean;
  onClose: () => void;
  messageInfos: Array<{
    message: ChatMessage;
    read: number;
    minimalUnitProductName: string;
    unresponded: boolean;
  }>;
}

/**
 * チャットメンションポップアップ
 * @param props
 * @constructor
 */
export const ChatMentionDialog = (props: ChatMentionDialogProps) => {

  /**
   * useNavigate
   */
  const navigate = useNavigate();

  /**
   * 行クリック時のイベントハンドラ
   */
  const handleItemClick = (message: ChatMessage, event: React.MouseEvent<HTMLDivElement>) => {
    // // const url = `/chat?stock_code=${message.stock_code}&message_id=${message.id}`;
    // // window.open(url, '_blank');
    // const chatPageParam = new ChatPageParam({
    //   stockCode: message.stock_code,
    //   messageId: message.id,
    // });
    //
    // EventService.getInstance().emitEvent(EventService.EVENT_CHAT_OPEN, chatPageParam);


    // 検索実行
    navigate(makeUrl(message));

  };

  /**
   * URLを作成
   * @param message
   */
  const makeUrl = (message: ChatMessage) => {
    const searchParam = new SearchParam({
      keyword: message.stock_code,
      chatMessageId: message.id,
    });

    // タイムスタンプをクエリ文字列に直接追加（検索を強制実行するため）
    const timestamp = new Date().getTime(); // 現在のタイムスタンプを取得
    const queryString = `${searchParam.toQueryString()}&chat_reset_time_stamp=${timestamp}`;

    return `/main?${queryString}`;
  }

  /**
   * メッセージの送信日時を取得
   * @param message
   */
  const getSentDateTime = (message: ChatMessage) => {
    return message.sent_date_time ? CommonUtils.getDateStringJP(message.sent_date_time) : '';
  }

  /**
   * 商品名を取得
   * @param minimalUnitProductName
   */
  const getProductName = (minimalUnitProductName: string) => {
    return CommonUtils.trimAfterFirstSlash(minimalUnitProductName);
  }

  /**
   * メッセージの一部
   * @param message
   */
  const getMessagePreview = (message: ChatMessage) => {
    const text = message.message ?? '';
    return text.slice(0, 50) + (text.length > 50 ? '...' : ''); // メッセージの先頭50文字を表示
  }

  /**
   * 新しいタブで開く
   * @param message
   */
  const onClickNewTab = (message: ChatMessage) => {
    window.open(makeUrl(message), '_blank');
  }

  /**
   * メッセージ概要
   */
  const messageSummary = (message: ChatMessage, minimalUnitProductName: string) => {
    return (
      <Box minWidth={"400px"}>
        <Box display={"flex"} flexDirection={"row"}>
          <Box flex={2}>
            <Typography variant="body2" sx={{fontWeight: "bold"}}>送信日時：{getSentDateTime(message)}</Typography>
          </Box>
          <Box display="flex" flexDirection={"row"} alignItems="center"  gap={1} flex={1}>
            <Box>
              <AccountCircleIcon fontSize="medium" style={{ color: UserService.getInstance().getUserColor(message.user_id ?? 0) }} />
            </Box>
            <Typography variant="body2">{UserService.getInstance().getUserName(message.user_id ?? 0 )}</Typography>
          </Box>
        </Box>
        <Typography variant="body2" color={"primary"}>商品名：{getProductName(minimalUnitProductName)}</Typography>
      </Box>
    );
  }
  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose} sx={{ '& .MuiDialog-paper': { minWidth: '640px', maxWidth: '95%'}}}>
      <Box sx={{ p: 2}}>
        <Typography sx={{fontWeight: "bold"}}>自分宛のメッセージ(最大500件)</Typography>
        <List>
          {props.messageInfos.map(({ message, read, minimalUnitProductName, unresponded }, index) => (
            <Box key={index} display={"flex"} flexDirection={"column"} >
              <Divider />
              <ListItem disablePadding>
                <Tooltip title={getMessagePreview(message)}>
                  <ListItemButton onClick={(e) => handleItemClick(message, e)}>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} width="100%">
                      {/* メッセージ概要 */}
                      {messageSummary(message, minimalUnitProductName)}
                      {/* 未返信 */}
                      {!unresponded ? '' : (
                        <Box ml={1}
                             component="span"
                             sx={{ backgroundColor: 'red', borderRadius: '4px', p: '2px 8px' }}
                        >
                          <Typography variant="body2" sx={{color: 'white', fontWeight: 'bold'}} >未返信</Typography>
                        </Box>
                      )}
                      {/* 未読 */}
                      {read ? '' : (
                        <Box ml={1}
                             component="span"
                             sx={{ backgroundColor: 'blue', borderRadius: '4px', p: '2px 8px' }}
                        >
                          <Typography variant="body2" sx={{color: 'white', fontWeight: 'bold'}} >未読</Typography>
                        </Box>
                      )}

                      <Box flexGrow={1} />

                      {/* チャット画面を開く */}
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation(); // ListItemButtonのonClickイベントを防ぐ
                          onClickNewTab(message);
                        }}
                        edge="end"
                        size="large"
                      >
                        <OpenInNewIcon />
                      </IconButton>

                    </Box>
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </Box>
          ))}
        </List>
      </Box>
    </Dialog>
  );
};
