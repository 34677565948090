import { AxiosError } from 'axios';
import {LogUtils} from "./LogUtils";

/**
 * APIエラー
 */
export class EcmgApiError {
  statusCode?: number;
  message?: string;
  errors: Record<string, string[] | string>;

  constructor(statusCode?: number, message?: string, errors: Record<string, string[] | string> = {}) {
    this.statusCode = statusCode;
    this.message = message;
    this.errors = errors;
  }

  toString(): string {
    return `EcmgApiError{statusCode: ${this.statusCode}, message: ${this.message}, errors: ${JSON.stringify(this.errors)}}`;
  }

  static fromError(e: any): EcmgApiError {
    let statusCode: number | undefined;
    let message: string | undefined;
    let errors: Record<string, string[] | string> = {};

    if (e instanceof AxiosError) {
      statusCode = e.response?.status;
      const data = e.response?.data;

      LogUtils.d(typeof data);

      if (data) {
        try {
          message = data['message'];
          errors = data['errors'];
        } catch (error) {
          LogUtils.ex(error);
        }
      }
    }
    return new EcmgApiError(statusCode, message, errors);
  }

  getErrorMessage(key: string): string {
    const keyErrors = this.errors[key];
    if (!keyErrors) {
      return '';
    }
    if (Array.isArray(keyErrors)) {
      return keyErrors[0];
    }
    return keyErrors;
  }

  getErrorMessages(key: string): string[] {
    const keyErrors = this.errors[key];
    if (!keyErrors) {
      return [];
    }
    if (Array.isArray(keyErrors)) {
      return keyErrors.map(e => e.toString());
    }
    return [keyErrors];
  }

  getErrorMessageMap(): Record<string, string> {
    const map: Record<string, string> = {};
    Object.keys(this.errors).forEach(key => {
      map[key] = this.getErrorMessage(key);
    });
    return map;
  }

  isClientError(): boolean {
    if (!this.statusCode) {
      return false;
    }
    return this.statusCode >= 400 && this.statusCode <= 499;
  }

  isServerError(): boolean {
    if (!this.statusCode) {
      return false;
    }
    return this.statusCode >= 500 && this.statusCode <= 599;
  }
}
