import {ChatMention} from "./ChatMention";
import {ChatReaction} from "./ChatReaction";
import {LoginService} from "../../Services/LoginService";
import {ChatRead} from "./ChatRead";

/**
 * チャットメッセージ
 */
export class ChatMessage {
  id?: number;
  stock_code?: string;
  user_id?: number;
  sent_date_time?: Date;
  message?: string;
  reply_message_id?: number;
  deleted?: number;
  mentions?: ChatMention[];
  reactions?: ChatReaction[];
  reads?: ChatRead[];
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<ChatMessage> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<ChatMessage>): ChatMessage {
    return new ChatMessage({
      ...this,
      ...params,
    });
  }

  static fromMap(json: { [key: string]: any }): ChatMessage {
    return new ChatMessage({
      ...json,
      sent_date_time: json.sent_date_time ? new Date(json.sent_date_time) : undefined,
      mentions: json.mentions ? json.mentions.map(ChatMention.fromMap) : [],
      reactions: json.reactions ? json.reactions.map(ChatReaction.fromMap) : [],
      reads: json.reads ? json.reads.map(ChatRead.fromMap) : [],
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): ChatMessage {
    const decoded = JSON.parse(jsonString);
    return ChatMessage.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      sent_date_time: this.sent_date_time ? this.sent_date_time.toISOString() : undefined,
      mentions: this.mentions ? this.mentions.map(mention => mention.toMap()) : [],
      reactions: this.reactions ? this.reactions.map(reaction => reaction.toMap()) : [],
      reads: this.reads ? this.reads.map(read => read.toMap()) : [],
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  /**
   * 自分のメッセージか？
   */
  public isMyMessage(): boolean {
    return LoginService.getInstance().isLoginUser(this.user_id ?? 0)
  }

  /**
   * 更新されているか？
   */
  public isUpdated(): boolean {
    return this.created_at?.getTime() !== this.updated_at?.getTime();
  }

  /**
   * 自分は既に読んだか？
   */
  public alreadyRead(): boolean {
    return this.reads?.some(read => read.isMe()) ?? false;
  }

}
