import {Product} from "./Product/Product";
import {OrderCountData} from "./Product/OrderCountData";
import {StoreProductPhoto} from "./Product/StoreProductPhoto";
import {FreeMarketInfo} from "./Product/FreeMarketInfo";
import {ProcurementPrice} from "./Product/ProcurementPrice";
import {UnitProduct} from "./Product/UnitProduct"; // 追加: ProcurementPriceのインポート

/**
 * 検索結果(1商品)
 */
export class SearchResult {
  // 商品データ
  product: Product = new Product();
  // 最小ユニット商品
  minimalUnitProduct: UnitProduct | undefined;
  // 注文数データ
  orderCountData:OrderCountData = new OrderCountData();
  // ユニット情報
  unitInfo: string = '';
  // 在庫数
  stockCount: number = 0;
  // 潜在在庫数
  potentialStockCount: number = 0;
  // 店舗商品写真
  storeProductPhotos: StoreProductPhoto[] = [];
  // フリマ情報
  freeMarketInfos: FreeMarketInfo[] = [];
  // 1w 仕入れ
  freeMarketTotalQuantityUpper: number = 0;
  // 月入売差
  stockDiff: number = 0;
  // 仕入れストップ（上限）までの日数
  procurementStop: number = 0;
  // 仕入れ再開（下限）までの日数
  procurementStart: number = 0;
  // 売切れまでの日数
  daysUntilSoldOut: number = 0;
  // 要仕入れ数
  neededProcurementCount: number = 0;
  // 仕入れ価格情報
  procurementPrices: ProcurementPrice[] = [];
  // 受取数(14日間)
  receiveCount14: number | undefined = 0;
  // 受取数(30日間)
  receiveCount30: number | undefined = 0;
  // 棚在庫
  shelfStockCount: number = 0;
  // カレンダー情報(タイプ:日付文字列)
  calendarInfo: { [key: string]: string; } = {};

  constructor(params: Partial<SearchResult> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<SearchResult>): SearchResult {
    return new SearchResult({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): SearchResult {
    return new SearchResult({
      product: json.product ? Product.fromMap(json.product) : undefined,
      minimalUnitProduct: json.minimalUnitProduct ? UnitProduct.fromMap(json.minimalUnitProduct) : undefined,
      orderCountData: json.orderCountData ? OrderCountData.fromMap(json.orderCountData) : undefined,
      unitInfo: json.unitInfo,
      stockCount: json.stockCount,
      potentialStockCount: json.potentialStockCount,
      storeProductPhotos: json.storeProductPhotos ? json.storeProductPhotos.map((sp: any) => StoreProductPhoto.fromMap(sp)) : undefined,
      freeMarketInfos: json.freeMarketInfos ? json.freeMarketInfos.map((fm: any) => FreeMarketInfo.fromMap(fm)) : undefined,
      freeMarketTotalQuantityUpper: json.freeMarketTotalQuantityUpper,
      stockDiff: json.stockDiff,
      procurementStop: json.procurementStop,
      procurementStart: json.procurementStart,
      daysUntilSoldOut: json.daysUntilSoldOut,
      neededProcurementCount: json.neededProcurementCount,
      procurementPrices: json.procurementPrices ? json.procurementPrices.map((pp: any) => ProcurementPrice.fromMap(pp)) : undefined,
      receiveCount14: json.receiveCount14,
      receiveCount30: json.receiveCount30,
      shelfStockCount: json.shelfStockCount,
      calendarInfo: json.calendarInfo,
    });
  }

  static fromJson(jsonString: string): SearchResult {
    const decoded = JSON.parse(jsonString);
    return SearchResult.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      product: this.product ? this.product.toMap() : undefined,
      minimalUnitProduct: this.minimalUnitProduct ? this.minimalUnitProduct.toMap() : undefined,
      orderCountData: this.orderCountData ? this.orderCountData.toMap() : undefined,
      unitInfo: this.unitInfo,
      stockCount: this.stockCount,
      potentialStockCount: this.potentialStockCount,
      storeProductPhotos: this.storeProductPhotos ? this.storeProductPhotos.map(sp => sp.toMap()) : undefined,
      freeMarketInfos: this.freeMarketInfos ? this.freeMarketInfos.map(fm => fm.toMap()) : undefined,
      freeMarketTotalQuantityUpper: this.freeMarketTotalQuantityUpper,
      stockDiff: this.stockDiff,
      procurementStop: this.procurementStop,
      procurementStart: this.procurementStart,
      daysUntilSoldOut: this.daysUntilSoldOut,
      neededProcurementCount: this.neededProcurementCount,
      procurementPrices: this.procurementPrices ? this.procurementPrices.map(pp => pp.toMap()) : undefined,
      receiveCount14: this.receiveCount14,
      receiveCount30: this.receiveCount30,
      shelfStockCount: this.shelfStockCount,
      calendarInfo: this.calendarInfo,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

}
