import { LogUtils } from "../Common/LogUtils";
import { HttpServiceBase } from "./HttpServiceBase";
import { EcmgApiError } from "../Common/EcmgApiError";
import { Store } from "../Models/Store";
import {EventService} from "./System/EventService";

export class StoreService extends HttpServiceBase {
  private static instance: StoreService = new StoreService();
  private storeMap: Map<number, Store> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): StoreService {
    return this.instance;
  }

  async init(): Promise<void> {
    LogUtils.d("Initializing StoreService");
    await this._loadStores();
  }

  /**
   * 店舗データ読み込み
   * @private
   */
  private async _loadStores(): Promise<void> {
    try {
      const url = "api/stores";
      const axios = await this.getAxios();

      const response = await axios.get(url, this.makeAuthorizeOption());
      LogUtils.d(response.toString());

      this.setData(response.data);

    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * データ設定
   * @param data
   */
  public setData(data: any): void {
    this.storeMap.clear();
    if (data && Array.isArray(data)) {
      data.forEach(storeData => {
        const store = Store.fromMap(storeData);
        if (store.id !== undefined) {
          this.storeMap.set(store.id, store);
        }
      });
    }
  }

  /**
   * 店舗データ取得
   * @param id
   */
  getStoreById(id: number): Store | undefined {
    return this.storeMap.get(id);
  }

  /**
   * すべての店舗を取得
   */
  getAllStores(): Store[] {
    return Array.from(this.storeMap.values());
  }

  /**
   * 店舗データ更新
   * @param store
   */
  public async updateStore(store: Store): Promise<string> {

    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/stores/${store.id}`,
        store,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      await this._loadStores();

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 注文同期の設定
   * @param storeId
   * @param isEnabled 注文同期のON/OFFの状態
   * @param startDate 注文同期の開始日
   */
  public async setOrderSyncSettings(storeId: number, isEnabled: boolean, startDate: string|null = null) {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `/api/stores/${storeId}/order-sync`,
        {
          start_date: startDate,
          enabled: isEnabled
        },
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      await this._loadStores();

    } catch (e) {
      LogUtils.ex(e);
    }
  }


  /**
   * 全商品同期予約
   * @param storeId
   * @param reserve 予約するか?
   */
  public async reserveAllProductSync(storeId: number, reserve: boolean) {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `/api/stores/${storeId}/reserve-all-product-sync?reserve=${reserve}`,
        {},
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      await this._loadStores();

      // イベント発行
      EventService.getInstance().emitEvent(EventService.EVENT_RESERVE_ALL_PRODUCT_SYNC);

    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * 全商品同期予約された店舗を取得
   */
  public getReservedAllProductSyncStores(): Store[] {
    // 店舗をループ
    const stores = this.getAllStores();
    const reservedStores: Store[] = [];
    for (const store of stores) {
      if (store.product_sync_flg === 1) {
        reservedStores.push(store);
      }
    }
    return reservedStores;
  }


  /**
   * API連携
   * @param mallNo
   * @param storeId
   */
  public async apiIntegration(mallNo: number, storeId: number) {
    LogUtils.d();
    const debugMode = process.env.NODE_ENV !== "production";
    const baseUrl = debugMode ? "http://localhost" : "";

    // 現在のURLを取得
    const currentUrl = window.location.href;


    window.location.href = `${baseUrl}/auth/mall?mall_no=${mallNo}&store_id=${storeId}&redirect_url=${encodeURIComponent(currentUrl)}`;
    // try {
    //   const axios = await this.getAxios(true);
    //
    //   const response = await axios.get(`/auth/mall?mall_no=${mallNo}&store_id=${storeId}`, this.makeAuthorizeOption());
    //   LogUtils.d(response.toString());
    // } catch (e) {
    //   LogUtils.ex(e);
    // }
  }

  /**
   * 個別商品同期
   * @param storeId
   * @param stockCode
   */
  public async syncProduct(storeId: number, stockCode: string): Promise<string> {
    LogUtils.d("Starting product synchronization");

    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/stores/${storeId}/product-sync/${stockCode}`,
        {},
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 複数商品同期
   * @param storeId
   * @param stockCodes
   */
  public async syncProductMulti(storeId: number, stockCodes: string): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/stores/${storeId}/product-sync-multi`,
        { stock_codes: stockCodes },
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

}
