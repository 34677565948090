/**
 * 操作履歴
 */
export class OperationHistory {
  id?: number;
  user_id?: number;
  operation_type?: string;
  stock_code?: string;
  unit_code?: string;
  store_id?: number;
  mall_product_id?: string;
  value?: string;
  additional_info?: string;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<OperationHistory> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<OperationHistory>): OperationHistory {
    return new OperationHistory({
      ...this,
      ...params,
    });
  }

  static fromMap(json: { [key: string]: any }): OperationHistory {
    return new OperationHistory({
      ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): OperationHistory {
    const decoded = JSON.parse(jsonString);
    return OperationHistory.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }
}
