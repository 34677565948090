import { LogUtils } from "../Common/LogUtils";
import { HttpServiceBase } from "./HttpServiceBase";
import { Mall } from "../Models/Mall";
import {EcmgApiError} from "../Common/EcmgApiError";

export class MallService extends HttpServiceBase {
  private static instance: MallService = new MallService();
  private mallMap: Map<number, Mall> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): MallService {
    return this.instance;
  }

  async init(): Promise<void> {
    LogUtils.d("Initializing MallService");
    await this._loadMalls();
  }

  /**
   * モールデータ読み込み
   * @private
   */
  private async _loadMalls(): Promise<void> {
    try {
      const url = "api/malls";
      const axios = await this.getAxios();

      const response = await axios.get(url, this.makeAuthorizeOption());
      LogUtils.d(response.toString());

      this.setData(response.data);

    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * データ設定
   * @param data
   */
  public setData(data: any): void {
    this.mallMap.clear();
    if (data && Array.isArray(data)) {
      data.forEach(mallData => {
        const mall = Mall.fromMap(mallData);
        if (mall.mall_no !== undefined) {
          this.mallMap.set(mall.mall_no, mall);
        }
      });
    }
  }

  /**
   * モールデータ取得
   * @param mallNo
   */
  getMallById(mallNo: number): Mall | undefined {
    return this.mallMap.get(mallNo);
  }

  /**
   * すべての店舗を取得
   */
  getAllMalls(): Mall[] {
    return Array.from(this.mallMap.values());
  }

  /**
   * モールデータ更新
   * @param mall
   */
  public async updateMall(mall: Mall): Promise<string> {

    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/malls/${mall.mall_no}`,
        mall,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      await this._loadMalls();

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
}
