import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
} from '@mui/material';
import {SalesWeeklyHistory} from "../../../../../Models/SalesData/SalesData";
import {SalesDataConstants} from "./SalesDataConstants";
import {CommonUtils} from "../../../../../Common/CommonUtils";


interface SalesWeeklyHistoryTableProps {
  maxHeight: number;
  weeklySalesHistory: SalesWeeklyHistory[];
}
/**
 * 販売数履歴（週次）
 * @param props
 * @constructor
 */
export const SalesWeeklyHistoryTable = (props: SalesWeeklyHistoryTableProps) => {
  /**
   * 定数
   */
  const FONT_SIZE = '12px';
  const PADDING = '4px';

  /**
   * セル
   * @param text
   * @param index
   * @param align
   */
  const tableCell = (text: string, index: number, align = 'center' as any) => {
    let style = {fontSize: FONT_SIZE, padding: PADDING} as any;

    // 偶数行の場合はグレーの背景にする
    if ((index+1) % 2 === 0) {
      style = {...style, backgroundColor: SalesDataConstants.LIGHT_GRAY};
    }

    return (
      <TableCell align={align} style={style}>
        {text}
      </TableCell>
    );
  }


  /**
   * 数値をフォーマット
   * @param num
   */
  const formatNumber = (num: number | undefined) => {
    if ( num == null ){
      return '---';
    }
    return CommonUtils.formatNumber(num);
  }

  /**
   * Y・Rを取得
   * @param row
   */
  const getYR = (row: SalesWeeklyHistory) => {
    const y = row.y_sales != null ? CommonUtils.formatNumber(row.y_sales) : '---';
    const r = row.r_sales != null ? CommonUtils.formatNumber(row.r_sales) : '---';
    return `(${y} / ${r})`;
  }

  /**
   * ヘッダーセル
   * @param text
   */
  const headerCell = (text: string) => {
    return (
      <TableCell
        align="center"
        sx={{fontSize: FONT_SIZE, padding: PADDING, backgroundColor: SalesDataConstants.MID_GRAY}}
      >
        <strong>{text}</strong>
      </TableCell>
    );
  }

  /**
   * Divider
   */
  const divider = (index: number) => {
    const style = { backgroundColor: SalesDataConstants.DARK_GRAY };

    // 先頭行
    if (index === 0) {
      return (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={9}>
            <Divider style={style}/>
          </TableCell>
        </TableRow>
      );
    }
    // それ以外
    else {
      // 4週区切りで実戦を引く
      const shouldInsertDivider = index % 4 === 0;
      if (shouldInsertDivider) {
        return (
          <TableRow>
            <TableCell style={{padding: 0}} colSpan={9}>
              <Divider style={style}/>
            </TableCell>
          </TableRow>
        );
      }
    }
    return null;
  };


  /**
   * 描画
   */
  return (
    <TableContainer component={Paper} style={{ maxHeight: props.maxHeight, overflow: 'auto' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          {divider(-1)}
          <TableRow>
            {headerCell("")}
            {headerCell("仕入数")}
            {headerCell("受取数")}
            {headerCell("在庫数")}
            {headerCell("販売数")}
            {headerCell("Y・R")}
            {headerCell("Y価格")}
            {headerCell("R価格")}
          </TableRow>
          {divider(-1)}
        </TableHead>

        <TableBody>
          {props.weeklySalesHistory.map((row, index) => {
            return (
              <>
                {divider(index)}
                <TableRow key={row.week}>
                  {tableCell(row.week, index, 'center')}
                  {tableCell(formatNumber(row.unreceived), index, 'right')}
                  {tableCell(formatNumber(row.received), index, 'right')}
                  {tableCell(formatNumber(row.stock), index, 'right')}
                  {tableCell(formatNumber(row.sales), index, 'right')}
                  {tableCell(getYR(row), index, 'center')}
                  {tableCell(formatNumber(row.y_price), index, 'right')}
                  {tableCell(formatNumber(row.r_price), index, 'right')}
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
