// StockCount.tsx
import React, {useEffect, useState} from 'react';
import {Box, Divider, Typography} from "@mui/material";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {SearchResult} from "../../../../../Models/SearchResult";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";

interface StockCountProps {
  searchResult: SearchResult;
  isSKU: boolean;
}

/**
 * 合計在庫数
 * @param props
 * @constructor
 */
export const StockCount = (props: StockCountProps) => {
  /**
   * 定数
   */

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    isAltPressed: false,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey) {
        updateState({ isAltPressed: true });
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.altKey) {
        updateState({ isAltPressed: false });
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    // イベントリスナーをクリーンアップ
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);


  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  // /**
  //  * ユニット商品更新時
  //  * （この中でstateを参照しないこと）
  //  */
  // const onUnitProductUpdated = (updatedUnitProduct: UnitProduct) => {
  //   LogUtils.d('onUnitProductUpdated: ' + JSON.stringify(updatedUnitProduct);
  //   // 同じ在庫コードのユニット商品の場合のみ更新
  //   if (updatedUnitProduct.stock_code !== props.searchResult.product.stock_code) {
  //     return;
  //   }
  //   // 在庫数を更新
  //   updateState({ stockCount: props.searchResult.stockCount });
  // }

  /**
   * 在庫数クリック時処理
   */
  const onClickStockCount = async (event: React.MouseEvent<HTMLDivElement>) => {
    // Altキーありの場合
    if (event.altKey) {
      // 商品名の先頭から40文字分＋改行＋在庫コードをクリップボードにコピー
      const productName = props.searchResult.minimalUnitProduct?.name ?? '';
      const stockCode = props.searchResult.product.stock_code ?? '';
      const text = productName.substring(0, 40) + '\n' + stockCode;
      await navigator.clipboard.writeText(text);

      const snippet = CommonUtils.getStringSnippet(productName, 10) + ', ' + stockCode;
      showSnackbarMessage('商品名と在庫コードをコピーしました。[' + snippet + ']');
    }
  }


  /**
   * 潜在在庫
   */
  const potentialStockCount = () => {
    // const potentialStockCount = props.searchResult.potentialStockCount;
    // const shelfStockCount = props.searchResult.shelfStockCount;
    //
    // if ( props.isSKU ){
    //   return (
    //     <Typography fontSize={"12px"} color={MainPageConstants.COLOR_DARK_GRAY}>
    //       (潜在在庫：{potentialStockCount} / 棚在庫：{shelfStockCount})
    //     </Typography>
    //   );
    // }
    // else {
    //   return (
    //     <Typography fontSize={"12px"} color={MainPageConstants.COLOR_DARK_GRAY}>
    //       ({potentialStockCount} / {shelfStockCount})
    //     </Typography>
    //   );
    // }

    const potentialStockCount = props.searchResult.potentialStockCount;
    if ( props.isSKU ){
      return (
        <Typography fontSize={"12px"} color={MainPageConstants.COLOR_DARK_GRAY}>
          (潜在在庫：{potentialStockCount})
        </Typography>
      );
    }
    else {
      return (
        <Typography fontSize={"12px"} color={MainPageConstants.COLOR_DARK_GRAY}>
          ({potentialStockCount})
        </Typography>
      );
    }
  }

  /**
   * 合計在庫数(SKU)
   */
  const stockCountSKU = () => {
    return (
      <>
        {/* 合計在庫数 */}
       <Typography fontSize={"14px"}>在庫数</Typography>
        <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"end"}>
          <Box onClick={onClickStockCount} sx={{ cursor: state.isAltPressed ? 'pointer' : 'default' }}>
            <Typography variant={"h2"} fontWeight="bold">
              {props.searchResult.stockCount}
            </Typography>
          </Box>
          {/*  棚在庫 */}
          <Box ml={1} pb={1}>
            <Typography fontSize={"20px"} color={MainPageConstants.COLOR_DARK_GRAY}>
              / {props.searchResult.shelfStockCount}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  /**
   * 合計在庫数(リスト)
   */
  const stockCountList = () => {
    return (
      <>
        {/* 合計在庫数 */}
        <Box display={"flex"} flexDirection={"column"} >
          <Box onClick={onClickStockCount} sx={{ cursor: state.isAltPressed ? 'pointer' : 'default' }}>
            <Typography variant={"h4"} fontWeight="bold">
              {props.searchResult.stockCount}
            </Typography>
          </Box>
          <Divider key={1} />
          {/*  棚在庫 */}
          <Box >
            <Typography fontSize={"12px"} color={MainPageConstants.COLOR_DARK_GRAY}>
              {props.searchResult.shelfStockCount}
            </Typography>
          </Box>
          <Divider key={2} />
        </Box>
      </>
    );
  }

  /**
   * 描画
   */
  return (
    <Box textAlign={"center"}>
      {/* 合計在庫数 */}
      {props.isSKU ? stockCountSKU() : stockCountList()}

      {/* 潜在在庫数 */}
      { potentialStockCount() }
    </Box>
  );
}

