
/**
 * 商品メモ画像情報
 */
export class ProductMemoPhoto {
  id?: number;
  stock_code?: string;
  number?: number;
  ext?: string;
  created_at?: Date;
  updated_at?: Date;

  // 画像URL用
  originalImageUrl: string = '';
  thumbnailImageUrl: string = '';


  constructor(params: Partial<ProductMemoPhoto> = {}) {
    Object.assign(this, params);

    // 画像URL
    this.originalImageUrl = `api/products/memo-photos?filename=${this.id}.${this.ext}`;
    this.thumbnailImageUrl = `api/products/memo-photos?filename=${this.id}_Thumb.${this.ext}`;

  }

  copyWith(params: Partial<ProductMemoPhoto>): ProductMemoPhoto {
    return new ProductMemoPhoto({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): ProductMemoPhoto {
    return new ProductMemoPhoto({
      ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): ProductMemoPhoto {
    const decoded = JSON.parse(jsonString);
    return ProductMemoPhoto.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  getDataGridValues(): string[] {
    return [
      this.id?.toString() || '',
      this.stock_code || '',
      this.number?.toString() || '',
      this.ext || '',
      this.created_at ? this.created_at.toISOString() : '',
      this.updated_at ? this.updated_at.toISOString() : '',
    ];
  }

}
