import { StoreProduct } from "../../../../../Models/Product/StoreProduct";
import { StoreProductPanel } from "./StoreProductPanel";
import {UnitOrderCountData} from "../../../../../Models/Product/OrderCountData";
import {Box} from "@mui/material";
import {Product} from "../../../../../Models/Product/Product";
import {UnitProduct} from "../../../../../Models/Product/UnitProduct";

interface StoreProductListProps {
  product: Product;
  unitProduct: UnitProduct;
  storeProducts: StoreProduct[];
  unitOrderCountData: UnitOrderCountData | undefined;
  canChange: boolean;
}

/**
 * 店舗商品リスト
 * @param props
 * @constructor
 */
export const StoreProductList = (props: StoreProductListProps) => {
  // LogUtils.d("unitOrderCountData:" + JSON.stringify(props.unitOrderCountData));

  return (
    <div>
      {props.storeProducts.map(sp => (
        <Box key={sp.id}>
          <StoreProductPanel
            product={props.product}
            unitProduct={props.unitProduct}
            storeProduct={sp}
            storeOrderCountData={props.unitOrderCountData?.getStoreOrderCountData(sp.store_id ?? 0)}
            canChange={props.canChange}
          />
          <Box mt={1} />
        </Box>
      ))}
    </div>
  );
};
