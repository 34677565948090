import { LogUtils } from "../../Common/LogUtils";
import { HttpServiceBase } from "../HttpServiceBase";
import { EcmgApiError } from "../../Common/EcmgApiError";
import { SpecialDate } from "../../Models/SpecialDate";
import {SpecialDateUtils} from "./SpecialDateUtils";
import {CSSProperties} from "react";
import {CommonUtils} from "../../Common/CommonUtils";

/**
 * 特別日サービス
 */
export class SpecialDateService extends HttpServiceBase {
  private static instance: SpecialDateService = new SpecialDateService();
  private specialDateMap: Map<number, SpecialDate> = new Map();
  private salesHistoryStyleMap: Map<string, CSSProperties> = new Map();
  private salesHistoryArrowStyleMap: Map<string, CSSProperties> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): SpecialDateService {
    return this.instance;
  }

  async init(): Promise<void> {
    LogUtils.d("Initializing SpecialDateService");
    await this._loadSpecialDates();
  }

  /**
   * 特別日データ読み込み
   * @private
   */
  private async _loadSpecialDates(): Promise<void> {
    try {
      const url = "api/special-dates";
      const axios = await this.getAxios();

      const response = await axios.get(url, this.makeAuthorizeOption());
      LogUtils.d(response.toString());

      this.setData(response.data);

    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * データ設定
   * @param data
   */
  public setData(data: any): void {
    this.specialDateMap.clear();
    if (data && Array.isArray(data)) {
      data.forEach(specialDateData => {
        const specialDate = SpecialDate.fromMap(specialDateData);
        if (specialDate.id !== undefined) {
          this.specialDateMap.set(specialDate.id, specialDate);
        }
      });
      // 365日前までの販売履歴 加飾データを作成
      this.makeSalesHistoryStyleMap();
    }
  }

  /**
   * 365日前までの販売履歴 加飾データを作成
   */
  private makeSalesHistoryStyleMap(): void {
  LogUtils.d("makeSalesHistoryStyleMap");

    // 今日から365日前までの日付をループ
    this.salesHistoryStyleMap.clear();
    this.salesHistoryArrowStyleMap.clear();
    for (let i = 0; i <= 365; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);

      const style = SpecialDateUtils.getStyle(date);
      this.salesHistoryStyleMap.set(CommonUtils.formatDate(date), style);

      const arrowStyle = SpecialDateUtils.getStyleExcludingFirstDay(date);
      this.salesHistoryArrowStyleMap.set(CommonUtils.formatDate(date), arrowStyle);
    }

    // // salesHistoryStyleMapエントリを全ループ
    // // @ts-ignore
    // for (const [date, style] of this.salesHistoryStyleMap) {
    //   LogUtils.d("date=" + CommonUtils.formatDate(date) + ", style=" + JSON.stringify(style));
    // }

  }

  /**
   * 特別日データ取得
   * @param id
   */
  getSpecialDateById(id: number): SpecialDate | undefined {
    return this.specialDateMap.get(id);
  }

  /**
   * すべての特別日を取得
   */
  getAllSpecialDates(): SpecialDate[] {
    return Array.from(this.specialDateMap.values());
  }

  /**
   * 指定日の販売履歴加飾スタイルを取得
   */
  getSalesHistoryStyle(date: Date): CSSProperties | undefined {
    return this.salesHistoryStyleMap.get(CommonUtils.formatDate(date));
  }

  /**
   * 指定日の販売履歴加飾スタイル(矢印用）を取得
   */
  getSalesHistoryStyleForArrow(date: Date): CSSProperties | undefined {
    return this.salesHistoryArrowStyleMap.get(CommonUtils.formatDate(date));
  }
  /**
   * N日前の販売履歴加飾スタイルを取得
   */
  getSalesHistoryStyleBeforeDays(days: number): CSSProperties | undefined {
    const date = new Date();
    date.setDate(date.getDate() - days);

    // LogUtils.d("getSalesHistoryStyleBeforeDays: days=" + days + ", date=" + CommonUtils.formatDate(date));
    // const style = this.getSalesHistoryStyle(date);
    // LogUtils.d("getSalesHistoryStyleBeforeDays: style=" + JSON.stringify(style));

    return this.getSalesHistoryStyle(date);
  }

  /**
   * N日前の販売履歴加飾スタイル（矢印用）を取得
   */
  getSalesHistoryStyleBeforeDaysForArrow(days: number): CSSProperties | undefined {
    const date = new Date();
    date.setDate(date.getDate() - days);

    // LogUtils.d("getSalesHistoryStyleBeforeDays: days=" + days + ", date=" + CommonUtils.formatDate(date));
    // const style = this.getSalesHistoryStyle(date);
    // LogUtils.d("getSalesHistoryStyleBeforeDays: style=" + JSON.stringify(style));

    return this.getSalesHistoryStyleForArrow(date);
  }

  /**
   * 特別日データ更新
   * @param specialDate
   */
  public async updateSpecialDate(specialDate: SpecialDate): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/special-dates/${specialDate.id}`,
        specialDate,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      await this._loadSpecialDates();

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 特別日追加
   * @param specialDate
   */
  public async addSpecialDate(specialDate: SpecialDate): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        'api/special-dates',
        specialDate,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());
      await this._loadSpecialDates();

      return response.data.message;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 特別日削除
   * @param specialDateId
   */
  public async deleteSpecialDate(specialDateId: number): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.delete(
        `api/special-dates/${specialDateId}`,
        this.makeAuthorizeOption()
      );

      await this._loadSpecialDates();

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 特定の日付に関連するすべてのSpecialDateを取得
   * @param date 対象の日付
   */
  public getSpecialDatesForDate(date: Date): SpecialDate[] {
    // dateの時間をリセット（コピーを作成）
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    return Array.from(this.specialDateMap.values()).filter(specialDate => {
      if (!specialDate.start_date) {
        return false;
      }
      const startDate = new Date(specialDate.start_date);
      const endDate = specialDate.end_date ? new Date(specialDate.end_date) : startDate;

      return date >= startDate && date <= endDate;
    });
  }

  /**
   * 特定の日付に関連するすべてのSpecialDateを取得（期間の先頭日を除く）
   * @param date
   */
  public getSpecialDatesExcludingFirstDayOfPeriod(date: Date): SpecialDate[] {
    // dateの時間をリセット（コピーを作成）
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    return Array.from(this.specialDateMap.values()).filter(specialDate => {
      if (!specialDate.start_date) {
        return false;
      }
      const startDate = new Date(specialDate.start_date);
      const endDate = specialDate.end_date ? new Date(specialDate.end_date) : startDate;

      // 期間が2日以上かつ、指定日が期間の先頭日ではないか確認
      const isPeriodMoreThanOneDay = endDate.getTime() > startDate.getTime(); // 期間が1日より長い
      const isNotFirstDayOfPeriod = date.getTime() > startDate.getTime(); // 先頭日ではない

      return isPeriodMoreThanOneDay && isNotFirstDayOfPeriod && date > startDate && date <= endDate;
    });
  }


}
