
export class LogUtils {
  static i(message?: string) {
    console.log(`-- INFO -- ${new Date().toISOString()} ${message ?? ""}`);
  }

  static w(message?: string) {
    console.warn(`-- WARN -- ${new Date().toISOString()} ${message ?? ""}`);
  }

  static e(message?: string) {
    console.error(`-- ERROR -- ${new Date().toISOString()} ${message ?? ""}`);
  }

  static d(message?: string) {
    const error = new Error();
    const methodName = error.stack?.split("\n")[2].trim().split(" ")[1];
    console.log(`-- DEBUG -- ${methodName}:${message ?? ""}`);
  }

  static ex(e: any) {
    console.error("↓↓↓↓ Error ↓↓↓↓ \n");
    console.error(e);
    console.error(e.stack ?? "");
  }


}
