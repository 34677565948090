import React, {useEffect} from 'react';
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography, Box
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {OperationHistory} from "../../../../../../Models/OperationHistory/OperationHistory";
import {UnreceivedDailyHistoryTable} from "./UnreceivedDailyHistoryTable";
import {Product} from "../../../../../../Models/Product/Product";

interface SalesDataDialogProps {
  open: boolean;
  onClose: () => void;
  unreceivedHistories: OperationHistory[];
  product: Product;
}

/**
 * 仕入履歴ダイアログ
 * @constructor
 * @param props
 */
export const UnreceivedHistoryDialog = (props: SalesDataDialogProps) => {
  /**
   * 定数
   */
  // リスト高さ
  const DAILY_LIST_HEIGHT = 800;
  // 日次データ幅
  const DAILY_DATA_WIDTH = 420;

  /**
   * props変更時
   */
  useEffect(() => {
  }, [props]);

  /**
   * レンダリング
   */
  return (
    <Dialog maxWidth={'xl'} open={props.open} onClose={props.onClose}>
      <AppBar position="static" style={{ minHeight: '40px' }}>
        <Toolbar style={{ minHeight: '40px' }}>
          <Typography sx={{ flexGrow: 1 }}>
            仕入履歴
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box  display={'flex'} flexDirection={'row'}>
          <Box sx={{minWidth: DAILY_DATA_WIDTH}}>
            <UnreceivedDailyHistoryTable
              maxHeight={DAILY_LIST_HEIGHT}
              unreceivedHistories={props.unreceivedHistories}
              product={props.product}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
