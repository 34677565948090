import React, {useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {MallSetting} from "./Mall/MallSetting";
import {AutoAdjustSetting} from "./AutoAdjust/AutoAdjustSetting";
import {CommonLayout} from "../Common/Layouts/CommonLayout";
import {Align, Alignment} from "../Common/Components/Align";
import {Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {StoreSetting} from "./Store/StoreSetting";
import {DeveloperSetting} from "./Developer/DeveloperSetting";
import {UserSetting} from "./User/UserSetting";
import {SpecialDateSetting} from "./SpecialDate/SpecialDateSetting";
import {CalendarSetting} from "./Calendar/CalendarSetting";
import {OfficeSetting} from "./Office/OfficeSetting";
import {LoginService} from "../../Services/LoginService";
import {TagCategorySetting} from "./TagCategory/TagCategorySetting";

/**
 * 設定画面
 * @constructor
 */
export const SettingPage = () => {
  // メインコンテンツ幅
  const MAIN_CONTENTS_WIDTH = "1300px";

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    page: 0,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useNavigate
   */
  const navigate = useNavigate();

  const isAdmin = LoginService.getInstance().loginUser?.isAdmin() ?? false;
  const tabs = [
    ...(isAdmin ? [{ label: "ユーザー設定", component: <UserSetting /> }] : []),
    ...(isAdmin ? [{ label: "事業所設定", component: <OfficeSetting /> }] : []),
    { label: "モール設定", component: <MallSetting /> },
    { label: "店舗設定", component: <StoreSetting /> },
    { label: "自動調整パターン", component: <AutoAdjustSetting /> },
    { label: "特別日設定", component: <SpecialDateSetting /> },
    { label: "カレンダー設定", component: <CalendarSetting /> },
    { label: "タグ分類設定", component: <TagCategorySetting /> },
    { label: "開発者用", component: <DeveloperSetting /> },
  ];

  /**
   * 描画
   */
  return (
    <CommonLayout title="設定" hideMainElement={true}>
      <Box mt={2} />
      <Align align={Alignment.center}>
        <Box width={MAIN_CONTENTS_WIDTH}>
          <Tabs value={state.page} onChange={(event, value) => updateState({ page: value })}>
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>

          {tabs[state.page] && tabs[state.page].component}

          <Box mt={10} />
          <Align align={Alignment.center}>
            <Button variant="contained" onClick={() => navigate('/main')}>メイン画面へ</Button>
          </Align>
        </Box>
      </Align>
    </CommonLayout>
  );
}
