import React, {useEffect, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {Badge} from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import {EventService} from "../../../../Services/System/EventService";
import {ChatService} from "../../../../Services/ChatService";
import {LogUtils} from "../../../../Common/LogUtils";
import {useSnackbar} from "../../Provider/SnackbarContext";
import {ChatMessage} from "../../../../Models/Chat/ChatMessage";
import ECProgress from "../../Components/ECProgress";
import {ChatMyMessageDialog} from "./ChatMyMessageDialog";
import Tooltip from "@mui/material/Tooltip";

interface UnrespondedMessageButtonProps {
}

/**
 * 未返信メッセージボタン
 * @constructor
 */
export const UnrespondedMessageButton = (props: UnrespondedMessageButtonProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    processing: false,
    unrespondedMessageCount: 0 ,
    dialogOpen: false,
    messageInfos: [] as Array<{
      message: ChatMessage;
      responded: number;
      unresponded: number;
      minimalUnitProductName: string;
    }>
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };


  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * useEffect
   */
  useEffect(() => {
    // イベントハンドラ登録（イベントハンドラの中でstateの値を参照しないこと）
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_FROM_ME_UNRESPONDED_MESSAGES, onUnrespondedMessageCount);

    // 未返信メッセージ数取得
    loadUnrespondedMessageCount().then();

    // クリーンアップ
    return () => {
      // イベントハンドラ解除
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_FROM_ME_UNRESPONDED_MESSAGES, onUnrespondedMessageCount);
    };
  }, []);

  /**
   * 未返信メッセージ数取得
   */
  const loadUnrespondedMessageCount = async () => {
    try {
      const count = await ChatService.getInstance().getFromMeUnrespondedMessageCount();
      updateState({unrespondedMessageCount: count});
    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * 未返信メッセージ数イベントハンドラ
   * @param unrespondedMessageCount
   */
  const onUnrespondedMessageCount = (unrespondedMessageCount: number) => {
  //LogUtils.d('onUnreadMessages: ' + unreadMessageIds.length);
    updateState({unrespondedMessageCount: unrespondedMessageCount});
  }

  /**
   * 未返信メッセージ数変更時
   */
  useEffect(() => {
    // ダイアログ表示時の場合はメッセージの再読み込み
    if (state.dialogOpen) {
      loadMyMessages().then();
    }
  }, [state.unrespondedMessageCount]);

  /**
   * 送信メッセージ読み込み
   */
  const loadMyMessages = async () => {
    // メッセージ情報を取得
    const messageInfos = await ChatService.getInstance().getMyMessagesWithUnrespondedFlag();
    if ( messageInfos.length === 0 ){
      return;
    }
    updateState({
      messageInfos: messageInfos
    });
  }

  /**
   * クリックイベントハンドラ
   */
  const handleClick = async () => {
    try {
      updateState({processing: true});

      // メンションメッセージ読み込み
      await loadMyMessages();

      updateState({dialogOpen: true});
    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('チャットメッセージ読み込みエラーが発生しました。');
    }
    finally {
      updateState({processing: false});
    }
  };

  /**
   * ポップアップを閉じる
   */
  const handleClosePopup = () => {
    updateState({dialogOpen: false});
  };

  /**
   * レンダリング
   */
  return (
    <>
      <Tooltip title={'送信したメッセージ'} >
        <IconButton
          onClick={handleClick}
          color="inherit"
          // disabled={state.unreadMessageIds.length === 0}
        >
          <Badge badgeContent={state.unrespondedMessageCount} color="error">
            <MailIcon />
          </Badge>

        </IconButton>
      </Tooltip>
      {/* ChatMentionPopupの条件付きレンダリング */}
      <ChatMyMessageDialog
        open={state.dialogOpen}
        messageInfos={state.messageInfos}
        onClose={handleClosePopup}
      />
      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </>
  );
};
