import * as React from "react";
import {Box, SxProps, Theme} from "@mui/material";

export enum Alignment {
  left,
  center,
  right
}

interface CenterOption{
  align: Alignment;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}
/**
 * 配置
 * @param props
 * @constructor
 */
export const Align = (props: CenterOption) => {
  let justifyContent;
  if ( props.align === Alignment.left ) {
    justifyContent = 'flex-start';
  }
  else if ( props.align === Alignment.center ) {
    justifyContent = 'center';
  }
  else if ( props.align === Alignment.right ) {
    justifyContent = 'flex-end';
  }

  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: justifyContent,
    width: '100%',
  };

  /**
   * 描画
   */
  return (
    <Box
      sx={Object.assign(style, props.sx)}>
      {props.children}
    </Box>
  );
}
