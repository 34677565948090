import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Mall} from "../../../Models/Mall";
import {AppBar, Box, IconButton, Toolbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {CommonUtils} from "../../../Common/CommonUtils";
import {EMTextField} from "../../Common/Components/EMTextField";

interface MallEditDialogProps {
  open: boolean;
  mall: Mall;
  onClose: () => void;
  onSave: (mall: Mall) => void;
  errors: Record<string, string>,
}

/**
 * モール設定ダイアログ
 * @constructor
 * @param props
 */
export const MallEditDialog = (props: MallEditDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    editedMall: new Mall({}),
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * props変更時処理
   */
  useEffect(() => {
    const editedMall = props.mall.copyWith({});
    // 各値を小数点第一位までとする
    editedMall.fee = fixNumber(editedMall.fee);
    editedMall.gross_margin_1 = fixNumber(editedMall.gross_margin_1);
    editedMall.gross_margin_2 = fixNumber(editedMall.gross_margin_2);

    updateState({ editedMall: editedMall });
  }, [props]);

  /**
   * 数値を小数点第一位までとする
   * @param value
   */
  const fixNumber = (value: number | undefined): number | undefined => {
    return value ? Math.round(value * 10) / 10 : undefined;
  }

  /**
   * フィールドが変更されたときのハンドラー
   * @param name
   * @param value
   */
  const handleChange = (name: string, value: string) => {

    let updatedValue: string|undefined = value;
    if ( value === '' ){
      updatedValue = undefined;
    }
    else {
      if ( !CommonUtils.isUnsignedNumeric(value) ) {
        return;
      }
    }
    const newMall = state.editedMall.copyWith({ [name]: updatedValue });
    updateState({ editedMall: newMall });
  };

  /**
   * 保存ボタンが押されたときの処理
   */
  const handleSave = () => {
    props.onSave(state.editedMall);
  };

  /**
   * テキストフィールド
   * @param name
   * @param label
   * @param value
   * @param handleChange
   */
  const renderNumberInput = (
    name: string,
    label: string,
    value: string | number | undefined,
    handleChange: (name: string, value: string) => void) => {

    return (
      <Box mt={2}>
        <EMTextField
          label={label}
          value={value == null ? '' : String(value)}
          onChange={(newValue) => handleChange(name, newValue)}
          error={props.errors[name]}
        />
      </Box>
    );
  };

  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            モール編集
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {/* 各フィールドの入力コンポーネント */}
        <Box mt={2} />
        <Typography>モール番号: {props.mall.mall_no ?? ''}</Typography> {/* 表示専用 */}
        <Box mt={2} />
        <Typography>モール名: {props.mall.name ?? ''}</Typography> {/* 表示専用 */}
        <Box mt={2} />
        {renderNumberInput('fee', 'モール手数料（率）', state.editedMall.fee, handleChange)}
        {renderNumberInput('shipping_fee_1', '送料（宅急便）', state.editedMall.shipping_fee_1, handleChange)}
        {renderNumberInput('shipping_fee_2', '送料（メール便）', state.editedMall.shipping_fee_2, handleChange)}
        {renderNumberInput('labor_cost', '人件費', state.editedMall.labor_cost, handleChange)}
        {renderNumberInput('gross_margin_1', '粗利率1', state.editedMall.gross_margin_1, handleChange)}
        {renderNumberInput('gross_margin_2', '粗利率2', state.editedMall.gross_margin_2, handleChange)}
        <Box mt={2} />
        <DialogActions>
          <Button onClick={handleSave} color="primary" variant={"contained"}>保存</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

