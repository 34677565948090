/**
 * 店舗商品
 */
export class StoreProduct {
  id?: number;
  mall_no?: number;
  store_id?: number;
  stock_code?: string;
  unit_code?: string;
  name?: string;
  unit?: number;
  price?: number;
  shipping_code?: number;
  processed?: number;
  pub_status?: number;
  mall_quantity?: number;
  mall_product_id?: string;
  has_defect?: number;
  additional_info?: string;
  first_reg_timestamp?: Date;
  man_inv_updated_at?: Date;
  man_price_updated_at?: Date;
  created_at?: Date;
  updated_at?: Date;
  price_setting_type?: number; // （追加情報）価格設定タイプ

  constructor(params: Partial<StoreProduct> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<StoreProduct>): StoreProduct {
    return new StoreProduct({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): StoreProduct {
    return new StoreProduct({
      ...json,
      first_reg_timestamp: json.first_reg_timestamp ? new Date(json.first_reg_timestamp) : undefined,
      man_inv_updated_at: json.man_inv_updated_at ? new Date(json.man_inv_updated_at) : undefined,
      man_price_updated_at: json.man_price_updated_at ? new Date(json.man_price_updated_at) : undefined,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): StoreProduct {
    const decoded = JSON.parse(jsonString);
    return StoreProduct.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      first_reg_timestamp: this.first_reg_timestamp ? this.first_reg_timestamp.toISOString() : undefined,
      man_inv_updated_at: this.man_inv_updated_at ? this.man_inv_updated_at.toISOString() : undefined,
      man_price_updated_at: this.man_price_updated_at ? this.man_price_updated_at.toISOString() : undefined,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  // こちらはDataGridの値として適切な項目を選択していますが、変更する必要がある場合があります。
  getDataGridValues(): string[] {
    return [
      this.id?.toString() || '',
      this.stock_code || '',
      this.unit_code || '',
      this.name || '',
      // ... その他の項目も同様に追加 ...
    ];
  }
}
