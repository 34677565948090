import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { EMTextField } from '../../Common/Components/EMTextField';
import { EMSelect } from '../../Common/Components/EMSelect';
import {SystemSetting} from "../../../Models/SystemSetting";
import {Constants} from "../../../Common/Constants";
import {LogUtils} from "../../../Common/LogUtils";

interface PatternDateEditDialogProps {
  open: boolean;
  systemSetting: SystemSetting;
  onClose: () => void;
  onSave: (systemSetting: SystemSetting) => void;
  errors: Record<string, string>;
}

/**
 * ◯の付く日編集ダイアログ
 * @param props
 * @constructor
 */
export const PatternDateEditDialog = (props: PatternDateEditDialogProps) => {
  const [state, setState] = useState({
    editedPatternDate: new SystemSetting({}),
  });

  /**
   * props変更時処理
   */
  useEffect(() => {
    setState({
      editedPatternDate: props.systemSetting.copyWith({}),
    });
  }, [props]);

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  /**
   * 特別日タイプのリストを返す
   */
  const specialDateTypes = () => {
    return Object.entries(Constants.specialDateLabels).map(([id, label]) => ({
      id: parseInt(id),
      label
    }));
  }

  /**
   * データ更新
   * @param specialType
   * @param patternDateString
   */
  const updatePatternDate = (specialType?: string, patternDateString?: string) => {
    const newPatternDateData = state.editedPatternDate.copyWith({});
    newPatternDateData.updatePatternDate(specialType, patternDateString);

    LogUtils.d(JSON.stringify(newPatternDateData));

    updateState({ editedPatternDate: newPatternDateData });
  }

  /**
   * データ取得
   */
  const getPatternDate = () : [number?, string?] => {
    return state.editedPatternDate.getPatternDate();
  }


  /**
   * 保存ボタンクリック時
   */
  const handleSave = () => {
    props.onSave(state.editedPatternDate);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            ◯の付く日編集
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box mt={2}>
          <EMSelect
            label="特定日タイプ"
            value={getPatternDate()[0]?.toString() ?? ''}
            onChange={(value) => updatePatternDate(value, undefined)}
            menuItems={specialDateTypes()}
            itemValueKey="id"
            itemLabelKey="label"
            error={props.errors['special_type']}
            sx={{ minWidth: 180 }}
          />
        </Box>
        <Box mt={2}>
          <EMTextField
            label="対象日"
            value={getPatternDate()[1] ?? ''}
            onChange={(value) => updatePatternDate(undefined, value)}
            error={props.errors['value']}
          />
        </Box>
        <Box mt={2}>
          <DialogActions>
            <Button onClick={handleSave} color="primary" variant="contained">
              保存
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
