import {Box, Typography} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {UserService} from "../../../../Services/UserService";

interface ChatTagToProps {
  tag: string;
}

/**
 * チャットタグ TO
 * @param props
 * @constructor
 */
export const ChatTagTo = (props: ChatTagToProps) => {
  // ユーザーIDを抽出
  const match = props.tag.match(/\[To:(.+?)]/);
  const userId = match ? match[1] : null;

  if (!userId) {
    return <span>{props.tag}</span>;
  }

  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center', mb: 1, verticalAlign: 'top'}}>
      <Box
        sx={{ display: 'inline-flex',alignItems: 'center', backgroundColor: '#7fffd4', borderRadius: 1 }}
      >
        <Typography variant="body2" sx={{ fontWeight: 'bold', ml:0.5, mr:0.5 }}>
          TO
        </Typography>
      </Box>
      <AccountCircleIcon style={{ color: UserService.getInstance().getUserColor(Number(userId)) }}/>
    </Box>
  );
};

