import {AIConstants} from "../AIConstants";

/**
 * AIチャットメッセージ
 */
export class AIChatMessage {
  id?: number;
  role?: string;
  sent_date_time?: Date;
  message?: string;

  constructor(params: Partial<AIChatMessage> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<AIChatMessage>): AIChatMessage {
    return new AIChatMessage({
      ...this,
      ...params,
    });
  }

  static fromMap(json: { [key: string]: any }): AIChatMessage {
    return new AIChatMessage({
      ...json,
      sent_date_time: json.sent_date_time ? new Date(json.sent_date_time) : undefined,
    });
  }

  static fromJson(jsonString: string): AIChatMessage {
    const decoded = JSON.parse(jsonString);
    return AIChatMessage.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      sent_date_time: this.sent_date_time ? this.sent_date_time.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }


  /**
   * 自分のメッセージか？
   */
  public isMyMessage(): boolean {
    return this.role === AIConstants.AI_ROLE_USER;
  }

}
