import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import {AIChatPageParam} from "./Model/AIChatPageParam";
import {AIChatView} from "./AIChatView";
import {AIConstants} from "./AIConstants";


/**
 * AIチャット画面
 *
 * @constructor
 */
export const AIChatPage = () => {
  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    chatPageParam: new AIChatPageParam({}),
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useLocation
   */
  const location = useLocation();

  /**
   * URLパラメータ変更時
   */
  useEffect(() => {
    const chatPageParam = AIChatPageParam.fromUrl();
    updateState({chatPageParam: chatPageParam});
  }, [location.search]);

  const chatTypeName = state.chatPageParam.type === AIConstants.AI_TYPE_DESIGN ? '設計書関連' :
    state.chatPageParam.type === AIConstants.AI_TYPE_DATA ? 'データ関連' :
      state.chatPageParam.type === AIConstants.AI_TYPE_SALES_FORECAST ? '利益予測' : '';

  /**
   * 描画
   */
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            flexGrow={1}
          >

            <Typography variant="h6" >
              {"EC管理システム [AIチャット : " +  chatTypeName + "]"}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        display={"flex"}
        flexDirection={"row"}
      >
        <Box ml={40}/>
        {/* チャットビュー */}
        <AIChatView
          chatPageParam={state.chatPageParam}
        />

        <Box mr={40}/>
      </Box>
    </>
  );
}
