import {CalendarColorItem} from "../Models/Calendar/CalendarColorItem";

/**
 * 共通定数
 */
export class Constants {
  //----------------------
  // 在庫コードの長さ
  //----------------------
  static readonly STOCK_CODE_LENGTH = 6;

  //----------------------
  // フォーマット
  //----------------------
  public static YEAR_MONTH_FORMAT = 'yyyy/MM';  // 年月
  public static DATE_FORMAT = 'yyyy/MM/dd';  // 日付
  public static TIME_FORMAT = 'HH:mm';       // 時間

  //----------------------
  // モール
  //----------------------
  static readonly MALL_BASE = 1;
  static readonly MALL_YAHOO = 2;
  static readonly MALL_RAKUTEN = 3;
  static readonly mallLabels: Record<number, string> = {
    [Constants.MALL_BASE]: 'BASE',
    [Constants.MALL_YAHOO]: 'Yahoo!',
    [Constants.MALL_RAKUTEN]: 'Rakuten',
  };

  //----------------------
  // 配送方法コード
  //----------------------
  static readonly SHIPPING_METHOD_CODE_EXPRESS = 1; // 宅配便
  static readonly SHIPPING_METHOD_CODE_MAIL = 2; // メール便

  //----------------------
  // エクスポートタイプ
  //----------------------
  // 全て
  static readonly  EXPORT_TYPE_ALL = 1;
  // 検索結果
  static readonly  EXPORT_TYPE_SEARCH_RESULT = 2;
  //----------------------
  // エクスポート項目
  //----------------------
  // 在庫コード
  static readonly EXPORT_STOCK_CODE = 'stock_code';
  // 商品名
  static readonly EXPORT_PRODUCT_NAME = 'product_name';
  // タグ
  static readonly EXPORT_TAGS = 'tags';
  // 在庫数
  static readonly EXPORT_INVENTORY_QUANTITY = 'inventory_quantity';
  // 潜在在庫
  static readonly EXPORT_POTENTIAL_INVENTORY = 'potential_inventory';
  // 未着在庫
  static readonly EXPORT_PENDING_INVENTORY = 'pending_inventory';
  // 1週間の仕入予想数
  static readonly EXPORT_WEEKLY_REPLENISHMENT_FORECAST = 'weekly_replenishment_forecast';
  // 1週間の販売実績
  static readonly EXPORT_WEEKLY_SALES_PERFORMANCE = 'weekly_sales_performance';
  // 1ヶ月の仕入と販売の差
  static readonly EXPORT_MONTHLY_REPLENISHMENT_SALES_DIFFERENCE = 'monthly_replenishment_sales_difference';
  // 仕入れストップまでの日数
  static readonly EXPORT_DAYS_UNTIL_REPLENISHMENT_STOP = 'days_until_replenishment_stop';
  // 仕入れ再開までの日数
  static readonly EXPORT_DAYS_UNTIL_REPLENISHMENT_RESUME = 'days_until_replenishment_resume';
  // 売切れまでの日数
  static readonly EXPORT_DAYS_UNTIL_SOLD_OUT = 'days_until_sold_out';
  // 要仕入数
  static readonly EXPORT_REQUIRED_REPLENISHMENT = 'required_replenishment';
  // 仕入の上限
  static readonly EXPORT_REPLENISHMENT_UPPER_LIMIT = 'replenishment_upper_limit';
  // 仕入の下限
  static readonly EXPORT_REPLENISHMENT_LOWER_LIMIT = 'replenishment_lower_limit';
  // 直近30日の販売数の1日平均数
  static readonly EXPORT_30DAY_SALES_DAILY_AVERAGE = '30day_sales_daily_average';
  // 直近14日分の販売数の推移
  static readonly EXPORT_14DAY_SALES_TREND = '14day_sales_trend';
  // 各モールの仕入価格の範囲
  static readonly EXPORT_MALL_PURCHASE_PRICE_RANGE = 'mall_purchase_price_range';
  // フリマ相場
  static readonly EXPORT_FREEMARKET_PRICE_RANGE = 'freemarket_price_range';
  // フリーメモ
  static readonly EXPORT_FREE_MEMO = 'free_memo';
  // メモ画像
  static readonly EXPORT_MEMO_IMAGE = 'memo_image';
  // 在庫パターン
  static readonly EXPORT_STOCK_PATTERN = 'stock_pattern';
  // 価格パターン
  static readonly EXPORT_PRICE_PATTERN = 'price_pattern';
  // ユニット情報
  static readonly EXPORT_UNIT_INFORMATION = 'unit_information';
  // ユニット名
  static readonly EXPORT_UNIT_NAME = 'unit_name';
  // ユニット在庫数
  static readonly EXPORT_UNIT_INVENTORY_QUANTITY = 'unit_inventory_quantity';
  // 店舗コード
  static readonly EXPORT_STORE_CODE = 'store_code';
  // 店舗商品ID
  static readonly EXPORT_STORE_PRODUCT_ID = 'store_product_id';
  // 直近30日の販売数の1日平均数（店舗）
  static readonly EXPORT_STORE_30DAY_SALES_DAILY_AVERAGE = 'store_30day_sales_daily_average';
  // 価格
  static readonly EXPORT_PRICE = 'price';
  // 公開ステータス
  static readonly EXPORT_PUBLIC_STATUS = 'public_status';
  // 在庫の更新日時
  static readonly EXPORT_STOCK_UPDATE_DATETIME = 'stock_update_datetime';
  // 価格の更新日時
  static readonly EXPORT_PRICE_UPDATE_DATETIME = 'price_update_datetime';
  // 日本語ラベル
  static readonly EXPORT_LABELS: { [key: string]: string } = {
    [Constants.EXPORT_STOCK_CODE]: '在庫コード',
    [Constants.EXPORT_PRODUCT_NAME]: '商品名',
    [Constants.EXPORT_TAGS]: 'タグ',
    [Constants.EXPORT_INVENTORY_QUANTITY]: '在庫数',
    [Constants.EXPORT_POTENTIAL_INVENTORY]: '潜在在庫',
    [Constants.EXPORT_PENDING_INVENTORY]: '未着在庫',
    [Constants.EXPORT_WEEKLY_REPLENISHMENT_FORECAST]: '1週間の仕入予想数',
    [Constants.EXPORT_WEEKLY_SALES_PERFORMANCE]: '1週間の販売実績',
    [Constants.EXPORT_MONTHLY_REPLENISHMENT_SALES_DIFFERENCE]: '1ヶ月の仕入と販売の差',
    [Constants.EXPORT_DAYS_UNTIL_REPLENISHMENT_STOP]: '仕入れストップまでの日数',
    [Constants.EXPORT_DAYS_UNTIL_REPLENISHMENT_RESUME]: '仕入れ再開までの日数',
    [Constants.EXPORT_DAYS_UNTIL_SOLD_OUT]: '売切れまでの日数',
    [Constants.EXPORT_REQUIRED_REPLENISHMENT]: '要仕入数',
    [Constants.EXPORT_REPLENISHMENT_UPPER_LIMIT]: '仕入の上限',
    [Constants.EXPORT_REPLENISHMENT_LOWER_LIMIT]: '仕入の下限',
    [Constants.EXPORT_30DAY_SALES_DAILY_AVERAGE]: '直近30日の販売数の1日平均数',
    [Constants.EXPORT_14DAY_SALES_TREND]: '直近14日分の販売数の推移',
    [Constants.EXPORT_MALL_PURCHASE_PRICE_RANGE]: '各モールの仕入価格の範囲',
    [Constants.EXPORT_FREEMARKET_PRICE_RANGE]: 'フリマ相場',
    [Constants.EXPORT_FREE_MEMO]: 'フリーメモ',
    [Constants.EXPORT_MEMO_IMAGE]: 'メモ画像',
    [Constants.EXPORT_STOCK_PATTERN]: '在庫パターン',
    [Constants.EXPORT_PRICE_PATTERN]: '価格パターン',
    [Constants.EXPORT_UNIT_INFORMATION]: 'ユニット情報',
    [Constants.EXPORT_UNIT_NAME]: 'ユニット名',
    [Constants.EXPORT_UNIT_INVENTORY_QUANTITY]: 'ユニット在庫数',
    [Constants.EXPORT_STORE_CODE]: '店舗コード',
    [Constants.EXPORT_STORE_PRODUCT_ID]: '店舗商品ID',
    [Constants.EXPORT_STORE_30DAY_SALES_DAILY_AVERAGE]: '直近30日の販売数の1日平均数（店舗）',
    [Constants.EXPORT_PRICE]: '価格',
    [Constants.EXPORT_PUBLIC_STATUS]: '公開ステータス',
    [Constants.EXPORT_STOCK_UPDATE_DATETIME]: '在庫の更新日時',
    [Constants.EXPORT_PRICE_UPDATE_DATETIME]: '価格の更新日時',
  };

  //----------------------
  // ソート条件
  //----------------------
  static readonly SORT_NO_TODAY_SALES = 1;         // 当日販売数
  static readonly SORT_NO_PREVIOUS_DAY_SALES = 2;  // 前日販売数
  static readonly SORT_NO_3DAY_SALES = 3;          // 3 日間販売数
  static readonly SORT_NO_7DAY_SALES = 4;          // 7 日間販売数
  static readonly SORT_NO_AVERAGE_SALES = 5;       // 平均販売実績
  static readonly SORT_NO_DAYS_TO_SOLD_OUT = 6;    // 売切までの日数
  static readonly SORT_NO_DAYS_TO_MINIMUM = 7;     // 下限までの日数
  static readonly SORT_NO_DAYS_TO_MAXIMUM = 8;     // 上限までの日数
  static readonly SORT_NO_1W_PURCHASE = 9;         // 1週間の仕入れ数
  static readonly SORT_NO_PURCHASE_SALES_DIFF = 10; // 仕入販売の差
  static readonly SORT_NO_REQUIRED_PURCHASE = 11;  // 要仕入数
  static readonly SORT_NO_STOCK_QUANTITY = 12;     // 在庫数
  static readonly SORT_NO_POTENTIAL_STOCK = 13;    // 潜在在庫数
  static readonly SORT_NO_PENDING_STOCK = 14;      // 未着在庫数
  static readonly SORT_NO_STOCK_UPDATE_DATE = 15;  // 在庫更新日
  static readonly SORT_NO_PRICE_UPDATE_DATE = 16;  // 価格更新日
  static readonly SORT_LABELS: { [key: number]: string } = {
    [Constants.SORT_NO_TODAY_SALES]: '当日販売数',
    [Constants.SORT_NO_PREVIOUS_DAY_SALES]: '前日販売数',
    [Constants.SORT_NO_3DAY_SALES]: '3日間販売数',
    [Constants.SORT_NO_7DAY_SALES]: '7日間販売数',
    [Constants.SORT_NO_AVERAGE_SALES]: '平均販売実績',
    [Constants.SORT_NO_DAYS_TO_SOLD_OUT]: '売切までの日数',
    [Constants.SORT_NO_DAYS_TO_MINIMUM]: '下限までの日数',
    [Constants.SORT_NO_DAYS_TO_MAXIMUM]: '上限までの日数',
    [Constants.SORT_NO_1W_PURCHASE]: '1週間の仕入れ数',
    [Constants.SORT_NO_PURCHASE_SALES_DIFF]: '仕入販売の差',
    [Constants.SORT_NO_REQUIRED_PURCHASE]: '要仕入数',
    [Constants.SORT_NO_STOCK_QUANTITY]: '在庫数',
    [Constants.SORT_NO_POTENTIAL_STOCK]: '潜在在庫数',
    [Constants.SORT_NO_PENDING_STOCK]: '未着在庫数',
    [Constants.SORT_NO_STOCK_UPDATE_DATE]: '在庫更新日',
    [Constants.SORT_NO_PRICE_UPDATE_DATE]: '価格更新日',
  };
  //----------------------
  // 検索除外項目
  //----------------------
  static readonly EXCLUDE_NO_TOTAL_STOCK_ZERO = 1;     // 合計在庫数「0」
  static readonly EXCLUDE_NO_AVERAGE_SALES_PER_DAY = 2; // 販売数平均/日「---」
  static readonly EXCLUDE_NO_DAYS_TO_MAXIMUM = 3;      // 上限まで「---」
  static readonly EXCLUDE_NO_DAYS_TO_MINIMUM = 4;      // 下限まで「---」
  static readonly EXCLUDE_NO_DAYS_TO_SOLD_OUT = 5;     // 売切まで「---」
  // 検索除外項目ラベルマップ
  static readonly EXCLUDE_LABELS: { [key: number]: string } = {
    [Constants.EXCLUDE_NO_TOTAL_STOCK_ZERO]: '合計在庫数「0」',
    [Constants.EXCLUDE_NO_AVERAGE_SALES_PER_DAY]: '販売数平均/日「---」',
    [Constants.EXCLUDE_NO_DAYS_TO_MAXIMUM]: '上限まで「---」',
    [Constants.EXCLUDE_NO_DAYS_TO_MINIMUM]: '下限まで「---」',
    [Constants.EXCLUDE_NO_DAYS_TO_SOLD_OUT]: '売切まで「---」',
  };

  //----------------------
  // ユーザータイプ
  //----------------------
  static readonly USER_TYPE_ADMIN = 1;
  static readonly USER_TYPE_USER = 2;
  static readonly USER_TYPE_OTHER = 3;
  static readonly USER_TYPE_LABELS: { [key: number]: string } = {
    [Constants.USER_TYPE_ADMIN]: '管理者',
    [Constants.USER_TYPE_USER]: 'ユーザー',
    [Constants.USER_TYPE_OTHER]: 'その他',
  };

  //----------------------
  // SKU初期表示
  //----------------------
  static readonly SKU_INITIAL_SALES = 1;
  static readonly SKU_INITIAL_UNRECEIVED = 2;
  static readonly SKU_INITIAL_LABELS: { [key: number]: string } = {
    [Constants.SKU_INITIAL_SALES]: '販売情報',
    [Constants.SKU_INITIAL_UNRECEIVED]: '仕入情報',
  };

  //----------------------
  // 特定日
  //----------------------
  static readonly SPECIAL_DATE_1 = 1;
  static readonly SPECIAL_DATE_2 = 2;
  static readonly SPECIAL_DATE_3 = 3;
  static readonly specialDateLabels: Record<number, string> = {
    [Constants.SPECIAL_DATE_1]: '特定日①',
    [Constants.SPECIAL_DATE_2]: '特定日②',
    [Constants.SPECIAL_DATE_3]: '特定日③',
  };

  //----------------------
  // キーワード検索対象
  //----------------------
  static readonly SEARCH_TARGET_PRODUCT_NAME = 1; // 商品名
  static readonly SEARCH_TARGET_MEMO = 2;    // メモ欄
  static readonly SEARCH_TARGET_LABELS: { [key: number]: string } = {
    [Constants.SEARCH_TARGET_PRODUCT_NAME]: '商品名',
    [Constants.SEARCH_TARGET_MEMO]: 'メモ欄',
  };

  //----------------------
  // デフォルトのカレンダーカラーアイテム
  //----------------------
  static readonly defaultCalendarColorItems: CalendarColorItem[] = [
    new CalendarColorItem({ number: 1, color: "#ffbcbc", displayName: "赤" }),
    new CalendarColorItem({ number: 2, color: "#eebbcb", displayName: "ピンク" }),
    new CalendarColorItem({ number: 3, color: "#b79fcb", displayName: "紫" }),
    new CalendarColorItem({ number: 4, color: "#bcffff", displayName: "水色" }),
    new CalendarColorItem({ number: 5, color: "#ffddbc", displayName: "オレンジ" }),
    new CalendarColorItem({ number: 6, color: "#a3d1ff", displayName: "青" }),
    new CalendarColorItem({ number: 7, color: "#ffffa3", displayName: "黄" }),
    new CalendarColorItem({ number: 8, color: "#bcffbc", displayName: "緑" }),
    new CalendarColorItem({ number: 9, color: "#eedcb3", displayName: "茶色" }),
    new CalendarColorItem({ number: 10, color: "#9079b6", displayName: "青紫" }),
    new CalendarColorItem({ number: 11, color: "grey", displayName: "灰色" }),
  ];

  //----------------------
  // チャット
  //----------------------
  static readonly CHAT_REACTION: { [key: number]: string } = {
    1: '🫡',
    2: '️🙇‍♂️',
    3: '🎉',
    4: '🙌️',
    5: '👏',
    6: '👍',
    7: '⁉️',
    8: '⭐',
    9: '🤚',
    10: '👌',
    11: '😁',
  };
  static readonly CHAT_REACTION_LABELS: { [key: number]: string } = {
    1: '了解',
    2: 'ありがとう',
    3: 'おめでとう',
    4: 'わーい',
    5: 'すごい',
    6: 'いいね',
    7: 'びっくり',
    8: 'スター',
    9: '待ってね',
    10: 'オッケー',
    11: 'にっこり',
  };
  static readonly CHAT_REACTION_FONT_SIZE: string = '20px';
  static readonly CHAT_MENTION_TYPE_MENTION = 1; // メンション
  static readonly CHAT_MENTION_TYPE_REPLY = 2;   // 返信


  //----------------------
  // 価格設定タイプ
  //----------------------
  static readonly PRICE_SETTING_TYPE_NONE = 0; // 価格パターン項目なし
  static readonly PRICE_SETTING_TYPE_BASE = 1; // 価格パターンあり（基準）
  static readonly PRICE_SETTING_TYPE_TARGET = 2; // 価格パターン項目あり（連動対象）

  //----------------------
  // フリーメモタイプ
  //----------------------
  static readonly FREE_MEMO_TYPE_NORMAL = 1; // 通常の画像メモ
  static readonly FREE_MEMO_TYPE_UNRECEIVED_LEFT = 2; // 仕入情報左側の画像メモ
  static readonly FREE_MEMO_TYPE_UNRECEIVED_RIGHT = 3; // 仕入情報右側の画像メモ

  //----------------------
  // 一括処理タイプ
  //----------------------
  static readonly BULK_ACTION_TYPE_SHOW_PRODUCT = 1; // 一括公開
  static readonly  BULK_ACTION_TYPE_HIDE_PRODUCT = 2; // 一括非公開
  static readonly  BULK_ACTION_TYPE_DELETE_PRODUCT = 3; // 一括削除
  static readonly  BULK_ACTION_TYPE_LABELS: { [key: number]: string } = {
    [Constants.BULK_ACTION_TYPE_SHOW_PRODUCT]: '一括公開',
    [Constants.BULK_ACTION_TYPE_HIDE_PRODUCT]: '一括非公開',
    [Constants.BULK_ACTION_TYPE_DELETE_PRODUCT]: '一括削除',
  };

  //----------------------
  // 一括処理最大件数
  //----------------------
  static readonly BULK_ACTION_SHOW_HIDE_PRODUCT_MAX_COUNT = 10; // 一括公開・非公開最大件数
  static readonly BULK_ACTION_DELETE_PRODUCT_MAX_COUNT = 10; // 一括削除最大件数
}
