import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Toolbar,
  Typography
} from "@mui/material";
import React, {useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import ECProgress from "../../Common/Components/ECProgress";
import {Constants} from "../../../Common/Constants";


interface ProductSyncDialogProps {
  open: boolean;
  storeId: number;
  onOK: (stockCode: string) => void;
  onClose: () => void;
}

/**
 * 個別商品同期ダイアログ(
 * @param props
 * @constructor
 */
export const ProductSyncDialog = (props: ProductSyncDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    stockCode: '' as string,
    processing: false,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 実行ボタンクリック時
   */
  const handleExecute = () => {
    // ここでSaleServiceの処理を追加
    if ( !state.stockCode || state.stockCode.length !== Constants.STOCK_CODE_LENGTH ) {
      alert('正しい在庫コードを入力してください。');
      return;
    }
    props.onOK(state.stockCode);
  };

  /**
   * 描画
   */
  return (
      <>
        <Dialog open={props.open} onClose={props.onClose}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                個別商品同期ダイアログ
              </Typography>
              <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Typography variant={"body2"}>
              新規または既存の商品を1件同期します。
            </Typography>

            <TextField
              fullWidth
              margin="normal"
              label="在庫コード"
              value={state.stockCode}
              onChange={(e) => {
                if (/^[a-zA-Z0-9-]*$/.test(e.target.value)) {
                  updateState({ stockCode: e.target.value });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleExecute();
                }
              }}
            />

            <DialogActions>
              <Button variant="contained" color="primary" onClick={handleExecute}>
                実行
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {/* プログレス表示 */}
        <ECProgress open={state.processing}></ECProgress>
      </>
  );
};
