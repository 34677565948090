import {AppBar, Box, IconButton, Typography} from '@mui/material';
import {ChatPageParam} from "../../../../../Models/Chat/ChatPageParam";
import {ChatView} from "../../../../Chat/ChatView";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import {EventService} from "../../../../../Services/System/EventService";
import {ChatService} from "../../../../../Services/ChatService";

interface ChatPanelProps {
  chatPageParam: ChatPageParam;
}

/**
 * チャットパネル
 * @param props
 * @constructor
 */
export const ChatPanel = (props: ChatPanelProps) => {

  /**
   * 新しいタブで開く
   */
  const handleOpenInNewTab = () => {
    const url = `/chat?${props.chatPageParam.toQueryString()}`;
    window.open(url, '_blank');
  };

  /**
   * 閉じる
   */
  const handleClose = () => {
    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_CLOSE);
  };


  /**
   * メッセージジャンプ
   * @param messageId
   */
  const reload = async (messageId: number) => {
    const message = await ChatService.getInstance().getMessage(messageId);
    if (message == null) {
      return;
    }
    const chatPageParam = new ChatPageParam({
      stockCode: message.stock_code,
      messageId: messageId,
    });
    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_OPEN, chatPageParam);
  }

  /**
   * 描画
   */
  return (
    <Box
      sx={{
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.19)',
        minWidth: '500px',
      }}
      display={"flex"}
      flexDirection={"column"}
    >
      <AppBar
        position="static"
        color={"secondary"}
        sx={{
          pl: 2, pr: 2,
          borderRadius: '10px 10px 0 0',
          height: '34px',
        }}
      >
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            flexGrow={1}
          >
            <Typography>
              {"チャット - " + props.chatPageParam.stockCode}
            </Typography>
            <Box flexGrow={1} />
            <IconButton onClick={handleOpenInNewTab} color={'inherit'}>
              <OpenInNewIcon />
            </IconButton>
            <IconButton onClick={handleClose} color={'inherit'}>
              <CloseIcon />
            </IconButton>
          </Box>
      </AppBar>

      {/* チャットビュー */}
      <Box pl={1} pr={1}>
        <ChatView
          chatPageParam={props.chatPageParam}
          bubbleWidth={300}
          height={'calc(98vh - 100px)'}
          onReloadPage={reload}
        />
      </Box>
    </Box>
  );
}

