import { LogUtils } from "../Common/LogUtils";
import { HttpServiceBase } from "./HttpServiceBase";
import { EcmgApiError } from "../Common/EcmgApiError";
import { User } from "../Models/User";
import {EventService} from "./System/EventService";
import {LoginService} from "./LoginService";

/**
 * ユーザーサービス
 */
export class UserService extends HttpServiceBase {
  private static instance: UserService = new UserService();
  private userMap: Map<number, User> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): UserService {
    return this.instance;
  }

  async init(): Promise<void> {
    LogUtils.d("Initializing UserService");
    await this._loadUsers();
  }

  /**
   * ユーザーデータ読み込み
   * @private
   */
  private async _loadUsers(): Promise<void> {
    try {
      const url = "api/users";
      const axios = await this.getAxios();

      const response = await axios.get(url, this.makeAuthorizeOption());
      LogUtils.d(response.toString());

      this.setData(response.data);

    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * データ設定
   * @param data
   */
  public setData(data: any): void {
    this.userMap.clear();
    if (data && Array.isArray(data)) {
      data.forEach(userData => {
        const user = User.fromMap(userData);
        if (user.id !== undefined) {
          this.userMap.set(user.id, user);
        }
      });
    }
  }

  /**
   * ユーザーデータ取得
   * @param id
   */
  getUserById(id: number): User | undefined {
    return this.userMap.get(id);
  }

  /**
   * ユーザー名取得
   * @param id
   */
  getUserName(id: number): string | undefined {
    return this.userMap.get(id)?.name;
  }

  /**
   * ユーザー色取得
   * @param id
   */
  getUserColor(id: number): string | undefined {
    return this.userMap.get(id)?.color;
  }

  /**
   * すべてのユーザーを取得
   */
  getAllUsers(): User[] {
    return Array.from(this.userMap.values());
  }

  /**
   * ユーザーデータ更新
   * @param user
   */
  public async updateUser(user: User): Promise<string> {
    try {

      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/users/${user.id}`,
        user,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      await this._loadUsers();

      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_USERS);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * ユーザー追加
   * @param user
   */
  public async addUser(user: User): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        'api/users',
        user,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());
      await this._loadUsers();

      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_USERS);

      return response.data.message;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * ユーザー削除
   * @param userId
   */
  public async deleteUser(userId: number): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.delete(
        `api/users/${userId}`,
        this.makeAuthorizeOption()
      );

      LogUtils.d(`Deleted user with ID ${userId}`);
      await this._loadUsers();

      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_USERS);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 事業所IDからユーザーを取得
   * @param officeId
   * @param withOutMe
   */
  public getUsersByOfficeId(officeId: number, withOutMe: boolean): User[] {
    const loginUserId = LoginService.getInstance().loginUser?.id ?? 0;
    return this.getAllUsers().filter(user =>
      user.office_id === officeId
      &&
      (withOutMe ? user.id !== loginUserId : true)
    );
  }

}
