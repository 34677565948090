import React from 'react';
import {Box, Divider} from "@mui/material";
import {UnreceivedStockCount} from "./UnreceivedStockCount";
import {SearchResult} from "../../../../../Models/SearchResult";
import {AddUnreceivedStock} from "./AddUnreceivedStock";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {USADigitCommon} from "./USADigitCommon";

interface UnreceivedStockAreaProps {
  searchResult: SearchResult;
  height: number;
  width: number;
}

/**
 * 未着在庫関連エリア
 * @param props
 * @constructor
 */
export const UnreceivedStockArea = (props: UnreceivedStockAreaProps) => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
    height: props.height,
    width: props.width,
    backgroundColor: MainPageConstants.COLOR_LIGHT_GREEN,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    gap: 2,
  };

  /**
   * 四捨五入
   * @param value
   */
  const round = (value: number | undefined) => {
    return value != null ? Math.round(value!) : value;
  }

  /**
   * 描画
   */
  return (
    <Box sx={BOX_STYLE}>
      {/* 未着在庫数 */}
      <UnreceivedStockCount product={props.searchResult.product} />
      {/* 仕入れ追加 */}
      <AddUnreceivedStock product={props.searchResult.product} />

      <Divider orientation={"vertical"} />

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/* 1w仕入れ */}
        <USADigitCommon leftLabel={'1w仕入'} rightLabel={'個'} value={props.searchResult.freeMarketTotalQuantityUpper} />
        {/* 1w販売数 */}
        <USADigitCommon leftLabel={'1w販売'} rightLabel={'個'} value={
          props.searchResult.orderCountData.oneWSales == null ? 0 : Math.round(props.searchResult.orderCountData.oneWSales)
        } />
        {/* 月入売差 */}
        <USADigitCommon leftLabel={'月入売差'} rightLabel={'個'} value={round(props.searchResult.stockDiff)} />
      </Box>

      <Divider orientation={"vertical"} />

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/* 上限まで */}
        <USADigitCommon leftLabel={'上限まで'} rightLabel={'日'} value={round(props.searchResult.procurementStop)} />
        {/* 下限まで */}
        <USADigitCommon leftLabel={'下限まで'} rightLabel={'日'} value={round(props.searchResult.procurementStart)} />
        {/* 売切まで */}
        <USADigitCommon leftLabel={'売切まで'} rightLabel={'日'} value={round(props.searchResult.daysUntilSoldOut)} />
      </Box>

    </Box>
  );
}
