// 販売推移データのインターフェース定義

export interface SalesSummary {
  [key: string]: {
    total: number;
    average: number;
  };
}

export interface SalesDailyHistory {
  week?: number;
  date: string;
  sales: number;
  received?: number;
  unreceived?: number;
  stock?: number;
  y_sales?: number;
  r_sales?: number;
  y_price?: number;
  r_price?: number;
  one_w_sales?: number;
  potential_stock?: number;
}

export interface SalesWeeklyHistory {
  week: string;
  sales: number;
  received?: number;
  unreceived?: number;
  stock?: number;
  y_sales?: number;
  r_sales?: number;
  y_price?: number;
  r_price?: number;
}

export interface SalesMonthlyHistory {
  year_month: string;
  sales: number;
  received?: number;
  unreceived?: number;
  stock?: number;
  y_sales?: number;
  r_sales?: number;
  y_price?: number;
  r_price?: number;
}

/**
 * 販売推移データ
 */
export class SalesData {
  sales_summary: SalesSummary = {};
  sales_daily_history: SalesDailyHistory[] = [];
  sales_weekly_history: SalesWeeklyHistory[] = [];
  sales_monthly_history: SalesMonthlyHistory[] = [];

  constructor(params: Partial<SalesData> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<SalesData>): SalesData {
    return new SalesData({
      ...this,
      ...params
    });
  }

  static fromMap(map: any): SalesData {
    return new SalesData({
      sales_summary: map['sales_summary'],
      sales_daily_history: map['sales_daily_history'].map((item: any) => ({
        week: item['week'],
        date: item['date'],
        sales: item['sales'],
        received: item['received'],
        unreceived: item['unreceived'],
        stock: item['stock'],
        y_sales: item['y_sales'],
        r_sales: item['r_sales'],
        y_price: item['y_price'],
        r_price: item['r_price'],
        one_w_sales: item['one_w_sales'],
        potential_stock: item['potential_stock']
      })),
      sales_weekly_history: map['sales_weekly_history'].map((item: any) => ({
        week: item['week'],
        sales: item['sales'],
        received: item['received'],
        unreceived: item['unreceived'],
        stock: item['stock'],
        y_sales: item['y_sales'],
        r_sales: item['r_sales'],
        y_price: item['y_price'],
        r_price: item['r_price']
      })),
      sales_monthly_history: map['sales_monthly_history'].map((item: any) => ({
        year_month: item['year_month'],
        sales: item['sales'],
        received: item['received'],
        unreceived: item['unreceived'],
        stock: item['stock'],
        y_sales: item['y_sales'],
        r_sales: item['r_sales'],
        y_price: item['y_price'],
        r_price: item['r_price']
      }))
    });
  }

  // JSON文字列からSalesDataオブジェクトを生成するスタティックメソッド
  static fromJson(jsonString: string): SalesData {
    const decoded = JSON.parse(jsonString);
    return SalesData.fromMap(decoded);
  }
}

/**
 * グラフデータ
 */
export class SalesGraphData {
  labels: string[] = [];
  yahooSales: number[] = [];
  rakutenSales: number[] = [];
  baseSales: number[] = [];

  constructor(params: Partial<SalesGraphData> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<SalesGraphData>): SalesGraphData {
    return new SalesGraphData({
      ...this,
      ...params
    });
  }

  static fromMap(map: any): SalesGraphData {
    return new SalesGraphData({
      labels: map['labels'],
      yahooSales: map['yahooSales'],
      rakutenSales: map['rakutenSales'],
      baseSales: map['baseSales']
    });
  }

  toMap(): { [key: string]: any } {
    return {
      labels: this.labels,
      yahooSales: this.yahooSales,
      rakutenSales: this.rakutenSales,
      baseSales: this.baseSales
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  // JSON文字列からSalesGraphDataオブジェクトを生成するスタティックメソッド
  static fromJson(jsonString: string): SalesGraphData {
    const decoded = JSON.parse(jsonString);
    return SalesGraphData.fromMap(decoded);
  }
}

/**
 * グラフデータ条件
 */
export class SalesChartCondition {
  startDate: string | undefined;
  endDate: string | undefined;
  startMonth: string | undefined;
  endMonth: string | undefined;
  isDaily: boolean = true;

  constructor(params: Partial<SalesChartCondition> = {}) {
    Object.assign(this, params);
  }

  // パラメータを更新するためのメソッド（オプショナル）
  copyWith(params: Partial<SalesChartCondition>): SalesChartCondition {
    return new SalesChartCondition({
      ...this,
      ...params
    });
  }

  // オブジェクトをマップ（オブジェクトリテラル）に変換
  toMap(): { [key: string]: any } {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      startMonth: this.startMonth,
      endMonth: this.endMonth,
      isDaily: this.isDaily
    };
  }

  // オブジェクトをJSON文字列に変換
  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  // マップ（オブジェクトリテラル）からオブジェクトを生成
  static fromMap(map: any): SalesChartCondition {
    return new SalesChartCondition({
      startDate: map['startDate'],
      endDate: map['endDate'],
      startMonth: map['startMonth'],
      endMonth: map['endMonth'],
      isDaily: map['isDaily']
    });
  }

  // JSON文字列からオブジェクトを生成
  static fromJson(jsonString: string): SalesChartCondition {
    const decoded = JSON.parse(jsonString);
    return SalesChartCondition.fromMap(decoded);
  }
}


