import React from 'react';
import axios from 'axios';

function TestPage() {
  const handleRakutenProductList = async () => {
    window.location.href = "/rakuten/product-list";
  };
  const handleYahooLogin = async () => {
    window.location.href = "/auth/mall?mall_no=2&store_id=2";
  };
  const handleYahooProductList = async () => {
    window.location.href = "/yahoo/product-list";
  };

  const handleBASELogin = async () => {
    window.location.href = "/auth/mall?mall_no=3&store_id=3";
  };
  const handleBASEProductList = async () => {
    window.location.href = "/base/product-list";
  };

  const handleBASELogin2 = async () => {
    window.location.href = "/auth/mall?mall_no=3&store_id=4";
  };
  const handleBASEProductList2 = async () => {
    window.location.href = "/base/product-list2";
  };

  return (
    <>
      <div>
        <button onClick={handleRakutenProductList}>Product List Rakuten</button>
      </div>
      <div>
        <button onClick={handleYahooLogin}>Login with Yahoo</button>
        <button onClick={handleYahooProductList}>Product List Yahoo</button>
      </div>
      <div>
        <button onClick={handleBASELogin}>Login with BASE(ValueStore)</button>
        <button onClick={handleBASEProductList}>Product List BASE(ValueStore)</button>
      </div>
      <div>
        <button onClick={handleBASELogin2}>Login with BASE(E-LOHAS)</button>
        <button onClick={handleBASEProductList2}>Product List BASE(E-LOHAS)</button>
      </div>
    </>
  );
}

export default TestPage;
