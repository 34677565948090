/**
 * 店舗
 */
export class Store {
  id?: number;
  mall_no?: number;
  mall_store_id?: string;
  store_name?: string;
  store_code?: string;
  client_id?: string;
  client_secret?: string;
  rkt_service_secret?: string;
  rkt_license_key?: string;
  rkt_reg_date?: Date;
  rkt_shop_url?: string;
  yahoo_pub_key?: string;
  yahoo_pub_key_ver?: number;
  yahoo_reg_date?: Date;
  base_shop_url?: string;
  access_token?: string;
  refresh_token?: string;
  expires_at?: Date;
  sync_flag?: number;
  reauth_required?: number;
  error_flag?: number;
  check_interval_n?: number;
  check_interval_e?: number;
  last_check_timestamp?: Date;
  product_sync_flg?: number;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<Store>) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<Store>): Store {
    return new Store({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): Store {
    return new Store({
      ...json,
      rkt_reg_date: json.rkt_reg_date ? new Date(json.rkt_reg_date) : undefined,
      yahoo_reg_date: json.yahoo_reg_date ? new Date(json.yahoo_reg_date) : undefined,
      expires_at: json.expires_at ? new Date(json.expires_at) : undefined,
      last_check_timestamp: json.last_check_timestamp ? new Date(json.last_check_timestamp) : undefined,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): Store {
    const decoded = JSON.parse(jsonString);
    return Store.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      rkt_reg_date: this.rkt_reg_date ? this.rkt_reg_date.toISOString() : undefined,
      yahoo_reg_date: this.yahoo_reg_date ? this.yahoo_reg_date.toISOString() : undefined,
      expires_at: this.expires_at ? this.expires_at.toISOString() : undefined,
      last_check_timestamp: this.last_check_timestamp ? this.last_check_timestamp.toISOString() : undefined,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  // こちらはDataGridの値として適切な項目を選択していますが、変更する必要がある場合があります。
  getDataGridValues(): string[] {
    return [
      this.store_name || '',
      this.store_code || '',
      this.mall_store_id || '',
      this.mall_no?.toString() || '',
    ];
  }
}
