import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider
} from '@mui/material';
import { SystemSettingService } from "../../../Services/SystemSettingService";
import { EMTextField } from "../../Common/Components/EMTextField";
import {SystemSetting} from "../../../Models/SystemSetting";
import {CalendarColorItem} from "../../../Models/Calendar/CalendarColorItem";
import {useSnackbar} from "../../Common/Provider/SnackbarContext";
import {LogUtils} from "../../../Common/LogUtils";
import {EcmgApiError} from "../../../Common/EcmgApiError";
import {CommonUtils} from "../../../Common/CommonUtils";
import ECProgress from "../../Common/Components/ECProgress";

/**
 * カレンダー設定
 * @constructor
 */
export const CalendarSetting = () => {
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
  };

  const [state, setState] = useState({
    calendarNameMap: {} as Record<string, SystemSetting | undefined>,
    calendarNameChanged: false,
    calendarColorDisplayNameChanged: false,
    calendarItems: [] as CalendarColorItem[],
    processing: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * 初期化
   */
  useEffect(() => {
    const service = SystemSettingService.getInstance();
    const calendarAName = service.getSettingByKey(SystemSetting.KEY_CALENDAR_NAME_A);
    const calendarBName = service.getSettingByKey(SystemSetting.KEY_CALENDAR_NAME_B);
    const calendarItems = service.getCalendarColorItems();

    updateState({
      calendarNameMap: {
        [SystemSetting.KEY_CALENDAR_NAME_A]: calendarAName,
        [SystemSetting.KEY_CALENDAR_NAME_B]: calendarBName,
      },
      calendarItems,
    });
  }, []);

  /**
   * カレンダー名変更
   * @param key
   * @param value
   */
  const handleCalendarNameChange = (key: string, value: string) => {
    let setting = state.calendarNameMap[key];
    if (!setting) {
      setting = new SystemSetting({key, value});
    }
    else {
      setting.value = value;
    }
    updateState({
      calendarNameMap: { ...state.calendarNameMap, [key]: setting },
      calendarNameChanged: true,
    });
    SystemSettingService.getInstance().updateSetting(setting).then();
  };

  /**
   * カレンダー名確定
   * @param key
   */
  const handleCalendarNameConfirm = async (key: string) => {

    if ( !state.calendarNameChanged ) {
      return;
    }

    let setting = state.calendarNameMap[key];
    if (!setting) {
      return;
    }

    let message = 'カレンダー名を更新しました。';

    try {
      updateState({ processing: true });

      await SystemSettingService.getInstance().updateSetting(setting);

      updateState({ processing: false, calendarNameChanged: false });

    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = 'カレンダー名設定処理でエラーが発生しました。';
      }
    }
    // スナックバー表示
    showSnackbarMessage(message);
  }

  /**
   * カレンダー色アイテム表示名変更
   * @param number
   * @param displayName
   */
  const handleCalendarItemDisplayNameChange = async (number: number, displayName: string) => {
    const updatedItems = state.calendarItems.map(item =>
      item.number === number ? new CalendarColorItem({ ...item, displayName }) : item
    );
    updateState({ calendarItems: updatedItems, calendarColorDisplayNameChanged: true });
  };


  /**
   * カレンダー色表示名確定
   * @param number
   */
  const handleCalendarItemDisplayNameConfirm = async (number: number) => {

    if ( !state.calendarColorDisplayNameChanged ) {
      return;
    }

    const itemToUpdate = state.calendarItems.find(item => item.number === number);
    if (!itemToUpdate) {
      return;
    }

    let message = 'カレンダー色表示名を更新しました。';

    try {
      updateState({ processing: true });

      await SystemSettingService.getInstance().updateCalendarColorItem(itemToUpdate!);

      updateState({ processing: false, calendarColorDisplayNameChanged: false });

    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = 'カレンダー色表示名設定処理でエラーが発生しました。';
      }
    }
    // スナックバー表示
    showSnackbarMessage(message);
  }

  /**
   * 描画
   */
  return (
    <Box sx={BOX_STYLE} mt={2}>
      <Typography>カレンダー設定</Typography>

      <Box mt={2} sx={{width: 800}}>
        <Box sx={{fontWeight: "bold"}}>カレンダー名 : </Box>
        <Box mt={2} display={"flex"} flexDirection={"row"}>
          <EMTextField
            label="カレンダーA"
            value={state.calendarNameMap[SystemSetting.KEY_CALENDAR_NAME_A]?.value || ''}
            onChange={(value) => handleCalendarNameChange(SystemSetting.KEY_CALENDAR_NAME_A, value)}
            onEnterKeyDown={() => {handleCalendarNameConfirm(SystemSetting.KEY_CALENDAR_NAME_A).then()}}
            onBlur={() => {handleCalendarNameConfirm(SystemSetting.KEY_CALENDAR_NAME_A).then()}}
          />
          <Box ml={1} />
          <EMTextField
            label="カレンダーB"
            value={state.calendarNameMap[SystemSetting.KEY_CALENDAR_NAME_B]?.value || ''}
            onChange={(value) => handleCalendarNameChange(SystemSetting.KEY_CALENDAR_NAME_B, value)}
            onEnterKeyDown={() => {handleCalendarNameConfirm(SystemSetting.KEY_CALENDAR_NAME_B).then()}}
            onBlur={() => {handleCalendarNameConfirm(SystemSetting.KEY_CALENDAR_NAME_B).then()}}
          />
        </Box>
      </Box>
      <Box mt={2} />
      <Divider />
      <Box mt={1} />

      <Box mt={2}>
        <Box sx={{fontWeight: "bold"}}>カレンダー色 : </Box>
        <TableContainer style={{ maxHeight: 400, overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>色</TableCell>
                <TableCell>表示名</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.calendarItems.map((item) => (
                <TableRow key={item.number}>
                  <TableCell>{item.number}</TableCell>
                  <TableCell><Box bgcolor={item.color} width="20px" height="20px" /></TableCell>
                  <TableCell>
                    <EMTextField
                      label={'表示名'}
                      value={item.displayName || ''}
                      onChange={(value) => handleCalendarItemDisplayNameChange(item.number!, value)}
                      onEnterKeyDown={() => handleCalendarItemDisplayNameConfirm(item.number!)}
                      onBlur={() => handleCalendarItemDisplayNameConfirm(item.number!)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* プログレス */}
      <ECProgress open={state.processing}></ECProgress>
    </Box>
  );
};
