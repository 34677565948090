import {Constants} from "../Common/Constants";

/**
 * ユーザー
 */
export class EcmgUser {
  id?: number;
  name?: string;
  email?: string;
  password?: string;
  user_type?: number;

  constructor(params: {
    id?: number,
    name?: string,
    email?: string,
    password?: string,
    user_type?: number,
  }) {
    this.id = params.id;
    this.name = params.name;
    this.email = params.email;
    this.password = params.password;
    this.user_type = params.user_type;
  }

  copyWith(params: {
    id?: number,
    name?: string,
    email?: string,
    password?: string,
    user_type?: number,
  }) {
    return new EcmgUser({
      id: params.id || this.id,
      name: params.name || this.name,
      email: params.email || this.email,
      password: params.password || this.password,
      user_type: params.user_type || this.user_type,
    });
  }

  static fromMap(json: { [key: string]: any }): EcmgUser {
    return new EcmgUser({
      id: json['id'],
      name: json['name'],
      email: json['email'],
      password: json['password'],
      user_type: json['user_type'],
    });
  }

  static fromJson(jsonString: string): EcmgUser {
    const decoded = JSON.parse(jsonString);
    return EcmgUser.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      'id': this.id,
      'name': this.name,
      'email': this.email,
      'password': this.password,
      'user_type': this.user_type,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  isAdmin(): boolean {
    return this.user_type === Constants.USER_TYPE_ADMIN;
  }

  isUser(): boolean {
    return this.user_type === Constants.USER_TYPE_USER;
  }

  isOther(): boolean {
    return this.user_type === Constants.USER_TYPE_OTHER;
  }

  isUserOrAdmin(): boolean {
    return this.isUser() || this.isAdmin();
  }


  getDataGridValues(): string[] {
    return [
      this.name || '',
      this.email || '',
    ];
  }
}
