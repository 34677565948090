import { LogUtils } from "../Common/LogUtils";
import { HttpServiceBase } from "./HttpServiceBase";
import { EcmgApiError } from "../Common/EcmgApiError";
import {SalesData, SalesGraphData} from "../Models/SalesData/SalesData";

/**
 * 店舗注文サービス
 */
export class SalesService extends HttpServiceBase {
  private static instance: SalesService = new SalesService();

  private constructor() {
    super();
  }

  static getInstance(): SalesService {
    return this.instance;
  }

  async init(): Promise<void> {
  }

  /**
   * 個別注文同期
   * @param storeId
   * @param mallOrderId
   */
  public async syncOrder(storeId: number, mallOrderId: string): Promise<string> {
    LogUtils.d("Starting order synchronization");

    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/sync-order`,
        {
          store_id: storeId,
          mall_order_id: mallOrderId
        },
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
  /**
   * 販売推移データ取得
   * @param stockCode
   * @param mallNo
   * @param mallProductId
   */
  public async getSalesData(stockCode: string, mallNo?: number, mallProductId?: string): Promise<SalesData> {
    LogUtils.d("Starting sales data synchronization");

    try {
      const axios = await this.getAxios(false);
      const response = await axios.get(
        `api/sales-summary/${stockCode}?mall_no=${mallNo ?? ""}&mall_product_id=${mallProductId ?? ""}`,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.data);

      return SalesData.fromMap(response.data);

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
  /**
   * 特定期間販売データ取得
   * @param stockCode
   * @param startDate
   * @param endDate
   * @param mallNo
   * @param mallProductId
   *
   * @returns total: number, average: number
   */
  public async getSpecificSalesData(
    stockCode: string,
    startDate: Date,
    endDate: Date,
    mallNo?: number,
    mallProductId?: string,
    ): Promise<{
      total?: number,
      average?: number,
    }> {

    try {
      const axios = await this.getAxios(false);
      const response = await axios.get(
        `api/specific-sales-summary/${stockCode}?
          mall_no=${mallNo ?? ""}
          &mall_product_id=${mallProductId ?? ""}
          &start_date=${startDate.toISOString()}
          &end_date=${endDate.toISOString()}`,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.data);

      return response.data;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 日次グラフデータ取得
   * @param startDate 開始日
   * @param endDate 終了日
   * @param stockCode 在庫コード
   */
  public async getDailyGraphData(stockCode: string, startDate: string, endDate: string): Promise<SalesGraphData> {
    LogUtils.d("Fetching daily graph data");

    try {
      const axios = await this.getAxios(false);
      const response = await axios.get(
        `api/sales-daily-graph?stock_code=${stockCode}&start_date=${startDate}&end_date=${endDate}`,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.data);

      return SalesGraphData.fromMap(response.data);

    } catch (e) {
      LogUtils.ex(e);
      throw new Error("Failed to fetch daily graph data");
    }
  }

  /**
   * 月次グラフデータ取得
   * @param startYearMonth 開始年月 (YY/MM)
   * @param endYearMonth 終了年月 (YY/MM)
   * @param stockCode 在庫コード
   */
  public async getMonthlyGraphData(stockCode: string, startYearMonth: string, endYearMonth: string): Promise<SalesGraphData> {
    LogUtils.d("Fetching monthly graph data");

    try {
      const axios = await this.getAxios(false);
      const response = await axios.get(
        `api/sales-monthly-graph?stock_code=${stockCode}&start_year_month=${startYearMonth}&end_year_month=${endYearMonth}`,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.data);

      return SalesGraphData.fromMap(response.data);

    } catch (e) {
      LogUtils.ex(e);
      throw new Error("Failed to fetch monthly graph data");
    }
  }



}
