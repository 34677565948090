import React from 'react';
import {Backdrop, CircularProgress} from "@mui/material";


interface CircularProgressOption {
  open: boolean;
}
/**
 * プログレス表示
 * @param props
 * @constructor
 */
const ECProgress = (props: CircularProgressOption) => {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.tooltip + 1 }} // zIndexをTooltipより上にする（とにかく高い値に）
        open={props.open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
};
export default ECProgress;
