// ChatActionPanel.tsx
import React, {useEffect, useRef, useState} from 'react';
import { IconButton, Box } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import EditIcon from '@mui/icons-material/Edit';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import DeleteIcon from '@mui/icons-material/Delete';
import {ChatMessage} from "../../../../Models/Chat/ChatMessage";
import {EventService} from "../../../../Services/System/EventService";
import {UserService} from "../../../../Services/UserService";
import {ChatReactionSelect} from "./ChatReactionSelect";
import {ReactionSelectPanelVisibilityService} from "./ReactionSelectPanelVisibilityService";

interface ChatActionPanelProps {
  visible: boolean;
  message: ChatMessage;
}

/**
 * チャットアクションパネル
 * @param props
 * @constructor
 */
export const ChatActionPanel = (props: ChatActionPanelProps) => {
  // リアクション選択パネル表示サービス
  const reactionSelectVisibilityService = ReactionSelectPanelVisibilityService.getInstance();
  // リアクションボタンRef
  const buttonRef = useRef<HTMLButtonElement>(null);

  /**
   * useEffect
   */
  useEffect(() => {
    reactionSelectVisibilityService.subscribe(updateReactionSelectVisibility);

    return () => {
      reactionSelectVisibilityService.unsubscribe(updateReactionSelectVisibility);
    };
  }, []);

  /**
   * リアクション選択パネルの表示更新イベント
   */
  const updateReactionSelectVisibility = () => {
    updateState({});
  }

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 自分のメッセージか？
   */
  const isMyMessage = props.message.isMyMessage();

  /**
   * 返信
   */
  const onReply = () => {
    let message = "[rp aid=" + props.message.id + " uid=" + props.message.user_id + "]";
    if ( props.message.user_id != null ) {
      message += " " + UserService.getInstance().getUserName(props.message.user_id) + 'さん\n';
    }
    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_MESSAGE_INSERT, message);
  }

  /**
   * 編集
   */
  const onEdit = () => {
    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_MESSAGE_REPLACE, props.message.message); // 入力欄置き換え
    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_MESSAGE_INSERT, ""); // フォーカスを移すため
    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_EDIT, props.message.id); // 編集モード
  }

  /**
   * 削除
   */
  const onDelete = () => {
    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_DELETE, props.message.id);
  }

  /**
   * リアクション表示切り替え
   */
  const handleReactionToggle = () => {
    const rect = buttonRef.current?.getBoundingClientRect();
    if (rect) {
      const position = {
        top: rect.top + rect.height,
        left: rect.left + rect.width / 2 - 200 // 真ん中位置を調整
      };
      reactionSelectVisibilityService.toggleMessageIdVisibility(props.message.id ?? 0, position);
    }
  };


  /**
   * リアクション表示終了
   * @param event
   */
  const handleReactionClose = (event: React.MouseEvent<EventTarget>) => {
    if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
      return;
    }
    reactionSelectVisibilityService.setMessageIdVisibility(props.message.id ?? 0, false);
  };

  /**
   * リアクションクリック
   * @param reaction
   */
   const handleReactionClick = (reaction: number) => {
      EventService.getInstance().emitEvent(EventService.EVENT_CHAT_REACTION, {messageId: props.message.id, reaction: reaction});
      reactionSelectVisibilityService.setMessageIdVisibility(props.message.id ?? 0, false);
   }

  /**
   * 描画
   */
  return (
    <Box display="flex" justifyContent="space-around" visibility={props.visible ? 'visible' : 'hidden'}>
      { !isMyMessage &&
        <IconButton size="small" aria-label="reply" onClick={onReply}>
          <ReplyIcon />
        </IconButton>
      }
      { isMyMessage &&
        <IconButton size="small" aria-label="edit" onClick={onEdit}>
          <EditIcon />
        </IconButton>
      }
      <IconButton size="small" aria-label="reaction" ref={buttonRef} onClick={handleReactionToggle}>
        <EmojiEmotionsIcon />
      </IconButton>
      { isMyMessage &&
        <IconButton size="small" aria-label="delete" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      }

      {/* リアクションパネル */}
      <ChatReactionSelect
        open={reactionSelectVisibilityService.isMessageIdVisible(props.message.id ?? 0)}
        anchorPosition={reactionSelectVisibilityService.getAnchorPosition() ?? { top: 0, left: 0 }}
        onClick={handleReactionClick}
        onClose={handleReactionClose} />

    </Box>
  );
};

