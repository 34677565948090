import { LogUtils } from "../Common/LogUtils";
import { HttpServiceBase } from "./HttpServiceBase";
import { EcmgApiError } from "../Common/EcmgApiError";

/**
 * 開発者サービス
 */
export class DeveloperService extends HttpServiceBase {
  private static instance: DeveloperService = new DeveloperService();

  private constructor() {
    super();
  }

  static getInstance(): DeveloperService {
    return this.instance;
  }

  /**
   * 全実行中プロセスエラー終了
   */
  public async setAllRunningProcessesAsError(): Promise<string> {
    LogUtils.d();

    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/developer/process-status/error-all`,
        {},
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.data.message);
      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

}
