import {
  AppBar, Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {StoreService} from "../../Services/StoreService";
import {Store} from "../../Models/Store";
import {CommonUtils} from "../../Common/CommonUtils";
import {EcmgApiError} from "../../Common/EcmgApiError";
import {LogUtils} from "../../Common/LogUtils";
import {useSnackbar} from "../Common/Provider/SnackbarContext";
import {EMTextField} from "../Common/Components/EMTextField";
import {EMSelect} from "../Common/Components/EMSelect";
import ECProgress from "../Common/Components/ECProgress";


interface MultiProductSyncDialogProps {
  open: boolean;
  onClose: () => void;
}

/**
 * 複数商品同期ダイアログ
 * @param props
 * @constructor
 */
export const MultiProductSyncDialog = (props: MultiProductSyncDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    stores: [] as Store[],
    selectedStore: null as number | null,
    stockCodes: '' as string,
    processing: false,
  });

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    const loadStores = async () => {
      const storeService = StoreService.getInstance();
      const allStores = storeService.getAllStores();
      updateState({ stores: allStores });
    };

    loadStores().then();
  }, []);

  /**
   * ダイアログ表示時処理
   */
  useEffect(() => {
    if (props.open) {
      updateState({selectedStore: null});
      updateState({stockCodes: ''});
    }
  }, [props.open]);

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * 実行ボタンクリック時
   */
  const handleExecute = () => {
    if (!state.selectedStore || !state.stockCodes || state.stockCodes === '') {
      alert('店舗と在庫コードを選択してください。');
      return;
    }
    // 在庫コードが6桁の英数字であることを確認
    const stockCodeArray = state.stockCodes.split(',');
    const isValidCodes = stockCodeArray.every(code => /^[A-Za-z0-9]{6}$/.test(code));

    if (!isValidCodes) {
      alert('在庫コードは6桁の英数字でなければなりません。各在庫コードを確認してください。');
      return;
    }

    synchronizeProducts().then();
  };

  /**
   * 複数商品同期
   */
  const synchronizeProducts = async () => {
    let message = '';

    try {
      updateState({processing: true});

      // 商品同期
      message = await StoreService.getInstance().syncProductMulti(state.selectedStore!, state.stockCodes!);

      updateState({processing: false});
    }
    catch (e) {
      LogUtils.ex(e);

      updateState({processing: false});

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = '指定商品同期処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);

    // ダイアログを閉じる
    props.onClose();
  }

  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            指定商品同期
          </Typography>
          <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography variant={"body1"}>
          指定された在庫コードの商品を同期するための機能です。
        </Typography>

        <Box mt={4} />

        <EMSelect
          label="店舗選択"
          value={state.selectedStore?.toString() ?? ''}
          onChange={(value) => updateState({ selectedStore: Number(value) })}
          menuItems={state.stores}
          itemValueKey="id"
          itemLabelKey="store_name"
          hideUnselected={false}
          sx={{ width: '100%' }}
        />

        <Box mt={4} />

        <EMTextField
          label="在庫コード（カンマ区切りで複数指定可)"
          value={state.stockCodes}
          onChange={(value) => {
            if (/^[a-zA-Z0-9-,]*$/.test(value)) {
              updateState({ stockCodes: value });
            }
          }}
        />

        <Box mt={2} />

        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleExecute}>
            実行
          </Button>
        </DialogActions>
      </DialogContent>

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </Dialog>
  );
};
