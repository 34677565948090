// StockCount.tsx
import {Box, Typography} from "@mui/material";
import {Product} from "../../../../../Models/Product/Product";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {SearchResult} from "../../../../../Models/SearchResult";

interface ProcurementCountProps {
  searchResult: SearchResult;
}

/**
 * 要仕入れ数
 * @param props
 * @constructor
 */
export const ProcurementCount = (props: ProcurementCountProps) => {

  /**
   * 描画
   */
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Typography fontSize={"14px"} color={MainPageConstants.COLOR_DARK_GRAY}>要仕入数</Typography>
      <Typography variant={"h4"} fontWeight={"bold"}>
        {props.searchResult.neededProcurementCount}
      </Typography>
    </Box>
  );
}
