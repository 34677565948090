/**
 * タグ
 */
export class Tag {
  id?: number;
  name?: string;
  color?: string;
  tag_category_id?: number;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<Tag> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<Tag>): Tag {
    return new Tag({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): Tag {
    return new Tag({
      ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): Tag {
    const decoded = JSON.parse(jsonString);
    return Tag.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  // DataGridなどで表示するための値を選択的に取得します。
  getDataGridValues(): string[] {
    return [
      this.id?.toString() || '',
      this.name || '',
      this.color || '',
      // ... 必要に応じてその他の項目を追加 ...
    ];
  }
}
