import React, {useEffect, useState} from 'react';
import {Box, Button, Divider, Popover} from '@mui/material';
import {ChatMessage} from "../../../../Models/Chat/ChatMessage";
import {ChatMessageItem} from "../ChatMessageItem";
import {ChatService} from "../../../../Services/ChatService";
import {LogUtils} from "../../../../Common/LogUtils";
import {useSnackbar} from "../../../Common/Provider/SnackbarContext";
import {EventService} from "../../../../Services/System/EventService";
import {ReplyInfo} from "../ChatInterface";

interface ChatMessagePopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  aid: string;
  onRePopup?: (replyInfo: ReplyInfo) => void; // ポップアップ表示時に再度ポップアップを表示した場合のコールバック
}

/**
 * メッセージのポップアップ表示
 * @param props
 * @constructor
 */
export const ChatMessagePopover = (props: ChatMessagePopoverProps) => {

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    processing: false,
    message: undefined as ChatMessage | undefined,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * パラメータ変更時
   */
  useEffect(() => {
    if (props.open) {
      getMessage().then();
    }
  }, [props.open]);

  /**
   * メッセージの取得
   */
  const getMessage = async () => {
    try {
      updateState({processing: true});

      const message = await ChatService.getInstance().getMessage(Number(props.aid));

      // メッセージをセット
      updateState({message: message});

      updateState({processing: false});
    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('メッセージが取得できませんでした。');
    }
    finally {
      updateState({processing: false});
    }
  }

  /**
   * 移動ボタンクリック
   */
  const onJumpButtonClick = () => {
    props.onClose();

    // const chatPageParam = ChatPageParam.fromUrl();
    // chatPageParam.messageId = Number(props.aid);
    // navigate(`/chat?${chatPageParam.toQueryString()}`);

    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_JUMP, props.aid);
  }

  /**
   * 描画
   */
  if (!state.message) {
    return <></>;
  }
  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{
        // ポップアップとボタンの間にスペースを作る
        ".MuiPaper-root": { marginTop: -4 },
      }}
    >
      {/* チャットメッセージ */}
      <Box>
        <ChatMessageItem
          message={state.message}
          popup={true}
          onRePopup={props.onRePopup}
        />
        <Divider/>
        {/*  このメッセージへ移動 */}
        <Box display={"flex"} justifyContent={"center"} mt={1} mb={1}>
          <Button
            variant={"outlined"}
            onClick={onJumpButtonClick}
          >
            このメッセージへ移動
          </Button>
        </Box>
      </Box>

      {/* プログレス表示 */}
      {/*<ECProgress open={state.processing}></ECProgress>*/}
    </Popover>
  );
};
