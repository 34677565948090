/**
 * 店舗商品画像
 */
export class StoreProductPhoto {
  id?: number;
  mall_no?: number;
  store_id?: number;
  stock_code?: string;
  unit_code?: string;
  mall_product_id?: string;
  number?: number;
  url?: string;
  label?: string;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<StoreProductPhoto> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<StoreProductPhoto>): StoreProductPhoto {
    return new StoreProductPhoto({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): StoreProductPhoto {
    return new StoreProductPhoto({
      ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): StoreProductPhoto {
    const decoded = JSON.parse(jsonString);
    return StoreProductPhoto.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  // こちらはDataGridの値として適切な項目を選択していますが、変更する必要がある場合があります。
  getDataGridValues(): string[] {
    return [
      this.id?.toString() || '',
      this.stock_code || '',
      this.unit_code || '',
      this.url || '',
      // ... その他の項目も同様に追加 ...
    ];
  }
}
