import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import {Constants} from "../../../../Common/Constants";
import {Button, Popover, Typography} from "@mui/material";

interface ChatReactionSelectProps {
  open: boolean; // ポップアップ表示フラグ
  anchorPosition: { top: number, left: number }; // ポップアップ表示位置
  onClick: (emojiNumber: number) => void; // 親コンポーネントからのイベントハンドラー
  onClose: (event: any) => void; // ポップアップを閉じる
}

/**
 * チャットリアクション選択
 * @param props
 * @constructor
 */
export const ChatReactionSelect = (props: ChatReactionSelectProps) => {

  /**
   * 絵文字クリック
   * @param emojiNumber
   */
  const handleEmojiClick = (emojiNumber: number) => {
    props.onClick(emojiNumber); // 親コンポーネントの関数を呼び出す
  };

  /**
   * リアクション
   */
  const reactions = () => {
    const keys = Object.keys(Constants.CHAT_REACTION); // キーの配列を取得
    const emojis = [];

    for (let i = 0; i < keys.length; i++) {
      const key = Number(keys[i]);
      const emoji = Constants.CHAT_REACTION[key];

      emojis.push(
        <Tooltip key={key} title={Constants.CHAT_REACTION_LABELS[key]}>
          <Button
            onClick={() => handleEmojiClick(key)}
            aria-label={`emoji ${key}`}
            sx={{
              padding: '2px',
              minWidth: '36px',
              height: '36px',
              fontSize: '16px',
            }}
          >
            <Typography
              sx={{fontSize: Constants.CHAT_REACTION_FONT_SIZE}}
            >
              {emoji}
            </Typography>
          </Button>
        </Tooltip>
      );
    }
    return emojis;
  }


  /**
   * 描画
   */
  return (
    <>
      <Popover
        open={props.open}
        anchorReference="anchorPosition"
        anchorPosition={props.anchorPosition}
        onClose={(e) => props.onClose(e)}
        sx={{ // Popover自体にスタイルを適用
          '& .MuiPaper-root': { // MUIのPaperコンポーネントをターゲットに
            border: 1,
            backgroundColor: 'white',
            borderRadius: 8,
          }
        }}
      >
        <Box display="flex" flexDirection="row">
          {reactions()}
        </Box>
      </Popover>
    </>
  );
};

