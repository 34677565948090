import { LogUtils } from "../Common/LogUtils";
import { HttpServiceBase } from "./HttpServiceBase";
import { SystemSetting } from "../Models/SystemSetting";
import { EcmgApiError } from "../Common/EcmgApiError";
import {CalendarColorItem} from "../Models/Calendar/CalendarColorItem";
import {Constants} from "../Common/Constants";

export class SystemSettingService extends HttpServiceBase {
  private static instance: SystemSettingService = new SystemSettingService();
  private settingMap: Map<string, SystemSetting> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): SystemSettingService {
    return this.instance;
  }

  async init(): Promise<void> {
    LogUtils.d("Initializing SystemSettingService");
    await this._loadSettings();
  }

  /**
   * 設定データ読み込み
   * @private
   */
  private async _loadSettings(): Promise<void> {
    try {
      const url = "api/system-settings";
      const axios = await this.getAxios();

      const response = await axios.get(url, this.makeAuthorizeOption());
      LogUtils.d(response.toString());

      this.setData(response.data);

    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * データ設定
   * @param data
   */
  public setData(data: any): void {
    this.settingMap.clear();
    if (data && Array.isArray(data)) {
      data.forEach(settingData => {
        const setting = SystemSetting.fromMap(settingData);
        if (setting.key !== undefined) {
          this.settingMap.set(setting.key, setting);
        }
      });
    }
  }

  /**
   * 設定データ取得
   * @param key
   */
  getSettingByKey(key: string): SystemSetting | undefined {
    return this.settingMap.get(key);
  }

  /**
   * すべての設定を取得
   */
  getAllSettings(): SystemSetting[] {
    return Array.from(this.settingMap.values());
  }

  /**
   * 設定データ設定・更新
   * @param setting
   */
  public async updateSetting(setting: SystemSetting): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        'api/system-settings',
        setting.toMap(), // 設定オブジェクトをマップに変換
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      await this._loadSettings(); // 設定を再読み込み

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * カレンダーカラーアイテムの取得
   */
  getCalendarColorItems(): CalendarColorItem[] {
    // APIから取得した設定JSON
    const settingJson = this.settingMap.get(SystemSetting.KEY_CALENDAR_COLOR_ITEMS)?.value;

    // デフォルトのカレンダーカラーアイテムを準備
    let items = Constants.defaultCalendarColorItems;

    if (settingJson) {
      try {
        // 設定JSONをパースしてカレンダーカラーアイテムの配列に変換
        const apiItems: CalendarColorItem[] = JSON.parse(settingJson).map((item: any) => new CalendarColorItem(item));

        // APIから取得したアイテムをデフォルトのアイテムとマージ
        // APIのアイテムでデフォルトのアイテムを上書き
        const mergedItemsMap = new Map<number, CalendarColorItem>();

        // デフォルトのアイテムをマップに追加
        items.forEach(item => mergedItemsMap.set(item.number!, item));

        // APIのアイテムでマップの内容を更新（上書き）
        apiItems.forEach(item => mergedItemsMap.set(item.number!, item));

        // マップの値を配列に変換
        items = Array.from(mergedItemsMap.values());

      } catch (e) {
        LogUtils.ex(e);
        // JSON解析に失敗した場合はデフォルトのアイテムをそのまま使用
      }
    }

    // マージしたカレンダーカラーアイテムの配列を返す
    return items;
  }


  /**
   * 特定のカレンダーカラーアイテムの更新
   * @param item 更新するカレンダーカラーアイテム
   */
  public async updateCalendarColorItem(item: CalendarColorItem): Promise<string> {
    const setting = this.settingMap.get(SystemSetting.KEY_CALENDAR_COLOR_ITEMS);
    let items: CalendarColorItem[] = [];

    // 既存の設定があれば、それを読み込む
    if (setting?.value) {
      try {
        const itemsArray = JSON.parse(setting.value);
        items = itemsArray.map((itemData: any) => new CalendarColorItem(itemData));
      } catch (e) {
        LogUtils.ex(e);
        // JSONの解析に失敗した場合は空の配列を使用
      }
    }

    // 更新するアイテムが既に存在するか検索
    const index = items.findIndex(existingItem => existingItem.number === item.number);

    if (index !== -1) {
      // 既存のアイテムを更新
      items[index] = item;
    } else {
      // 新しいアイテムを追加
      items.push(item);
    }

    // 更新したアイテムの配列をJSON文字列に変換して保存
    const updatedSettingJson = JSON.stringify(items.map(item => item.toMap()));

    // SystemSettingを更新する
    const updatedSetting = new SystemSetting({
      id: setting?.id,
      key: "calendar-color-items",
      value: updatedSettingJson,
    });

    return await this.updateSetting(updatedSetting);
  }
}
