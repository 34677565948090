import React, {useEffect, useRef, useState} from 'react';
import {CommonLayout} from "../Common/Layouts/CommonLayout";
import {SKUPanel} from "./Components/SKU/SKUPanel";
import {LogUtils} from "../../Common/LogUtils";
import ECProgress from "../Common/Components/ECProgress";
import {ProductService} from "../../Services/ProductService";
import {useSnackbar} from "../Common/Provider/SnackbarContext";
import {Box, Divider} from "@mui/material";
import {EventService} from "../../Services/System/EventService";
import {SearchComponent} from "./Components/Common/SearchComponent";
import {useLocation, useNavigate} from "react-router-dom";
import {ListPanel} from "./Components/List/ListPanel";
import {SearchResult} from "../../Models/SearchResult";
import {MainPageConstants} from "./Common/MainPageConstants";
import {SearchParam} from "../../Common/SearchParam";
import {SalesData} from "../../Models/SalesData/SalesData";
import {SalesDataDialog} from "./Components/Common/SalesData/SalesDataDialog";
import {SalesService} from "../../Services/SalesService";
import {ChatPanel} from "./Components/Common/Chat/ChatPanel";
import {ChatPageParam} from "../../Models/Chat/ChatPageParam";
import {SelectionService} from "../../Services/System/SelectionService";

/**
 * メイン画面
 *
 * @constructor
 */
export const MainPage = () => {
  /**
   * 定数
   */
   // 検索結果更新チェック間隔（ミリ秒）
  const SEARCH_RESULTS_UPDATE_CHECK_INTERVAL = 30000;
  // 画面幅
  const WINDOW_WIDTH = "1920px";
  // メインコンテンツ幅
  const MAIN_CONTENTS_WIDTH = "1300px";

  /**
   * useRef（ResizeObserverエラー対策)
   */
  const elementRef = useRef(null);

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    searchResults: [] as SearchResult[],
    totalPageCount: 0,
    isListMode: false,
    processing: false,
    lastCheckedTime: new Date(), // 最後に検索結果の更新をチェックした時間
    salesDataDialogOpen: false,
    salesDataDialogParam: {
      salesData: new SalesData(),
      displayCode: '',
      productName: '',
      stockCode: '',
      mallNo: undefined as number | undefined,
      mallProductId: undefined as string | undefined,
    },
    chatPageParam: undefined as ChatPageParam | undefined,
    lastSearchParam: new SearchParam({}),
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();
  /**
   * useLocation
   */
  const location = useLocation();
  /**
   * useNavigate
   */
  const navigate = useNavigate();

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    LogUtils.d('MainPage:マウント時・アンマウント時処理');

    // イベントハンドラ登録（イベントハンドラの中でstateの値を参照しないこと）
    EventService.getInstance().onEvent(EventService.EVENT_UPDATE_SEARCH_RESULTS, onSearchResultChanged);
    EventService.getInstance().onEvent(EventService.EVENT_SHOW_SALES_DATA, onShowSalesData);
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_OPEN, onChatOpen);
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_CLOSE, onChatClose);
    EventService.getInstance().onEvent(EventService.EVENT_SELECTION_CHANGE, onSelectionChanged);

    // ResizeObserverエラー対策
    const resizeObserver = new ResizeObserver(entries => {
      try {
        for (let entry of entries) {
          LogUtils.d('Size changed:' + entry.contentRect);
        }
      } catch (error) {
        LogUtils.ex(error);
      }
    });
    const currentElement = elementRef.current;
    if (currentElement) {
      resizeObserver.observe(currentElement);
    }

    // クリーンアップ
    return () => {
      // イベントハンドラ解除
      EventService.getInstance().removeListener(EventService.EVENT_UPDATE_SEARCH_RESULTS, onSearchResultChanged);
      EventService.getInstance().removeListener(EventService.EVENT_SHOW_SALES_DATA, onShowSalesData);
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_OPEN, onChatOpen);
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_CLOSE, onChatClose);
      EventService.getInstance().removeListener(EventService.EVENT_SELECTION_CHANGE, onSelectionChanged);

      // ResizeObserver解除
      if (currentElement) {
        resizeObserver.unobserve(currentElement);
      }
    };
  }, []);

  /**
   * チャット表示
   */
  const onChatOpen = (chatPageParam: ChatPageParam) => {
    setState(prevState => {
      // すでに該当のチャットが表示されている場合は何もしない
      if ( prevState.chatPageParam?.stockCode === chatPageParam.stockCode ){
        return prevState;
      }
      return {
        ...prevState,
        chatPageParam: chatPageParam,
      };
    });
  }

  /**
   * チャット非表示
   */
  const onChatClose = () => {
    //LogUtils.d('MainPage:onChatClose');
    updateState({chatPageParam: undefined});
  }

  /**
   * 検索結果の更新をチェック
   */
  useEffect(() => {
    const interval = setInterval(async () => {
      const ret = await ProductService.getInstance().checkSearchResultsUpdates(state.lastCheckedTime);

      // lastCheckedTimeだけ更新
      if ( ret ){
        updateState({ lastCheckedTime: new Date() });
      }

    }, SEARCH_RESULTS_UPDATE_CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [state.lastCheckedTime]);

  /**
   * 検索条件変更時
   */
  useEffect(() => {
    // 検索条件
    const searchParams = SearchParam.fromUrl();

    // 検索条件を保存
    updateState({lastSearchParam: searchParams});

    // 検索実行
    searchProducts(searchParams).then();

  }, [location.search]);

  /**
   * 検索結果更新時
   */
  const onSearchResultChanged = () => {
    // コールバックからstateを参照できないので、setStateを使う
    setState(prevState => {

      LogUtils.d('MainPage:onSearchResultChanged');

      const searchResults = ProductService.getInstance().getSearchResults();

      // 選択サービスに件数を設定
      const total = ProductService.getInstance().getPaginationInfo().total;
      SelectionService.instance().setAllCount(total);

      // 件数が1件の場合は無条件に選択
      if (total === 1) {
        SelectionService.instance().setSelection(searchResults[0].product.stock_code, true);
      }

      //-----------------------
      // チャット表示パラメータ
      //-----------------------
      let chatPageParam = prevState.chatPageParam;
      // SKUである
      if ( total === 1 ){
        const searchParams = SearchParam.fromUrl();
        // かつ、前回と比べて在庫コードまたはメッセージIDが異なる場合は、チャットパラメータを作り直す
        if ( prevState.chatPageParam?.stockCode !== searchResults[0].product.stock_code ||
          prevState.chatPageParam?.messageId !== searchParams.chatMessageId){

          chatPageParam = new ChatPageParam(        {
            stockCode: searchResults[0].product.stock_code,
            messageId: searchParams.chatMessageId,
            chatResetTimeStamp: Date.now(), // 別にURLから取得する必要なし
          });
        }
      }

      // ステート更新
      return {
        ...prevState,
        searchResults: searchResults,
        totalPageCount: ProductService.getInstance().getPaginationInfo().totalPages,
        isListMode: ProductService.getInstance().isListMode(),
        chatPageParam: chatPageParam,
      };
    });
  }

  /**
   * 検索実行
   * @param searchParam
   */
  const onSearch = (searchParam: SearchParam) => {
    // 必要なら選択状態クリア
    if ( state.lastSearchParam.needClearSelection(searchParam) ) {
      SelectionService.instance().clearSelection();
    }

    // チャットメッセージIDはクリア
    searchParam.chatMessageId = undefined;

    // 検索実行
    navigate(`/main?${searchParam.toQueryString()}`);

    // コンテナ内のトップにスクロール
    setTimeout(() => {
      const container = document.getElementById('main-content-container');
      if (container) {
        container.scrollTop = 0;
      }
    }, 100);
  }

  /**
   * 商品検索
   */
  const searchProducts = async (searchParam: SearchParam) => { // 新しいページ番号を引数として追加
    try {
      // const keyword = searchParam.keyword;
      // // 空なら何もしない
      // if (keyword === '' || keyword === null) {
      //   return;
      // }

      updateState({processing: true});

      // チャットパラメータをクリア
      // ここで行わないと、検索結果が変わったときにチャットパネルが表示されたままになる
      updateState({chatPageParam: undefined});

      if ( !searchParam.canSearch() ){
        ProductService.getInstance().clearSearchResults();
      }
      else {
        await ProductService.getInstance().searchProducts(searchParam);
      }

      updateState({processing: false});
    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('検索処理でエラーが発生しました。');
    }
    finally {
      updateState({processing: false});
    }
  }

  /**
   * 販売推移データ表示
   * @param arg 文字列(stockCode) または配列 (stockCode, mallNo, mallProductId, productName)
   */
  const onShowSalesData = async (arg: any) => {
    LogUtils.d('MainPage:onShowSalesData');
    try {
      if (arg === '' || arg === null) {
        return;
      }

      updateState({processing: true});

      // 引数が文字列の場合
      let stockCode = '';
      let mallNo = undefined;
      let mallProductId = undefined;
      if (typeof arg === 'string') {
        stockCode = arg;
      }
      // 引数が配列の場合
      else if (Array.isArray(arg)) {
        stockCode = arg[0];
        mallNo = arg[1];
        mallProductId = arg[2];
      }

      const salesData = await SalesService.getInstance().getSalesData(stockCode, mallNo, mallProductId);
      const searchResult = ProductService.getInstance().getSearchedResultByStockCode(stockCode);
      let productName;
      if ( mallProductId ){
        productName = arg[3];
      }
      else {
        productName = searchResult?.minimalUnitProduct?.name || '';
      }

      const salesDataDialogParam = {
        salesData: salesData,
        displayCode: mallProductId ?? stockCode,
        productName: productName,
        stockCode: stockCode,
        mallNo: mallNo,
        mallProductId: mallProductId,
      }
      updateState({
        salesDataDialogOpen: true,
        salesDataDialogParam: salesDataDialogParam,
        processing: false});

    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('販売推移データ表示でエラーが発生しました。');
    }
    finally {
      updateState({processing: false});
    }
  }

  /**
   * 商品表示
   */
  const showProduct = (page: number) => {
    // 商品が見つからない場合
    if ( state.searchResults.length === 0 ){
      return (
        <Box>表示する商品はありません。</Box>
      );
    }
    // 商品が1件かつページ番号が1ならSKU表示する
    else if ( state.searchResults.length === 1 && page === 1 ){
      return (
        <SKUPanel
          key={state.searchResults[0].product.stock_code}
          searchResult={state.searchResults[0]}
        />
      );
    }
    // それ以外はリスト表示する
    else {
      return state.searchResults.map(searchResult => (
        <ListPanel key={searchResult.product.stock_code} searchResult={searchResult} />
      ));
    }
  }

  /**
   * 選択状態変更時
   */
  const onSelectionChanged = () => {
    updateState({});
  }


  /**
   * 描画
   */
  const initialSearchParam = SearchParam.fromUrl();

  return (
    <CommonLayout
      title={'メイン'}
    >
      <Box
        ref={elementRef} // ResizeObserverエラー対策
        display={"flex"}
        flexDirection={"row"}
        minWidth={WINDOW_WIDTH}
        mt={1}
      >
        <Box ml={4} />

        {/* メインコンテンツ */}
        <Box
          id="main-content-container"
          width={MAIN_CONTENTS_WIDTH}
          style={{ overflowY: 'auto', maxHeight: '90vh' }}
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none' // WebKitブラウザ用のスタイル
            }
          }}
        >
          {/* 検索パネル */}
          <SearchComponent
            onSearch={onSearch}
            initialSearchParam={initialSearchParam}
            isListMode={state.isListMode}
            totalPageCount={state.totalPageCount}
          />
          <Divider sx={{ backgroundColor: MainPageConstants.COLOR_DARK_GRAY}} />
          <Box mt={2}/>

          {/* 商品表示 */}
          { showProduct(initialSearchParam.page ?? 1) }

          {/* ページネーションOnly */}
          <SearchComponent
            onSearch={onSearch}
            initialSearchParam={initialSearchParam}
            paginationOnly={true}
            isListMode={state.isListMode}
            totalPageCount={state.totalPageCount}
          />
        </Box>

        {/* チャット */}
        {state.chatPageParam &&
          <Box display={"flex"} flexDirection={"row"} >
            <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundColor: MainPageConstants.COLOR_DARK_GRAY, width: '5px', ml: 2, mr: 2}}
            />
            <ChatPanel chatPageParam={state.chatPageParam} />
          </Box>
        }
      </Box>

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

      {/* 販売推移ダイアログ */}
      <SalesDataDialog
        open={state.salesDataDialogOpen}
        onClose={() => {updateState({salesDataDialogOpen: false})}}
        salesData={state.salesDataDialogParam.salesData}
        displayCode={state.salesDataDialogParam.displayCode}
        productName={state.salesDataDialogParam.productName}
        stockCode={state.salesDataDialogParam.stockCode}
        mallNo={state.salesDataDialogParam.mallNo}
        mallProductId={state.salesDataDialogParam.mallProductId}
      />

    </CommonLayout>
  );
}
