import React from 'react';
import { ProcurementPrice } from "../../../../../Models/Product/ProcurementPrice";
import { Box, Typography } from '@mui/material';
import { MainPageConstants } from "../../../Common/MainPageConstants";
import {Constants} from "../../../../../Common/Constants";
import {CommonUtils} from "../../../../../Common/CommonUtils";

interface ProcurementPricesProps {
  procurementPrices: ProcurementPrice[];
  width: number;
}

/**
 * 仕入価格
 * @param props
 * @constructor
 */
export const ProcurementPrices = (props: ProcurementPricesProps) => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: props.width,
  };

  /**
   * モール略称
   * @param mall_no
   */
  const getMallName = (mall_no: number | undefined): string => {
    switch (mall_no) {
      case Constants.MALL_BASE:
        return '自';
      case Constants.MALL_YAHOO:
        return 'Y!';
      case Constants.MALL_RAKUTEN:
        return '楽';
      default:
        return '';
    }
  };

  /**
   * 価格範囲
   * @param price1
   * @param price2
   */
  const getPriceRange = (price1: number | undefined, price2: number | undefined): [string, string] => {
    // 1のくらいを切り捨て
    if ( price1 ){
      price1 = Math.floor(price1 / 10) * 10;
    }
    if ( price2 ){
      price2 = Math.floor(price2 / 10) * 10;
    }

    return [CommonUtils.formatNumber(price1), CommonUtils.formatNumber(price2)];
  };

  return (
    <Box sx={BOX_STYLE}>
      {props.procurementPrices.map((priceInfo, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography color={MainPageConstants.COLOR_DARK_GRAY} style={{ flex: 1 }}>
            {getMallName(priceInfo.mall_no)}
          </Typography>
          <Typography color={MainPageConstants.COLOR_DARK_GRAY} style={{ flex: 1, textAlign: 'right' }}>
            {getPriceRange(priceInfo.procurement_price1, priceInfo.procurement_price2)[0]}
          </Typography>
          <Typography color={MainPageConstants.COLOR_DARK_GRAY} style={{ flex: 1, textAlign: 'center' }}>
            -
          </Typography>
          <Typography color={MainPageConstants.COLOR_DARK_GRAY} style={{ flex: 1, textAlign: 'right' }}>
            {getPriceRange(priceInfo.procurement_price1, priceInfo.procurement_price2)[1]}
          </Typography>
        </div>
      ))}
    </Box>
  );
}
