import React from 'react';
import Button from '@mui/material/Button';

interface FourButtonsProps {
  onClick: (num: number) => void;
  buttonSize?: number; // ボタンのサイズ (例: 50, 60 など)
  disabled?: boolean;
}

export const FourButtons = (props: FourButtonsProps) => {
  return (
    <div style={{ display: 'flex' }}>
      {[1, 2, 3, 4].map((num) => (
        <Button
          key={num}
          variant="contained"
          color={"info"}
          disabled={props.disabled}
          sx={{
            width: props.buttonSize,
            height: props.buttonSize,
            minWidth: props.buttonSize,
            m: 0.5,
          }}
          onClick={() => props.onClick(num)}
        >
          {num}
        </Button>
      ))}
    </div>
  );
};
