import {useEffect} from "react";
import {UnitProductPanel} from "./UnitProductPanel";
import {Product} from "../../../../../Models/Product/Product";
import {Box, Divider} from "@mui/material";
import {OrderCountData} from "../../../../../Models/Product/OrderCountData";
import {LogUtils} from "../../../../../Common/LogUtils";
import {SKUConstants} from "../SKUConstants";
import {MainPageConstants} from "../../../Common/MainPageConstants";

interface UnitProductListProps {
  product: Product;
  orderCountData: OrderCountData | undefined;
  canChange: boolean;
}

/**
 * ユニット商品リスト
 * @param props
 * @constructor
 */
export const UnitProductList = (props: UnitProductListProps) => {

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    LogUtils.d('UnitProductList:マウント時・アンマウント時処理');

    // クリーンアップ
    return () => {
    };

  }, []);


  /**
   * 描画
   */
  return (
    <div>
      <Divider />
      {/* ヘッダー部分の描画 */}
      <Box display="flex" alignItems="center" color={MainPageConstants.COLOR_DARK_GRAY}>
        <Box sx={{ width: SKUConstants.UNIT_NAME_WIDTH, textAlign: 'center' }}>ユニット名</Box>
        <Box sx={{ width: SKUConstants.UNIT_SHIPPING_METHOD_WIDTH, textAlign: 'center' }}>配送</Box>
        <Box sx={{ width: SKUConstants.UNIT_STOCK_COUNT_WIDTH, textAlign: 'center' }}>在庫数</Box>
        <Box sx={{ width: SKUConstants.STORE_PRODUCT_CODE_WIDTH + SKUConstants.STORE_PRODUCT_PRICE_WIDTH, textAlign: 'center' }}>価格</Box>
        <Box sx={{ width: SKUConstants.STORE_PRODUCT_SALES_TREND_WIDTH, textAlign: 'center' }}>販売実績</Box>
        <Box sx={{ width: SKUConstants.STORE_PRODUCT_STATUS_WIDTH, textAlign: 'center' }}>ステータス</Box>
        <Box sx={{ width: SKUConstants.STORE_PRODUCT_UPDATED_AT_WIDTH, textAlign: 'center' }}>更新日時</Box>
      </Box>

      <Divider />
      {/* リスト */}
      {props.product.unit_products?.map(unitProduct => (
        <Box key={unitProduct.id}>
          <UnitProductPanel
            product={props.product}
            unitProduct={unitProduct}
            unitOrderCountData={props.orderCountData?.getUnitOrderCountData(unitProduct.unit_code ?? '')}
            canChange={props.canChange}
          />
          <Divider />
        </Box>
      ))}
    </div>
  );
};
