/**
* 選択状態サービス
*/
export class SelectionService {

  /**
   * シングルトン
   * @private
   */
  private static _instance: SelectionService;
  private constructor() { }
  public static instance(): SelectionService {
      if (!SelectionService._instance) {
          SelectionService._instance = new SelectionService();
      }
      return SelectionService._instance;
  }

  // 全選択モードか
  private allSelectMode: boolean = false;
  // 個別選択（全選択モード時は非選択のもの、そうでない場合は選択されているもの）
  private individualSet : Set<any> = new Set<any>();
  // 全件数
  private allCount: number = 0;

  /**
   * 全件数取得
   */
  public getAllCount(): number {
      return this.allCount;
  }

  /**
   * 全選択モード設定
   * @param value
   */
  public setAllSelectMode( value: boolean ): void{
      this.individualSet.clear();
      this.allSelectMode = value;
  }

  /**
   * 個別選択設定
   * @param id
   * @param isSelect
   */
  public setSelection( id: any, isSelect: boolean ): void {
      if ( this.allSelectMode ){
          isSelect ? this.individualSet.delete(id) : this.individualSet.add(id);
      }
      else {
          isSelect ? this.individualSet.add(id) : this.individualSet.delete(id);
      }
  }

  /**
   * 個別選択一括設定
   * @param ids
   */
  public setIndividuals( ids: number[] ): void {
      this.individualSet = new Set(ids);
  }

  /**
   * 全件数設定
   * @param count
   */
  public setAllCount( count: number): void {
      this.allCount = count;
  }

  /**
   * 全選択解除
   */
  public clearSelection(): void {
      this.allSelectMode = false;
      this.individualSet.clear();
  }

  /**
   * 指定のIDが選択されているか
   * @param id
   */
  public isSelected(id: any) : boolean {
      if ( this.allSelectMode ){
          return !this.individualSet.has(id);
      }
      else {
          return this.individualSet.has(id);
      }
  }

  /**
   * サーバーからのレコードに選択情報{boolean}を付加する
   * @param records
   */
  public makeWithSelectionRecord(records: any[]): any[] {
      return records.map((record) => {
          const id = record['id'];
          record['selection'] = this.isSelected(id);
          return record;
      });

  }

  /**
   * 全選択モードか
   */
  public isAllSelectMode(): boolean {
      return this.allSelectMode;
  }

  /**
   * 全てのレコードが選択されているか
   */
  public isAllSelect() {
      return this.getSelectCount() === this.allCount;
  }

  /**
   * 一部のレコードが選択されているか
   */
  public isIndeterminate(): boolean {
    return (this.allSelectMode && !this.isAllSelect())
            || (!this.allSelectMode && this.isAnySelect());
  }

  /**
   * 1件でもレコードが選択されているか
   */
  public isAnySelect(){
      return this.getSelectCount() > 0;
  }

  /**
   * 現在の選択件数の取得
   */
  public getSelectCount() {
      if ( this.allSelectMode ){
          return this.allCount - this.individualSet.size;
      }
      else {
          return this.individualSet.size;
      }
  }

  /**
   * 選択情報取得
   */
  public getSelectionData(): Object {
      return {
          allSelectMode: this.allSelectMode,
          individualSet: Array.from(this.individualSet)
      };
  }

}
