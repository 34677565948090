import React, { useCallback } from 'react';
import {Box, Button, Typography} from '@mui/material';
import {LogUtils} from "../../../../Common/LogUtils";
import {UnitProduct} from "../../../../Models/Product/UnitProduct";
import {MainPageConstants} from "../../Common/MainPageConstants";
import {Product} from "../../../../Models/Product/Product";
import ECProgress from "../../../Common/Components/ECProgress";
import {useSnackbar} from "../../../Common/Provider/SnackbarContext";
import {EcmgApiError} from "../../../../Common/EcmgApiError";
import {CommonUtils} from "../../../../Common/CommonUtils";
import {ProductService} from "../../../../Services/ProductService";


interface MallIntegrationButtonProps {
  product: Product;
  width: number;
}

/**
 * モール連携ボタン
 * @param props
 * @constructor
 */
export const MallIntegrationButton = (props: MallIntegrationButtonProps) => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    width: props.width,
  };

  /**
   * useState
   */
  const [state, setState] = React.useState({
    processing: false,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * ボタンクリック時処理
   */
  const handleButtonClick = async () => {
    if (!window.confirm('この商品のモール間在庫連動をONにしますか？（元には戻せません）')) {
      return;
    }

    let message = '';
    try {
      // 処理中フラグON
      updateState({ processing: true });

      // モール連携
      await ProductService.getInstance().updateMallIntegration(props.product.stock_code ?? '');
      message = 'モール連携をONにしました。正しい在庫数を入力してください。';

      // 処理中フラグOFF
      updateState({ processing: false });
    }
    catch (e) {
      LogUtils.ex(e);
      message = 'モール連携のONに失敗しました。';
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  /**
   * 描画
   */

  return (
    props.product?.mall_flag ?
      (
        <Box />
      ) :
      (
        <Box sx={BOX_STYLE}>
          <Typography fontSize={"10px"} color={MainPageConstants.COLOR_DARK_GRAY}>
            {'現在、モール間の在庫は連動していません'}
          </Typography>
          <Button
            onClick={handleButtonClick}
            color={"warning"}
            sx={{
              fontSize: "12px",
              width: "100%",
              }}
            variant="contained">
              モール間の在庫連動をONにする
          </Button>

          {/* プログレス表示 */}
          <ECProgress open={state.processing}></ECProgress>

        </Box>
      )
  );
};
