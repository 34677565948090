import React, { useState, useEffect } from 'react';
import {SalesChartCondition, SalesGraphData} from "../../../../../../Models/SalesData/SalesData";
import {Box, Button, IconButton, Typography} from "@mui/material";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import {SalesChart} from "./SalesChart";
import {SalesService} from "../../../../../../Services/SalesService";
import {LogUtils} from "../../../../../../Common/LogUtils";
import {useSnackbar} from "../../../../../Common/Provider/SnackbarContext";
import {MonthRangeDialog} from "../../../../../Common/Components/MonthRangeDialog";
import {DateRangeDialog} from "../../../../../Common/Components/DateRangeDialog";


interface SalesChartPanelProps {
  stockCode: string;
  mallNo?: number;
  mallProductId?: string;
  graphHeight?: number;
  onFullScreenClick?: () => void;
  isFullScreen?: boolean;
  chartCondition: SalesChartCondition;
  onChartConditionChange?: (condition: SalesChartCondition) => void;
}

/**
 * 販売数チャートパネル
 * @param props
 * @constructor
 */
export const SalesChartPanel = (props: SalesChartPanelProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    dailyData: new SalesGraphData(),
    monthlyData: new SalesGraphData(),
    isDateRangeDialogOpen: false,
    isMonthRangeDialogOpen: false,
  });

  /**
   * コンポーネントの状態を更新します。
   * @param newState - 更新する新しい部分状態。
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * 在庫コード変更時
   */
  useEffect(() => {

  }, [props.stockCode]);

  /**
   * 日次データ変更時
   */
  useEffect(() => {
    // 日次データの取得処理
    getDailyData().then();
  }, [props.chartCondition.startDate, props.chartCondition.endDate]);

  /**
   * 月次データ変更時
   */
  useEffect(() => {
    // 月次データの取得処理
    getMonthlyData().then();
  }, [props.chartCondition.startMonth, props.chartCondition.endMonth]);

  /**
   * 日次データ取得処理
   */
  const getDailyData = async () => {
    try {
      if ( props.chartCondition.startDate == null || props.chartCondition.endDate == null ){
        return;
      }
      const graphData = await SalesService.getInstance().getDailyGraphData(
        props.stockCode,
        props.chartCondition.startDate,
        props.chartCondition.endDate );

      updateState({
        dailyData: graphData,
      });
    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('日次グラフデータ取得でエラーが発生しました。');
    }
  }

  /**
   * 月次データ取得処理
   */
  const getMonthlyData = async () => {
    try {
      if ( props.chartCondition.startMonth == null || props.chartCondition.endMonth == null ){
        return;
      }
      const graphData = await SalesService.getInstance().getMonthlyGraphData(
        props.stockCode,
        props.chartCondition.startMonth,
        props.chartCondition.endMonth );

      updateState({
        monthlyData: graphData,
      });
    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('月次グラフデータ取得でエラーが発生しました。');
    }
  }
  /**
   * 期間表示クリック
   */
  const handlePeriodDisplayClick = () => {
    if (props.chartCondition.isDaily) {
      updateState({ isDateRangeDialogOpen: true });
    } else {
      updateState({ isMonthRangeDialogOpen: true });
    }
  };

  /**
   * 日付範囲保存
   * @param startDate
   * @param endDate
   */
  const handleDateRangeSave = (startDate: Date, endDate: Date) => {
    updateState({
      isDateRangeDialogOpen: false, // 日付範囲ダイアログを閉じる
    });
    const newCondition = new SalesChartCondition({
      startDate: startDate.toISOString().slice(0, 10),
      endDate: endDate.toISOString().slice(0, 10),
      startMonth: props.chartCondition.startMonth,
      endMonth: props.chartCondition.endMonth,
      isDaily: props.chartCondition.isDaily,
    });
    props.onChartConditionChange?.(newCondition);
  };

  /**
   * 月範囲保存
   * @param startMonth
   * @param endMonth
   */
  const handleMonthRangeSave = (startMonth: Date, endMonth: Date) => {
    updateState({
      isMonthRangeDialogOpen: false, // 月範囲ダイアログを閉じる
    });
    const newCondition = new SalesChartCondition({
      startDate: props.chartCondition.startDate,
      endDate: props.chartCondition.endDate,
      startMonth: startMonth.toISOString().slice(0, 7),
      endMonth: endMonth.toISOString().slice(0, 7),
      isDaily: props.chartCondition.isDaily,
    });
    props.onChartConditionChange?.(newCondition);
  };

  /**
   * 日次・月次切り替え
   * @param isDaily
   */
  const handleDailyMonthlyChange = (isDaily: boolean) => {
    const newCondition = new SalesChartCondition({
      startDate: props.chartCondition.startDate,
      endDate: props.chartCondition.endDate,
      startMonth: props.chartCondition.startMonth,
      endMonth: props.chartCondition.endMonth,
      isDaily: isDaily,
    });
    props.onChartConditionChange?.(newCondition);

  }


  /**
   * 描画
   */
  return (
    <Box >
      {/* 上段 */}
      <Box display="flex" flexDirection={"row"} alignItems={"center"}>
        {/* 日次・月次切り替えボタン */}
        <Box ml={2} />
        <Button
          size={'small'}
          variant={props.chartCondition.isDaily ? 'contained' : 'outlined'}
          onClick={() => handleDailyMonthlyChange(true)}
        >
          日次
        </Button>
        <Box ml={0.5} />
        <Button
          size={'small'}
          variant={!props.chartCondition.isDaily ? 'contained' : 'outlined'}
          onClick={() => handleDailyMonthlyChange(false)}
        >
          月次
        </Button>

        {/* 全画面表示アイコン */}
        <IconButton onClick={props.onFullScreenClick}>
          {props.isFullScreen ? <FullscreenExit/> : <FullscreenIcon /> }
        </IconButton>

        <Box flexGrow={1} />

        {/* 期間表示 */}
        <Box onClick={handlePeriodDisplayClick}>
          <Typography sx={{fontSize: '12px', cursor: 'pointer'}}>
            {props.chartCondition.isDaily ? `${props.chartCondition.startDate} 〜 ${props.chartCondition.endDate}` : `${props.chartCondition.startMonth} 〜 ${props.chartCondition.endMonth}`}
          </Typography>
        </Box>
      </Box>

      {/* SalesChart */}
      <Box>
        <SalesChart
          height={props.graphHeight}
          // noRotation={state.isDaily}
          graphData= {(props.chartCondition.isDaily ? state.dailyData : state.monthlyData)}
        />
      </Box>

      {/* 日付範囲ダイアログ */}
      <DateRangeDialog
        open={state.isDateRangeDialogOpen}
        onClose={() => updateState({ isDateRangeDialogOpen: false })}
        onSave={handleDateRangeSave}
        initialStartDate={new Date(props.chartCondition.startDate ?? '')}
        initialEndDate={new Date(props.chartCondition.endDate ?? '')}
      />

      {/* 月範囲ダイアログ */}
      <MonthRangeDialog
        open={state.isMonthRangeDialogOpen}
        onClose={() => updateState({ isMonthRangeDialogOpen: false })}
        onSave={handleMonthRangeSave}
        initialStartMonth={new Date(props.chartCondition.startMonth ?? '')}
        initialEndMonth={new Date(props.chartCondition.endMonth ?? '')}
      />
    </Box>
  );
};

