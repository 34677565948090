import React from 'react';
import Button from '@mui/material/Button';
import {SearchResult} from "../../../../../Models/SearchResult";
import {EventService} from "../../../../../Services/System/EventService";
import {ChatPageParam} from "../../../../../Models/Chat/ChatPageParam";

interface ChatButtonProps {
  searchResult: SearchResult;
}

/**
 * チャットボタン
 * @param props
 * @constructor
 */
export const ChatButton = (props: ChatButtonProps) => {

  /**
   * クリック
   */
  const onClick = () => {
    // const chatPageParam = new ChatPageParam({
    //   stockCode: props.searchResult.product.stock_code,
    // });
    // const url = `/chat?${chatPageParam.toQueryString()}`;
    // window.open(url, '_blank');

    const chatPageParam = new ChatPageParam({
      stockCode: props.searchResult.product.stock_code,
    });

    EventService.getInstance().emitEvent(EventService.EVENT_CHAT_OPEN, chatPageParam);
  }

  /**
   * 描画
   */
  return (
    <Button
      variant="outlined"
      color={"secondary"}
      size={"small"}
      onClick={onClick}
    >
      チャット
    </Button>
  );
};
