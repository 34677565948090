import React, {useEffect, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {Badge} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import {EventService} from "../../../../Services/System/EventService";
import {ChatService} from "../../../../Services/ChatService";
import {LogUtils} from "../../../../Common/LogUtils";
import {useSnackbar} from "../../Provider/SnackbarContext";
import {ChatMentionDialog} from "./ChatMentionDialog";
import {ChatMessage} from "../../../../Models/Chat/ChatMessage";
import ECProgress from "../../Components/ECProgress";
import Tooltip from "@mui/material/Tooltip";

interface UnreadMentionButtonProps {
}

/**
 * 未返信メンションボタン
 * @constructor
 */
export const UnrespondedMentionButton = (props: UnreadMentionButtonProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    processing: false,
    unreadMessageCount: 0,
    unrespondedMessageCount: 0,
    dialogOpen: false,
    messageInfos: [] as Array<{
      message: ChatMessage;
      read: number;
      minimalUnitProductName: string;
      unresponded: boolean;
    }>
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };


  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * useEffect
   */
  useEffect(() => {
    // イベントハンドラ登録（イベントハンドラの中でstateの値を参照しないこと）
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_TO_ME_UNREAD_MESSAGES, onUnreadMessageCount);
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_TO_ME_UNRESPONDED_MESSAGES, onUnrespondedMessageCount);

    // 未返信メッセージ数取得
    loadUnrespondedMessageCount().then();

    // クリーンアップ
    return () => {
      // イベントハンドラ解除
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_TO_ME_UNREAD_MESSAGES, onUnreadMessageCount);
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_TO_ME_UNRESPONDED_MESSAGES, onUnrespondedMessageCount);
    };
  }, []);

  /**
   * 未返信メッセージ数取得
   */
  const loadUnrespondedMessageCount = async () => {
    try {
      const unrespondedMessageCount = await ChatService.getInstance().getToMeUnrespondedMessageCount();
      updateState({unrespondedMessageCount: unrespondedMessageCount});
    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * 未読メッセージ数イベントハンドラ
   * @param unreadMessageCount
   */
  const onUnreadMessageCount = (unreadMessageCount: number) => {
    //LogUtils.d('onUnreadMessages: ' + unreadMessageIds.length);
    updateState({unreadMessageCount: unreadMessageCount});
  }

  /**
   * 未返信メッセージ数イベントハンドラ
   * @param unrespondedMessageCount
   */
  const onUnrespondedMessageCount = (unrespondedMessageCount: number) => {
  //LogUtils.d('onUnreadMessages: ' + unreadMessageIds.length);
    updateState({unrespondedMessageCount: unrespondedMessageCount});
  }

  /**
   * 未読・未返信メッセージ数変更時
   */
  useEffect(() => {
    // ダイアログ表示時の場合はメッセージの再読み込み
    if (state.dialogOpen) {
      loadMentionedMessages().then();
    }
  }, [state.unreadMessageCount, state.unrespondedMessageCount]);

  /**
   * メンションメッセージ読み込み
   */
  const loadMentionedMessages = async () => {
    // メッセージ情報を取得
    const messageInfos = await ChatService.getInstance().getMentionedMessages();
    if ( messageInfos.length === 0 ){
      return;
    }
    updateState({
      messageInfos: messageInfos
    });
  }

  /**
   * クリックイベントハンドラ
   */
  const handleClick = async () => {
    try {
      updateState({processing: true});

      // メンションメッセージ読み込み
      await loadMentionedMessages();

      updateState({dialogOpen: true});
    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('チャットメッセージ読み込みエラーが発生しました。');
    }
    finally {
      updateState({processing: false});
    }
  };

  /**
   * ポップアップを閉じる
   */
  const handleClosePopup = () => {
    updateState({dialogOpen: false});
  };

  /**
   * レンダリング
   */
  return (
    <>
      <Tooltip title={'自分宛のメッセージ'} >
        <IconButton
          onClick={handleClick}
          color="inherit"
          // disabled={state.unreadMessageIds.length === 0}
        >
          <Badge badgeContent={state.unrespondedMessageCount} color="error">
            <NotificationsIcon />
          </Badge>

        </IconButton>
      </Tooltip>
      {/* ChatMentionPopupの条件付きレンダリング */}
      <ChatMentionDialog
        open={state.dialogOpen}
        messageInfos={state.messageInfos}
        onClose={handleClosePopup}
      />
      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </>
  );
};
