import {createTheme} from "@mui/material";

/**
 * カスタムテーマ
 */
export const appTheme = createTheme({
  palette: {
    info: {
      main: '#CCCCCC',
    },
  },
});
