import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EMTextField } from "../../Common/Components/EMTextField";
import { MuiColorInput } from "mui-color-input";
import {TagCategory} from "../../../Models/Tag/TagCategory";

interface TagCategoryEditDialogProps {
  open: boolean;
  tagCategory: TagCategory;
  onClose: () => void;
  onSave: (tagCategory: TagCategory) => void;
  errors: Record<string, string>;
}

/**
 * タグ分類編集ダイアログ
 * @param props
 * @constructor
 */
export const TagCategoryEditDialog = (props: TagCategoryEditDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    editedTagCategory: new TagCategory({}),
  });

  /**
   * props変更時処理
   */
  useEffect(() => {
    updateState({
      editedTagCategory: props.tagCategory.copyWith({})
    });
  }, [props]);

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * テキストフィールドの値変更時
   * @param name
   * @param value
   */
  const handleChange = (name: string, value: string) => {
    const newTagCategoryData = state.editedTagCategory.copyWith({ [name]: value });
    updateState({ editedTagCategory: newTagCategoryData });
  };

  /**
   * 色の変更時
   * @param value
   */
  const handleColorChange = (value: string) => {
    const newTagCategoryData = state.editedTagCategory.copyWith({ color: value });
    updateState({ editedTagCategory: newTagCategoryData });
  };

  /**
   * 保存ボタンクリック時
   */
  const handleSave = () => {
    props.onSave(state.editedTagCategory);
  };

  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            タグ分類編集
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box mt={2} />
        <Typography>ID: {props.tagCategory.id ?? ''}</Typography> {/* 表示専用 */}
        <Box mt={2} />
        <EMTextField
          label="タグ分類名"
          value={state.editedTagCategory.name ?? ''}
          onChange={(value) => handleChange('name', value)}
          error={props.errors['name']}
        />
        <Box mt={1} />
        <Box display={'flex'} flexDirection={"column"} alignItems={'start'} sx={{ width: 200 }}>
          <Typography variant={"caption"} sx={{ color: "grey" }}>タグ分類の色</Typography>
          <Box ml={2} />
          <MuiColorInput
            size={'small'}
            format={'hex'}
            isAlphaHidden={false}
            value={state.editedTagCategory.color ?? ''}
            onChange={handleColorChange}
          />
        </Box>
        <Box mt={2} />
        <DialogActions>
          <Button onClick={handleSave} color="primary" variant={"contained"}>保存</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
