import React, {useRef} from 'react';
import {Box, Typography} from '@mui/material';
import {ChatMessage} from "../../../Models/Chat/ChatMessage";
import {ChatUser} from "./ChatUser";
import {ChatMessageBubble} from "./ChatMessageBubble";
import {ChatMessageContent} from "./ChatMessageContent";
import {CommonUtils} from "../../../Common/CommonUtils";
import Tooltip from '@mui/material/Tooltip';
import EditIcon from "@mui/icons-material/Edit";
import {ChatReactionPanel} from "./Reaction/ChatReactionPanel";
import {ChatReadPanel} from "./Read/ChatReadPanel";
import {ReplyInfo} from "./ChatInterface";

interface ChatMessageItemProps {
  message: ChatMessage;
  popup?: boolean;  // ポップアップ表示か？
  onRePopup?: (replyInfo: ReplyInfo) => void;  // ポップアップ表示時に再度ポップアップを表示した場合のコールバック
  isEditing?: boolean;
  bubbleWidth?: number;
}

/**
 * チャットメッセージアイテム
 * @param props
 * @constructor
 */
export const ChatMessageItem = (props: ChatMessageItemProps) => {
  // BoxのRef
  const itemRef = useRef(null);

  /**
   * 日時・既読情報
   */
  const dateTimeAndRead = (forPopup: boolean, align: string) => {
    if ( props.message.sent_date_time == null ) {
      return '';
    }
    const dateString = CommonUtils.getDateStringJP(props.message.sent_date_time);
    const dateArray = dateString.split(' ');

    let updateDateString = '';
    if ( props.message.updated_at != null ) {
      updateDateString = CommonUtils.getDateStringJP(props.message.updated_at);
    }

    // 編集情報
    const editInfo = props.message.isUpdated() ? (
        <Tooltip title={
          <Typography variant="caption">
            投稿: {dateString}<br/>
            編集: {updateDateString}
          </Typography>
        } placement="top">
          <EditIcon sx={{cursor: "pointer", fontSize: "18px", color: "grey", mr: 1, ml: 1}}/>
        </Tooltip>
    ) : null;

    // ポップアップ
    if ( forPopup ) {
      return (
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"column"} alignItems={"end"}>
            <ChatReadPanel message={props.message} />
          </Box>
          <Box display={"flex"} flexDirection={"row"} >
            <Typography variant="body2">{dateString}</Typography>
            { editInfo }
          </Box>
        </Box>
      );
    }
    // 通常
    else {
      return (
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"column"} alignItems={align}>
            <ChatReadPanel message={props.message} />
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            { align === "end" && editInfo }
            <Box display={"flex"} flexDirection={"column"} alignItems={align}>
              <Typography variant="body2">{dateArray[0]}</Typography>
              <Typography variant="body2">{dateArray[1]}</Typography>
            </Box>
            { align === "start" && editInfo }
          </Box>
        </Box>
      );
    }
  }



  /**
   * 自分がメンションされているか？
   */
  const isMention = () => {
    const mentions = props.message.mentions ?? [];
    for (const mention of mentions) {
      if ( mention.isForMe() ) {
        return true;
      }
    }
    return false;
  }

  /**
   * 他人のメッセージ
   */
  const otherMessage = () => {
    return (
      <Box display={"flex"} flexDirection={"column"}>
        <ChatUser message={props.message} showName={true}/>
        <Box display={"flex"} flexDirection={"row"} alignItems={"end"}>
          <Box ml={4} />
          <ChatMessageBubble
            parentRef={itemRef}
            message={props.message}
            mention={isMention()}
            minWidth={props.bubbleWidth}
            maxWidth={props.bubbleWidth}
          />
          <Box ml={1} />
          {dateTimeAndRead(false, "start")}
        </Box>
      </Box>
    );
  }

  /**
   * 自分のメッセージ
   */
  const myMessage = () => {
    return (
      <Box display={"flex"} flexDirection={"column"} alignItems={"end"}>
        {/*<ChatUser message={props.message} showName={false}/>*/}
        <Box display={"flex"} flexDirection={"row"} alignItems={"end"}>
          {dateTimeAndRead(false, "end")}
          <Box mr={1} />
          <ChatMessageBubble
            parentRef={itemRef}
            message={props.message}
            right={true}
            isEditing={props.isEditing}
            minWidth={props.bubbleWidth}
            maxWidth={props.bubbleWidth}
          />
          <Box mr={4} />
        </Box>
      </Box>
    );
  }

  /**
   * ポップアップ用メッセージ
   */
  const popupMessage = () => {
    return (
      <Box display={"flex"} flexDirection={"column"} sx={{padding: 1 , minWidth: 500}}>
        <Box display={"flex"} flexDirection={"row"} >
          <ChatUser message={props.message} showName={true}/>
          <Box flexGrow={1} />
          {dateTimeAndRead(true, "")}
        </Box>

        <Box display={"flex"} flexDirection={"row"}>
          <Box ml={4} />
          <Box display={"flex"} flexDirection={"column"}>
            <ChatMessageContent
              parentRef={itemRef}
              message={props.message}
              onRePopup={props.onRePopup}
            />
            <Box mt={1} />
            <ChatReactionPanel
              message={props.message}
              disableClick={true}
            />
          </Box>
        </Box>

      </Box>
    );
  }

  /**
   * コンテンツ
   */
  const contents = () => {
    if ( props.popup ) {
      return popupMessage();
    }
    else {
      if ( props.message.isMyMessage() ){
        return myMessage();
      }
      else {
        return otherMessage();
      }
    }
  }

  /**
   * 描画
   */
  return (
    <Box ref={itemRef}>
      {contents()}
    </Box>
  );
};

