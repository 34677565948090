import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {ChatPageParam} from "../../Models/Chat/ChatPageParam";
import {LogUtils} from "../../Common/LogUtils";
import {ProductService} from "../../Services/ProductService";
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import {CommonUtils} from "../../Common/CommonUtils";
import {ChatView} from "./ChatView";
import {ChatService} from "../../Services/ChatService";


/**
 * チャット画面
 *
 * @constructor
 */
export const ChatPage = () => {
  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    chatPageParam: new ChatPageParam({}),
    productName: '',
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useLocation
   */
  const location = useLocation();

  /**
   * URLパラメータ変更時
   */
  useEffect(() => {
    const chatPageParam = ChatPageParam.fromUrl();
    updateState({chatPageParam: chatPageParam});
    loadProductName(chatPageParam.stockCode ?? '').then();
  }, [location.search]);

  /**
   * 商品名読み込み
   */
  const loadProductName = async (stockCode: string) => {
    try {
      const result = await ProductService.getInstance().getSearchResultByStockCode(stockCode);
      updateState({productName: result.minimalUnitProduct?.name ?? ''});
    }
    catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * ツールバークリック
   */
  const onClickToolbar = () => {
    CommonUtils.openProductPage(state.chatPageParam.stockCode ?? '');
  }


  /**
   * ページ遷移
   * @param messageId
   */
  const reloadPage = async (messageId: number) => {
    const message = await ChatService.getInstance().getMessage(messageId);
    if (message == null) {
      return;
    }

    const chatPageParam = new ChatPageParam({
      stockCode: message.stock_code,
      messageId: messageId,
    });
    window.location.href = `/chat?${chatPageParam.toQueryString()}`;
  }


  /**
   * 描画
   */
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            flexGrow={1}
            sx={{cursor: "pointer"}}
            onClick={onClickToolbar}
          >

            <Typography variant="h6" >
              {"EC管理システム [チャット : " +  state.productName + "]"}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        display={"flex"}
        flexDirection={"row"}
      >
        <Box ml={40}/>
        {/* チャットビュー */}
        <ChatView
          chatPageParam={state.chatPageParam}
          onReloadPage={reloadPage}
        />

        <Box mr={40}/>
      </Box>
    </>
  );
}
