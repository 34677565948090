import {
  FormControl, InputBaseComponentProps, SxProps,
  TextField, Theme,
} from "@mui/material";
import {CommonUtils} from "../../../Common/CommonUtils";
import {useState} from "react";

interface EMTextFieldProps {
  error?: string;
  label: string;
  value: string;
  onChange?: (value: string) => void;
  onEnterKeyDown?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  readonly?: boolean;
  sx?: SxProps<Theme>;
  multiline?: boolean;
  rows?: number;
  type?: string;
  inputProps?:  InputBaseComponentProps;
  inputMode?: "search" | "text" | "email" | "tel" | "url" | "none" | "numeric" | "decimal" | undefined;
}

/**
 * テキストフィールド
 *
 * @constructor
 */
export const EMTextField = (props: EMTextFieldProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    isComposing: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };
  /**
   * 描画
   */
  return (
    <FormControl fullWidth variant="outlined" sx={props.sx}>
      <TextField
        size="small"
        type={props.type}
        label={props.label}
        variant="outlined"
        value={props.value}
        disabled={props.disabled}
        onCompositionStart={() => {
          if (props.inputMode !== 'numeric') { // 数値入力モードの場合は無視（なぜか意図せずに発火するので）
            updateState({isComposing: true});
          }
        }}
        onCompositionEnd={() => {
          if (props.inputMode !== 'numeric') {
            updateState({isComposing: false});
          }
        }}
        onChange={(event) => {
          if (props.onChange) {
            props.onChange(event.target.value);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !state.isComposing && props.onEnterKeyDown) {
            props.onEnterKeyDown();
          }
        }}
        onBlur={() => {
          if (props.onBlur) {
            props.onBlur();
          }
        }}
        error={!CommonUtils.isEmpty(props.error)}
        helperText={props.error}
        multiline={props.multiline} // マルチラインプロパティをTextFieldに渡す
        rows={props.multiline ? props.rows : 1} // rowsプロパティをTextFieldに渡す
        InputProps={{
          readOnly: props.readonly,
        }}
        inputProps={{
          inputMode: props.inputMode,
          // style: {
          //   fontSize: props.fontSize,
          // },
        }}
      />
    </FormControl>
  );
};
