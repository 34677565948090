import React, {useEffect, useState} from 'react';
import {Box, Divider, Typography} from "@mui/material";
import {SearchResult} from "../../../../../Models/SearchResult";
import {ProcurementCount} from "./ProcurementCount";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {InlineEditField} from "../../../../Common/Components/InlineEditField";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {ProductService} from "../../../../../Services/ProductService";
import {LogUtils} from "../../../../../Common/LogUtils";
import {EcmgApiError} from "../../../../../Common/EcmgApiError";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";


interface ProcurementCountAreaProps {
  searchResult: SearchResult;
  height: number;
  width: number;
}

/**
 * 要仕入数関連エリア
 * @param props
 * @constructor
 */
export const ProcurementCountArea = (props: ProcurementCountAreaProps) => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
    height: props.height,
    width: props.width,
    backgroundColor: MainPageConstants.COLOR_LIGHT_YELLOW,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    gap: 2,
  };


  /**
   * useSnackbar
   */
  const { showSnackbarMessage } = useSnackbar();

  /**
   * useState
   */
  const [state, setState] = useState({
    procurementUpper: undefined as number | undefined,
    procurementLower: undefined as number | undefined,
    processingUpper: false,
    processingLower: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * props更新時
   */
  useEffect(() => {
    // 仕入れの上限
    const procurementUpper = props.searchResult.product.procurement_upper;
    updateState({ procurementUpper: procurementUpper });

    // 仕入れの下限
    const procurementLower = props.searchResult.product.procurement_lower;
    updateState({ procurementLower: procurementLower });
  }, [props]);


  /**
   * 仕入れの上限変更時
   */
  const onActionUpper = async (valueText: string) => {
    let message = '';

    try {
      // 数字のみ許可
      if (!CommonUtils.isUnsignedInteger(valueText)) {
        return;
      }

      const value = Number(valueText);
      updateState({ processingUpper: true, procurementUpper: value });

      // 上限変更
      message = await ProductService.getInstance().updateProcurementUpper(props.searchResult.product.stock_code!, value);

      updateState({processingUpper: false});

    } catch (e) {
      LogUtils.ex(e);

      updateState({ processingUpper: false, procurementUpper: props.searchResult.product.procurement_upper });

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = '仕入れの上限更新処理でエラーが発生しました。';
      }
    }

    showSnackbarMessage(message);
  };


  /**
   * 仕入れの下限変更時
   */
  const onActionLower = async (valueText: string) => {
    let message = '';

    try {
      // 数字のみ許可
      if (!CommonUtils.isUnsignedInteger(valueText)) {
        return;
      }

      const value = Number(valueText);
      updateState({ processingLower: true, procurementLower: value });

      // 下限変更
      message = await ProductService.getInstance().updateProcurementLower(props.searchResult.product.stock_code!, value);

      updateState({processingLower: false});

    } catch (e) {
      LogUtils.ex(e);

      updateState({ processingLower: false, procurementLower: props.searchResult.product.procurement_lower});

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = '仕入れの下限更新処理でエラーが発生しました。';
      }
    }

    showSnackbarMessage(message);
  };


  /**
   * 描画
   */
  return (
    <Box sx={BOX_STYLE}>
      {/* 要仕入数 */}
      <ProcurementCount searchResult={props.searchResult} />

      <Divider orientation={"vertical"} />

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/*  仕入れの上限 */}
        <InlineEditField
          value={String(state.procurementUpper ?? 0)}
          onAction={onActionUpper}
          isDisabled={false}
          inputType={'numeric'}
          allowNegative={false}
          processing={state.processingUpper}
          width={MainPageConstants.COUNT_INPUT_WIDTH}
        />

        <Box mt={0.5}></Box>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <img width={24} src="/images/namisen.gif" alt="〜" />
        </Box>
        <Box mt={0.5}></Box>

        {/*  仕入れの下限 */}
        <InlineEditField
          value={String(state.procurementLower ?? 0)}
          onAction={onActionLower}
          isDisabled={false}
          inputType={'numeric'}
          allowNegative={false}
          processing={state.processingLower}
          width={MainPageConstants.COUNT_INPUT_WIDTH}
        />

      </Box>

    </Box>
  );
}
