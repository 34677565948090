import React from 'react';
import {Box, Typography} from '@mui/material';
import {ChatMessage} from "../../../Models/Chat/ChatMessage";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {UserService} from "../../../Services/UserService";

interface ChatUserProps {
  message: ChatMessage;
  showName: boolean;
}

/**
 * チャットユーザー名
 * @param props
 * @constructor
 */
export const ChatUser = (props: ChatUserProps) => {

  /**
   * ユーザー名
   */
  const userName = () => {
    if (props.showName) {
      return UserService.getInstance().getUserName(props.message.user_id ?? 0);
    } else {
      return '';
    }
  }

  /**
   * 描画
   */
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box>
        <AccountCircleIcon fontSize="large" style={{ color: UserService.getInstance().getUserColor(props.message.user_id ?? 0) }} />
      </Box>
      <Typography variant="body1">{userName()}</Typography>
    </Box>
  );
};

