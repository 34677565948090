import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { User } from "../../../Models/User";
import {EMSelect} from "../../Common/Components/EMSelect";
import {Constants} from "../../../Common/Constants";
import {EMTextField} from "../../Common/Components/EMTextField";
import {OfficeService} from "../../../Services/OfficeService";
import {MuiColorInput} from "mui-color-input";

interface UserEditDialogProps {
  open: boolean;
  user: User;
  onClose: () => void;
  onSave: (user: User) => void;
  errors: Record<string, string>,
}

/**
 * ユーザー編集ダイアログ
 * @param props
 * @constructor
 */
export const UserEditDialog = (props: UserEditDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    editedUser: new User({}),
  });

  /**
   * props変更時処理
   */
  useEffect(() => {
    updateState({
      editedUser: props.user.copyWith({})
    });
  }, [props]);

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * テキストフィールドの値変更時
   * @param name
   * @param value
   */
  const handleChange = (name: string, value: string) => {
    const newUserData = state.editedUser.copyWith({ [name]: value });
    updateState({ editedUser: newUserData });
  };

  /**
   * ユーザータイプの変更時
   * @param value
   */
  const handleUserTypeChange = (value: string) => {
    const newUserType = parseInt(value);
    const newUserData = state.editedUser.copyWith({ user_type: isNaN(newUserType) ? undefined : newUserType });
    updateState({ editedUser: newUserData });
  };

  /**
   * 事業所IDの変更時
   * @param value
   */
  const handleOfficeIdChange = (value: string) => {
    const newOfficeId = parseInt(value);
    const newUserData = state.editedUser.copyWith({ office_id: isNaN(newOfficeId) ? undefined : newOfficeId });
    updateState({ editedUser: newUserData });
  };

  /**
   * 色の変更時
   * @param value
   */
  const handleColorChange = (value: string) => {
    const newUserData = state.editedUser.copyWith({ color: value });
    updateState({ editedUser: newUserData });
  };

  /**
   * 保存ボタンクリック時
   */
  const handleSave = () => {
    props.onSave(state.editedUser);
  };

  /**
   * ユーザータイプの選択肢を Constants クラスから作成
   */
  const userTypes =  () => {
    return Object.entries(Constants.USER_TYPE_LABELS).map(([id, label]) => ({
      id: parseInt(id),
      label
    }));
  }

  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            ユーザー編集
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box mt={2} />
        <Typography>ID: {props.user.id ?? ''}</Typography> {/* 表示専用 */}
        <Box mt={2} />
        <EMTextField
          label="ユーザー名"
          value={state.editedUser.name ?? ''}
          onChange={(value) => handleChange('name', value)}
          error={props.errors['name']}
        />
        <Box mt={2} />
        <EMTextField
          label="メールアドレス"
          value={state.editedUser.email ?? ''}
          onChange={(value) => handleChange('email', value)}
          error={props.errors['email']}
        />
        <Box mt={2} />
        <EMTextField
          label="パスワード"
          type="password"
          value={state.editedUser.password ?? ''}
          onChange={(value) => handleChange('password', value)}
          error={props.errors['password']}
        />
        <Box mt={2} />
        <EMTextField
          label="パスワード(確認)"
          type="password"
          value={state.editedUser.password_confirm ?? ''}
          onChange={(value) => handleChange('password_confirm', value)}
          error={props.errors['password_confirm']}
        />
        <Box mt={2} />
        <EMSelect
          label="ユーザータイプ"
          value={state.editedUser.user_type?.toString() ?? ''}
          onChange={handleUserTypeChange}
          menuItems={userTypes()}
          itemValueKey="id"
          itemLabelKey="label"
          hideUnselected={true}
          error={props.errors['user_type']}
          sx={{ width: 200 }}
        />
        <Box mt={2} />
        <EMSelect
          label="事業所"
          value={state.editedUser.office_id?.toString() ?? ''}
          onChange={handleOfficeIdChange}
          menuItems={OfficeService.getInstance().getAllOffices()}
          itemValueKey="id"
          itemLabelKey="name"
          error={props.errors['office_id']}
          sx={{ width: 200 }}
        />
        <Box mt={1} />
        <Box display={'flex'} flexDirection={"column"} alignItems={'start'} sx={{ width: 200 }}>
          <Typography variant={"caption"} sx={{color: "grey"}}>ユーザーの色</Typography>
          <Box ml={2} />
          <MuiColorInput
            size={'small'}
            format={'hex'}
            isAlphaHidden={false}
            value={state.editedUser.color ?? ''}
            onChange={handleColorChange}
          />
        </Box>
        <Box mt={2} />
        <DialogActions>
          <Button onClick={handleSave} color="primary" variant={"contained"}>保存</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
