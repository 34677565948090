import React from 'react';
import { StoreProduct } from "../../../../../Models/Product/StoreProduct";
import { Box, Typography } from "@mui/material";
import {MainPageConstants} from "../../../Common/MainPageConstants";

interface StoreProductUpdateDurationProps {
  storeProduct: StoreProduct;
  width: number;
}

/**
 * 店舗商品更新期間
 * @param props
 * @constructor
 */
export const StoreProductUpdateDuration = (props: StoreProductUpdateDurationProps) => {

  /**
   * 指定した日付から現在までの経過時間をフォーマットして返す関数
   * @param date
   */
  const formatDateDiff = (date: Date | undefined): string => {
    if (!date) return '---'; // 日付が存在しない場合

    const now = new Date();
    const diffMilliseconds = now.getTime() - date.getTime(); // 経過時間（ミリ秒）

    const minutes = diffMilliseconds / (60 * 1000);
    const hours = minutes / 60;
    const days = hours / 24;
    const months = days / 30.44; // 月平均日数で計算
    const years = days / 365.25; // 閏年を考慮して計算

    // それぞれの経過時間に応じてフォーマットを適用
    if (minutes < 60) {
      return `${Math.floor(minutes)}m`;
    } else if (hours < 24) {
      return `${Math.floor(hours)}h`;
    } else if (days < 93) {
      return `${Math.floor(days)}d`;
    } else if (days < 365) {
      return `${Math.round(months * 10) / 10}M`;
    } else {
      return `${Math.round(years * 10) / 10}Y`;
    }
  };

  /**
   * 描画
   */
  return (
    <Box
      sx={{
        width: props.width,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography color={MainPageConstants.COLOR_DARK_GRAY} fontSize={"16px"}>
        {formatDateDiff(props.storeProduct.man_inv_updated_at)} / {formatDateDiff(props.storeProduct.man_price_updated_at)}
      </Typography>
    </Box>
  );
};
