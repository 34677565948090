import React, {useEffect, useState} from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography, AppBar, Toolbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ProductService } from "../../Services/ProductService";
import {EMTextField} from "../Common/Components/EMTextField";
import {LogUtils} from "../../Common/LogUtils";
import {EcmgApiError} from "../../Common/EcmgApiError";
import {CommonUtils} from "../../Common/CommonUtils";
import {useSnackbar} from "../Common/Provider/SnackbarContext";

interface ProductDeleteDialogProps {
  open: boolean;
  onClose: () => void;
}

/**
 * 商品削除ダイアログ
 * @param props
 * @constructor
 */
export const ProductDeleteDialog = (props: ProductDeleteDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    csvData: '' as string,
    processing: false
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 初期化
   */
  useEffect(() => {
    updateState({csvData: ''});
  }, [props]);

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * CSVデータのバリデーション
   * @param rows
   */
  const validateCsvData = (rows: string[][]) => {
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (row.length !== 3) {
        return `行${i + 1}: CSVデータの形式が正しくありません。列数が3である必要があります。`;
      }
      const validMallNames = ["Yahoo!", "Rakuten", "BASE"];
      if (!validMallNames.includes(row[0])) {
        return `行${i + 1}: モール名が無効です。'Yahoo!', 'Rakuten', 'BASE'のいずれかである必要があります。`;
      }
    }
    return null;
  };

  /**
   * 実行ボタン押下時
   */
  const handleExecute = async () => {
    const rows = parseCsvData(state.csvData);

    const validationError = validateCsvData(rows);
    if (validationError) {
      alert(validationError);
      return;
    }
    // 確認ダイアログ表示
    if (!window.confirm('商品削除を実行します。よろしいですか？')) {
      return;
    }

    let message = '';
    try {
      updateState({ processing: true });

      // 商品削除処理
      message = await ProductService.getInstance().deleteProducts(rows);
      // 再検索
      await ProductService.getInstance().reSearch();

      updateState({processing: false});
    }
    catch (e) {
      LogUtils.ex(e);

      updateState({processing: false});

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = '商品削除処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);

    // ダイアログを閉じる
    props.onClose();
  };

  /**
   * CSVデータをパースする
   * @param csvData
   */
  const parseCsvData = (csvData: string) => {
    const data = csvData.trim();
    return data.split("\n").map(row => row.split(","));
  };

  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            商品削除
          </Typography>
          <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          モール側で削除された商品をシステムから削除するためのダイアログです。<br/>
          貼り付ける削除データの仕様は以下の通りです。<br/>
          [モール名],[モール店舗識別子],[商品識別子]<br/>
          ※モール名、モール店舗識別子は店舗設定の詳細から確認できます。
        </Typography>
        <Typography variant="body2">
          例:
          <br/>
          BASE,valuestore-theshop-jp,79185179
          <br/>
          Yahoo!,elohas,ML21GIFTMESSAGE-01P1
          <br/>
          Rakuten,r-valuestore,ml21giftmessage-01p1
          <br/>
          ：
        </Typography>

        <EMTextField
          label="CSVデータ"
          value={state.csvData}
          onChange={(value) => updateState({ csvData: value })}
          multiline
          rows={8}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleExecute} disabled={state.processing}>
          実行
        </Button>
      </DialogActions>
    </Dialog>
  );

};
