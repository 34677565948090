import { LogUtils } from "../Common/LogUtils";
import { HttpServiceBase } from "./HttpServiceBase";
import { EcmgApiError } from "../Common/EcmgApiError";
import {SelectionService} from "./System/SelectionService";
import {SearchParam} from "../Common/SearchParam";

/**
 * 一括処理サービス
 */
export class BulkActionService extends HttpServiceBase {
  private static instance: BulkActionService = new BulkActionService();

  private constructor() {
    super();
  }

  static getInstance(): BulkActionService {
    return this.instance;
  }

  async init(): Promise<void> {
  }

  /**
   * 一括処理実行
   * @param actionType
   */
  public async executeBulkAction(actionType: number): Promise<string> {

    try {
      // 現在の検索条件を取得
      const searchParam = SearchParam.fromUrl();

      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/bulk-action?` + searchParam.toQueryString(),
        {
          action_type: actionType,
          selection: JSON.stringify(SelectionService.instance().getSelectionData()),
        },
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }


}
