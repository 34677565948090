import React from 'react';
import {Box, Typography} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {SmartToyOutlined} from "@mui/icons-material";
import {AIChatMessage} from "../Model/AIChatMessage";

interface ChatUserProps {
  message: AIChatMessage;
  showName: boolean;
}

/**
 * AIチャットユーザー名
 * @param props
 * @constructor
 */
export const AIChatUser = (props: ChatUserProps) => {

  /**
   * ユーザー名
   */
  const userName = () => {
    if (props.message.isMyMessage()) {
      return "あなた";
    } else {
      return "AI";
    }
  }

  /**
   * アイコン
   */
  const icon = () => {
    if ( props.message.isMyMessage() ){
      return (
        <AccountCircleIcon fontSize="large"  />
      );
    }
    else {
      return (
        <SmartToyOutlined fontSize="large" />
      );
    }
  }

  /**
   * 描画
   */
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box>
        {icon()}
      </Box>
      <Typography variant="body1">{userName()}</Typography>
    </Box>
  );
};

