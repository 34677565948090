import React, {useState, KeyboardEvent, useEffect} from 'react';
import {Box, TextField, FormControl, Typography, CircularProgress} from '@mui/material';
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {FourButtons} from "./FourButtons";
import {MainPageConstants} from "../../../Common/MainPageConstants";

interface AddCountComponentProps {
  label: string;
  stockCount: string | undefined
  onAction: (value: string) => void;
  processing?: boolean;
  isDisabled: boolean;
}

/**
 * 数値入力コンポーネント
 * @param props
 * @constructor
 */
export const AddCountComponent = (props: AddCountComponentProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    stockCount: '',
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * props変更時
   */
  useEffect(() => {
    updateState({ stockCount: props.stockCount ?? '' });
  }, [props]);

  /**
   * 数値入力
   * @param event
   */
  const handleStockChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === '') {
      updateState({ stockCount: '' });
      return;
    }

    if (!CommonUtils.isSignedInteger(value)) {
      event.target.value = state.stockCount?.toString() || "";
      return;
    }
    updateState({ stockCount: value });
  };

  /**
   * キープレス
   * @param event
   */
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      props.onAction(state.stockCount || '');
    }
  };

  /**
   * フォーカス
   * @param event
   */
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  /**
   * フォーカスアウト
   */
  const handleBlur = () => {
    if (!props.processing) {
      updateState({ stockCount: '' });
    }
  };

  /**
   * ボタンクリック
   * @param num
   */
  const onClickFourButton = (num: number) => {
    const numString = num.toString();
    updateState({ stockCount: numString });
    props.onAction(numString);
  };

  /**
   * 描画
   */
  return (
    <Box sx={{ flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: MainPageConstants.COLOR_DARK_GRAY
        }}
      >
        <Typography variant="body1">{props.label}</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', width: MainPageConstants.COUNT_INPUT_WIDTH}}>
          <FormControl fullWidth variant="outlined">
            <TextField
              value={state.stockCount}
              onChange={handleStockChange}
              onKeyDown={handleKeyPress}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={props.isDisabled || !!props.processing}
              size="small"
              sx={{
                flex: 1,
                backgroundColor: props.isDisabled ? MainPageConstants.COLOR_LIGHT_GRAY : '#ffffff',
                '& fieldset': {
                  borderColor: 'darkgreen',
                },
                '& .MuiInputBase-input': {
                  color: MainPageConstants.COLOR_DARK_GRAY
                }
              }}
              inputProps={{
                inputMode: 'numeric',
                maxLength: 4,
                style: {
                  textAlign: 'right',
                  padding: 2,
                },
              }}
            />
          </FormControl>
          {props.processing && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
                height: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
                ml: 1 }}>
              <CircularProgress size={MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT} />
            </Box>
          )}
        </Box>
      </Box>
      <Box mt={1}></Box>
      <FourButtons
        onClick={onClickFourButton}
        buttonSize={MainPageConstants.FOUR_BUTTON_SIZE}
        disabled={props.isDisabled || !!props.processing}
      />
    </Box>
  );
};
