import { LogUtils } from "../Common/LogUtils";
import { HttpServiceBase } from "./HttpServiceBase";
import { EcmgApiError } from "../Common/EcmgApiError";
import {OperationHistory} from "../Models/OperationHistory/OperationHistory";

/**
 * 操作履歴サービス
 */
export class OperationHistoryService extends HttpServiceBase {
  private static instance: OperationHistoryService = new OperationHistoryService();

  private constructor() {
    super();
  }

  static getInstance(): OperationHistoryService {
    return this.instance;
  }


  /**
   * 仕入履歴取得
   * @param stockCode
   */
  public async getUnreceivedHistory(stockCode: string): Promise<OperationHistory[]> {
    LogUtils.d();
    try {
      const axios = await this.getAxios(true);
      const response = await axios.get(
        `/api/operation-history/unreceived?stock_code=${stockCode}`,
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      const ret: OperationHistory[] = [];
      if (response.data && Array.isArray(response.data)) {
        response.data.forEach(data => {
          const history = OperationHistory.fromMap(data);
          ret.push(history);
        });
      }
      return ret;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

}
