import {StoreProduct} from "./StoreProduct";

/**
 * ユニット商品
 */
export class UnitProduct {
  id?: number;
  stock_code?: string;
  unit_code?: string;
  is_min_unit?: number;
  name?: string;
  unit_name?: string;
  unit?: number;
  shipping_code?: number;
  has_defect?: number;
  stock_count?: number;
  hiring_store_id?: number;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<UnitProduct> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<UnitProduct>): UnitProduct {
    return new UnitProduct({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): UnitProduct {
    return new UnitProduct({
      ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): UnitProduct {
    const decoded = JSON.parse(jsonString);
    return UnitProduct.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  // こちらはDataGridの値として適切な項目を選択していますが、変更する必要がある場合があります。
  getDataGridValues(): string[] {
    return [
      this.id?.toString() || '',
      this.stock_code || '',
      this.unit_code || '',
      this.is_min_unit?.toString() || '',
      // ... その他の項目も同様に追加 ...
    ];
  }
}
