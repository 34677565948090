import {StoreOrderCountData} from "../../../../../Models/Product/OrderCountData";
import {StoreProduct} from "../../../../../Models/Product/StoreProduct";
import {StoreProductPrice} from "./StoreProductPrice";
import {SKUConstants} from "../SKUConstants";
import {Box} from "@mui/material";
import {StoreProductSalesTrend} from "./StoreProductSalesTrend";
import {StoreProductStatus} from "./StoreProductStatus";
import {StoreProductUpdateDuration} from "./StoreProductUpdateDuration";
import {StoreCode} from "./StoreCode";
import {Product} from "../../../../../Models/Product/Product";
import {UnitProduct} from "../../../../../Models/Product/UnitProduct";
import {MainPageConstants} from "../../../Common/MainPageConstants";

interface StoreProductPanelProps {
  product: Product;
  unitProduct: UnitProduct;
  storeProduct: StoreProduct;
  storeOrderCountData: StoreOrderCountData | undefined;
  canChange: boolean;
}

export const StoreProductPanel = (props: StoreProductPanelProps) => {

  // LogUtils.d("storeOrderCountData:" + JSON.stringify(props.storeOrderCountData));
  const isNonPublic = props.storeProduct.pub_status === 0; // 非公開を判定する条件

  return (
    <Box
      display="flex"
      alignItems="start"
      sx={isNonPublic ? { backgroundColor: MainPageConstants.COLOR_LIGHT_GREEN } : {}}
    >
      {/* 店舗コード */}
      <StoreCode
        storeProduct={props.storeProduct}
        width={SKUConstants.STORE_PRODUCT_CODE_WIDTH}
      />
      {/* 店舗商品価格 */}
      <StoreProductPrice
        hasPricePattern={props.product.price_pattern_id != null}
        storeProduct={props.storeProduct}
        width={SKUConstants.STORE_PRODUCT_PRICE_WIDTH}
        canChange={props.canChange}
      />
      {/* 店舗商品販売実績 */}
      <StoreProductSalesTrend
        storeProduct={props.storeProduct}
        storeOrderCountData={props.storeOrderCountData}
        width={SKUConstants.STORE_PRODUCT_SALES_TREND_WIDTH}
      />
      {/* 店舗商品公開ステータス */}
      <StoreProductStatus
        storeProduct={props.storeProduct}
        width={SKUConstants.STORE_PRODUCT_STATUS_WIDTH}
        canChange={props.canChange}
      />
      {/* 店舗商品更新期間 */}
      <StoreProductUpdateDuration
        storeProduct={props.storeProduct}
        width={SKUConstants.STORE_PRODUCT_UPDATED_AT_WIDTH}
      />
    </Box>
  );
};
