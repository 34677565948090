import {Box, Typography} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ReplyIcon from "@mui/icons-material/Reply";
import React, {useEffect, useState} from "react";
import {ChatMessagePopover} from "./ChatMessagePopover";
import {UserService} from "../../../../Services/UserService";
import {ReplyInfo} from "../ChatInterface";



interface ChatTagReplyProps {
  replyInfo: ReplyInfo | null;
  onRePopup? : (replyInfo: ReplyInfo) => void; // ポップアップ表示時に再度ポップアップを表示した場合のコールバック
}

/**
 * チャットタグ 返信
 * @param props
 * @constructor
 */
export const ChatTagReply = (props: ChatTagReplyProps) => {

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    replyInfo: null as ReplyInfo | null,
    anchorEl: null as HTMLDivElement | null,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useEffect
   */
  useEffect(() => {
    updateState({ replyInfo: props.replyInfo }); // 再ポップアップのためにstateを使う
  }, [props.replyInfo]);

  /**
   * メッセージのポップアップ表示
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if ( state.replyInfo == null ) {
      return;
    }
    // 返信元の再ポップアップ
    if ( props.onRePopup ) {
      props.onRePopup(state.replyInfo);
    }
    // 通常
    else {
      updateState({ anchorEl: event.currentTarget });
    }
  };

  /**
   * ポップアップを閉じる
   */
  const handleClose = () => {
    // 返信情報を初期値にリセット
    updateState({ anchorEl: null, replyInfo: props.replyInfo});
  };

  /**
   * ポップアップ表示
   * @param replyInfo
   */
  const handleRePopup = (replyInfo: ReplyInfo) => {
    const anchorEl = state.anchorEl;
    updateState({ anchorEl: null }); // 一度閉じる
    setTimeout(() => {
      // 返信情報を更新
      updateState({ replyInfo: replyInfo, anchorEl: anchorEl }); // 再表示
    });
  }

  /**
   * 描画
   */
  const replyInfo = state.replyInfo ?? props.replyInfo; // state更新が追いつかず、ちらつくことがあるので
  const uid = Number(replyInfo?.uid ?? '');
  const color = UserService.getInstance().getUserColor(uid);
  //LogUtils.d('ChatTagReply ' + replyInfo?.aid + ' uid:' + uid + ' color:' + color);
  return (
    <Box sx={{display: 'inline-flex', alignItems: 'center', mb: 1, verticalAlign: 'top'}}>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          backgroundColor: '#7fffd4',
          borderRadius: 1,
          cursor: state.replyInfo != null ? 'pointer' : 'default',
        }}
        onClick={handleClick}
      >
        <ReplyIcon/>
        <Typography variant="body2" sx={{fontWeight: 'bold', mr: 1}}>
          RE
        </Typography>
      </Box>
      <AccountCircleIcon style={{ color: color }}/>

      <ChatMessagePopover
        open={Boolean(state.anchorEl)}
        anchorEl={state.anchorEl}
        onClose={handleClose}
        aid={state.replyInfo?.aid ?? ''}
        onRePopup={handleRePopup}
      />
    </Box>
  );
};

