import React from 'react';
import Box from '@mui/material/Box';
import {Button, Popover} from "@mui/material";
import {ChatMessage} from "../../../../Models/Chat/ChatMessage";
import {EventService} from "../../../../Services/System/EventService";

interface ChatQuotePanelProps {
  open: boolean; // ポップアップ表示フラグ
  anchorPosition: { top: number, left: number }; // ポップアップ表示位置
  message: ChatMessage; // チャットメッセージ
  selectedText: string; // 選択テキスト
  onClose: () => void; // ポップアップを閉じる
}

/**
 * チャット引用パネル
 * @param props
 * @constructor
 */
export const ChatQuotePanel = (props: ChatQuotePanelProps) => {
  /**
   * 引用クリック
   */
  const handleQuoteClick = () => {

    props.onClose();

    setTimeout(() => {
      let message = "[引用 aid=" + props.message.id;
      message += " uid=" + props.message.user_id;
      const unixTime = props.message.sent_date_time ? Math.floor(props.message.sent_date_time.getTime() / 1000) : 0;
      message += " time=" + unixTime;
      message += "]" + props.selectedText + "[/引用]\n";
      EventService.getInstance().emitEvent(EventService.EVENT_CHAT_MESSAGE_INSERT, message);

    } , 100); // ポップアップが閉じるまでの時間

  };

  /**
   * 描画
   */
  return (
    <>
      <Popover
        open={props.open}
        anchorReference="anchorPosition"
        anchorPosition={props.anchorPosition}
        onClose={(e) => props.onClose()}
      >
        <Box>
          <Box display="flex" flexDirection="row">
            <Button onClick={handleQuoteClick}>メッセージに引用</Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

