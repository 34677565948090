import React from 'react';
import {Box, Typography} from "@mui/material";
import {DigitDisplay} from "../../../../Common/Components/DigitDisplay";
import {MainPageConstants} from "../../../Common/MainPageConstants";

interface USADigitCommonProps {
  leftLabel: string;
  rightLabel: string;
  value: number | undefined;
}

/**
 * 未着在庫関連エリア 数値表記共通
 * @param props
 * @constructor
 */
export const USADigitCommon = (props: USADigitCommonProps) => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: 2,
  };

  const ELEMENT_STYLE = {
    width: 60,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  /**
   * 描画
   */
  return (
    <Box sx={BOX_STYLE}>
      <Typography fontSize={"14px"} color={MainPageConstants.COLOR_DARK_GRAY} sx={ELEMENT_STYLE}>{props.leftLabel}</Typography>
      <DigitDisplay
        value={props.value}
        length={3}
        filledColor={MainPageConstants.COLOR_BLACK}
        //emptyColor={MainPageConstants.COLOR_TRANSPARENT}
        fontSize={"24px"}
      />
      <Typography fontSize={"14px"} color={"grey"}>{props.rightLabel}</Typography>
    </Box>
  );
}

