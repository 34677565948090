import React, { useEffect, useState } from 'react';
import {
  Box,
  Button, Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {StoreService} from "../../../Services/StoreService";
import {Store} from "../../../Models/Store";
import {SettingConstants} from "../SettingConstants";
import {LogUtils} from "../../../Common/LogUtils";
import {EcmgApiError} from "../../../Common/EcmgApiError";
import {CommonUtils} from "../../../Common/CommonUtils";
import {useSnackbar} from "../../Common/Provider/SnackbarContext";
import ECProgress from "../../Common/Components/ECProgress";
import {Constants} from "../../../Common/Constants";
import {SyncOrderDialog} from "./SyncOrderDialog";
import {StoreEditDialog} from "./StoreEditDialog";
import {ProductSyncDialog} from "./ProductSyncDialog";
import {EMTextField} from "../../Common/Components/EMTextField";
import {SystemSettingService} from "../../../Services/SystemSettingService";
import {SystemSetting} from "../../../Models/SystemSetting";

/**
 * 店舗設定コンポーネント
 */
export const StoreSetting = () => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
  };
  /**
   * useState
   */
  const [state, setState] = useState({
    stores: [] as Store[],
    exclusiveKeyword: '',
    exclusiveKeywordChanged: false,
    editDialogOpen: false,
    editErrors: {},
    orderSyncDialogOpen: false,
    productSyncDialogOpen: false,
    selectedStore: new Store({}),
    processing: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();
  /**
   * マウント時の処理
   */
  useEffect(() => {
    updateState({ stores: StoreService.getInstance().getAllStores() });
    const exclusiveKeyword = SystemSettingService.getInstance().getSettingByKey(SystemSetting.KEY_EXCLUSIVE_KEYWORD);
    updateState({ exclusiveKeyword: exclusiveKeyword?.value ?? '' });

    // アンマウント時の処理
    return () => {
    };
  }, []);

  /**
   * API連携クリック時
   * @param mallNo
   * @param storeId
   */
  const handleAPIIntegration = (mallNo: number, storeId: number) => {
    StoreService.getInstance().apiIntegration(mallNo, storeId).then();
  };

  /**
   * 注文同期チェックボックス
   * @param store
   */
  const handleOrderSyncCheck = async (store: Store) => {
    updateState({ selectedStore: store });

    const currentOrderSync = store.sync_flag === 1;

    //--------------
    // チェックON
    //--------------
    if ( !currentOrderSync ) {
      updateState({orderSyncDialogOpen: true});
    }
    //--------------
    // チェックOFF
    //--------------
    else {
      // サービスメソッドを呼び出して設定を更新
      await StoreService.getInstance().setOrderSyncSettings(
        store.id!,
        false,
      );
      // 店舗情報再取得
      updateState({ stores: StoreService.getInstance().getAllStores() });
    }
  };

  /**
   * 注文同期ダイアログOK
   */
  const handleSyncDialogOK = async (syncDate: string, syncTime: string) => {
    if (state.selectedStore == null) {
      return;
    }
    if ( syncDate === '' || syncTime === '' ) {
      alert('日付と時間を入力してください。');
      return;
    }

    updateState({ orderSyncDialogOpen: false });

    // 日付と時間を結合してISO形式の日付文字列を作成
    const startDate = `${syncDate}T${syncTime}:00`;

    // サービスメソッドを呼び出して設定を更新
    await StoreService.getInstance().setOrderSyncSettings(
      state.selectedStore.id!,
      true,
      startDate
    );
    // 店舗情報再取得
    updateState({ stores: StoreService.getInstance().getAllStores() });
  };

  /**
   * 注文同期ダイアログクローズ
   */
  const handleSyncDialogClose = () => {
    if (state.selectedStore == null) {
      return;
    }
    updateState({ orderSyncDialogOpen: false });
    // 店舗情報再取得
    updateState({ stores: StoreService.getInstance().getAllStores() });
  };


  /**
   * 全商品同期チェックボックス
   * @param store
   * @param reserve
   */
  const handleAllProductSyncCheck = async (store: Store, reserve: boolean) => {
    // サービスメソッドを呼び出して設定を更新
    await StoreService.getInstance().reserveAllProductSync(
      store.id!,
      reserve,
    );
    // 店舗情報再取得
    updateState({ stores: StoreService.getInstance().getAllStores() });
  };



  /**
   * 編集ボタンクリック時の処理
   * @param store
   */
  const handleEditClick = (store: Store) => {
    updateState({
      selectedStore: store ,
      editDialogOpen: true,
      editErrors: {},
    });
  };

  /**
   * 編集ダイアログでの保存処理
   * @param editedStore
   */
  const handleEditSave = async (editedStore: Store) => {
    let message = '';

    try {
      updateState({
        processing: true,
        selectedStore: editedStore
      });

      // 店舗情報更新
      message = await StoreService.getInstance().updateStore(editedStore);
      // 店舗情報再取得
      updateState({
        processing: false,
        stores: StoreService.getInstance().getAllStores() ,
        editDialogOpen: false,
        editErrors: {},
      });
    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });
      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
        updateState({ editErrors: e.getErrorMessageMap()});
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = '店舗情報登録処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  /**
   * 編集ダイアログを閉じる処理
   */
  const handleEditDialogClose = () => {
    updateState({
      editDialogOpen: false,
    });
  };

  // /**
  //  * 個別商品同期ボタンクリック時の処理
  //  * @param store
  //  */
  // const handleProductSyncClick = (store: Store) => {
  //   updateState({
  //     selectedStore: store ,
  //     productSyncDialogOpen: true,
  //   });
  // };

  /**
   * 個別商品同期ダイアログでの保存処理
   */
  const handleProductSyncSave = async (stockCode: string) => {
    let message = '';

    try {
      if ( !state.selectedStore ) {
        return;
      }

      updateState({ processing: true, productSyncDialogOpen: false });

      // 個別商品同期実行
      message = await StoreService.getInstance().syncProduct(state.selectedStore.id!, stockCode);

      updateState({ processing: false });
    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });
      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = '個別商品同期処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  /**
   * 個別商品同期ダイアログを閉じる処理
   */
  const handleProductSyncDialogClose = () => {
    updateState({
      productSyncDialogOpen: false,
    });
  };

  /**
   * 商品同期対象外キーワード保存処理
   */
  const handleExclusiveKeywordSave = async () => {
    let message = '商品同期対象外キーワードを更新しました。';

    if ( !state.exclusiveKeywordChanged ){
      return;
    }

    try {
      updateState({
        processing: true,
      });

      await SystemSettingService.getInstance().updateSetting(new SystemSetting({
        key: SystemSetting.KEY_EXCLUSIVE_KEYWORD,
        value: state.exclusiveKeyword,
      }));

      updateState({
        processing: false,
        exclusiveKeywordChanged: false,
      });
    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });
      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
        updateState({ editErrors: e.getErrorMessageMap()});
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = '商品同期対象外キーワード保存処理でエラーが発生しました。';
      }
    }
    // スナックバー表示
    showSnackbarMessage(message);
  };

  /**
   * 描画
   */
  return (
    <>
      <Box mt={2}></Box>
      <Box sx={BOX_STYLE}>
        <Box><Typography>店舗設定</Typography></Box>
        <TableContainer>
            <Table>
              <TableHead style={SettingConstants.TABLE_HEADER_STYLE}>
                <TableRow>
                  <TableCell>店舗名</TableCell>
                  <TableCell align={"center"}>API連携</TableCell>
                  <TableCell align={"center"}>注文同期</TableCell>
                  <TableCell align={"center"}>全商品同期予約</TableCell>
                  <TableCell align={"center"}>アクション</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.stores.map((store) => (
                  <TableRow key={store.id}>
                    <TableCell>{store.store_name}</TableCell>
                    <TableCell align={"center"}>
                      <Button
                        variant={"outlined"}
                        disabled={store.mall_no === Constants.MALL_RAKUTEN}
                        onClick={() => { handleAPIIntegration(store.mall_no!, store.id!) }}>
                        API連携
                      </Button>
                    </TableCell>
                    <TableCell align={"center"}>
                      <label>
                        <Checkbox checked={store.sync_flag === 1} onChange={() => handleOrderSyncCheck(store)} />
                        注文同期を行う
                      </label>
                    </TableCell>
                    <TableCell align={"center"}>
                      {/*<Button*/}
                      {/*  variant={"outlined"}*/}
                      {/*  onClick={() => { handleProductSyncClick(store) }}>*/}
                      {/*  個別商品同期*/}
                      {/*</Button>*/}
                      <label>
                        <Checkbox
                          checked={store.product_sync_flg === 1}
                          onChange={(e) => handleAllProductSyncCheck(store, e.target.checked)} />
                        全商品同期を予約する
                      </label>
                    </TableCell>
                    <TableCell align={"center"}>
                      <Button onClick={() => handleEditClick(store)}>
                        <EditIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
        </TableContainer>
      </Box>

      <Box
        mt={2}
      >
        <EMTextField
          label="商品同期対象外キーワード(カンマ区切り指定)"
          value={state.exclusiveKeyword}
          onChange={(value) => updateState({ exclusiveKeyword: value, exclusiveKeywordChanged: true })}
          onEnterKeyDown={handleExclusiveKeywordSave}
          onBlur={handleExclusiveKeywordSave}
        />
      </Box>

      {/*  編集ダイアログ */}
      <StoreEditDialog
        open={state.editDialogOpen}
        store={state.selectedStore}
        onClose={handleEditDialogClose}
        onSave={handleEditSave}
        errors={state.editErrors}
      />

      {/* 注文同期ダイアログ */}
      <SyncOrderDialog
        open={state.orderSyncDialogOpen}
        onClose={handleSyncDialogClose}
        onOK={handleSyncDialogOK}
      />

      {/* 個別商品同期ダイアログ */}
      <ProductSyncDialog
        open={state.productSyncDialogOpen}
        storeId={state.selectedStore.id ?? -1}
        onClose={handleProductSyncDialogClose}
        onOK={handleProductSyncSave}
      />

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>

    </>
  );
};

