import {Box, Link, Typography} from "@mui/material";
import {Product} from "../../../../../Models/Product/Product";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import React, {useState} from "react";
import {LogUtils} from "../../../../../Common/LogUtils";
import {OperationHistory} from "../../../../../Models/OperationHistory/OperationHistory";
import {OperationHistoryService} from "../../../../../Services/OperationHistoryService";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";
import {UnreceivedHistoryDialog} from "./History/UnreceivedHistoryDialog";

interface UnreceivedStockCountProps {
  product: Product;
}

/**
 * 未着在庫数
 * @param props
 * @constructor
 */
export const UnreceivedStockCount = (props: UnreceivedStockCountProps) => {

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    unreceivedHistories: [] as OperationHistory[],
    unreceivedHistoryDialogOpen: false,
    processing: false,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * 仕入履歴表示
   */
  const onShowHistory = async () => {
    try {
      updateState({processing: true});

      const data
        = await OperationHistoryService.getInstance().getUnreceivedHistory(props.product.stock_code ?? '');

      updateState({
        unreceivedHistoryDialogOpen: true,
        unreceivedHistories: data,
        processing: false});
    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('仕入履歴表示でエラーが発生しました。');
    }
    finally {
      updateState({processing: false});
    }
  }

  /**
   * 描画
   */
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Typography fontSize={"14px"} color={MainPageConstants.COLOR_DARK_GRAY}>未着在庫</Typography>
      <Typography variant={"h4"} fontWeight={"bold"}>
        {props.product.unreceived_stock}
      </Typography>

      {/* 仕入履歴表示リンク */}
      <Link
        component="button"
        variant="body2"
        onClick={() => onShowHistory()}
        sx={{ mt: 2, cursor: 'pointer', textDecoration: 'underline' }}
      >
        履歴
      </Link>

      {/* 仕入履歴ダイアログ */}
      <UnreceivedHistoryDialog
        open={state.unreceivedHistoryDialogOpen}
        onClose={() => {updateState({unreceivedHistoryDialogOpen: false})}}
        unreceivedHistories={state.unreceivedHistories}
        product={props.product}
      />
    </Box>
  );
}
