import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Office } from "../../../Models/Office";
import {EMSelect} from "../../Common/Components/EMSelect";
import {Constants} from "../../../Common/Constants";
import {EMTextField} from "../../Common/Components/EMTextField";

interface OfficeEditDialogProps {
  open: boolean;
  office: Office;
  onClose: () => void;
  onSave: (office: Office) => void;
  errors: Record<string, string>,
}

/**
 * 事業所編集ダイアログ
 * @param props
 * @constructor
 */
export const OfficeEditDialog = (props: OfficeEditDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    editedOffice: new Office({}),
  });

  /**
   * props変更時処理
   */
  useEffect(() => {
    updateState({
      editedOffice: props.office.copyWith({})
    });
  }, [props]);

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * テキストフィールドの値変更時
   * @param name
   * @param value
   */
  const handleChange = (name: string, value: string) => {
    const newOfficeData = state.editedOffice.copyWith({ [name]: value });
    updateState({ editedOffice: newOfficeData });
  };

  /**
   * 事業所タイプの変更時
   * @param value
   */
  const handleSkuInitialTypeChange = (value: string) => {
    const newType = parseInt(value);
    const newOfficeData = state.editedOffice.copyWith({ sku_initial: isNaN(newType) ? undefined : newType });
    updateState({ editedOffice: newOfficeData });
  };

  /**
   * 保存ボタンクリック時
   */
  const handleSave = () => {
    props.onSave(state.editedOffice);
  };

  /**
   * SKU初期表示の選択肢を Constants クラスから作成
   */
  const skuInitialTypes =  () => {
    return Object.entries(Constants.SKU_INITIAL_LABELS).map(([id, label]) => ({
      id: parseInt(id),
      label
    }));
  }

  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            事業所編集
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box mt={2} />
        <Typography>ID: {props.office.id ?? ''}</Typography> {/* 表示専用 */}
        <Box mt={2} />
        <EMTextField
          label="事業所名"
          value={state.editedOffice.name ?? ''}
          onChange={(value) => handleChange('name', value)}
          error={props.errors['name']}
        />
        <Box mt={2} />
        <EMSelect
          label="SKU画面初期表示"
          value={state.editedOffice.sku_initial?.toString() ?? ''}
          onChange={handleSkuInitialTypeChange}
          menuItems={skuInitialTypes()}
          itemValueKey="id"
          itemLabelKey="label"
          hideUnselected={true}
          error={props.errors['sku_initial']}
          sx={{ width: 200 }}
        />
        <Box mt={2} />
        <DialogActions>
          <Button onClick={handleSave} color="primary" variant={"contained"}>保存</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
