import {HttpServiceBase} from "./HttpServiceBase";
import {ChatMessage} from "../Models/Chat/ChatMessage";
import {LogUtils} from "../Common/LogUtils";
import {EcmgApiError} from "../Common/EcmgApiError";
import {ChatMention} from "../Models/Chat/ChatMention";
import {ChatReaction} from "../Models/Chat/ChatReaction";
import Pusher from "pusher-js";
import {SearchResult} from "../Models/SearchResult";

/**
 * Pusherのチャットメッセージ更新データ
 */
export interface ChatMessageUpdateData {
  type: 'create' | 'update' | 'delete';
  data: ChatMessage;
  lastMessageId: number | null;
}

/**
 * チャットサービス
 */
export class ChatService extends HttpServiceBase {

  // チャンネルプレフィックス
  static readonly MESSAGE_CHANNEL_PREFIX = 'private-ec-manage-chat.'; // チャンネル名のプレフィックス(privateがつく)
  // イベント名
  static readonly EVENT_CHAT_MESSAGE_UPDATE = 'ChatMessageUpdate';


  private static instance: ChatService = new ChatService();

  // Pusher
  private pusher : Pusher;

  private constructor() {
    super();

    this.pusher = new Pusher('6430923891493fc6e4cb', {
      cluster: 'ap3',
      authEndpoint: '/broadcasting/auth',
      // userAuthentication: {
      //   headers: this.makeAuthorizeOption()?.headers,
      // } as any,
    });
  }

  public static getInstance(): ChatService {
    return ChatService.instance;
  }

  /**
   * メンションチャンネルを購読する
   * @param stockCode
   * @param callback
   */
  public subscribeToMessageChannel(stockCode: string, callback: (data: ChatMessageUpdateData) => void ) {
    const channel = this.pusher.subscribe(ChatService.MESSAGE_CHANNEL_PREFIX + stockCode);

    // 新しいメッセージを受け取った時の処理
    channel.bind(ChatService.EVENT_CHAT_MESSAGE_UPDATE, (data: any) => {
      LogUtils.d(JSON.stringify(data));

      const ret = {
        type: data.type,
        data: ChatMessage.fromMap(data.data),
        lastMessageId: data.lastMessageId,
      }

      callback(ret);
    });

    return channel;
  }

  /**
   * メッセージチャンネルの購読を解除する
   * @param stockCode
   */
  public unsubscribeMessageChannel(stockCode: string) {
    this.pusher.unsubscribe(ChatService.MESSAGE_CHANNEL_PREFIX + stockCode);
  }

  /**
   * 指定在庫コードの最初と最終メッセージIDを取得する。
   * @param stockCode 在庫コード
   * @returns Promise<number|null, number|null> 最初メッセージID, 最終メッセージID
   */
  public async getFirstLastMessagesIdByStockCode( stockCode: string): Promise<[number|null, number|null]> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.get(`api/chat/last-message-id-by-stock-code`, {
        params: {
          stock_code: stockCode,
        }
      });
      return response.data;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 指定在庫コードのチャットメッセージをページネーションで取得する。
   * @param stockCode 在庫コード
   * @param messageId メッセージID
   * @param direction 取得方向
   * @param limit 取得件数
   * @returns Promise<ChatMessage[]> チャットメッセージの配列
   */
  public async getMessagesByStockCode(
    stockCode: string,
    messageId: number | undefined,
    direction: 'up' | 'down' = 'up',
    limit: number = 100): Promise<ChatMessage[]> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.get(`api/chat/messages-by-stock-code`, {
        params: {
          stock_code: stockCode,
          message_id: messageId,
          direction: direction,
          limit
        }
      });
      return response.data.map((item: any) => ChatMessage.fromMap(item));

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * チャットメッセージを保存する。
   * @param message 新しいメッセージのデータ
   */
  public async storeMessage(message: ChatMessage): Promise<ChatMessage> {

    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/chat/messages`, // APIエンドポイント
        message.toMap(), // 送信するメッセージデータ
        this.makeAuthorizeOption() // 認証情報を含むオプション
      );

      LogUtils.d("Chat message stored successfully:" + JSON.stringify(response.data));

      // 成功時には、サーバーから返されたメッセージデータを`ChatMessage`クラスのインスタンスとして返す
      return ChatMessage.fromMap(response.data);
    } catch (e) {
      LogUtils.ex(e); // エラーログの出力
      throw EcmgApiError.fromError(e); // エラーを適切にラップしてスロー
    }
  }


  /**
   * 特定のチャットメッセージを取得する。
   * @param id メッセージID
   */
  public async getMessage(id: number): Promise<ChatMessage> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.get(`api/chat/messages/${id}`, this.makeAuthorizeOption());

      LogUtils.d(response.data);

      return ChatMessage.fromMap(response.data);
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * チャットメッセージを更新する。
   * @param id メッセージID
   * @param updateData 更新データ
   */
  public async updateMessage(id: number, updateData: Partial<ChatMessage>): Promise<ChatMessage> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(`api/chat/messages/${id}`, updateData, this.makeAuthorizeOption());

      LogUtils.d(response.data);

      return ChatMessage.fromMap(response.data);
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * チャットメッセージを削除する。
   * @param id メッセージID
   */
  public async deleteMessage(id: number): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.delete(`api/chat/messages/${id}`, this.makeAuthorizeOption());

      LogUtils.d(response.data);

      return response.data.message;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 指定メッセージIDに紐づくチャットメッセージを取得する
   * @param messageIds メッセージIDの配列
   * @returns Promise<{message: ChatMessage, searchResult: SearchResult}[]> チャットメッセージ情報の配列
   */
  public async getMessagesByMessageIds(messageIds: number[]): Promise<{message: ChatMessage, searchResult: SearchResult}[]> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.get('/api/chat/messages-by-message-ids', {
        params: { messageIds: messageIds.join(',') }, // 配列をクエリパラメータに変換
      });
      return response.data.map((item: any) => ({
        message: ChatMessage.fromMap(item.message),
        searchResult: SearchResult.fromMap(item.searchResult ?? {}) // nullの場合あり
      }));
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * チャットメンションを登録する。
   * @param mention メンションデータ
   */
  public async storeMention(mention: Partial<ChatMention>): Promise<ChatMention> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(`api/chat/mentions`, mention, this.makeAuthorizeOption());

      return response.data;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * チャットメンションを取得する
   * @param id
   */
  public async getMention(id: number): Promise<ChatMention> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.get(`api/chat/mentions/${id}`, this.makeAuthorizeOption());


      return response.data;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * チャットメンションを更新する
   * @param id
   * @param mention
   */
  public async updateMention(id: number, mention: Partial<ChatMention>): Promise<ChatMention> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(`api/chat/mentions/${id}`, mention, this.makeAuthorizeOption());

      return response.data;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * チャットメンションを削除する
   * @param id
   */
  public async deleteMention(id: number): Promise<void> {
    try {
      const axios = await this.getAxios(true);
      await axios.delete(`api/chat/mentions/${id}`, this.makeAuthorizeOption());

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  //
  // /**
  //  * チャットメンションを既読にする
  //  * @param id
  //  */
  // public async updateMentionRead(id: number): Promise<ChatMention> {
  //   try {
  //     const axios = await this.getAxios(true);
  //     const response = await axios.post(`api/chat/mentions/read/${id}`, this.makeAuthorizeOption());
  //
  //     return response.data;
  //   } catch (e) {
  //     LogUtils.ex(e);
  //     throw EcmgApiError.fromError(e);
  //   }
  // }

  /**
   * メッセージを既読にする
   * @param id
   */
  public async updateMessageRead(id: number): Promise<ChatMention> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(`api/chat/messages/read/${id}`, this.makeAuthorizeOption());

      return response.data;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  // /**
  //  * 未読のメッセージIDを取得する
  //  */
  // public async getUnreadMessageIds(): Promise<number[]> {
  //   try {
  //     const axios = await this.getAxios(true);
  //     const response = await axios.get(`api/chat/unread-message-ids`, this.makeAuthorizeOption());
  //
  //     return response.data;
  //   } catch (e) {
  //     LogUtils.ex(e);
  //     throw EcmgApiError.fromError(e);
  //   }
  // }

  /**
   * チャットリアクションを登録する。
   * @param reactionData リアクションデータ
   */
  public async storeReaction(reactionData: Partial<ChatReaction>): Promise<ChatReaction> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(`api/chat/reactions`, reactionData, this.makeAuthorizeOption());


      return response.data;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 特定のチャットリアクションを取得する。
   * @param id リアクションID
   */
  public async getReaction(id: number): Promise<ChatReaction> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.get(`api/chat/reactions/${id}`, this.makeAuthorizeOption());

      return response.data;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 特定のチャットリアクションを更新する。
   * @param id リアクションID
   * @param reactionData 更新するリアクションデータ
   */
  public async updateReaction(id: number, reactionData: Partial<ChatReaction>): Promise<ChatReaction> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(`api/chat/reactions/${id}`, reactionData, this.makeAuthorizeOption());

      return response.data;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 特定のチャットリアクションを削除する。
   * @param id リアクションID
   */
  public async deleteReaction(id: number): Promise<void> {
    try {
      const axios = await this.getAxios(true);
      await axios.delete(`api/chat/reactions/${id}`, this.makeAuthorizeOption());

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }


  /**
   * 自分にメンションされているメッセージのうち、未返信ありのメッセージの件数を返す
   * @returns number
   */
  public async getToMeUnrespondedMessageCount(): Promise<number> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.get('/api/chat/to-me-messages-unresponded-count');
      return response.data;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 自分にメンションされているメッセージを最大500件返す
   * @returns Promise<{message: ChatMessage, read: number, minimalUnitProductName: string, unresponded: boolean}[]> チャットメッセージ情報の配列
   */
  public async getMentionedMessages(): Promise<{message: ChatMessage, read: number, minimalUnitProductName: string, unresponded: boolean}[]> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.get('/api/chat/messages-for-me');
      return response.data.map((item: any) => ({
        message: ChatMessage.fromMap(item.message),
        read: item.read,
        minimalUnitProductName: item.minimalUnitProductName,
        unresponded: item.unresponded,
      }));
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 自分が送信したメッセージのうち、未返信ありのメッセージの件数を返す
   * @returns number
   */
  public async getFromMeUnrespondedMessageCount(): Promise<number> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.get('/api/chat/from-me-messages-unresponded-count');
      return response.data;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 自分が送信したメッセージを最大500件返す(未返信フラグ付き)
   * @returns Promise<{message: ChatMessage, unresponded: number, minimalUnitProductName: string}[]> チャットメッセージ情報の配列
   */
  public async getMyMessagesWithUnrespondedFlag():
    Promise<{
      message: ChatMessage,
      responded: number,
      unresponded: number,
      minimalUnitProductName: string}[]> {

    try {
      const axios = await this.getAxios(true);
      const response = await axios.get('/api/chat/messages-with-unresponded-flag');
      return response.data.map((item: any) => ({
        message: ChatMessage.fromMap(item.message),
        responded: item.responded,
        unresponded: item.unresponded,
        minimalUnitProductName: item.minimalUnitProductName,
      }));
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
}
