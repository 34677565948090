import React, {KeyboardEvent, useEffect, useState} from "react";
import {Box, CircularProgress, FormControl, TextField} from "@mui/material";
import {StoreProduct} from "../../../../../Models/Product/StoreProduct";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {ProductService} from "../../../../../Services/ProductService";
import {EcmgApiError} from "../../../../../Common/EcmgApiError";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {LogUtils} from "../../../../../Common/LogUtils";
import {Constants} from "../../../../../Common/Constants";

interface StoreProductPriceProps {
  storeProduct: StoreProduct;
  hasPricePattern: boolean;
  width: number;
  canChange: boolean;
}

/**
 * 店舗商品価格
 * @constructor
 */
export const StoreProductPrice = (props: StoreProductPriceProps) => {
  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    price: undefined as number | undefined,
    processing: false,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {

  }, []);


  /**
   * props変更時時処理
   */
  useEffect(() => {
    // 価格設定
    const price = props.storeProduct.price || 0;
    updateState({ price: price});

  }, [props]);

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();


  /**
   * 数値フォーマット
   * @param num
   */
  const formatNumber = (num: number) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  /**
   * 価格変更時
   * @param event
   */
  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/,/g, ""); // コンマを除去

    if ( value === '' ){
      updateState({price: undefined});
      return;
    }

    if ( !CommonUtils.isUnsignedInteger(value) ) {
      return;
    }
    const numValue = Number(value);
    if ( numValue > 100000 ){
      return;
    }
    updateState({ price: numValue});
  };

  /**
   * キー押下時
   * @param event
   */
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      // 価格更新処理を実行する
      updatePriceCount().then();
    }
  };

  /**
   * フォーカス時
   * @param event
   */
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  /**
   * フォーカスアウト時
   */
  const handleBlur = () => {
    if ( !state.processing ){
      const price = props.storeProduct.price || 0;
      updateState({ price: price});
    }
  };

  /**
   * 価格更新
   */
  const updatePriceCount = async () => {
    let showError = false;
    let message = '';

    try {
      // 価格が変わっていないなら何もしない
      if (state.price === props.storeProduct.price) {
        return;
      }
      // 価格がnullまたは0未満なら何もしない
      if (state.price == null || state.price < 0) {
        const price = props.storeProduct.price || 0;
        updateState({ price: price});
        return;
      }

      updateState({processing: true});

      // 価格パターンが設定されている場合
      if ( props.hasPricePattern && props.storeProduct.price_setting_type !== Constants.PRICE_SETTING_TYPE_NONE ){
        // 一括価格更新
        const response = await ProductService.getInstance().bulkUpdatePrice(props.storeProduct, state.price);
        message = response.message;
        showError = !response.success;
      }
      // 価格パターンが設定されていない場合
      else {
        // 価格更新
        message = await ProductService.getInstance().updateStoreProductPrice(props.storeProduct, state.price);
      }

      updateState({processing: false});
    }
    catch (e) {
      LogUtils.ex(e);

      const price = props.storeProduct.price || 0;
      updateState({ price: price, processing: false});

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = '価格更新処理でエラーが発生しました。';
      }
      showError = true;
    }

    // スナックバー表示
    showSnackbarMessage(message, showError);
  }


  /**
   * 使用不可か？
   */
  const isDisabled = () => {
    // 変更不可なら使用不可
    if ( !props.canChange ){
      return true;
    }

    // 価格パターンがあり、かつ連動対象なら使用不可
    return props.hasPricePattern && props.storeProduct.price_setting_type === Constants.PRICE_SETTING_TYPE_TARGET;
  }

  /**
   * 描画
   */
  return (
    <Box sx={{ width: props.width, display: 'flex', alignItems: 'center' }}>
      <FormControl fullWidth variant="outlined">
        <TextField
          value={state.price == null ? '' : formatNumber(state.price)}
          disabled={isDisabled() || state.processing}
          onChange={handlePriceChange}
          onKeyDown={handleKeyPress}
          onBlur={handleBlur}
          onFocus={handleFocus}
          sx={{
            backgroundColor: '#f0f8ff',
            '& fieldset': {
              borderColor: 'darkgreen',
            },
            '& .MuiInputBase-input': {
              color: MainPageConstants.COLOR_DARK_GRAY
            }
          }}
          inputProps={{
            inputMode: 'numeric',
            maxLength: 7,
            style: {
              textAlign: 'right',
              padding: 2,
            },
          }}
          size="small"
        />
      </FormControl>
      {state.processing && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
            height: MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT,
            ml: 1 }}>
          <CircularProgress size={MainPageConstants.CIRCLE_PROGRESS_SIZE_FOR_INPUT} />
        </Box>
      )}
    </Box>
  );
};
