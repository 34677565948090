import React, {useState, useRef, useEffect} from 'react';
import {
  InputAdornment,
  TextField,
  Button,
  Pagination,
  Box, Stack, Divider, Typography, Checkbox
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {SearchParam} from "../../../../Common/SearchParam";
import {EMSelect} from "../../../Common/Components/EMSelect";
import {Constants} from "../../../../Common/Constants";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import {ECTagSelect} from "../../../Common/Components/Tag/ECTagSelect";
import {Tag} from "../../../../Models/Tag/Tag";
import {EventService} from "../../../../Services/System/EventService";
import {TagService} from "../../../../Services/Tag/TagService";
import {MainPageConstants} from "../../Common/MainPageConstants";
import {SelectionService} from "../../../../Services/System/SelectionService";
import {BulkActionMenu} from "./BulkAction/BulkActionMenu";
import {LoginService} from "../../../../Services/LoginService";

interface SearchComponentProps {
  onSearch: (searchParam: SearchParam) => void;
  initialSearchParam: SearchParam;
  totalPageCount: number;
  isListMode: boolean;
  paginationOnly?: boolean;
}

/**
 * 検索コンポーネント
 * @param props
 * @constructor
 */
export const SearchComponent = (props: SearchComponentProps) => {

  const inputRef = useRef<HTMLInputElement | null>(null);

  /**
   * useState
   */
  const [state, setState] = useState({
    searchParam: props.initialSearchParam,
    showPagination: false,
    isComposing: false,
    showExcludeOptions: false,
    selectedIncludeTags: [] as Tag[],
    selectedExcludeTags: [] as Tag[],
    optionTags: [] as Tag[],
    includeTagOr: false,
  });

  /**
   * コンポーネントの状態を更新します。
   * @param newState - 更新する新しい部分状態。
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * マウント・アンマウント時処理
   */
  useEffect(() => {
    // イベントハンドラ登録（イベントハンドラの中でstateの値を参照しないこと）
    EventService.getInstance().onEvent(EventService.EVENT_NEW_TAGS, onChangeTags);
    EventService.getInstance().onEvent(EventService.EVENT_UPDATE_DELETE_TAGS, onChangeTags);

    // タグ条件候補
    const optionTags = TagService.getInstance().getAllTags();
    updateState({optionTags});

    // クリーンアップ
    return () => {
      // イベントハンドラ解除
      EventService.getInstance().removeListener(EventService.EVENT_NEW_TAGS, onChangeTags);
      EventService.getInstance().removeListener(EventService.EVENT_UPDATE_DELETE_TAGS, onChangeTags);
    };
  }, []);

  /**
   * タグ変更時
   */
  const onChangeTags = () => {
    const optionTags = TagService.getInstance().getAllTags();
    updateState({optionTags});

    // イベントハンドラの中でstateは参照できない
    // MEMO:「タグ条件反映」がされているものについては、選択タグリストが更新される。
    // // オプションタグに存在するタグのみを選択タグリストに保持
    // const selectedIncludeTags = state.selectedIncludeTags.filter(tag =>
    //   optionTags.some(optionTag => optionTag.id === tag.id)
    // );
    // const selectedExcludeTags = state.selectedExcludeTags.filter(tag =>
    //   optionTags.some(optionTag => optionTag.id === tag.id)
    // );
    //
    // // 更新された選択タグで状態を更新
    // updateState({
    //   selectedIncludeTags,
    //   selectedExcludeTags
    // });
  }

  /**
   * props変更時
   */
  useEffect(() => {
    updateState({
      searchParam: props.initialSearchParam,
      showPagination: props.isListMode,
    });

    // タグ条件
    const optionTags = TagService.getInstance().getAllTags();
    let selectedIncludeTags = [];
    let selectedExcludeTags = [];

    // includeTagListに基づいてselectedIncludeTagsを構築
    if (props.initialSearchParam.includeTagList) {
      for (let tagId of props.initialSearchParam.includeTagList) {
        const foundTag = optionTags.find(tag => Number(tag.id) === tagId);
        if (foundTag) {
          selectedIncludeTags.push(foundTag);
        }
      }
    }

    // excludeTagListに基づいてselectedExcludeTagsを構築
    if (props.initialSearchParam.excludeTagList) {
      for (let tagId of props.initialSearchParam.excludeTagList) {
        const foundTag = optionTags.find(tag => Number(tag.id) === tagId);
        if (foundTag) {
          selectedExcludeTags.push(foundTag);
        }
      }
    }

    updateState({
      selectedIncludeTags,
      selectedExcludeTags
    });

  }, [props]);

  /**
   * 検索条件更新
   * @param newValue
   */
  const updateSearchParam = (newValue: Partial<SearchParam>) => {
    const newSearchParam = state.searchParam.copyWith(newValue);
    updateState({ searchParam: newSearchParam });
    return newSearchParam;
  };

  /**
   * 検索処理を実行します。
   */
  const handleSearch = () => {
    const ret = updateSearchParam({ page: 1 });
    props.onSearch(ret);
  };

  /**
   * ページ番号が変わったときの処理
   * @param event - イベントオブジェクト
   * @param page - 新しいページ番号
   */
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    const ret = updateSearchParam({ page: page });
    props.onSearch(ret);
  };

  /**
   * 貼付検索
   */
  const handlePasteSearch = async () => {
    const text = await navigator.clipboard.readText();
    if ( text === '' ){
      return;
    }
    const ret = updateSearchParam({ keyword: text, page: 1 });
    props.onSearch(ret);
  };

  /**
   * ソート変更時処理
   * @param value
   */
  const handleSortChange = (value: string) => {
    const ret = updateSearchParam({ sortNo: value === '' ? undefined : Number(value) });
    props.onSearch(ret);
  };
  /**
   * 昇順・降順変更時処理
   * @param value
   */
  const handleOrderChange = (value: string) => {
    const isDesc = value === '' ? undefined : value === 'true';
    const ret = updateSearchParam({ isDesc: isDesc });
    props.onSearch(ret);
  };
  /**
   * 除外Noチェックボックス変更時
   * @param excludeNo
   */
  const handleCheckboxChange = (excludeNo: number) => {
    const newExcludeNoList = state.searchParam.excludeNoList?.includes(excludeNo)
      ? state.searchParam.excludeNoList.filter(no => no !== excludeNo)
      : [...(state.searchParam.excludeNoList || []), excludeNo];

    const ret = updateSearchParam({ excludeNoList: newExcludeNoList });
    props.onSearch(ret);
  };
  /**
   * キーワード変更時
   * @param e
   */
  const onKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSearchParam({
      keyword: e.target.value,
      page: 1,
    });
    updateState({showPagination: false});
  }

  /**
   * 検索対象変更時処理
   * @param values
   */
  const handleSearchTargetChange = (values: string[]) => {
    updateSearchParam({ searchTargets: values.map(Number) });
    //props.onSearch(newSearchParam); -- これをすると商品名のチェックを外せない
  };

  /**
   * 除外項目表示切替
   */
  const toggleExcludeOptions = () => {
    updateState({showExcludeOptions: !state.showExcludeOptions});
  };

  /**
   * 検索ボックス
   */
  const searchBox = () => {
    if ( props.paginationOnly ){
      return null;
    }
    return (
      <>
        <Box flexGrow={1}>
          <TextField
            variant="outlined"
            value={state.searchParam.keyword ?? ''}
            onChange={onKeywordChange}
            onKeyDown={e => {
              if (e.key === 'Enter' && !state.isComposing) { // 変換中でなければ検索を実行
                handleSearch();
              }
            }}
            onCompositionStart={() => updateState({isComposing: true})} // 変換開始時に状態を更新
            onCompositionEnd={() => updateState({isComposing: false})} // 変換終了時に状態を更新
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            onClick={() => inputRef.current?.select()}
            inputRef={inputRef}
            size={'small'}
            sx={{ minWidth: 300 }}
          />
        </Box>
        <Button variant="contained" color="primary" onClick={handleSearch} >
          検索
        </Button>
        <Button variant="outlined" color="primary" onClick={handlePasteSearch} >
          貼付検索
        </Button>
        {/* 検索対象 */}
        {searchTargetSelect()}

      </>
    );
  }
  /**
   * ソート順プルダウン
   */
  const sortSelect = () => {
    if ( props.paginationOnly ){
      return null;
    }
    const menuItems = Object.entries(Constants.SORT_LABELS).map(([key, label]) => ({
      value: key,
      label: label,
    }));
    return (
      <EMSelect
        label={'ソート項目'}
        value={state.searchParam.sortNo?.toString() || ''}
        menuItems={menuItems}
        itemValueKey="value"
        itemLabelKey="label"
        onChange={handleSortChange}
        showValueWithLabel={false}
        sx={{ minWidth: 170 }}
      />
    );

  }

  /**
   * 昇順・降順プルダウン
   */
  const orderSelect = () => {
    if ( props.paginationOnly ){
      return null;
    }
    const menuItems = [
      { value: 'false', label: '昇順' },
      { value: 'true', label: '降順' },
    ];
    return (
      <EMSelect
        label={'昇順・降順'}
        value={state.searchParam.isDesc?.toString() || 'true'} // 降順をデフォルトに
        menuItems={menuItems}
        itemValueKey="value"
        itemLabelKey="label"
        onChange={handleOrderChange}
        showValueWithLabel={false}
        hideUnselected={true}
        sx={{ minWidth: 100 }}
      />
    );
  }
  /**
   * 除外Noチェックボックス / タグ検索
   */
  const renderCheckboxesAndTags = () => {
    if ( !state.showExcludeOptions || props.paginationOnly  ){
      return null;
    }
    return (
      <Box display={"flex"} flexDirection={"column"} flexGrow={1}>
        <Divider sx={{ backgroundColor: MainPageConstants.COLOR_DARK_GRAY}} />
        <Box mt={1} display={"flex"} flexDirection={"row"}>
          <Typography variant={"body2"} fontWeight={"bold"}>■ 検索対象外にする項目 ■</Typography>
          <Box ml={2}></Box>
          {
            Object.entries(Constants.EXCLUDE_LABELS).map(([key, label]) => {
              const excludeNo = parseInt(key, 10);
              return (
                <Box ml={2} key={excludeNo}>
                  <input
                    type="checkbox"
                    checked={state.searchParam.excludeNoList?.includes(excludeNo) || false}
                    onChange={() => handleCheckboxChange(excludeNo)}
                  />
                  {label}
                </Box>
              );
            })
          }
        </Box>
        <Box mt={1}></Box>
        <Divider />
        <Box display={"flex"} flexDirection={"column"}>
          <Box mt={2} ml={1} display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant={"body2"} fontWeight={"bold"}>含むタグ：</Typography>
            <Box sx={{width: 400}}>
              <ECTagSelect
                selectedTags={state.selectedIncludeTags}
                optionTags={state.optionTags}
                showEditButton={false}
                showBorder={true}
                isClearable={true}
                onChange={(tags) => updateState({selectedIncludeTags: tags})}
              />
            </Box>
            <Box ml={2}/>
            <Typography variant={"body2"} fontWeight={"bold"}>含まないタグ：</Typography>
            <Box sx={{width: 400}}>
              <ECTagSelect
                selectedTags={state.selectedExcludeTags}
                optionTags={state.optionTags}
                showEditButton={false}
                showBorder={true}
                isClearable={true}
                onChange={(tags) => updateState({selectedExcludeTags: tags})}
              />
            </Box>
            <Box ml={2}/>
            <Button variant="outlined" color="secondary" onClick={updateTagCondition} >
              タグ条件反映
            </Button>
          </Box>
          {/* 含むタグのORチェックボックス */}
          <Box ml={9} display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Checkbox
              size={"small"}
              onChange={(e) => updateState({includeTagOr: e.target.checked})}
            />
            <Typography variant={"body2"}>OR検索</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  /**
   * タグ条件反映
   */
  const updateTagCondition = () => {
    const includeTagList = state.selectedIncludeTags.map(tag => tag.id!);
    const excludeTagList = state.selectedExcludeTags.map(tag => tag.id!);
    const ret = updateSearchParam({
     includeTagList,
     excludeTagList,
     includeTagOr: state.includeTagOr,
     page: 1
    });
    props.onSearch(ret);
  }

  /**
   * 除外項目表示切替ボタン
   */
  const renderToggleExcludeOptionsButton = () => {
    if ( props.paginationOnly  ){
      return null;
    }

    return (
      <Box mt={0.2}>
        <IconButton
          onClick={toggleExcludeOptions}
          style={{
            borderRadius: '50%',
            width: '36px',
            height: '36px',
            border: '2px solid rgba(0, 0, 0, 0.54)',
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {state.showExcludeOptions ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </IconButton>
      </Box>
    );
  }

  /**
   * 検索対象プルダウン
   */
  const searchTargetSelect = () => {
    if ( props.paginationOnly ){
      return null;
    }
    const menuItems = Object.entries(Constants.SEARCH_TARGET_LABELS).map(([key, label]) => ({
      value: key,
      label: label,
    }));
    return (
      <EMSelect
        label={'検索対象'}
        multiple={true}
        multiValues={state.searchParam.searchTargets?.map(String) ?? []}
        menuItems={menuItems}
        itemValueKey="value"
        itemLabelKey="label"
        onMultiSelectChange={handleSearchTargetChange}
        showValueWithLabel={false}
        sx={{ minWidth: 180, }}
      />
    );
  }

  /**
   * 検索機能行
   */
  const searchRow = () => {
    if ( props.paginationOnly ){
      return null;
    }
    return (
      <Box mt={1} display="flex" alignItems="center" >
        <Box display={"flex"} flexDirection={"row"} gap={1} alignItems="center">
          {/* 検索ボックス */}
          {searchBox()}

          <Box ml={1} />

          {/* ソート順 */}
          {sortSelect()}
          {/* 昇順・降順 */}
          {orderSelect()}

          <Box ml={1} />

          {/* 除外条件表示切り替え */}
          {renderToggleExcludeOptionsButton()}

          <Box ml={2} />

          {/* 一括処理メニュー */}
          <BulkActionMenu />
        </Box>
      </Box>
    );
  }

  /**
   * 全て選択変更時
   */
  const onAllSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    SelectionService.instance().setAllSelectMode(e.target.checked);
    EventService.getInstance().emitEvent(EventService.EVENT_SELECTION_CHANGE);
  }

  /**
   * レコード選択部
   */
  const selectRecord = () => {
    if ( props.paginationOnly ){
      return null;
    }

    const selectedInfo =
      "[" + SelectionService.instance().getAllCount() + " 件中 " + SelectionService.instance().getSelectCount() + " 件を選択中]";

    return (
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        {/* 全て選択 */}
        {props.isListMode && (
          <Box mr={1}>
            <Checkbox
              size={"small"}
              indeterminate={SelectionService.instance().isIndeterminate()}
              checked={SelectionService.instance().isAllSelect()}
              disabled={SelectionService.instance().getAllCount() === 0}
              onChange={onAllSelectChange}
            />
            全て選択 {selectedInfo}
          </Box>
        )}
      </Box>
    );
  }

  /**
   * 描画
   */
  return (
    <Stack>
      {/* 検索機能 */}
      {searchRow()}

      <Box mt={1} />
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        {/* レコード選択部 */}
        {selectRecord()}

        <Box flexGrow={1}/>
        {/* ページネーション */}
        {state.showPagination && (
          <Pagination
            count={props.totalPageCount}
            page={state.searchParam.page}
            onChange={handlePageChange}
          />
        )}
      </Box>
      <Box mt={1} />

      {/* チェックボックス/タグ検索 */}
      <Box display="flex" alignItems="center">
        {renderCheckboxesAndTags()}
      </Box>
    </Stack>


  );
}

