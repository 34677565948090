import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { EMDatePicker } from "./EMDatePicker";

interface MonthRangeDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (startMonth: Date, endMonth: Date) => void;
  initialStartMonth?: Date;
  initialEndMonth?: Date;
}

/**
 * 年月範囲ダイアログ
 * @param props
 * @constructor
 */
export const MonthRangeDialog = (props: MonthRangeDialogProps) => {
  const [state, setState] = useState({
    startMonth: props.initialStartMonth,
    endMonth: props.initialEndMonth,
  });

  useEffect(() => {
    if (props.open) {
      setState({
        startMonth: props.initialStartMonth,
        endMonth: props.initialEndMonth,
      });
    }
  }, [props.open, props.initialStartMonth, props.initialEndMonth]);

  const handleSave = () => {
    if (state.startMonth && state.endMonth) {
      props.onSave(state.startMonth, state.endMonth);
    }
  };


  /**
   * 開始月変更
   * @param date
   */
  const onStartChanged = (date: Date | undefined) => {
    // 開始月が変更された場合は、終了月も変更
    setState({
      startMonth: date,
      endMonth: date,
    });
  }

  const isSaveDisabled = !state.startMonth || !state.endMonth || new Date(state.startMonth) > new Date(state.endMonth);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            開始年月と終了年月の入力
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box mt={2}>
          <EMDatePicker
            label="開始年月"
            value={state.startMonth}
            onChange={onStartChanged}
            yearMonth={true}
          />
        </Box>
        <Box mt={2}>
          <EMDatePicker
            label="終了年月"
            value={state.endMonth}
            onChange={(endMonth) => setState({ ...state, endMonth })}
            yearMonth={true}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={isSaveDisabled}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};
