/**
 * モール
 */
export class Mall {
  mall_no?: number;
  name?: string;
  fee?: number;
  shipping_fee_1?: number;
  shipping_fee_2?: number;
  labor_cost?: number;
  gross_margin_1?: number;
  gross_margin_2?: number;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<Mall>) {
    Object.assign(this, params);

  }


  copyWith(params: Partial<Mall>): Mall {
    return new Mall({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): Mall {
    return new Mall({
      ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): Mall {
    const decoded = JSON.parse(jsonString);
    return Mall.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  // こちらはDataGridの値として適切な項目を選択していますが、変更する必要がある場合があります。
  getDataGridValues(): (string | undefined)[] {
    return [
      this.mall_no?.toString(),
      this.name,
      this.fee?.toString(),
      this.shipping_fee_1?.toString(),
      this.shipping_fee_2?.toString(),
      this.labor_cost?.toString(),
      this.gross_margin_1?.toString(),
      this.gross_margin_2?.toString(),
    ];
  }
}
