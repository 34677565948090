import React from "react";
import * as holiday_jp from '@holiday-jp/holiday_jp';
import {SpecialDateService} from "./SpecialDateService";
import {Constants} from "../../Common/Constants";
import {SystemSettingService} from "../SystemSettingService";
import {SystemSetting} from "../../Models/SystemSetting";

/**
 * 特別日ユーティリティクラス
 */
export class SpecialDateUtils {
  /**
   * 定数
   */
  // 青
  static readonly COLOR_BLUE = '#DDF2FF';
  // ピンク
  static readonly COLOR_PINK = '#FFE1E1';
  // 下線
  static readonly STYLE_UNDERLINE = { textDecoration: 'underline solid 2px rgba(105, 105, 105, 1.0)' };
  // マーカー
  static readonly STYLE_MARKER = { backgroundImage: 'linear-gradient(transparent 50%, rgba(255, 255, 0, 0.7) 90%)' };
  //static readonly STYLE_MARKER = { textDecoration: 'underline solid 4px rgba(255, 255, 0, 0.7)' };

  // 太字
  static readonly STYLE_BOLD = {color: 'red', fontWeight: 'bold'};


  /**
   * スタイル返却
   */
  static getStyle(date: Date): React.CSSProperties {
    //--------------
    // 祝日スタイル
    //--------------
    const ret = SpecialDateUtils.getHolidayStyle(date);

    //--------------
    // 特別日を取得
    //--------------
    const specialDate = SpecialDateService.getInstance().getSpecialDatesForDate(date);
    // ループ
    for (const date of specialDate) {
      let specialStyle = SpecialDateUtils.getSpecialDateStyle(date.special_type);
      // スタイルをマージ
      Object.assign(ret, specialStyle);
    }

    //--------------
    // ◯の付く日を取得
    //--------------
    const patternDateStyle = SpecialDateUtils.getPatternDateStyle(date);
    // スタイルをマージ
    Object.assign(ret, patternDateStyle);

    return ret;
  }

  /**
   * 先頭日を除く期間のスタイル返却
   */
  static getStyleExcludingFirstDay(date: Date): React.CSSProperties {
    const ret: React.CSSProperties = {};

    //--------------
    // 特別日を取得（先頭日を除く）
    //--------------
    const specialDates = SpecialDateService.getInstance().getSpecialDatesExcludingFirstDayOfPeriod(date);
    // ループ
    for (const specialDate of specialDates) {
      let specialStyle = SpecialDateUtils.getSpecialDateStyle(specialDate.special_type);
      // スタイルをマージ
      Object.assign(ret, specialStyle);
    }

    return ret;
  }

  /**
   * 祝日スタイル
   * @param date
   */
  static getHolidayStyle(date: Date): React.CSSProperties {
    // 祝日の場合は赤色
    if (holiday_jp.isHoliday(date)) {
      return {backgroundColor: SpecialDateUtils.COLOR_PINK};
    }
    // 日曜日の場合は赤色
    if (date.getDay() === 0) {
      return {backgroundColor: SpecialDateUtils.COLOR_PINK};
    }
    // 土曜日の場合は青色
    if (date.getDay() === 6) {
      return {backgroundColor: SpecialDateUtils.COLOR_BLUE};
    }
    return {};
  }

  /**
   * ◯の付く日スタイル
   */
  static getPatternDateStyle(date: Date): React.CSSProperties {
    //--------------
    // ◯の付く日を取得
    //--------------
    const patternDate
      = SystemSettingService.getInstance().getSettingByKey(SystemSetting.KEY_PATTERN_DATE);
    if (patternDate) {
      const [specialType, pattern] = patternDate.getPatternDate();
      if (specialType && pattern) {
        // ◯の付く日の場合はスタイルを返す
        if (SpecialDateUtils.isPatternDate(date, pattern)) {
          return SpecialDateUtils.getSpecialDateStyle(specialType);
        }
      }
    }
    return {};
  }

  /**
   * ◯の付く日かどうか
   * @param date
   * @param pattern
   */
  static isPatternDate(date: Date, pattern: string): boolean {
    // パターンをカンマで分割し、各要素をトリムする
    const patternArray = pattern.split(',').map(s => s.trim());

    // 日付の日を取得し、文字列に変換
    const dayString = date.getDate().toString();

    // 日付の下一桁を取得
    const lastDigit = dayString[dayString.length - 1];

    // トリムされたパターン配列の中に下一桁が存在するかチェック
    return patternArray.includes(lastDigit);
  }


  /**
   * 特別日タイプに応じたスタイルを返す
   */
  static getSpecialDateStyle(specialType?: number): React.CSSProperties {
    if (specialType === Constants.SPECIAL_DATE_1) {
      // 下線
      return SpecialDateUtils.STYLE_UNDERLINE;
    } else if (specialType === Constants.SPECIAL_DATE_2) {
      // マーカー
      return SpecialDateUtils.STYLE_MARKER;
    } else if (specialType === Constants.SPECIAL_DATE_3) {
      // 太字
      return SpecialDateUtils.STYLE_BOLD;
    }
    return {};
  }
}
