import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent, FormControl,
  IconButton, InputLabel, MenuItem, Select,
  TextField,
  Toolbar,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {StoreService} from "../../Services/StoreService";
import {Store} from "../../Models/Store";
import {CommonUtils} from "../../Common/CommonUtils";
import {EcmgApiError} from "../../Common/EcmgApiError";
import {LogUtils} from "../../Common/LogUtils";
import {SalesService} from "../../Services/SalesService";
import {useSnackbar} from "../Common/Provider/SnackbarContext";


interface OrderSyncDialogProps {
  open: boolean;
  onClose: () => void;
}

/**
 * 個別注文同期ダイアログ(Ver.1 暫定）
 * @param props
 * @constructor
 */
export const OrderSyncDialog = (props: OrderSyncDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    stores: [] as Store[],
    selectedStore: null as number | null,
    orderId: '' as string,
    processing: false,
  });

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    const loadStores = async () => {
      const storeService = StoreService.getInstance();
      const allStores = storeService.getAllStores();
      updateState({ stores: allStores });
    };

    loadStores().then();
  }, []);


  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * 実行ボタンクリック時
   */
  const handleExecute = () => {
    // ここでSaleServiceの処理を追加
    if (!state.selectedStore || !state.orderId) {
      alert('店舗と注文IDを選択してください。');
      return;
    }

    synchronizeOrder().then();
  };

  /**
   * 個別注文同期
   */
  const synchronizeOrder = async () => {
    let message = '';

    try {
      // 必要な前提条件や検証をここに追加できます（例: storeIdやorderIdの存在確認など）

      updateState({processing: true});

      // 個別注文同期
      message = await SalesService.getInstance().syncOrder(state.selectedStore!, state.orderId!);

      updateState({processing: false});
    }
    catch (e) {
      LogUtils.ex(e);

      updateState({processing: false});

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = '個別注文同期処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);

    // ダイアログを閉じる
    props.onClose();
  }


  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            個別注文同期
          </Typography>
          <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography variant={"body2"}>
          既にステータスが完了になった注文を強制的に再同期するための機能です。
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="store-select-label">店舗選択</InputLabel>
          <Select
            labelId="store-select-label"
            value={state.selectedStore}
            onChange={(e) => updateState({ selectedStore: e.target.value as number })}
          >
            {state.stores.map(store => (
              <MenuItem key={store.id} value={store.id}>
                {store.store_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          margin="normal"
          label="注文ID"
          value={state.orderId}
          onChange={(e) => {
            if (/^[a-zA-Z0-9-]*$/.test(e.target.value)) {
              updateState({ orderId: e.target.value });
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleExecute();
            }
          }}
        />

        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleExecute}>
            実行
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
