import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TableContainer,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { SettingConstants } from "../SettingConstants";
import { LogUtils } from "../../../Common/LogUtils";
import { EcmgApiError } from "../../../Common/EcmgApiError";
import { CommonUtils } from "../../../Common/CommonUtils";
import ECProgress from "../../Common/Components/ECProgress";
import { useSnackbar } from "../../Common/Provider/SnackbarContext";
import { TagCategoryEditDialog } from "./TagCategoryEditDialog";
import {TagCategory} from "../../../Models/Tag/TagCategory";
import {TagCategoryService} from "../../../Services/Tag/TagCategoryService";
import {EventService} from "../../../Services/System/EventService";

/**
 * タグ分類設定
 * @constructor
 */
export const TagCategorySetting = () => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
  };

  /**
   * useState
   */
  const [state, setState] = useState({
    tagCategories: [] as TagCategory[],
    processing: false,
    editDialogOpen: false,
    editingTagCategory: null as TagCategory | null,
    editErrors: {} as Record<string, string>,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    const tagCategories = TagCategoryService.getInstance().getAllTagCategories();
    updateState({ tagCategories });

    // イベントハンドラ登録
    EventService.getInstance().onEvent(EventService.EVENT_UPDATE_TAG_CATEGORIES, onTagCategoriesUpdated);

    // クリーンアップ
    return () => {
      EventService.getInstance().removeListener(EventService.EVENT_UPDATE_TAG_CATEGORIES, onTagCategoriesUpdated);
    };
  }, []);

  /**
   * タグ分類更新時
   */
  const onTagCategoriesUpdated = () => {
    const tagCategories = TagCategoryService.getInstance().getAllTagCategories();
    updateState({ tagCategories });
  }

  /**
   * タグ分類編集ボタンクリック時
   * @param tagCategory
   */
  const handleEdit = (tagCategory: TagCategory) => {
    updateState({
      editingTagCategory: tagCategory,
      editDialogOpen: true,
      editErrors: {},
    });
  };

  /**
   * タグ分類保存ボタンクリック時
   * @param tagCategory
   */
  const handleTagCategorySave = async (tagCategory: TagCategory) => {
    let message = '';

    try {
      updateState({
        processing: true,
        editingTagCategory: tagCategory
      });

      if (tagCategory.id == null) {
        // タグ分類新規登録
        message = await TagCategoryService.getInstance().addTagCategory(tagCategory);
      } else {
        // タグ分類更新
        message = await TagCategoryService.getInstance().updateTagCategory(tagCategory);
      }

      updateState({
        processing: false,
        editDialogOpen: false,
        editingTagCategory: null,
        editErrors: {},
      });

    } catch (e) {
      LogUtils.ex(e);

      updateState({
        processing: false,
      });

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
        updateState({ editErrors: e.getErrorMessageMap() });
      }
      if (CommonUtils.isEmpty(message)) {
        message = 'タグ分類登録処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  /**
   * タグ分類削除ボタンクリック時
   * @param tagCategoryId
   */
  const handleDelete = async (tagCategoryId: number) => {
    if (!window.confirm('このタグ分類を削除しますか？')) {
      return;
    }

    let message = '';

    try {
      updateState({ processing: true });

      // タグ分類削除
      message = await TagCategoryService.getInstance().deleteTagCategory(tagCategoryId);

      updateState({ processing: false });

    } catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = 'タグ分類削除処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  /**
   * タグ分類移動ボタンクリック時
   * @param id
   * @param direction
   */
  const handleMove = async (id: number, direction: 'up' | 'down') => {
    let message = '';

    try {
      updateState({ processing: true });

      await TagCategoryService.getInstance().moveTagCategory(id, direction);

      updateState({ processing: false });

    } catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = 'タグ分類の順序変更でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  /**
   * レンダリング
   */
  return (
    <Box sx={BOX_STYLE} mt={2}>
      <Box><Typography>タグ分類設定</Typography></Box>
      <Box display="flex" justifyContent="flex-end" mr={2}>
        <Button variant={"contained"} component="span" onClick={() => handleEdit(new TagCategory())}>
          新規登録
        </Button>
      </Box>
      <TableContainer style={{ maxHeight: '400px' }}>
        <Table>
          <TableHead style={SettingConstants.TABLE_HEADER_STYLE}>
            <TableRow>
              <TableCell>タグ分類名</TableCell>
              <TableCell>色</TableCell>
              <TableCell align={"center"}>表示順</TableCell>
              <TableCell align={"center"}>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.tagCategories.map((tagCategory, index) => (
              <TableRow key={tagCategory.id}>
                <TableCell>{tagCategory.name}</TableCell>
                <TableCell><Box bgcolor={tagCategory.color} width="20px" height="20px" /></TableCell>
                <TableCell align={"center"}>
                  <Button onClick={() => handleMove(tagCategory.id!, 'up')} disabled={index === 0}>
                    <ArrowUpwardIcon />
                  </Button>
                  <Button onClick={() => handleMove(tagCategory.id!, 'down')} disabled={index === state.tagCategories.length - 1}>
                    <ArrowDownwardIcon />
                  </Button>
                </TableCell>
                <TableCell align={"center"}>
                  <Button onClick={() => handleEdit(tagCategory)}>
                    <EditIcon />
                  </Button>
                  <Button onClick={() => handleDelete(tagCategory.id!)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {state.tagCategories.length === 0 ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <Typography variant={"body2"}>登録されているタグ分類はありません</Typography>
        </Box>
      ) : null}

      {/* タグ分類編集ダイアログ */}
      <TagCategoryEditDialog
        open={state.editDialogOpen}
        tagCategory={state.editingTagCategory ?? new TagCategory()}
        onClose={() => updateState({ editDialogOpen: false })}
        onSave={handleTagCategorySave}
        errors={state.editErrors}
      />

      {/* プログレス */}
      <ECProgress open={state.processing}></ECProgress>
    </Box>
  );
}
