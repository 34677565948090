import React, {useEffect, useState} from 'react';
import {MinUnitProductImage} from "../Common/MinUnitProductImage";
import StockCountComponent from "../Common/StockCount/StockCountComponent";
import {SalesTrend} from "../Common/SalesTrend";
import {ProductName} from "../Common/ProductName";
import {Box, Checkbox, Divider, Stack} from '@mui/material';
import { ShippingMethod } from '../Common/ShippingMethod';
import {UnitProduct} from "../../../../Models/Product/UnitProduct";
import {ListConstants} from "./ListConstants";
import {SearchResult} from "../../../../Models/SearchResult";
import {UnreceivedStockArea} from "../Common/UnreceivedStockArea/UnreceivedStockArea";
import {ProcurementCountArea} from "../Common/ProcurementCountArea/ProcurementCountArea";
import {MainPageConstants} from "../../Common/MainPageConstants";
import {ReceivedStockArea} from "../Common/ReceivedStockArea/ReceivedStockArea";
import {ChatButton} from "../Common/Chat/ChatButton";
import {AddCalendar} from "../Common/Calendar/AddCalenar";
import {SelectionService} from "../../../../Services/System/SelectionService";
import {EventService} from "../../../../Services/System/EventService";

interface SKUPanelProps {
  searchResult: SearchResult;
}

/**
 * Listパネル
 * @param props
 * @constructor
 */
export const ListPanel = (props: SKUPanelProps) => {

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    minUnitProduct: undefined as UnitProduct | undefined,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    // クリーンアップ
    return () => {
    };
  }, []);

  /**
   * props変更時処理
   */
  useEffect(() => {

    // 最小ユニット商品取得
    const minUnitProduct = props.searchResult.minimalUnitProduct;
    updateState({ minUnitProduct: minUnitProduct });

    // クリーンアップ
    return () => {
    };
  }, [props]);


  /**
   * チェックボックス変更時
   */
  const onSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    SelectionService.instance().setSelection(props.searchResult.product.stock_code!, e.target.checked);
    EventService.getInstance().emitEvent(EventService.EVENT_SELECTION_CHANGE);
  }

  /**
   * チェックボックス
   */
  const checkBox = () => {
    return (
      <Checkbox
        size={"small"}
        checked={SelectionService.instance().isSelected(props.searchResult.product.stock_code!)}
        onChange={onSelectChange}
      />
    );
  }

  /**
   * 描画
   */
  return (
    <>
      <Stack spacing={1}>
        {/* 1段目 */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          {/* 合計在庫数 */}
          <StockCountComponent
            isSKU={false}
            searchResult={props.searchResult}
            height={ListConstants.STOCK_COUNT_HEIGHT}
            width={ListConstants.STOCK_COUNT_WIDTH}
          />
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            {/* 配送方法 */}
            <ShippingMethod
              unitProducts={props.searchResult.product.unit_products ?? []}
              width={ListConstants.SHIPPING_METHOD_WIDTH}
            />
            <Box display={"flex"} flexDirection={"row"} >
              {/* チェックボックス */}
              {checkBox()}
              {/* チャットボタン  */}
              <ChatButton
                searchResult={props.searchResult}
              />
            </Box>
          </Box>
          {/* 商品名 */}
          <Box flexGrow={1}>
            <ProductName
              product={props.searchResult.product}
              minUnitProduct={state.minUnitProduct}
              height={ListConstants.PRODUCT_NAME_HEIGHT}
              isListMode={true}
            />
          </Box>
        </Box>

        {/* 2段目 */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          gap={2}
        >
          {/* サムネイル画像 */}
          <MinUnitProductImage
            searchResult={props.searchResult}
            imageSize={ListConstants.IMAGE_SIZE}
          />
          <Box sx={{ width: ListConstants.IMAGE_MARGIN_RIGHT }}/>
          <Box display="flex" flexDirection="column" flexGrow={1} gap={1}>

            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
              {/* 受取在庫関連 */}
              <ReceivedStockArea
                searchResult={props.searchResult}
                height={ListConstants.RECEIVED_COUNT_AREA_HEIGHT}
                width={ListConstants.RECEIVED_COUNT_AREA_WIDTH}
              />
              {/* 未着在庫関連 */}
              <UnreceivedStockArea
                searchResult={props.searchResult}
                height={ListConstants.UNRECEIVED_COUNT_AREA_HEIGHT}
                width={ListConstants.UNRECEIVED_COUNT_AREA_WIDTH}
              />
              {/* 仕入数関連 */}
              <ProcurementCountArea
                searchResult={props.searchResult}
                height={ListConstants.PROCUREMENT_COUNT_AREA_HEIGHT}
                width={ListConstants.PROCUREMENT_COUNT_AREA_WIDTH}
              />
            </Box>
          </Box>
        </Box>

        {/* 3段目 */}
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }} >
          {/*  カレンダー追加　*/}
          <Box minWidth={ListConstants.CALENDAR_ADD_MIN_WIDTH} >
            <AddCalendar
              stockCode={props.searchResult.product.stock_code!}
              calendarInfo={props.searchResult.calendarInfo}
              isSKU={false}
            />
          </Box>

          {/* 販売傾向 */}
          <SalesTrend
            stockCode={props.searchResult.product.stock_code!}
            isSKU={false}
            orderCountData={props.searchResult.orderCountData}
            height={ListConstants.SALES_TREND_HEIGHT}
          />
        </Box>

      </Stack>
      <Box mt={4}/>
      <Divider sx={{ backgroundColor: MainPageConstants.COLOR_DARK_GRAY}} />
      <Box mt={4}/>
    </>
  );
}

