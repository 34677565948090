import {Box, Typography} from "@mui/material";
import {OfficeService} from "../../../../Services/OfficeService";

interface ChatTagToOfficeProps {
  tag: string;
}

/**
 * チャットタグ TO OFFICE
 * @param props
 * @constructor
 */
export const ChatTagToOffice = (props: ChatTagToOfficeProps) => {
  // 事業所IDを抽出
  const match = props.tag.match(/\[tooffice:(.+?)]/);
  const officeId = match ? match[1] : null;

  if (!officeId) {
    return <span>{props.tag}</span>;
  }

  // 事業所名取得
  const office = OfficeService.getInstance().getOfficeById(Number(officeId));

  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center', mb: 1, verticalAlign: 'top'}}>
      <Box
        sx={{ display: 'inline-flex',alignItems: 'center', backgroundColor: '#7fffd4', borderRadius: 1 }}
      >
        <Typography variant="body2" sx={{ fontWeight: 'bold', ml:0.5, mr:0.5 }}>
          TO {office?.name}
        </Typography>
      </Box>
    </Box>
  );
};

