import React, {useState} from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import GroupIcon from '@mui/icons-material/Group';
import {ChatMessage} from "../../../../Models/Chat/ChatMessage";
import {Constants} from "../../../../Common/Constants";
import {EventService} from "../../../../Services/System/EventService";
import {LoginService} from "../../../../Services/LoginService";
import Tooltip from "@mui/material/Tooltip";
import {ChatReactionPopup} from "./ChatReactionPopup";
import {Typography} from "@mui/material";

interface ChatReactionPanelProps {
  message: ChatMessage;
  disableClick?: boolean;
}

/**
 * リアクションパネル
 * @param props
 * @constructor
 */
export const ChatReactionPanel = (props: ChatReactionPanelProps) => {
  // 自分のユーザーID
  const myUserId = LoginService.getInstance().loginUser?.id ?? 0;
  // リアクション数
  const reactionCount = props.message.reactions?.length ?? 0;

  // リアクションごとの件数を集計
  const reactionCounts = props.message.reactions?.reduce((acc, reaction) => {
    if ( reaction.reaction_number === undefined ) {
      return acc;
    }
    acc[reaction.reaction_number] = (acc[reaction.reaction_number] || 0) + 1;
    return acc;
  }, {} as { [key: number]: number }) ?? {};



  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    popupOpen: false,
    anchorEl: null as HTMLElement | null,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * リアクションボタンのクリックイベントハンドラ
   * @param reactionNumber
   */
  const onReactionClick = (reactionNumber: number) => {
    // 指定番号のリアクションを抽出（複数）
    const reactions = props.message.reactions?.filter(reaction => reaction.reaction_number === reactionNumber) ?? [];

    // 自身のリアクションが含まれているか？
    const myReaction = reactions?.find(reaction => reaction.user_id === myUserId);

    // 削除
    if ( myReaction != null ){
      EventService.getInstance().emitEvent(EventService.EVENT_CHAT_REACTION_DELETE, myReaction.id);
    }
    // 更新
    else {
      EventService.getInstance().emitEvent(EventService.EVENT_CHAT_REACTION,
        {messageId: props.message.id, reaction: reactionNumber});
    }
  };

  /**
   * リアクション確認ボタンのクリックイベントハンドラ
   * @param e
   */
  const onViewReactionsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    updateState({anchorEl: e.currentTarget}); // アンカー要素を設定
    updateState({popupOpen: true}); // ポップアップを開く
  };

  /**
   * ポップアップを閉じる
   */
  const handleClosePopup = () => {
    updateState({popupOpen: false});
  };

  /**
   * 描画
   */
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      {Object.entries(reactionCounts).map(([reactionNumber, count]) => {
        const isMyReactionIncluded = props.message.reactions?.some(reaction => reaction.reaction_number === Number(reactionNumber) && reaction.user_id === myUserId);
        let tooltipTitle = '';
        if ( !props.disableClick ){
          tooltipTitle = isMyReactionIncluded ? 'このリアクションを取り消す' : '同じリアクションをする';
        }

        return (
          <Tooltip key={reactionNumber} title={tooltipTitle}>
            <Button
              variant="outlined"
              onClick={() => props.disableClick ? null : onReactionClick(Number(reactionNumber))}
              sx={{
                padding: 0,
                minWidth: '48px'
              }}
            >
              <Typography sx={{fontSize: Constants.CHAT_REACTION_FONT_SIZE}}>
                {Constants.CHAT_REACTION[Number(reactionNumber)]} {count}
              </Typography>
            </Button>
          </Tooltip>
        );
      })}
      {reactionCount > 0 && !props.disableClick && (
        <Tooltip title={"リアクションを確認する"}>
          <IconButton color="primary" onClick={onViewReactionsClick}>
            <GroupIcon />
          </IconButton>
        </Tooltip>
        )
      }
      {/* ChatReactionPopupの条件付きレンダリング */}
      {state.popupOpen && (
        <ChatReactionPopup
          open={state.popupOpen}
          anchorEl={state.anchorEl}
          message={props.message}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

