import React from 'react';
import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";

interface AddCalendarButtonProps {
  onClick: () => void; // クリックイベントハンドラ
  label: string; // 表示する文字
  showPlus?: boolean; // プラスを表示するかどうか
  labelBackgroundColor?: string; // ラベルの背景色
  labelColor?: string; // ラベルの文字色
  iconColor?: string; // アイコンの色
  tooltip?: string; // ツールチップ
}

/**
 * カレンダーボタン
 * @constructor
 */
export const CalendarButton = (props: AddCalendarButtonProps) => {
  return (
    <Tooltip title={props.tooltip}>
      <IconButton onClick={props.onClick} size={"small"}>
        <Box position="relative" display="inline-flex">
          <CalendarTodayIcon fontSize="large" sx={{color: props.iconColor}} />
          <Box
            component="span"
            position="absolute"
            top="0"
            right="0"
            bgcolor= {props.labelBackgroundColor ?? "primary.main"}
            color= {props.labelColor ?? "common.white"}
            borderRadius="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="16px"
            height="16px"
            fontSize="0.75rem"
            sx={{
              transform: 'translate(-60%, 80%)', // バッジをアイコンの右上に調整
              minWidth: '16px', // バッジの最小幅を保証
            }}
          >
            {props.label}
          </Box>
          {props.showPlus && ( // プラスを表示する場合のみ表示
            <Box
              component="span"
              position="absolute"
              top="0"
              right="0"
              bgcolor="red"
              color="common.white"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="16px"
              height="16px"
              fontSize="1.5rem"
              sx={{
                transform: 'translate(42%, 0%)', // バッジをアイコンの右上に調整
                minWidth: '16px', // バッジの最小幅を保証
              }}
            >
              +
            </Box>
          )}
        </Box>
      </IconButton>
    </Tooltip>
  );
};
