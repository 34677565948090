/**
 * フリーマーケット情報
 */
export class FreeMarketInfo {
  id?: number;
  stock_code?: string;
  number?: number;
  name?: string;
  price?: number;
  quantity_lower?: number | null;
  quantity_upper?: number | null;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<FreeMarketInfo> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<FreeMarketInfo>): FreeMarketInfo {
    return new FreeMarketInfo({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): FreeMarketInfo {
    return new FreeMarketInfo({
      ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): FreeMarketInfo {
    const decoded = JSON.parse(jsonString);
    return FreeMarketInfo.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }

  getDataGridValues(): string[] {
    return [
      this.id?.toString() || '',
      this.stock_code || '',
      this.name || '',
      this.price?.toString() || '',
      this.quantity_lower?.toString() || '',
      this.quantity_upper?.toString() || '',
    ];
  }
}
