import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {SnackbarProvider} from "./Pages/Common/Provider/SnackBarProvider";
import TestPage from "./Test";
import {LoginService} from "./Services/LoginService";
import React, {useEffect, useState} from "react";
import ECProgress from "./Pages/Common/Components/ECProgress";
import {LoginPage} from "./Pages/LoginPage";
import {MainPage} from "./Pages/Main/MainPage";
import {ProductService} from "./Services/ProductService";
import {EventService} from "./Services/System/EventService";
import {SettingPage} from "./Pages/Settings/SettingPage";
import {ThemeProvider} from "@mui/material";
import {appTheme} from "./theme";
import {ChatPage} from "./Pages/Chat/ChatPage";
import {InitialLoader} from "./Services/System/InitialLoader";
import {AIChatPage} from "./Pages/AI/AIChatPage";

export const App = () => {

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    processing: true,
    isLogin: false,
  });
  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {

    EventService.getInstance().onEvent(EventService.EVENT_LOGGED_IN, handleLoggedIn);
    EventService.getInstance().onEvent(EventService.EVENT_LOGGED_OUT, handleLoggedOut);


    // 各サービスの初期化
    (async () => {
      // ログインサービス
      await LoginService.getInstance().init();
      if ( LoginService.getInstance().isLogin() ) {
        // 初期データ読み込み
        await InitialLoader.getInstance().load();
      }

      // 商品サービス
      await ProductService.getInstance().init();

      const isLogin = LoginService.getInstance().isLogin();
      updateState({isLogin: isLogin, processing: false});

      // クリーンアップ
      return () => {
        EventService.getInstance().removeListener(EventService.EVENT_LOGGED_IN, handleLoggedIn);
        EventService.getInstance().removeListener(EventService.EVENT_LOGGED_OUT, handleLoggedOut);
      };

    })();
  }, []);

  /**
   * ログイン時処理
   */
  const handleLoggedIn = () => {
    window.location.href = '/main'; // リロード
  };
  /**
   * ログアウト時処理
   */
  const handleLoggedOut = () => {
    window.location.href = '/login'; // リロード
  };

  /**
   * プライベートルート描画
   * @param element
   */
  const privateRoute = (element: any) => {
    if (!state.isLogin) {
      return <Navigate to="/login" />;
    }
    return element;
  };

  /**
   * 描画
   */
  if (state.processing) {
    return <ECProgress open={state.processing}></ECProgress>;
  }

  return (
    <ThemeProvider theme={appTheme}>
      <SnackbarProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/main" replace />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/main" element={privateRoute(<MainPage />)} />
            <Route path="/settings" element={privateRoute(<SettingPage />)} />
            <Route path="/chat" element={privateRoute(<ChatPage />)} />
            <Route path="/test" element={privateRoute(<TestPage />)} />
            <Route path="/ai" element={privateRoute(<AIChatPage />)} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
