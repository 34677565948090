// ReactionSelectPanelVisibilityService.ts
interface AnchorPosition {
  top: number;
  left: number;
}

export class ReactionSelectPanelVisibilityService {
  private static instance: ReactionSelectPanelVisibilityService;
  private activeMessageId: number | null = null;
  private anchorPosition: AnchorPosition | null = null;
  private listeners: Function[] = [];

  private constructor() {}

  public static getInstance(): ReactionSelectPanelVisibilityService {
    if (!ReactionSelectPanelVisibilityService.instance) {
      ReactionSelectPanelVisibilityService.instance = new ReactionSelectPanelVisibilityService();
    }
    return ReactionSelectPanelVisibilityService.instance;
  }

  public toggleMessageIdVisibility(messageId: number, position?: AnchorPosition): void {
    if (this.activeMessageId === messageId) {
      this.activeMessageId = null;
      this.anchorPosition = null;
    } else {
      this.activeMessageId = messageId;
      this.anchorPosition = position || null;
    }
    this.notifyListeners();
  }

  public setMessageIdVisibility(messageId: number, visible: boolean, position?: AnchorPosition): void {
    if (visible) {
      this.activeMessageId = messageId;
      this.anchorPosition = position || null;
    } else if (this.activeMessageId === messageId) {
      this.activeMessageId = null;
      this.anchorPosition = null;
    }
    this.notifyListeners();
  }

  public isMessageIdVisible(messageId: number): boolean {
    return this.activeMessageId === messageId;
  }

  public getAnchorPosition(): AnchorPosition | null {
    return this.anchorPosition;
  }

  public subscribe(listener: Function): void {
    this.listeners.push(listener);
  }

  public unsubscribe(listener: Function): void {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  private notifyListeners(): void {
    this.listeners.forEach(listener => listener());
  }
}
