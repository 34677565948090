import { LogUtils } from "../Common/LogUtils";
import { HttpServiceBase } from "./HttpServiceBase";
import { EcmgApiError } from "../Common/EcmgApiError";
import {CalendarItem} from "../Models/Calendar/CalendarItem";

/**
 * カレンダーサービス
 */
export class CalendarService extends HttpServiceBase {
  private static instance: CalendarService = new CalendarService();

  private constructor() {
    super();
  }

  static getInstance(): CalendarService {
    return this.instance;
  }

  /**
   * 特定のカレンダー情報取得
   * @param yearMonth 年月
   * @param calendarType カレンダータイプ
   * @returns Promise<CalendarItem[]>
   */
  public async getCalendarData(yearMonth: string, calendarType: string): Promise<CalendarItem[]> {
    LogUtils.d("Fetching calendar data");

    try {
      const axios = await this.getAxios(false);
      const response = await axios.get(
        `api/calendar_items/by_year_month_and_type?year_month=${yearMonth}&calendar_type=${calendarType}`,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.data);

      // レスポンスデータが配列であることを想定し、それぞれのオブジェクトをCalendarItemインスタンスに変換
      if ( Array.isArray(response.data) ){
        return response.data.map((item: any) => CalendarItem.fromMap(item));
      }
      else {
        return [];
      }
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * カレンダーアイテムの新規作成
   * @param calendarItemData カレンダーアイテムのデータ
   */
  public async createCalendarItem(calendarItemData: Partial<CalendarItem>): Promise<CalendarItem> {
    LogUtils.d("Creating new calendar item");

    try {
      const axios = await this.getAxios(false);
      const response = await axios.post(
        `api/calendar_items`,
        calendarItemData,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.data);
      return CalendarItem.fromMap(response.data);

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * IDによるカレンダーアイテムの詳細取得
   * @param id カレンダーアイテムのID
   */
  public async getCalendarItemById(id: number): Promise<CalendarItem> {
    LogUtils.d(`Fetching calendar item with ID: ${id}`);

    try {
      const axios = await this.getAxios(false);
      const response = await axios.get(
        `api/calendar_items/${id}`,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.data);
      return CalendarItem.fromMap(response.data);

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * カレンダーアイテムの更新
   * @param id 更新するカレンダーアイテムのID
   * @param updateData 更新データ
   */
  public async updateCalendarItem(id: number, updateData: Partial<CalendarItem>): Promise<CalendarItem> {
    LogUtils.d(`Updating calendar item with ID: ${id}`);

    try {
      const axios = await this.getAxios(false);
      const response = await axios.put(
        `api/calendar_items/${id}`,
        updateData,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.data);
      return CalendarItem.fromMap(response.data);

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * カレンダーアイテムの削除
   * @param id 削除するカレンダーアイテムのID
   */
  public async deleteCalendarItem(id: number): Promise<void> {
    LogUtils.d(`Deleting calendar item with ID: ${id}`);

    try {
      const axios = await this.getAxios(false);
      await axios.delete(
        `api/calendar_items/${id}`,
        this.makeAuthorizeOption()
      );

      LogUtils.d("Deleted successfully");

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }


}
