import React, { useState } from "react";
import { Product } from "../../../../../Models/Product/Product";
import { ProductService } from "../../../../../Services/ProductService";
import { EcmgApiError } from "../../../../../Common/EcmgApiError";
import { useSnackbar } from "../../../../Common/Provider/SnackbarContext";
import { LogUtils } from "../../../../../Common/LogUtils";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {AddCountComponent} from "../../Common/AddCountComponent/AddCountComponent";

interface AddProductStockProps {
  product: Product;
}

/**
 * 一括在庫追加入力
 * @param props
 * @constructor
 */
export const AddProductStock = (props: AddProductStockProps) => {

  /**
   * useSnackbar
   */
  const { showSnackbarMessage } = useSnackbar();

  /**
   * useState
   */
  const [state, setState] = useState({
    stockCount: undefined as string | undefined,
    processing: false,
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 一括在庫振分処理
   */
  const autoDistributeStock = async (stockCountText: string) => {
    let message = '';

    try {
      // 半角数字とハイフンのみ許可
      if (!CommonUtils.isSignedInteger(stockCountText)) {
        return;
      }

      updateState({ processing: true, stockCount: stockCountText });

      // 一括在庫振分処理
      const stockCount = Number(stockCountText);
      message = await ProductService.getInstance().bulkDistributeStock(props.product.stock_code!, stockCount);

      updateState({processing: false, stockCount: ''});

    } catch (e) {
      LogUtils.ex(e);

      updateState({ stockCount: undefined, processing: false});

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = '一括在庫振分処理でエラーが発生しました。';
      }
    }

    showSnackbarMessage(message);
  };

  /**
   * 使用不可か？
   */
  const isDisabled = () => {
    return props.product.mall_flag !== 1 || props.product.stock_pattern_id == null;
  }

  /**
   * 描画
   */
  return (
    <AddCountComponent
      label="追加 ＋"
      stockCount={state.stockCount}
      onAction={autoDistributeStock}
      processing={state.processing}
      isDisabled={isDisabled()}
    />
  );
}
