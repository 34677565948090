import React, {useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

interface SyncOrderDialogProps {
  open: boolean;
  onClose: () => void;
  onOK: (date: string, time: string) => void;
}

/**
 * 注文同期ダイアログ
 */
export const SyncOrderDialog = (props: SyncOrderDialogProps) => {
  /**
   * useState
   */
  const [state, setState] = useState({
    syncDate: '',
    syncTime: ''
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * 描画
   */
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>同期開始日時設定</DialogTitle>
      <DialogContent >
        <Box mb={2}></Box>

        <TextField
          label="日付"
          type="date"
          value={state.syncDate}
          style={{ width: '200px' }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => {
            updateState({syncDate: e.target.value});
          }}
        />
        <Box mr={2} component="span" />
        <TextField
          label="時間"
          type="time"
          style={{ width: '200px' }}
          value={state.syncTime}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => {
            updateState({syncTime: e.target.value});
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onOK(state.syncDate, state.syncTime)}>OK</Button>
        <Button onClick={props.onClose}>キャンセル</Button>
      </DialogActions>
    </Dialog>
  );
}
