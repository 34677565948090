import { LogUtils } from "../../Common/LogUtils";
import { HttpServiceBase } from "../HttpServiceBase";
import {UserService} from "../UserService";
import {MallService} from "../MallService";
import {StoreService} from "../StoreService";
import {PricePatternService} from "../Pattern/PricePatternService";
import {StockPatternService} from "../Pattern/StockPatternService";
import {SystemSettingService} from "../SystemSettingService";
import {SpecialDateService} from "../SpecialDate/SpecialDateService";
import {TagService} from "../Tag/TagService";
import {OfficeService} from "../OfficeService";
import {TagCategoryService} from "../Tag/TagCategoryService";

export class InitialLoader extends HttpServiceBase {
  private static instance: InitialLoader = new InitialLoader();

  private constructor() {
    super();
  }

  static getInstance(): InitialLoader {
    return this.instance;
  }

  async load(): Promise<void> {
    try {
      const axios = await this.getAxios();
      const response = await axios.get(
        `api/initial-load`,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      const data = response.data as any;
      if ( data == null ){
        return ;
      }

      UserService.getInstance().setData(data['users']);
      MallService.getInstance().setData(data['malls']);
      StoreService.getInstance().setData(data['stores']);
      PricePatternService.getInstance().setData(data['price_patterns']);
      StockPatternService.getInstance().setData(data['stock_patterns']);
      SystemSettingService.getInstance().setData(data['system-settings']);
      SpecialDateService.getInstance().setData(data['special-dates']);
      TagService.getInstance().setData(data['tags']);
      TagCategoryService.getInstance().setData(data['tag_categories']);
      OfficeService.getInstance().setData(data['offices']);


    } catch (e) {
      LogUtils.ex(e);
    }
  }

}
