import React from 'react';
import {Box, Typography} from '@mui/material';
import {OrderCountData} from '../../../../Models/Product/OrderCountData';
import {DigitDisplay} from "../../../Common/Components/DigitDisplay";
import {MainPageConstants} from "../../Common/MainPageConstants";
import {EventService} from "../../../../Services/System/EventService";
import {SpecialDateService} from "../../../../Services/SpecialDate/SpecialDateService";

interface SalesTrendProps {
  stockCode: string;
  orderCountData?: OrderCountData;
  onDayClick?: (day: number) => void;
  height: number;
  width?: number;
  isSKU: boolean;
}

/**
 * 販売傾向
 * @constructor
 * @param props
 */
export const SalesTrend = (props: SalesTrendProps) => {
  /**
   * 定数
   */
  const LIST_BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
    height: props.height,
    width: props.width ?? '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    cursor: 'pointer'
  };
  const LIST_LEFT_BOX_STYLE = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };
  const LIST_RIGHT_BOX_STYLE = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1
  };
  const SKU_BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
    height: props.height,
    width: props.width ?? '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    cursor: 'pointer'
  };
  const SKU_UPPER_BOX_STYLE = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };
  const SKU_LOWER_BOX_STYLE = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1
  };

  /**
   * スタイルを取得
   * @param days
   * @param stock
   */
  const getStyle = (days: number): React.CSSProperties | undefined => {
    // 指定日の在庫数を取得
    const stock = props.orderCountData?.dailyStock?.[days] ?? null;
    // 日付ごとのスタイルを取得
    let style = SpecialDateService.getInstance().getSalesHistoryStyleBeforeDays(days);
    if (stock === null || stock === 0 ) {
      style = {...style, fontStyle: "italic"}; // 在庫数が0の場合はイタリック
    }
    return style;
  }

  /**
   * 販売数推移を描画
   * @param startDay
   * @param endDay
   * @param isSKU
   */
  const renderDailySales = (startDay: number, endDay: number, isSKU: boolean) => {
    const digitFontSize = isSKU ? "18px" : "20px";
    const arrowFontSize = isSKU ? "14px" : "16px";


    const values = [];
    for (let i = startDay; i <= endDay; i++) {
      const dailySales = props.orderCountData?.dailySales?.[i] || 0;
      values.push(dailySales);
    }
    return (
      <>
        {values.map((value, index) => (
          <React.Fragment key={index}>
            <span
              style={{
                cursor: props.onDayClick ? 'pointer' : 'default',
              }}
              onClick={() => props.onDayClick && props.onDayClick(startDay + index)}>
              <DigitDisplay
                value={value}
                length={3}
                filledColor={MainPageConstants.COLOR_DARK_GRAY}
                //emptyColor={MainPageConstants.COLOR_TRANSPARENT}
                fontSize={digitFontSize}
                cursor={"pointer"}
                style={getStyle(startDay + index)}
              />
            </span>
            {index !== values.length - 1 &&
              <Typography
                component="span"
                display="inline"
                color={MainPageConstants.COLOR_DARK_GRAY}
                fontSize={arrowFontSize}
                style={SpecialDateService.getInstance().getSalesHistoryStyleBeforeDaysForArrow(startDay + index)}
              >
                {index === 6 ? ' | ' : ' ← '}
              </Typography>
            }
          </React.Fragment>
        ))}
      </>
    );
  };

  /**
   * 販売平均数文字列を返す
   */
  const getAverageSales = () => {
    if ( props.orderCountData?.averageSales === undefined || props.orderCountData.averageSales === -1 ){
      return ( '---' );
    }
    return ( props.orderCountData.averageSales.toFixed(1)  );
  }

  /**
   * クリック時処理
   */
  const onClick = () => {
    EventService.getInstance().emitEvent(EventService.EVENT_SHOW_SALES_DATA, props.stockCode);
  }

  /**
   * 今日の販売数を返す
   */
  const todaySales = () => {
    return props.orderCountData?.todaySales ??  0;
  }

  /**
   * 描画
   */
  return props.isSKU ?
  // SKUの場合
  (
    <Box sx={SKU_BOX_STYLE} onClick={onClick}>
      {/* 上側 */}
      <Box sx={SKU_UPPER_BOX_STYLE}>
        <Box ml={1} />
        <Typography color={MainPageConstants.COLOR_RED}>直近30日の平均販売数/日</Typography>
        <Typography variant="h5" color={MainPageConstants.COLOR_RED} fontWeight="bold">
          【 {getAverageSales()} 】
        </Typography>
        <Box flexGrow={1} />
        <DigitDisplay
          value={todaySales()}
          length={3}
          filledColor={MainPageConstants.COLOR_DARK_GRAY}
          fontSize={"18px"}
          cursor={"pointer"}
          style={getStyle(0)}
        />
        <Box mr={3} />
      </Box>
      {/* 下側 */}
      <Box sx={SKU_LOWER_BOX_STYLE}>
        <Typography component="span">{renderDailySales(1, 7, true)}</Typography>
        <Box mt={1} />
        <Typography component="span">{renderDailySales(8, 14, true)}</Typography>
        <Box mt={1} />
        <Typography component="span">{renderDailySales(15, 21, true)}</Typography>
        <Box mt={1} />
        <Typography component="span">{renderDailySales(22, 28, true)}</Typography>
      </Box>
    </Box>
  )
  :
  // リストの場合
  (
    <Box sx={LIST_BOX_STYLE} onClick={onClick}>
      {/* 左側 */}
      <Box sx={LIST_LEFT_BOX_STYLE}>
        <Typography variant="h6" color={MainPageConstants.COLOR_RED} fontWeight="bold">
          【販売：{getAverageSales()} 個/日 】
        </Typography>
      </Box>

      {/* 右側 */}
      <Box sx={LIST_RIGHT_BOX_STYLE}>
        {/* 本日の販売数 */}
        <Box display={"flex"} flexDirection={"row"} alignItems={"end"}>
          <span>
            <DigitDisplay
              value={todaySales()}
              length={3}
              filledColor={MainPageConstants.COLOR_DARK_GRAY}
              fontSize={"30px"}
              cursor={"pointer"}
              style={getStyle(0)}
            />
          </span>
          <Typography
            component="span"
            display="inline"
            color={MainPageConstants.COLOR_DARK_GRAY}
            fontSize={"20px"}
            style={SpecialDateService.getInstance().getSalesHistoryStyleBeforeDaysForArrow(0)}
          >
            {'　|'}
          </Typography>

          {/* 1日前〜14日前の販売数 */}
          <Typography component="span">{renderDailySales(1, 14, false)}</Typography>
        </Box>

      </Box>
    </Box>
  );
}
