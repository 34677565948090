import React, {useEffect, useState} from 'react';
import {useSnackbar} from "../Common/Provider/SnackbarContext";
import ECProgress from "../Common/Components/ECProgress";
import {Box, } from "@mui/material";
import {AIChatPageParam} from "./Model/AIChatPageParam";
import {AIChatMessage} from "./Model/AIChatMessage";
import {AIChatMessagesList} from "./AIChatMessagesList";
import {AIChatInputForm} from "./AIChatInputForm";
import {AIChatService} from "./AIChatService";
import {LogUtils} from "../../Common/LogUtils";
import {AIConstants} from "./AIConstants";

interface AIChatViewProps {
  chatPageParam: AIChatPageParam;
  bubbleWidth?: number;
  height?: string;
}

/**
 * AIチャットビュー
 *
 * @constructor
 */
export const AIChatView = (props: AIChatViewProps) => {
  /**
   * 初期状態
   */
  const initialState = {
    processing: false,
    messages: [] as AIChatMessage[],
    threadId: undefined as string | undefined,
    addEvent: undefined as {
      prevMessageId: number;
    } | undefined,
  }

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState(initialState);

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
  // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * マウント・アンマウント時
   */
  useEffect(() => {
    // 全てクリア
    updateState(initialState);
  }, []);


  /**
   * メッセージ送信
   * @param message
   */
  const handleSendMessage = async (message: string) => {
    try {
      //updateState({processing: true});

      // 末尾にメッセージを追加
      const lastMessage = state.messages.length > 0 ? state.messages[state.messages.length - 1] : undefined;
      const newUserMessageId = (lastMessage?.id ?? 0) + 1;
      const newAIMessageId = newUserMessageId + 1;
      updateState({
        messages: [
          ...state.messages,
          // ユーザーメッセージ
          new AIChatMessage({
            id: newUserMessageId,
            role: AIConstants.AI_ROLE_USER,
            message: message,
            sent_date_time: new Date(),
          }),
          // 待機メッセージ
          new AIChatMessage({
            id: newAIMessageId,
            role: AIConstants.AI_ROLE_ASSISTANT,
            message: AIConstants.WAITING_MESSAGE,
            sent_date_time: new Date(),
          }),

        ],
        addEvent: {
          prevMessageId: lastMessage?.id ?? 0
        }
      });

      // スレッドID取得
      let threadId = state.threadId;
      if (threadId == null) {
        threadId = await AIChatService.getInstance().createThread();
        updateState({threadId: threadId});
      }

      // メッセージ送信
      await AIChatService.getInstance().sendMessage(
        props.chatPageParam.type ?? '',
        message,
        threadId,
        (message) => onMessageReceived(newAIMessageId, message)
      );

    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('送信処理でエラーが発生しました。');
    }
    finally {
      //updateState({processing: false});
    }
  };

  /**
   * メッセージ受信時
   * @param targetMessageId
   * @param message
   */
  const onMessageReceived = (targetMessageId: number, message: string) => {
    setState(prevState => {

      // 末尾のメッセージを取得(0件でなければ)
      const lastMessage = prevState.messages.length > 0 ? prevState.messages[prevState.messages.length - 1] : undefined;

      // 最後のメッセージが対象のメッセージの場合は、そのメッセージを更新
      if (lastMessage?.id === targetMessageId) {
        const newMessages = prevState.messages.map(m => {
          if (m.id === lastMessage.id) {
            // 待機メッセージは削除
            const currentMessage = m.message?.replace(AIConstants.WAITING_MESSAGE, '');
            return m.copyWith({message: currentMessage + message});
          }
          // 待機メッセージの場合は削除
          if (m.message === AIConstants.WAITING_MESSAGE) {
            return m.copyWith({message: '[キャンセルされました]'});
          }
          return m;
        });
        return {
          ...prevState,
          messages: newMessages,
        }
      }
      // 末尾のメッセージが対象でなければ何もしない
      else {
        return prevState;
      }
    });
  }

  /**
   * 描画
   */
  return (
    <>
      <Box
        flexGrow={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: props.height ?? 'calc(100vh - 64px)', // 64px is the height of the AppBar
          overflow: 'hidden'
        }}
      >
        {/* メッセージリスト */}
        <AIChatMessagesList
          messages={state.messages}
          bubbleWidth={props.bubbleWidth}
          addEvent={state.addEvent}
        />

        {/* 入力欄 */}
        <Box pb={1}>
          <AIChatInputForm
            onSendMessage={handleSendMessage}
          />
        </Box>
      </Box>
      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>
    </>
  );
}
