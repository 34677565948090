import React, {ReactNode, useEffect, useState} from 'react';
import {Box, LinearProgress} from '@mui/material';
import {AIChatMessage} from "../Model/AIChatMessage";
import {marked} from 'marked';
import DOMPurify from 'dompurify';
import {LogUtils} from "../../../Common/LogUtils";
import {AIConstants} from "../AIConstants";
interface AIChatMessageContentProps {
  message: AIChatMessage;
}

/**
 * AIチャット内容
 * @param props
 * @constructor
 */
export const AIChatMessageContent = (props: AIChatMessageContentProps) => {
  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useEffect マウント・アンマウント時
   */
  useEffect(() => {
  }, []);

  /**
   * 文字列解析
   * @param content
   */
  const parseTaggedContent = (content: string): ReactNode[] => {
  LogUtils.d('parseTaggedContent:'+ content);

    // 待機メッセージならプログレスを表示
    if (content === AIConstants.WAITING_MESSAGE) {
      return [<LinearProgress />];
    }


    let currentText = '';
    const nodes: ReactNode[] = [];
    let componentKey = 0; // コンポーネントに割り当てるキー

    for (let i = 0; i < content.length; i++) {
      const char = content[i];
      // 通常の文字
      currentText += char;
    }

    if (currentText) {
      nodes.push(renderMemoWithMarkdown(""+ componentKey, currentText));
      //nodes.push(...renderTextWithLineBreaks(`text-${componentKey}`, currentText));
    }

    return nodes;
  }
  //
  // /**
  //  * テキストを改行で分割して描画
  //  * @param keyPrefix
  //  * @param text
  //  */
  // const  renderTextWithLineBreaks = (keyPrefix: string, text: string): ReactNode[] => {
  //   return text.split('\n').map((line, index) => (
  //     <span key={`${keyPrefix}-${index}`} style={{verticalAlign: 'top'}}>
  //       {renderMemoWithMarkdown(line)}
  //       {index < text.split('\n').length - 1 && <br />}
  //     </span>
  //   ));
  // }

  /**
   * メモをマークダウン付きでレンダリング
   * @param key
   * @param text
   */
  const renderMemoWithMarkdown = (key: string, text: string) => {
  // LogUtils.d('markdownText:'+ text);


    const rawHtml = marked(text) as string;
    const cleanHtml = DOMPurify.sanitize(rawHtml);
    return <span key={`${key}`} style={{verticalAlign: 'top'}} dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
  };

  // /**
  //  * メモをリンク付きでレンダリング
  //  * @param text
  //  */
  // const renderMemoWithLinks = (text: string) => {
  //   const urlRegex = /(https?:\/\/[^\s]+)/g;
  //   return text.split(urlRegex).flatMap((part, i) =>
  //     i % 2 === 0 ? (
  //       part.split('\n').map((line, lineIndex) =>
  //         // Check if it's the last line; if not, add a <br /> after it
  //         <React.Fragment key={`${i}-${lineIndex}`}>
  //           {line}
  //           {lineIndex < part.split('\n').length - 1 ? <br /> : null}
  //         </React.Fragment>
  //       )
  //     ) : (
  //       <Link
  //         href={part}
  //         key={i}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         style={{
  //           whiteSpace: 'break-spaces',
  //           overflowWrap: 'break-word',
  //           wordBreak: 'break-all'
  //         }}>
  //         {part}
  //       </Link>
  //     )
  //   );
  // };

  /**
   * 描画
   */
  return (
    <Box>
      {parseTaggedContent(props.message.message ?? '')}
    </Box>
  );
};

