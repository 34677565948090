import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {SalesSummary} from "../../../../../Models/SalesData/SalesData";
import {Divider, IconButton} from "@mui/material";
import {CalendarIcon} from "@mui/x-date-pickers";
import {DateRangeDialog} from "../../../../Common/Components/DateRangeDialog";
import {LogUtils} from "../../../../../Common/LogUtils";
import {SalesService} from "../../../../../Services/SalesService";
import ECProgress from "../../../../Common/Components/ECProgress";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {SalesDataConstants} from "./SalesDataConstants";

interface SalesSummaryProps {
  salesSummary: SalesSummary;
  stockCode: string;
  mallNo?: number;
  mallProductId?: string;
}

/**
 * 販売数集計
 * @constructor
 * @param props
 */
export const SalesSummaryComponent = (props: SalesSummaryProps) => {
  /**
   * 定数
   */
  const FONT_SIZE = '12px';
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
  };
  const LABEL_STYLE = {
    width: 80,
    textAlign: 'center',
    fontSize: FONT_SIZE,
  };
  const DATA_STYLE = {
    width: 60,
    textAlign: 'center',
    fontSize: FONT_SIZE,
  };
  // 期間キーと表示ラベルの対応
  const periods = {
    '1': '1日間',
    '3': '3日間',
    '7': '7日間',
    '14': '14日間',
    '30': '30日間',
    '60': '60日間',
    '90': '90日間',
    '180': '180日間',
    '365': '365日間',
    'all_time': '全期間',
  } as any;

  // キーをソートする関数（all_timeは最後に）
  const sortedKeys = Object.keys(periods).sort((a, b) => {
    if (a === 'all_time') return 1;
    if (b === 'all_time') return -1;
    return parseInt(a) - parseInt(b);
  });


  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * 状態
   */
  const [state, setState] = useState({
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
    specificTotal: undefined as number | undefined,
    specificAverage: undefined as number | undefined,
    calendarDialogOpen: false,
    processing: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  /**
   * 在庫コード変更時処理
   */
  useEffect(() => {
    updateState({
      startDate: undefined,
      endDate: undefined,
      specificTotal: undefined,
      specificAverage: undefined,
    });
  }, [props.stockCode]);


  /**
   * カレンダーボタンクリック
   */
  const handleCalendarClick = () => {
    // ダイアログを開く
    updateState({ calendarDialogOpen: true });
  }

  /**
   * 数値をフォーマット
   * @param num
   * @param digits
   */
  const formatNumber = (num: number | undefined, digits = 0) => {
    if ( num == null ){
      return '---';
    }
    return CommonUtils.formatNumber(num, digits);
  }

  /**
   * 期間指定
   */
  const specificDate = () => {
    // 期間の日数を求める
    let difDays = '---日間';
    if ( state.startDate && state.endDate ) {
      const diffDays = (state.endDate?.getTime()! - state.startDate?.getTime()!) / (1000 * 60 * 60 * 24) + 1;
      difDays = diffDays + '日間';
    }
    // 合計
    let specificTotal= formatNumber(state.specificTotal);
    // 平均
    let specificAverage= formatNumber(state.specificAverage, 2);

    // 開始日
    let startDate = '--- から';
    if ( state.startDate ){
      startDate = CommonUtils.formatDate(state.startDate) + ' から';
    }
    // 終了日
    let endDate = '--- まで';
    if ( state.endDate ){
      endDate = CommonUtils.formatDate(state.endDate) + ' まで';
    }

    const dataStyle = {
      textAlign: 'center',
      fontSize: FONT_SIZE,
    };

    return (
      <Box display="flex" flexDirection={"row"}
        sx={{
          height: '100%',
          boxSizing: 'border-box',
          border: '1px solid lightgray',

        }}
      >
        <Box px={1} sx={{backgroundColor: SalesDataConstants.MID_GRAY}}>
          <Typography sx={{fontSize: FONT_SIZE}}>
            <strong>
              期<br/>
              間<br/>
              指<br/>
              定<br/>
            </strong>
          </Typography>
        </Box>
        <Box display="flex" flexDirection={"column"} justifyContent={"space-evenly"}
          sx={{minWidth: 60}}>
          <Box>
            <Typography sx={dataStyle}>{difDays}</Typography>
          </Box>
          <Divider/>
          <Box >
            <Typography sx={dataStyle}>{specificTotal}</Typography>
          </Box>
          <Divider/>
          <Box>
            <Typography sx={dataStyle}>{specificAverage}</Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection={"column"} sx={{minWidth: 100}}>
          <Box sx={{textAlign: 'center'}}>
            <IconButton color="inherit" onClick={handleCalendarClick}>
              <CalendarIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography sx={dataStyle}>{startDate}</Typography>
          </Box>
          <Box>
            <Typography sx={dataStyle}>{endDate}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  /**
   * 期間指定更新時
   */
  const handleSpecificDateChange = async (startDate: Date, endDate: Date) => {
    try {

      updateState({processing: true});

      const salesData = await SalesService.getInstance().getSpecificSalesData(
        props.stockCode,
        startDate, endDate,
        props.mallNo,
        props.mallProductId );

      updateState({
        startDate: startDate,
        endDate: endDate,
        specificTotal: salesData.total,
        specificAverage: salesData.average,
        calendarDialogOpen: false,
        processing: false});

    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('販売推移データ表示でエラーが発生しました。');
    }
    finally {
      updateState({processing: false});
    }
  }

  /**
   * 見出し列
   */
  const titleColumn = () => {
    return (
      <Box display={"flex"} flexDirection={"column"} justifyContent={"space-evenly"}
        sx={{backgroundColor: SalesDataConstants.MID_GRAY}} >
        <Box>
          <Typography sx={LABEL_STYLE}><strong>集計期間</strong></Typography>
        </Box>
        <Divider/>
        {/* 販売数合計行 */}
        <Box>
          <Typography sx={LABEL_STYLE}><strong>販売数合計</strong></Typography>
        </Box>
        <Divider />
        {/* 平均販売数行 */}
        <Box>
          <Typography sx={LABEL_STYLE}><strong>平均販売数</strong></Typography>
        </Box>
      </Box>
    );
  }

  /**
   * データ列
   * @param key
   * @param index
   */
  const dataColumn = (key: string, index: number) => {
    let backgroundColor = index % 2 === 0 ? 'white' : SalesDataConstants.LIGHT_GRAY;
    let style = DATA_STYLE as any;
    let dividerColor = undefined as any;
    if ( key === 'all_time' ){
      backgroundColor = SalesDataConstants.DARK_GRAY;
      style = {...DATA_STYLE, color: 'white'};
      dividerColor = SalesDataConstants.MID_GRAY;
    }

    return (
      <Box
        display="flex" flexDirection={"column"} justifyContent={"space-evenly"}
        sx={{
          backgroundColor: backgroundColor,
        }}
      >
        {/* ヘッダ行 */}
        <Box>
          <Typography sx={style} key={key}><strong>{periods[key]}</strong></Typography>
        </Box>
        <Divider color={dividerColor}/>
        {/* 販売数合計行 */}
        <Box>
          <Typography sx={style} key={key}>{formatNumber(props.salesSummary[key].total)}</Typography>
        </Box>
        <Divider color={dividerColor}/>
        {/* 平均販売数行 */}
        <Box>
          <Typography sx={style} key={key}>{formatNumber(props.salesSummary[key].average, 2)}</Typography>
        </Box>

      </Box>
    );
  }


  /**
   * 描画
   */
  return (
    <>
      <Box display="flex" flexDirection={"row"}>
        <Box display="flex" flexDirection={"row"} sx={BOX_STYLE} >
          {/*  見出し列 */}
          {titleColumn()}
          {/*  データ列 */}
          <Box display="flex" flexDirection={"row"} justifyContent="space-between">
            {sortedKeys.map((key, index) => (
              dataColumn(key, index)
            ))}
          </Box>
        </Box>

        {/* 期間指定 */}
        <Box ml={1} >
          {specificDate()}
        </Box>
      </Box>

      {/* 期間指定ダイアログ */}
      <DateRangeDialog
        open={state.calendarDialogOpen}
        initialStartDate={state.startDate}
        initialEndDate={state.endDate}
        onClose={() => updateState({calendarDialogOpen: false})}
        onSave={(startDate, endDate) => handleSpecificDateChange(startDate, endDate)}
      />

      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>
    </>
  );
};

