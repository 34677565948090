import {HttpServiceBase} from "../HttpServiceBase";
import {StockPattern} from "../../Models/Pattern/StockPattern";
import {LogUtils} from "../../Common/LogUtils";
import {EventService} from "../System/EventService";
import {EcmgApiError} from "../../Common/EcmgApiError";
import axios from "axios";
import {CommonUtils} from "../../Common/CommonUtils";

/**
 * 在庫パターンサービス
 */
export class StockPatternService extends HttpServiceBase {
  private static instance: StockPatternService = new StockPatternService();
  private stockPatternMap: Map<number, StockPattern> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): StockPatternService {
    return this.instance;
  }

  /**
   * 初期化
   */
  async init(): Promise<void> {
    LogUtils.d("Initializing StockPatternService");
    await this._loadStockPatterns();

    // 在庫パターン更新イベント
    const eventService = EventService.getInstance();
    eventService.onEvent(EventService.EVENT_UPDATE_STOCK_PATTERNS, async () => {
      await this._loadStockPatterns();
    });
  }

  /**
   * 在庫パターンを読み込む
   * @private
   */
  private async _loadStockPatterns(): Promise<void> {
    try {
      const url = "api/stock_patterns";
      const axios = await this.getAxios();

      const response = await axios.get(url, this.makeAuthorizeOption());
      LogUtils.d(response.toString());

      this.setData(response.data);

    } catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * データ設定
   * @param data
   */
  public setData(data: any): void {
    this.stockPatternMap.clear();
    if (data && Array.isArray(data)) {
      data.forEach(patternData => {
        const pattern = StockPattern.fromMap(patternData);
        if (pattern.id !== undefined) {
          this.stockPatternMap.set(pattern.id, pattern);
        }
      });
    }
  }

  /**
   * 在庫パターンを取得する
   * @param id
   */
  getPatternById(id: number): StockPattern | undefined {
    return this.stockPatternMap.get(id);
  }

  /**
   * 在庫パターンを全て取得する
   */
  getAllPatterns(): StockPattern[] {
    return Array.from(this.stockPatternMap.values());
  }

  /**
   * 新しい在庫パターンを作成する
   * @param file アップロードするCSVファイル
   */
  public async createPattern(file: File): Promise<string> {
    LogUtils.d();

    const formData = new FormData();
    formData.append('csv_file', file);

    const options = await this.makeAuthorizeOption({
      'Content-Type': 'multipart/form-data'
    });

    // 拡張子を除いたファイル名
    const fileName = CommonUtils.removeExtension(file.name);
    try {
      const response = await axios.post(
        `api/stock-patterns?name=${fileName}`,
          formData,
          options
      );
      LogUtils.d(response.toString());

      // 新しい在庫パターンを読み込む
      await this._loadStockPatterns();
      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_STOCK_PATTERNS);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 在庫パターンを更新する
   * @param patternId 在庫パターンID
   * @param file 更新するCSVファイル
   */
  public async updatePattern(patternId: number, file: File): Promise<string> {
    LogUtils.d();

    const formData = new FormData();
    formData.append('csv_file', file);

    const options = await this.makeAuthorizeOption({
      'Content-Type': 'multipart/form-data'
    });

    // 拡張子を除いたファイル名
    const fileName = CommonUtils.removeExtension(file.name);
    try {
      const response = await axios.post(
        `api/stock-patterns/${patternId}?name=${fileName}`,
        formData,
        options
      );
      LogUtils.d(response.toString());

      // 新しい在庫パターンを読み込む
      await this._loadStockPatterns();
      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_STOCK_PATTERNS);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 在庫パターンを削除する
   * @param patternId 在庫パターンID
   */
  public async deletePattern(patternId: number): Promise<string> {
    LogUtils.d();

    try {
      const axios = await this.getAxios(true);
      const response = await axios.delete(
        `api/stock-patterns/${patternId}`,
        this.makeAuthorizeOption()
      );
      LogUtils.d(response.toString());

      // 新しい在庫パターンを読み込む
      await this._loadStockPatterns();
      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_STOCK_PATTERNS);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
}
