import {AppBar, Box, Button, IconButton, Toolbar, Typography} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DownloadIcon from '@mui/icons-material/Download';
import {LoginService} from "../../../Services/LoginService";
import {LogUtils} from "../../../Common/LogUtils";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {OrderSyncDialog} from "../../MenuCommand/OrderSyncDialog";
import {ExportDialog} from "../../MenuCommand/IO/ExportDialog";
import {ImportButton} from "../../MenuCommand/IO/ImportButton";
import {ja} from "date-fns/locale";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {ProductDeleteDialog} from "../../MenuCommand/ProductDeleteDialog";
import {StoreService} from "../../../Services/StoreService";
import {AccessTime} from "@mui/icons-material";
import {EventService} from "../../../Services/System/EventService";
import {CalendarButton} from "../Components/Calendar/CalendarButton";
import {CalendarDialog} from "../Components/Calendar/CalendarDialog";
import {UnrespondedMentionButton} from "./ChatMention/UnrespondedMentionButton";
import {UnrespondedMessageButton} from "./ChatMention/UnrespondedMessageButton";
import Tooltip from "@mui/material/Tooltip";
import {MultiProductSyncDialog} from "../../MenuCommand/MultiProductSyncDialog";
import {MigrateStockCodeDialog} from "../../MenuCommand/MigrateStockCodeDialog";

//import {LogUtils} from "../Common/LogUtils";

interface CommonLayoutOption {
  title?: string;
  hideMainElement?: boolean;
  children: React.ReactNode;
}

/**
 * 共通レイアウト
 * @param props
 * @constructor
 */
export const CommonLayout = (props: CommonLayoutOption) => {
  /**
   * 定数
   */

  /**
   * useNavigate
   */
  const navigate = useNavigate();

  /**
   * useState
   */
  const [state, setState] = useState({
    orderSyncDialogOpen: false,
    exportDialogOpen: false,
    productSyncDialogOpen: false,
    productDeleteDialogOpen: false,
    migrateStockCodeDialogOpen: false,
    calendarDialogOpen: false,
    selectedCalendarType: 'A',
  });

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };


  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {

    // イベントハンドラ登録（イベントハンドラの中でstateの値を参照しないこと）
    EventService.getInstance().onEvent(EventService.EVENT_RESERVE_ALL_PRODUCT_SYNC, onReserveAllProductSyncChanged);

    // クリーンアップ
    return () => {
      // イベントハンドラ解除
      EventService.getInstance().removeListener(EventService.EVENT_RESERVE_ALL_PRODUCT_SYNC, onReserveAllProductSyncChanged);
    };
  }, []);

  /**
   * 全商品同期予約変更時
   */
  const onReserveAllProductSyncChanged = () => {
    updateState({});
  }

  /**
   * ログアウト
   */
  const handleLogout = async () => {
    try {
      // ログアウト
      await LoginService.getInstance().logout();
    } catch (e) {
      LogUtils.ex(e);
    }
  };

  /**
   * 設定画面への遷移
   */
  const navigateToSettings = () => {
    navigate('/settings');
  };

  /**
   * 全商品同期予約アイコン
   */
  const allProductSyncIcon = () => {
    const reservedStores = StoreService.getInstance().getReservedAllProductSyncStores();
    if (reservedStores.length === 0) {
      return null;
    }
    if ( props.hideMainElement ) {
      return null;
    }
    return (
      <Tooltip title={'全商品同期予約されている店舗があります'} >
        <AccessTime />
      </Tooltip>
    );
  }

  /**
   * カレンダーボタンクリック時の処理
   * @param label
   */
  const handleButtonClick = (label: string) => {
    updateState({calendarDialogOpen: true, selectedCalendarType: label});
  };

  /**
   * カレンダーボタン
   * @param label
   */
  const calendarButton = (label: string) => {
    return (
      <CalendarButton
        tooltip={'カレンダー' + label + 'を開く'}
        label={label}
        onClick={() => handleButtonClick(label)}
        labelBackgroundColor={"primary.main"}
        labelColor={"white"}
        iconColor={"white"}
      />
    )
  }

  /**
   * 描画
   */
  const title = props.title != null ? '[' + props.title + ']' : '';
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Box style={{ flexGrow: 1 }} display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="h6" >
              {"EC管理システム " +  title}
            </Typography>
            <Box ml={2} />
            {/* 全商品同期予約ありアイコン */}
            {allProductSyncIcon()}
          </Box>

          {!props.hideMainElement && (
            <>
              {/* 個別注文同期 */}
              <Tooltip title={"既にステータスが完了になった注文を強制的に再同期するための機能"}>
                <Button
                  variant={"outlined"}
                  color="inherit"
                  onClick={() => updateState({orderSyncDialogOpen: true})}
                >
                  個別注文同期
                </Button>
              </Tooltip>
              <Box ml={2} />
              {/* 指定商品同期 */}
              <Tooltip title={"指定された在庫コードの商品を同期するための機能"}>
                <Button
                  variant={"outlined"}
                  color="inherit"
                  onClick={() => updateState({productSyncDialogOpen: true})}
                >
                  指定商品同期
                </Button>
              </Tooltip>
              <Box ml={2} />
              {/* 商品削除 */}
              <Tooltip title={"モール側で削除された商品をシステムから削除するための機能"}>
                <Button
                  variant={"outlined"}
                  color="inherit"
                  onClick={() => updateState({productDeleteDialogOpen: true})}
                >
                  商品削除
                </Button>
              </Tooltip>
              <Box ml={2} />
              {/* 在庫コード移行 */}
              <Tooltip title={"商品の関連データを旧在庫コードから新在庫コードへ移行するための機能"}>
                <Button
                  variant={"outlined"}
                  color="inherit"
                  onClick={() => updateState({migrateStockCodeDialogOpen: true})}
                >
                  在庫コード移行
                </Button>
              </Tooltip>
              <Box ml={10} />
              {/* カレンダーボタン */}
              {calendarButton('A')}
              {calendarButton('B')}

              <Box ml={2} />
              {/* インポート */}
              <ImportButton />
              {/* エクスポート */}
              <Tooltip title={'エクスポート'} >
                <IconButton edge="end" color="inherit" onClick={() => updateState({exportDialogOpen: true})}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Box ml={4} />
              {/* 未読メンション */}
              <UnrespondedMentionButton />
              <Box ml={1} />
              {/* 未返信 */}
              <UnrespondedMessageButton />
              <Box ml={10} />
              {/* 設定 */}
              <Tooltip title={'設定画面'} >
                <IconButton edge="end" color="inherit" onClick={navigateToSettings}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
              <Box ml={3} />
              {/* ログアウト */}
              <Tooltip title={(LoginService.getInstance().loginUser?.name ?? '') + 'でログインしています'} >
                <Button color="inherit" onClick={handleLogout}>ログアウト</Button>
              </Tooltip>
            </>
          )}
        </Toolbar>
      </AppBar>

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}> {/* https://mui.com/x/react-date-pickers/getting-started/ */}
          {/* メインコンテンツ */}
          {props.children}
      </LocalizationProvider>

      {/* 個別注文同期ダイアログ */}
      <OrderSyncDialog
        open={state.orderSyncDialogOpen}
        onClose={() => {updateState({orderSyncDialogOpen: false})}} />

      {/* エクスポートダイアログ */}
      <ExportDialog
        open={state.exportDialogOpen}
        onClose={() => {updateState({exportDialogOpen: false})}} />

      {/* 指定商品同期ダイアログ */}
      <MultiProductSyncDialog
        open={state.productSyncDialogOpen}
        onClose={() => {updateState({productSyncDialogOpen: false})}} />

      {/* 商品削除ダイアログ */}
      <ProductDeleteDialog
        open={state.productDeleteDialogOpen}
        onClose={() => {updateState({productDeleteDialogOpen: false})}} />

      {/* 在庫コード移行ダイアログ */}
      <MigrateStockCodeDialog
        open={state.migrateStockCodeDialogOpen}
        onClose={() => {updateState({migrateStockCodeDialogOpen: false})}} />

      {/* カレンダーダイアログ */}
      <CalendarDialog
        open={state.calendarDialogOpen}
        onClose={() => updateState({calendarDialogOpen: false})}
        calendarType={state.selectedCalendarType}
      />

    </div>
  );
};
