import React from 'react';
import { Box, Typography } from '@mui/material';
import {DigitDisplay} from "../../../../Common/Components/DigitDisplay";
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {StoreOrderCountData} from "../../../../../Models/Product/OrderCountData";
import {EventService} from "../../../../../Services/System/EventService";
import {StoreProduct} from "../../../../../Models/Product/StoreProduct";
import {SpecialDateService} from "../../../../../Services/SpecialDate/SpecialDateService";

interface StoreSalesTrendProps {
  storeProduct: StoreProduct;
  storeOrderCountData?: StoreOrderCountData;
  onDayClick?: (day: number) => void;
  width: number;
}

/**
 * 店舗商品販売傾向
 * @constructor
 * @param props
 */
export const StoreProductSalesTrend = (props: StoreSalesTrendProps) => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    width: props.width,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    cursor: 'pointer'
  };
  const LEFT_BOX_STYLE = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };
  const RIGHT_BOX_STYLE = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  /**
   * 販売数推移を描画
   * @param startDay
   * @param endDay
   */
  const renderDailySales = (startDay: number, endDay: number) => {
    const values = [];
    for (let i = startDay; i <= endDay; i++) {
      const value = props.storeOrderCountData?.dailySales?.[i] || 0;
      values.push(value);
    }
    return (
      <>
        {values.map((value, index) => (
          <React.Fragment key={index}>
            <span
              style={{
                cursor: props.onDayClick ? 'pointer' : 'default',
              }}
              onClick={() => props.onDayClick && props.onDayClick(startDay + index)}>
              <DigitDisplay
                value={value}
                length={2}
                filledColor={MainPageConstants.COLOR_DARK_GRAY}
                //emptyColor={MainPageConstants.COLOR_TRANSPARENT}
                cursor={"pointer"}
                style={SpecialDateService.getInstance().getSalesHistoryStyleBeforeDays(startDay + index)}
              />
            </span>
            {index !== values.length - 1 &&
              <Typography
                component="span"
                display="inline"
                color={MainPageConstants.COLOR_DARK_GRAY}
                style={SpecialDateService.getInstance().getSalesHistoryStyleBeforeDaysForArrow(startDay + index)}
              > ← </Typography>
            }
          </React.Fragment>
        ))}
      </>
    );
  };

  /**
   * 販売平均数文字列を返す
   */
  const getAverageSales = () => {
    if ( props.storeOrderCountData?.averageSales === undefined || props.storeOrderCountData.averageSales === -1 ){
      return ( '【 --- 】' );
    }
    return ( '【 ' +props.storeOrderCountData.averageSales.toFixed(1) + ' 】' );
  }

  /**
   * クリック時処理
   */
  const onClick = () => {
    EventService.getInstance().emitEvent(
      EventService.EVENT_SHOW_SALES_DATA, [
        props.storeProduct.stock_code,
        props.storeProduct.mall_no,
        props.storeProduct.mall_product_id,
        props.storeProduct.name
      ]);
  }

  /**
   * 描画
   */
  return (
    <Box sx={BOX_STYLE} onClick={onClick}>
      {/* 左側 */}
      <Box sx={LEFT_BOX_STYLE}>
        <Typography color={MainPageConstants.COLOR_RED} fontWeight="bold">
          {getAverageSales()}
        </Typography>
      </Box>

      {/* 右側 */}
      <Box sx={RIGHT_BOX_STYLE}>
        <Typography component="span">{renderDailySales(1, 7)}</Typography>
      </Box>
    </Box>
  );
}
