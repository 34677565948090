
/**
 * チャットページのパラメータ
 */
export class ChatPageParam {
  stockCode?: string;
  messageId?: number | undefined;
  chatResetTimeStamp?: number | undefined;

  constructor(params: Partial<ChatPageParam>) {
    Object.assign(this, params);
  }

  /**
   * 現在のチャットページパラメータをURLから取得
   */
  static fromUrl(): ChatPageParam {
    const params = new URLSearchParams(window.location.search);
    const stockCode = params.get('stock_code');
    const messageId = params.get('message_id') != null ? parseInt(params.get('message_id') || '0', 10) : undefined;
    const chatResetTimeStamp = params.get('chat_reset_time_stamp') != null ? parseInt(params.get('chat_reset_time_stamp') || '0', 10) : undefined;

    return new ChatPageParam({
      stockCode:  stockCode ?? undefined,
      messageId: messageId,
      chatResetTimeStamp: chatResetTimeStamp,
    });
  }

  /**
   * クエリストリングを生成する
   */
  toQueryString(): string {
    const params = new URLSearchParams();
    if (this.stockCode != null) {
      params.append('stock_code', this.stockCode);
    }
    if ( this.messageId != null) {
      params.append('message_id', this.messageId.toString());
    }
    if ( this.chatResetTimeStamp != null) {
      params.append('chat_reset_time_stamp', this.chatResetTimeStamp.toString());
    }
    return params.toString();
  }

  /**
   * 現在のインスタンスをコピーし、指定されたパラメータで更新します。
   * @param params - 更新するパラメータ。
   */
  copyWith(params: Partial<ChatPageParam>): ChatPageParam {
    return new ChatPageParam({
      ...this,
      ...params
    });
  }

  /**
   * オブジェクトから ChatPageParam インスタンスを作成します。
   * @param json - オブジェクト。
   */
  static fromMap(json: { [key: string]: any }): ChatPageParam {
    return new ChatPageParam({
      stockCode: json.stockCode,
      messageId: json.messageId,
      chatResetTimeStamp: json.chatResetTimeStamp,
    });
  }

  /**
   * JSON 文字列から ChatPageParam インスタンスを作成します。
   * @param jsonString - JSON 文字列。
   */
  static fromJson(jsonString: string): ChatPageParam {
    const decoded = JSON.parse(jsonString);
    return ChatPageParam.fromMap(decoded);
  }

  /**
   * ChatPageParam インスタンスをオブジェクトに変換します。
   */
  toMap(): { [key: string]: any } {
    return {
      stockCode: this.stockCode,
      messageId: this.messageId,
      chatResetTimeStamp: this.chatResetTimeStamp,
    };
  }

  /**
   * ChatPageParam インスタンスを JSON 文字列に変換します。
   */
  toJson(): string {
    return JSON.stringify(this.toMap());
  }
}
