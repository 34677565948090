import { LogUtils } from "../../Common/LogUtils";
import { HttpServiceBase } from "../HttpServiceBase";
import { EcmgApiError } from "../../Common/EcmgApiError";
import {EventService} from "../System/EventService";
import {ProductService} from "../ProductService";
import {SearchParam} from "../../Common/SearchParam";

/**
 * インポートサービス
 */
export class ImportService extends HttpServiceBase {
  private static instance: ImportService = new ImportService();

  private constructor() {
    super();
  }

  static getInstance(): ImportService {
    return this.instance;
  }

  /**
   * ファイルをインポートする
   * @param file アップロードするCSVファイル
   */
  public async import(file: File): Promise<string> {
    LogUtils.d();

    const formData = new FormData();
    formData.append('csv_file', file);

    const options = await this.makeAuthorizeOption({
      'Content-Type': 'multipart/form-data'
    });

    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        `api/import`,
        formData,
        options
      );
      LogUtils.d(response.toString());

      // 再検索
      const searchParam = SearchParam.fromUrl();
      await ProductService.getInstance().searchProducts(searchParam);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }


}
