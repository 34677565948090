/**
 * チャットリアクション
 */
export class ChatReaction {
  id?: number;
  chat_message_id?: number;
  user_id?: number;
  reaction_number?: number;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<ChatReaction> = {}) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<ChatReaction>): ChatReaction {
    return new ChatReaction({
      ...this,
      ...params,
    });
  }

  static fromMap(json: { [key: string]: any }): ChatReaction {
    return new ChatReaction({
      ...json,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): ChatReaction {
    const decoded = JSON.parse(jsonString);
    return ChatReaction.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }
}
