import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TableContainer,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Office } from "../../../Models/Office";
import { OfficeService } from "../../../Services/OfficeService";
import { SettingConstants } from "../SettingConstants";
import {Constants} from "../../../Common/Constants";
import {LogUtils} from "../../../Common/LogUtils";
import {EcmgApiError} from "../../../Common/EcmgApiError";
import {CommonUtils} from "../../../Common/CommonUtils";
import ECProgress from "../../Common/Components/ECProgress";
import {useSnackbar} from "../../Common/Provider/SnackbarContext";
import {EventService} from "../../../Services/System/EventService";
import {OfficeEditDialog} from "./OfficeEditDialog";

/**
 * 事業所設定
 * @constructor
 */
export const OfficeSetting = () => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
  };

  /**
   * useState
   */
  const [state, setState] = useState({
    offices: [] as Office[],
    processing: false,
    editDialogOpen: false,
    editingOffice: null as Office | null,
    editErrors: {} as Record<string, string>,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    const offices = OfficeService.getInstance().getAllOffices();
    updateState({ offices });

    // イベントハンドラ登録
    EventService.getInstance().onEvent(EventService.EVENT_UPDATE_OFFICES, onOfficesUpdated);

    // クリーンアップ
    return () => {
      EventService.getInstance().removeListener(EventService.EVENT_UPDATE_OFFICES, onOfficesUpdated);
    };
  }, []);


  /**
   * 事業所更新時
   */
  const onOfficesUpdated = () => {
    const offices = OfficeService.getInstance().getAllOffices();
    updateState({ offices });
  }

  /**
   * 事業所編集ボタンクリック時
   * @param office
   */
  const handleEdit = (office: Office) => {
    updateState({
      editingOffice: office,
      editDialogOpen: true ,
      editErrors: {},
    });
  };

  /**
   * 事業所保存ボタンクリック時
   * @param office
   */
  const handleOfficeSave = async (office: Office) => {

    let message = '';

    try {
      updateState({
        processing: true,
        editingOffice: office
      });

      if ( office.id == null ) {
        // 事業所新規登録
        message = await OfficeService.getInstance().addOffice(office);
      }
      else {
        // 事業所更新
        message = await OfficeService.getInstance().updateOffice(office);
      }

      updateState({
        processing: false,
        editDialogOpen: false,
        editingOffice: null,
        editErrors: {},
      });

    }
    catch (e) {
      LogUtils.ex(e);

      updateState({
        processing: false,
      });

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
        updateState({ editErrors: e.getErrorMessageMap()});
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = '事業所登録処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);

  };

  /**
   * 事業所削除ボタンクリック時
   * @param officeId
   */
  const handleDelete = async (officeId: number) => {
    if(!window.confirm('この事業所を削除しますか？')) {
      return;
    }

    let message = '';

    try {
      updateState({ processing: true });

      // 事業所削除削除
      message = await OfficeService.getInstance().deleteOffice(officeId);

      updateState({ processing: false });

    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false });

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = '事業所削除処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  // 描画
  return (
    <Box sx={BOX_STYLE}  mt={2}>
      <Box><Typography>事業所設定</Typography></Box>
      <Box display="flex" justifyContent="flex-end" mr={2}>
        <Button variant={"contained"} component="span" onClick={() => handleEdit(new Office({}))}>
          新規登録
        </Button>
      </Box>
      <TableContainer style={{ maxHeight: '400px' }}>
        <Table>
          <TableHead style={SettingConstants.TABLE_HEADER_STYLE}>
            <TableRow>
              <TableCell>事業所名</TableCell>
              <TableCell>SKU画面初期表示</TableCell>
              <TableCell align={"center"}>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.offices.map(office => (
              <TableRow key={office.id}>
                <TableCell>{office.name}</TableCell>
                <TableCell>{Constants.SKU_INITIAL_LABELS[office.sku_initial ?? 0]}</TableCell>
                <TableCell align={"center"}>
                  <Button onClick={() => handleEdit(office)}>
                    <EditIcon />
                  </Button>
                  <Button onClick={() => handleDelete(office.id!)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {state.offices.length === 0 ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <Typography variant={"body2"}>登録されている事業所はありません</Typography>
        </Box>
      ) : null }

      {/* 事業所編集ダイアログ */}
      <OfficeEditDialog
        open={state.editDialogOpen}
        office={state.editingOffice ?? new Office({})}
        onClose={() => updateState({ editDialogOpen: false })}
        onSave={handleOfficeSave}
        errors={state.editErrors}
      />

      {/* プログレス */}
      <ECProgress open={state.processing}></ECProgress>
    </Box>
  );
}
