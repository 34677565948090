// MinUnitProductImage.tsx
import React, { useState, useEffect } from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import {CustomImageGallery} from "./CustomImageGallery";
import {Box} from "@mui/material";
import {LogUtils} from "../../../../Common/LogUtils";
import {SearchResult} from "../../../../Models/SearchResult";
import {useSnackbar} from "../../../Common/Provider/SnackbarContext";
import {CommonUtils} from "../../../../Common/CommonUtils";


interface MinUnitProductImageProps {
  searchResult: SearchResult;
  imageSize: number;
}

/**
 * 商品画像
 * @param props
 * @constructor
 */
export const MinUnitProductImage = (props: MinUnitProductImageProps) => {
  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState({
    images: [] as any[],
    showGallery: false,
    processing: false,
  });

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {
    fetchPhotos();

    // クリーンアップ
    return () => {
    };
  }, []);

  /**
   * 商品画像取得
   */
  const fetchPhotos = () => {
    try {
      const photos = props.searchResult.storeProductPhotos;
      // ReactImageGallery用に変換
      const images = photos.map((photo) => {
        return {
          original: photo.url,
          thumbnail: photo.url,
          description: photo.label,
        }
      });
      updateState({ images: images});
    }
    catch (e) {
      LogUtils.ex(e);
    }
  }

  /**
   * 画像クリック時処理
   */
  const handleImageClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    // Altキーありの場合
    if (event.altKey) {
      // 商品名をコピー
      const productName = props.searchResult.minimalUnitProduct?.name;
      if (productName) {
        await navigator.clipboard.writeText(productName);
        showSnackbarMessage('商品名をコピーしました。[' + CommonUtils.getStringSnippet(productName, 20) + ']');
      }
    }
    // Altキーなしの場合
    else {
      updateState({showGallery: true});
    }
  }

  /**
   * 描画
   */
  return (
    <div>
      <Box border={1} borderColor="grey.300" sx={{width: props.imageSize, height: props.imageSize}}>
        {state.images.length > 0 && (
            <img
              src={state.images[0].thumbnail}
              alt={state.images[0].description}
              height={props.imageSize}
              style={{ cursor: 'pointer' }}
              onClick={handleImageClick}
            />
        )}
      </Box>

      <CustomImageGallery
        open={state.showGallery}
        title={props.searchResult.product.stock_code}
        items={state.images}
        onClose={ () => {
          updateState({showGallery: false});
        }}
      />
    </div>
  );
}


