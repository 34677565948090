import { LogUtils } from "../Common/LogUtils";
import { HttpServiceBase } from "./HttpServiceBase";
import { Office } from "../Models/Office";
import { EcmgApiError } from "../Common/EcmgApiError";
import {EventService} from "./System/EventService";

/**
 * 事業所サービス
 */
export class OfficeService extends HttpServiceBase {
  private static instance: OfficeService = new OfficeService();
  private officeMap: Map<number, Office> = new Map();

  private constructor() {
    super();
  }

  static getInstance(): OfficeService {
    return this.instance;
  }

  async init(): Promise<void> {
    LogUtils.d("Initializing OfficeService");
    await this._loadOffices();
  }

  /**
   * オフィスデータ読み込み
   * @private
   */
  private async _loadOffices(): Promise<void> {
    try {
      const url = "api/offices";
      const axios = await this.getAxios();

      const response = await axios.get(url, this.makeAuthorizeOption());
      LogUtils.d(response.toString());

      this.setData(response.data);

    } catch (e) {
      LogUtils.ex(e);
    }
  }

  public setData(data: any): void {
    this.officeMap.clear();
    if (data && Array.isArray(data)) {
      data.forEach(officeData => {
        const office = Office.fromMap(officeData);
        if (office.id !== undefined) {
          this.officeMap.set(office.id, office);
        }
      });
    }
  }

  /**
   * オフィスデータ取得
   * @param id
   */
  getOfficeById(id: number): Office | undefined {
    return this.officeMap.get(id);
  }

  /**
   * すべてのオフィスを取得
   */
  getAllOffices(): Office[] {
    return Array.from(this.officeMap.values());
  }

  /**
   * オフィスデータ更新
   * @param office
   */
  public async updateOffice(office: Office): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.put(
        `api/offices/${office.id}`,
        office,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());

      await this._loadOffices();

      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_OFFICES);

      return response.data.message;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 事業所追加
   * @param office
   */
  public async addOffice(office: Office): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.post(
        'api/offices',
        office,
        this.makeAuthorizeOption()
      );

      LogUtils.d(response.toString());
      await this._loadOffices();

      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_OFFICES);

      return response.data.message;
    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }

  /**
   * 事業所削除
   * @param officeId
   */
  public async deleteOffice(officeId: number): Promise<string> {
    try {
      const axios = await this.getAxios(true);
      const response = await axios.delete(
        `api/offices/${officeId}`,
        this.makeAuthorizeOption()
      );

      LogUtils.d(`Deleted office with ID ${officeId}`);
      await this._loadOffices();

      // イベント
      EventService.getInstance().emitEvent(EventService.EVENT_UPDATE_OFFICES);

      return response.data.message;

    } catch (e) {
      LogUtils.ex(e);
      throw EcmgApiError.fromError(e);
    }
  }
}
