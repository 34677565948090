import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TableContainer,
  Typography,
  CircularProgress
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StockPatternService } from "../../../Services/Pattern/StockPatternService";
import { StockPattern } from "../../../Models/Pattern/StockPattern";
import {CommonUtils} from "../../../Common/CommonUtils";
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import {LogUtils} from "../../../Common/LogUtils";
import {EcmgApiError} from "../../../Common/EcmgApiError";
import {useSnackbar} from "../../Common/Provider/SnackbarContext";
import {EventService} from "../../../Services/System/EventService";
import {SettingConstants} from "../SettingConstants";

/**
 * 在庫パターン設定
 * @constructor
 */
export const StockPatternSetting = () => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '10px',
  };
  /**
   * useState
   */
  const [state, setState] = useState({
    patterns: [] as StockPattern[],
    updatingPatterns: [] as number[],  // number型のpatternIdの配列
    deletingPatterns: [] as number[],  // number型のpatternIdの配列
    creating: false,
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * マウント時・アンマウント時処理
   */
  useEffect(() => {

    // イベントハンドラ登録
    EventService.getInstance().onEvent(EventService.EVENT_UPDATE_STOCK_PATTERNS, onStockPatternsUpdated);

    updateState({ patterns: StockPatternService.getInstance().getAllPatterns() });

    // クリーンアップ
    return () => {
      EventService.getInstance().removeListener(EventService.EVENT_UPDATE_STOCK_PATTERNS, onStockPatternsUpdated);
    };

  }, []);


  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * 在庫パターン更新時
   */
  const onStockPatternsUpdated = () => {
    const patterns = StockPatternService.getInstance().getAllPatterns();
    updateState({ patterns: patterns });

    LogUtils.d('onStockPatternsUpdated: ' + JSON.stringify(patterns));
  }

  /**
   * アップロードボタンクリック時処理
   * @param patternId
   * @param event
   */
  const handleUpload = async (patternId: number, event: React.ChangeEvent<HTMLInputElement>) => {
    let message = '';

    try {
      const files = event.target.files;
      if ( !files || files.length === 0 ) {
        return;
      }
      const ret = window.confirm('在庫パターンを更新しますか？\n※各商品の在庫一括更新は現状行われません。');
      if(!ret) {
        return;
      }
      const selectedFile = files[0];


      const currentUpdatingPatterns = [...state.updatingPatterns];
      currentUpdatingPatterns.push(patternId);
      updateState({ updatingPatterns: currentUpdatingPatterns });

      // 在庫パターン更新
      message = await StockPatternService.getInstance().updatePattern(patternId, selectedFile);

      const finishedUpdatingPatterns = state.updatingPatterns.filter(id => id !== patternId);
      updateState({ updatingPatterns: finishedUpdatingPatterns }); // 処理終了時にpatternIdを削除

    }
    catch (e) {
      LogUtils.ex(e);

      const finishedUpdatingPatterns = state.updatingPatterns.filter(id => id !== patternId);
      updateState({ updatingPatterns: finishedUpdatingPatterns }); // 処理終了時にpatternIdを削除

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = '在庫パターン更新処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  /**
   * 削除ボタンクリック時処理
   * @param patternId
   */
  const handleDelete = async (patternId: number) => {
    if(!window.confirm('削除しますか？\n※この在庫パターンを設定している商品の在庫パターンはクリアされます。')) {
      return;
    }

    let message = '';

    try {
      const currentDeletingPatterns = [...state.deletingPatterns];
      currentDeletingPatterns.push(patternId);
      updateState({ deletingPatterns: currentDeletingPatterns });

      // 在庫パターン削除
      message = await StockPatternService.getInstance().deletePattern(patternId);

      const finishedDeletingPatterns = state.deletingPatterns.filter(id => id !== patternId);
      updateState({ deletingPatterns: finishedDeletingPatterns }); // 処理終了時にpatternIdを削除

    }
    catch (e) {
      LogUtils.ex(e);

      const finishedDeletingPatterns = state.deletingPatterns.filter(id => id !== patternId);
      updateState({ deletingPatterns: finishedDeletingPatterns }); // 処理終了時にpatternIdを削除

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = '在庫パターン削除処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  }

  /**
   * 新規登録ボタンクリック時処理
   * @param event
   */
  const handleCreate = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let message = '';

    try {
      const files = event.target.files;
      if ( !files || files.length === 0 ) {
        return;
      }
      const selectedFile = files[0];

      updateState({ creating: true });

      // 在庫パターン作成
      message = await StockPatternService.getInstance().createPattern(selectedFile);

      updateState({ creating: false });

    }
    catch (e) {
      LogUtils.ex(e);

      updateState({ creating: false });

      if (e instanceof EcmgApiError && e.message != null ) {
        message = 'エラー：' + e.message;
      }
      if ( CommonUtils.isEmpty(message) ) {
        message = '在庫パターン作成処理でエラーが発生しました。';
      }
    }

    // スナックバー表示
    showSnackbarMessage(message);
  };

  /**
   * 描画
   */
  return (
    <Box sx={BOX_STYLE}>
      <Box><Typography>在庫パターン設定</Typography></Box>
      <Box display="flex" justifyContent="flex-end" mr={2}>
        {state.creating ? (
          <CircularProgress size={24} />
        ) : (
          <label>
            <Button variant={"contained"} component="span">新規登録</Button>
            <input type="file" accept=".csv" hidden onChange={handleCreate} />
          </label>
        )}
      </Box>
      <TableContainer style={{ maxHeight: '400px' }}>
        <Table>
          <TableHead style={SettingConstants.TABLE_HEADER_STYLE}>
            <TableRow>
              <TableCell>パターン名</TableCell>
              <TableCell>最終更新日時</TableCell>
              <TableCell align={"center"}>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.patterns.map(pattern => (
              <TableRow key={pattern.id}>
                <TableCell>{pattern.name}</TableCell>
                <TableCell>{CommonUtils.convertToAppTimezone(pattern.updated_at!.toISOString())}</TableCell>
                <TableCell align={"center"}>
                  {state.updatingPatterns.includes(pattern.id!) ? (
                    <CircularProgress size={24} />
                  ) : (
                    <label>
                      <Button component="span">
                        <UploadIcon />
                      </Button>
                      <input type="file" accept=".csv" hidden onChange={event => handleUpload(pattern.id!, event)} />
                    </label>
                  )}
                  {state.deletingPatterns.includes(pattern.id!) ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Button onClick={() => handleDelete(pattern.id!)}>
                      <DeleteIcon />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {state.patterns.length === 0 ? (
        <Box  display="flex" justifyContent="center" mt={2}>
          <Typography variant={"body2"}>登録されている在庫パターンはありません</Typography>
        </Box>
      ) : null }
    </Box>
  );
}
