import React from 'react';
import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {OperationHistory} from "../../../../../../Models/OperationHistory/OperationHistory";
import {UnreceivedHistoryConstants} from "./UnreceivedHistoryConstants";
import {CommonUtils} from "../../../../../../Common/CommonUtils";
import {UserService} from "../../../../../../Services/UserService";
import {Product} from "../../../../../../Models/Product/Product";


interface SalesDailyHistoryTableProps {
  maxHeight: number;
  unreceivedHistories: OperationHistory[];
  product: Product;
}

/**
 * 仕入履歴（日次）
 * @param props
 * @constructor
 */
export const UnreceivedDailyHistoryTable = (props: SalesDailyHistoryTableProps) => {
  /**
   * 定数
   */
  const FONT_SIZE = '12px';
  const PADDING = '4px';

  // 累計未着在庫数
  let accumulatedUnreceivedStock = 0;

  /**
   * セル
   * @param text
   * @param align
   * @param isHighlight
   */
  const tableCell = (text: string, align = 'center' as any, isHighlight = false) => {
    const style = {
      fontSize: FONT_SIZE,
      padding: PADDING,
      color: isHighlight ? '#ff4500' : 'inherit',  // 赤色の条件を追加
      backgroundColor: bkColor
    };

    return (
      <TableCell align={align} style={style}>
        {text}
      </TableCell>
    );
  }

  /**
   * 数値をフォーマット
   * @param num
   */
  const formatNumber = (num: number | undefined) => {
    if ( num == null ){
      return '---';
    }
    return CommonUtils.formatNumber(num);
  }

  /**
   * ヘッダーセル
   * @param text
   */
  const headerCell = (text: string) => {
    return (
      <TableCell
        align="center"
        sx={{fontSize: FONT_SIZE, padding: PADDING, backgroundColor: UnreceivedHistoryConstants.MID_GRAY}}
      >
        <strong>{text}</strong>
      </TableCell>
    );
  }

  /**
   * Divider
   */
  const divider = () => {
    const style = { backgroundColor: UnreceivedHistoryConstants.DARK_GRAY };
    if (drawDivider) {
      return (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={9}>
            <Divider style={style}/>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  };

  let prevDate = undefined as Date | undefined;
  let bkColor = UnreceivedHistoryConstants.LIGHT_GRAY as string | undefined;
  let drawDivider = true;

  /**
   * 描画
   */
  return (
    <TableContainer component={Paper} style={{ maxHeight: props.maxHeight, overflow: 'auto' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headerCell("日付")}
            {headerCell("曜日")}
            {headerCell("時間")}
            {headerCell("仕入数")}
            {headerCell("担当者")}
          </TableRow>
        </TableHead>

        <TableBody>
          {props.unreceivedHistories.map((row) => {

            // 累計を計算し、赤色にすべきかを判定する
            let newAccumulatedUnreceivedStock = accumulatedUnreceivedStock;
            const unreceivedStock = parseInt(row.value ?? '0');
            if ( unreceivedStock > 0 ) {
              newAccumulatedUnreceivedStock += unreceivedStock;
            }
            const productUnreceivedStock = (props.product.unreceived_stock ?? 0);
            const isHighlight =
              productUnreceivedStock > 0
              && unreceivedStock > 0
              && accumulatedUnreceivedStock < productUnreceivedStock;  // 赤色表示条件の判断
            accumulatedUnreceivedStock = newAccumulatedUnreceivedStock;

            // 日付が変わったら背景色を変える
            if (prevDate != null && prevDate.toDateString() !== row.created_at?.toDateString()) {
              bkColor = bkColor === UnreceivedHistoryConstants.LIGHT_GRAY ? undefined : UnreceivedHistoryConstants.LIGHT_GRAY;
              drawDivider = true;
            }
            else {
              drawDivider = false;
            }

            const dateTime = row.created_at?.toISOString() ?? '';
            const ret = (
              <>
                {divider()}
                <TableRow key={row.id}>
                  {tableCell(CommonUtils.getDateTextForTable(dateTime, prevDate?.toDateString()), 'right', isHighlight)}
                  {tableCell(CommonUtils.getWeekTextForTable(dateTime), 'center', isHighlight)}
                  {tableCell(CommonUtils.getTimeTextForTable(dateTime), 'center', isHighlight)}
                  {tableCell(formatNumber(parseInt(row.value ?? '')), 'right', isHighlight)}
                  {tableCell(UserService.getInstance().getUserName(row.user_id ?? -1) ?? '', 'center', isHighlight)}
                </TableRow>
              </>
            );

            prevDate = row.created_at; // 前回日時を保存
            return ret;
          })}


          {props.unreceivedHistories.length === 0 &&
            <TableRow>
              <TableCell colSpan={9} align="center">
                表示するデータはありません。
              </TableCell>
            </TableRow>
          }

        </TableBody>
      </Table>
    </TableContainer>
  );
};
