import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, Divider, Box
} from '@mui/material';
import {SalesMonthlyHistory} from "../../../../../Models/SalesData/SalesData";
import {SalesDataConstants} from "./SalesDataConstants";
import {CommonUtils} from "../../../../../Common/CommonUtils";

interface SalesMonthlyHistoryTableProps {
  maxHeight: number;
  monthlySalesHistory: SalesMonthlyHistory[];
}
/**
 * 販売数履歴（月次）
 * @param props
 * @constructor
 */
export const SalesMonthlyHistoryTable = (props: SalesMonthlyHistoryTableProps) => {
  /**
   * 定数
   */
  const FONT_SIZE = '12px';
  const PADDING = '4px';

  /**
   * 月文字列を取得
   * @param yearMonth
   */
  const getMonthText = (yearMonth: string): string => {
    // 現在の年月を取得
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // getMonth()は0-11を返すので+1する

    // 引数の年月を分割
    const [year, month] = yearMonth.split('/').map(Number);

    // 今月の場合
    if (year === currentYear && month === currentMonth) {
      return "今月";
    }

    // 先月の場合
    let lastMonthYear = currentYear;
    let lastMonth = currentMonth - 1;
    if (lastMonth === 0) {
      lastMonth = 12;
      lastMonthYear -= 1;
    }
    if (year === lastMonthYear && month === lastMonth) {
      return "先月";
    }

    // それ以外
    return `${month}月`;
  };

  /**
   * セル
   * @param text
   * @param index
   * @param align
   */
  const tableCell = (text: string, index: number, align = 'center' as any) => {
    let style = {fontSize: FONT_SIZE, padding: PADDING} as any;
    // 偶数行の場合はグレーの背景にする
    if ((index+1) % 2 === 0) {
      style = {...style, backgroundColor: SalesDataConstants.LIGHT_GRAY};
    }
    return (
      <TableCell align={align} style={style}>
        {text}
      </TableCell>
    );
  }


  /**
   * 数値をフォーマット
   * @param num
   */
  const formatNumber = (num: number | undefined) => {
    if ( num == null ){
      return '---';
    }
    return CommonUtils.formatNumber(num);
  }

  /**
   * Y・Rを取得
   * @param row
   */
  const getYR = (row: SalesMonthlyHistory) => {
    const y = row.y_sales != null ? CommonUtils.formatNumber(row.y_sales) : '---';
    const r = row.r_sales != null ? CommonUtils.formatNumber(row.r_sales) : '---';
    return `(${y} / ${r})`;
  }

  /**
   * ヘッダーセル
   * @param text
   */
  const headerCell = (text: string) => {
    return (
      <TableCell
        align="center"
        sx={{fontSize: FONT_SIZE, padding: PADDING, backgroundColor: SalesDataConstants.MID_GRAY}}
      >
        <strong>{text}</strong>
      </TableCell>
    );
  }

  /**
   * Divider
   */
  const divider = (index: number) => {
    const style = { backgroundColor: SalesDataConstants.DARK_GRAY };

    // 先頭行
    if (index === 0) {
      return (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={9}>
            <Divider style={style}/>
          </TableCell>
        </TableRow>
      );
    }
    // 2行目
    else if (index === 1) {
      // indexが0と1の間に二重線を表示
      return (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={9}>
            <Divider style={style}/>
            <Box sx={{marginTop: '2px'}}/>
            <Divider style={style}/>
          </TableCell>
        </TableRow>
      );
    }
    // それ以外
    else {
      // 12ヶ月おきで実戦を引く
      const shouldInsertDivider = index % 12 === 0;
      if (shouldInsertDivider) {
        return (
          <TableRow>
            <TableCell style={{padding: 0}} colSpan={9}>
              <Divider style={style}/>
            </TableCell>
          </TableRow>
        );
      }
    }
    return null;
  };


  /**
   * 描画
   */
  return (
    <TableContainer component={Paper} style={{ maxHeight: props.maxHeight, overflow: 'auto' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          {divider(-1)}
          <TableRow>
            {headerCell("")}
            {headerCell("仕入数")}
            {headerCell("受取数")}
            {headerCell("在庫数")}
            {headerCell("販売数")}
            {headerCell("Y・R")}
            {headerCell("Y価格")}
            {headerCell("R価格")}
          </TableRow>
          {divider(-1)}
        </TableHead>

        <TableBody>
          {props.monthlySalesHistory.map((row, index) => {
            return (
              <>
                {divider(index)}
                <TableRow key={row.year_month}>
                  {tableCell(getMonthText(row.year_month), index, 'center')}
                  {tableCell(formatNumber(row.unreceived), index, 'right')}
                  {tableCell(formatNumber(row.received), index, 'right')}
                  {tableCell(formatNumber(row.stock), index, 'right')}
                  {tableCell(formatNumber(row.sales), index, 'right')}
                  {tableCell(getYR(row), index, 'center')}
                  {tableCell(formatNumber(row.y_price), index, 'right')}
                  {tableCell(formatNumber(row.r_price), index, 'right')}
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
