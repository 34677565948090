import React from 'react';
import {Box} from '@mui/material';
import {MainPageConstants} from "../../Main/Common/MainPageConstants";

interface DigitDisplayProps {
  value: number | undefined;
  length: number;
  filledColor: string;
  //emptyColor: string;
  fontSize?: string;
  style?: React.CSSProperties;
  cursor?: string;
}

/**
 * 数値表示コンポーネント
 * @param props
 * @constructor
 */
export const DigitDisplay: React.FC<DigitDisplayProps> = (props: DigitDisplayProps) => {
  const { value, length, filledColor, fontSize } = props;

  // valueがundefinedの場合、---を表示
  if (value === undefined) {
    return (
      <Box component="span" style={{ fontSize: fontSize, color: filledColor }}>
        {'-'.repeat(length)}
      </Box>
    );
  }

  const stringValue = value.toString();
  const paddedValue = stringValue.padStart(length, '0');

  // styleにcolorが指定されているならfilledColorは無視
  const applyFilledColor = props.style && 'color' in props.style ? undefined : filledColor;

  return (
    <Box component="span" style={{
      fontSize: fontSize,
      cursor: props.cursor,
      ...props.style
    }}>  {/* fontSizeをstyle内で指定 */}
      {paddedValue.split('').map((digit, index) => (
        <span
          key={index}
          style={{
            color: index < length - stringValue.length ? MainPageConstants.COLOR_TRANSPARENT : applyFilledColor,
          }}
        >
          {digit}
        </span>
      ))}
    </Box>
  );
}
