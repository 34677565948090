import React, {useEffect, useRef, useState} from 'react';
import {useSnackbar} from "../Common/Provider/SnackbarContext";
import {ChatPageParam} from "../../Models/Chat/ChatPageParam";
import {LogUtils} from "../../Common/LogUtils";
import ECProgress from "../Common/Components/ECProgress";
import {ChatMessageUpdateData, ChatService} from "../../Services/ChatService";
import {ChatMessage} from "../../Models/Chat/ChatMessage";
import {ChatMessagesList} from "./ChatMessagesList";
import {Box, } from "@mui/material";
import {ChatInputForm} from "./ChatInputForm";
import {LoginService} from "../../Services/LoginService";
import {EventService} from "../../Services/System/EventService";
import {ChatReaction} from "../../Models/Chat/ChatReaction";

interface ChatViewProps {
  chatPageParam: ChatPageParam;
  bubbleWidth?: number;
  height?: string;
  onReloadPage: (messageId: number) => void; // メッセージジャンプが必要な場合のコールバック
}

/**
 * チャットビュー
 *
 * @constructor
 */
export const ChatView = (props: ChatViewProps) => {
  /**
   * 定数
   */
  const LOAD_MESSAGES = 100; // 1回で取得するメッセージ数
  const LOAD_INTERVAL = 3000; // 最小読み込み間隔 (ミリ秒)

  /**
   * useRef
   */
  const checkingReadRef = useRef(false);

  /**
   * 初期状態
   */
  const initialState = {
    processing: false,
    messages: [] as ChatMessage[], // IDの昇順
    initialDispMessageId: undefined as number | undefined,
    additionalLoading: null as number | null,
    addEvent: undefined as {
      prevMessageId: number;
    } | undefined,
    editingMessageId: undefined as number | undefined,
  }

  /**
   * useState
   * propsで初期化しないこと!
   */
  const [state, setState] = useState(initialState);

  /**
   * 状態更新
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * useContext
   */
    // スナックバー表示
  const { showSnackbarMessage } = useSnackbar();

  /**
   * マウント・アンマウント時
   */
  useEffect(() => {
    // イベントハンドラ登録（イベントハンドラの中でstateの値を参照しないこと）
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_EDIT, onMessageEdit);
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_DELETE, onMessageDelete);
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_REACTION, onMessageReaction);
    EventService.getInstance().onEvent(EventService.EVENT_CHAT_REACTION_DELETE, onMessageReactionDelete);

    // クリーンアップ
    return () => {
      // イベントハンドラ解除
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_EDIT, onMessageEdit);
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_DELETE, onMessageDelete);
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_REACTION, onMessageReaction);
      EventService.getInstance().removeListener(EventService.EVENT_CHAT_REACTION_DELETE, onMessageReactionDelete);
    };
  }, []);

  /**
   * メッセージ編集
   * @param messageId
   */
  const onMessageEdit = (messageId: number) => {
    // コールバックからstateを参照できないので、setStateを使う
    setState(prevState => {
      return {
        ...prevState,
        editingMessageId: messageId
      };
    });
  }

  /**
   * メッセージ削除
   * @param messageId
   */
  const onMessageDelete = async (messageId: number) => {
    // 確認
    if (!window.confirm('メッセージを削除しますか？')) {
      return;
    }
    try {
      // メッセージ削除
      await ChatService.getInstance().deleteMessage(messageId);
    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('削除処理でエラーが発生しました。');
    }
  }

  /**
   * メッセージリアクション
   * @param data
   */
  const onMessageReaction = async (data: {messageId: number, reaction: number}) => {
    try {
      const chatReaction = new ChatReaction({
        chat_message_id: data.messageId,
        user_id: LoginService.getInstance().loginUser?.id,
        reaction_number: data.reaction,
      });

      // メッセージリアクション
      await ChatService.getInstance().storeReaction(chatReaction);

    } catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('リアクション処理でエラーが発生しました。');
    }
  }

  /**
   * メッセージリアクション削除
   * @param reactionId
   */
  const onMessageReactionDelete = async (reactionId: number) => {
    try {
      // メッセージリアクション削除
      await ChatService.getInstance().deleteReaction(reactionId);

    } catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('リアクション処理でエラーが発生しました。');
    }

  }

  /**
   * チャットパラメータ変更時
   */
  useEffect(() => {
    // 初期化
    updateState(initialState);

    const chatPageParam = props.chatPageParam;

    // メッセージ読み込み
    loadMessages(chatPageParam).then();

    //-----------------------------
    // Pusherでのチャンネル購読を管理
    //-----------------------------
    const stockCode = chatPageParam.stockCode;
    if (stockCode) {
      // 以前のチャンネル購読を解除するロジック（必要に応じて）
      ChatService.getInstance().unsubscribeMessageChannel(stockCode);
      // 新しいstockCodeに基づいてチャンネルを購読
      ChatService.getInstance().subscribeToMessageChannel(stockCode, onPusherMessage);
    }

    //-----------------------------
    // コンポーネントのクリーンアップ時にチャンネル購読を解除
    //-----------------------------
    return () => {
      if (stockCode) {
        ChatService.getInstance().unsubscribeMessageChannel(stockCode);
      }
    };

  }, [props.chatPageParam]);

  // /**
  //  * メッセージ読み込みチェック
  //  */
  // useEffect(() => {
  //   if ( state.messages.length === 0) {
  //     return;
  //   }
  //   //----------------------------------
  //   // 最初と最終のメッセージ読み込みチェック
  //   //----------------------------------
  //   let firstMessageLoaded = true;
  //   if (!state.firstMessageLoaded && state.firstMessageId != null ) {
  //     // messageの中から探す
  //     const index = state.messages.findIndex(m => m.id === state.firstMessageId);
  //     if (index < 0) {
  //       firstMessageLoaded = false;
  //     }
  //   }
  //
  //   let lastMessageLoaded = true;
  //   if (!state.lastMessageLoaded && state.lastMessageId != null ) {
  //     // messageの中から探す
  //     const index = state.messages.findIndex(m => m.id === state.lastMessageId);
  //     if (index < 0) {
  //       lastMessageLoaded = false;
  //     }
  //   }
  //
  //   updateState({
  //     firstMessageLoaded: firstMessageLoaded,
  //     lastMessageLoaded: lastMessageLoaded,
  //   });
  //
  // }, [state.messages, state.firstMessageId, state.lastMessageId]);

  /**
   * Pusherメッセージ受信時
   */
  const onPusherMessage = (updateData: ChatMessageUpdateData) => {
    switch (updateData.type) {
      case 'create':
        onPusherCreateMessage(updateData.data, updateData.lastMessageId);
        break;
      case 'update':
        onPusherUpdateMessage(updateData.data);
        break;
      case 'delete':
        onPusherDeleteMessage(updateData.data);
        break;
    }
  }
  /**
   * Pusherメッセージ受信時（新規作成）
   * @param message
   * @param lastMessageId
   */
  const onPusherCreateMessage = (message: ChatMessage, lastMessageId: number | null) => {
    // コールバックからstateを参照できないので、setStateを使う
    setState(prevState => {

      // すでに同じIDのメッセージがある場合は追加しない
      const index = prevState.messages.findIndex(m => m.id === message.id);
      if (index >= 0) {
        return prevState; // 追加するメッセージがすでにある
      }
      // 最終メッセージがまだ読み込まれていない場合は、追加しない
      if ( lastMessageId != null && prevState.messages.findIndex(m => m.id === lastMessageId) < 0 ) {
        return prevState;
      }

      return {
        ...prevState,
        messages: prevState.messages.concat([message]),
        addEvent: {
          prevMessageId: lastMessageId ?? 0
        }
      };
    });
  }
  /**
   * Pusherメッセージ受信時（更新）
   * @param message
   */
  const onPusherUpdateMessage = (message: ChatMessage) => {
    // コールバックからstateを参照できないので、setStateを使う
    setState(prevState => {
      const index = prevState.messages.findIndex(m => m.id === message.id);
      if (index < 0) {
        return prevState; // 更新するメッセージが見つからなければ、現在の状態をそのまま返す
      }
      // 更新するメッセージが見つかった場合、そのメッセージのみを更新して新しい配列を作成
      const newMessages = prevState.messages.map((m, i) =>
        i === index ? message : m
      );
      return {
        ...prevState,
        messages: newMessages
      };
    });
  };

  /**
   * Pusherメッセージ受信時（削除）
   * @param message
   */
  const onPusherDeleteMessage = (message: ChatMessage) => {
    // コールバックからstateを参照できないので、setStateを使う
    setState(prevState => {
      return {
        ...prevState,
        messages: prevState.messages.filter(m => m.id !== message.id),
      };
    });
  };


  /**
   * メッセージ読み込み
   */
  const loadMessages = async (chatPageParam: ChatPageParam) => {
    try {
      updateState({processing: true});

      // メッセージ取得(過去)
      let messages = await ChatService.getInstance().getMessagesByStockCode(
        chatPageParam.stockCode ?? '',
        chatPageParam.messageId,
        'up',
        LOAD_MESSAGES);

      // メッセージIDが指定されている場合
      if ( chatPageParam.messageId != null ) {
        // メッセージ取得(未来)
        const futureMessages = await ChatService.getInstance().getMessagesByStockCode(
          chatPageParam.stockCode ?? '',
          chatPageParam.messageId,
          'down',
          LOAD_MESSAGES);

        // 先頭のメッセージは重複するので削除
        futureMessages.shift();

        // メッセージを結合
        messages = messages.concat(futureMessages);
      }

      // // この時点での最終メッセージID
      // const [firstMessageId, lastMessageId]
      //     = await ChatService.getInstance().getFirstLastMessagesIdByStockCode(chatPageParam.stockCode ?? '');
      // updateState({
      //   firstMessageId: firstMessageId,
      //   lastMessageId: lastMessageId
      // });

      // 初期表示メッセージ
      let initialDispMessageId = chatPageParam.messageId;
      if ( initialDispMessageId == null ) {
        initialDispMessageId = messages.length > 0 ? messages[messages.length - 1].id : undefined;
      }

      // メッセージをセット
      updateState({
        messages: messages,
        initialDispMessageId
      });


      updateState({processing: false});
    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('読み込み処理でエラーが発生しました。');
    }
    finally {
      updateState({processing: false});
    }
  }

  /**
   * メッセージ送信
   * @param message
   */
  const handleSendMessage = async (message: string) => {
    try {
      //updateState({processing: true});

      //-------------
      // 新規
      //-------------
      if ( state.editingMessageId == null ) {
        // 送信データ作成
        const chatMessage = new ChatMessage({
          stock_code: props.chatPageParam.stockCode,
          user_id: LoginService.getInstance().loginUser?.id,
          sent_date_time: new Date(),
          message: message,
        });

        // メッセージ送信
        await ChatService.getInstance().storeMessage(chatMessage);
      }
      //-------------
      // 編集
      // -------------
      else {
        // メッセージ送信
        await ChatService.getInstance().updateMessage(state.editingMessageId, {
          message: message,
        });
        updateState({editingMessageId: undefined});
      }

      //updateState({processing: false});
    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('送信処理でエラーが発生しました。');
    }
    finally {
      //updateState({processing: false});
    }
  };

  /**
   * 追加読み込み
   * @param direction
   */
  const onLoadMore = async (direction: 'up' | 'down') => {
    const now = Date.now();
    if (state.additionalLoading && now - state.additionalLoading < LOAD_INTERVAL) {
      //LogUtils.d('additional loading skip');
      return;  // 最後の読み込みから一定時間が経過していない場合は処理を中断
    }
    if ( state.messages.length === 0 ) {
      return;
    }

    // if ( direction === 'up' && state.firstMessageLoaded ) {
    //   return;
    // }
    // if ( direction === 'down' && state.lastMessageLoaded ) {
    //   return;
    // }

    try {

      updateState({additionalLoading: now});

      const messageId = state.messages[direction === 'up' ? 0 : state.messages.length - 1].id;
      const messages = await ChatService.getInstance().getMessagesByStockCode(
        props.chatPageParam.stockCode ?? '',
        messageId,
        direction,
        LOAD_MESSAGES);

      if ( messages.length > 1 ) {
        if (direction === 'up') {
          // 末尾のメッセージは重複するので削除
          messages.pop();
          updateState({
            messages: messages.concat(state.messages),
            initialDispMessageId: messages[messages.length - 1].id,
          });
        }
        else {
          // 先頭のメッセージは重複するので削除
          messages.shift();
          updateState({messages: state.messages.concat(messages)});
        }
      }

    }
    catch (e) {
      LogUtils.ex(e);
      showSnackbarMessage('読み込み処理でエラーが発生しました。');
    }

  }

  /**
   * 表示されているメッセージ変更
   * @param visibleMessages
   */
  const onVisibleMessagesChange = async (visibleMessages: number[]) => {
    if (checkingReadRef.current) {
      return;
    }
    try {
      checkingReadRef.current = true;

      // ログインID
      const userId = LoginService.getInstance().loginUser?.id ?? 0;
      // 表示メッセージオブジェクトのうち、自分以外のメッセージを取得
      const messages = state.messages.filter(
        m => visibleMessages.includes(m.id ?? 0) && m.user_id !== userId);
      // 既読済みでないメッセージを取得
      const unreadMessages = messages.filter(m => !m.alreadyRead());
      // メッセージをループ
      for (const message of unreadMessages) {
        // 既読にする
        await ChatService.getInstance().updateMessageRead(message.id ?? 0); // これでメンションも既読になる

        // //------------------------------------------------------------
        // // Pusherで更新するのはコストが高いのでここでメモリ上もreadにしちゃう
        // //------------------------------------------------------------
        // // 既読
        // message.reads?.push(new ChatRead({
        //   chat_message_id: message.id,
        //   user_id: userId,
        // }));
        // // 自分へのメンション
        // const myMentions = message.mentions?.filter(m => m.user_id === userId) ?? [];
        // for (const mention of myMentions) {
        //   mention.read = 1;
        // }
      }
    }
    catch (e) {
      LogUtils.ex(e);
    }
    finally {
      checkingReadRef.current = false;
    }
  }

  /**
   * 描画
   */
  return (
    <>
      <Box
        flexGrow={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: props.height ?? 'calc(100vh - 64px)', // 64px is the height of the AppBar
          overflow: 'hidden'
        }}
      >
        {/* メッセージリスト */}
        <ChatMessagesList
          stockCode={props.chatPageParam.stockCode ?? ''}
          messages={state.messages}
          initialDispMessageId={state.initialDispMessageId}
          onLoadMore={onLoadMore}
          addEvent={state.addEvent}
          editingMessageId={state.editingMessageId}
          onVisibleMessagesChange={onVisibleMessagesChange}
          bubbleWidth={props.bubbleWidth}
          onReloadPage={props.onReloadPage}
          chatResetTimeStamp={props.chatPageParam.chatResetTimeStamp}
        />

        {/* 入力欄 */}
        <Box pb={1}>
          <ChatInputForm
            onSendMessage={handleSendMessage}
            editMode={state.editingMessageId != null}
            onEditCancel={() => updateState({ editingMessageId: undefined })}
          />
        </Box>
      </Box>
      {/* プログレス表示 */}
      <ECProgress open={state.processing}></ECProgress>
    </>
  );
}
