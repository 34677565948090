/**
 * 特別日
 */
export class SpecialDate {
  id?: number;
  start_date?: Date;
  end_date?: Date;
  special_type?: number;
  note?: string;
  created_at?: Date;
  updated_at?: Date;

  constructor(params: Partial<SpecialDate>) {
    Object.assign(this, params);
  }

  copyWith(params: Partial<SpecialDate>): SpecialDate {
    return new SpecialDate({
      ...this,
      ...params
    });
  }

  static fromMap(json: { [key: string]: any }): SpecialDate {
    return new SpecialDate({
      ...json,
      start_date: json.start_date ? new Date(json.start_date) : undefined,
      end_date: json.end_date ? new Date(json.end_date) : undefined,
      created_at: json.created_at ? new Date(json.created_at) : undefined,
      updated_at: json.updated_at ? new Date(json.updated_at) : undefined,
    });
  }

  static fromJson(jsonString: string): SpecialDate {
    const decoded = JSON.parse(jsonString);
    return SpecialDate.fromMap(decoded);
  }

  toMap(): { [key: string]: any } {
    return {
      ...this,
      start_date: this.start_date ? this.start_date.toISOString() : undefined,
      end_date: this.end_date ? this.end_date.toISOString() : undefined,
      created_at: this.created_at ? this.created_at.toISOString() : undefined,
      updated_at: this.updated_at ? this.updated_at.toISOString() : undefined,
    };
  }

  toJson(): string {
    return JSON.stringify(this.toMap());
  }
}
