import {
  FormControl,
  FormHelperText, SxProps, Theme,
} from "@mui/material";
import {CommonUtils} from "../../../Common/CommonUtils";
import {DatePicker} from "@mui/x-date-pickers";
import {Constants} from "../../../Common/Constants";

interface EMDatePickerProps {
  htmlId?: string;
  error?: string;
  label: string;
  value: Date | undefined;
  onChange?: (value: Date | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  readonly ?: boolean;
  sx?: SxProps<Theme>;
  yearMonth?: boolean;
}

/**
 * 日付選択
 *
 * @constructor
 */
export const EMDatePicker = (props: EMDatePickerProps) => {
  /**
   * 描画
   */
  return (
    <FormControl error={!CommonUtils.isEmpty(props.error)} sx={props.sx}>
      <DatePicker
        views={props.yearMonth ? ['year', 'month'] : ['day']}
        label={props.label}
        value={props.value ?? null}
        format={props.yearMonth ? Constants.YEAR_MONTH_FORMAT : Constants.DATE_FORMAT}
        onChange={(value) => {
          if ( props.onChange ) {
            props.onChange(value ?? undefined);
          }
        }}
        slotProps={{
          textField: {
            id: props.htmlId,
            variant: 'outlined',
            size: 'small',
            error: !CommonUtils.isEmpty(props.error),
            onBlur: props.onBlur,
          }
        }}
        disabled={props.disabled}
        readOnly={props.readonly}
      />
      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  );
}
